import { Injectable } from '@angular/core';
import { FormsRouter, SubmitContactFormData,  } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { SessionsService } from '../sessions/sessions.service';
import { FormsService } from './forms.service';

@Injectable()
export class FormsServiceApi implements FormsService {
  private client: CreateTRPCProxyClient<FormsRouter>;

  constructor(
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<FormsRouter>(getTRPCClientOptions(`${environment.apiUrl}/contactForms`, () => this.sessionsService.getAccessToken()));
  }

  submitContact(data: SubmitContactFormData): Promise<string> {
    return this.client.submitContact.mutate(data).then(({ id }) => id);
  }
}