import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { QRCodeModule } from 'angularx-qrcode';
import { AccordionModule } from 'primeng/accordion';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { HomeAssistancePlanDetailsSidebarComponent } from '../../../../components/home-assistance-plan-details-sidebar/home-assistance-plan-details-sidebar.component';
import { HomeAssistancePurchaseDetailsComponent } from '../../../../components/home-assistance-purchase-details/home-assistance-purchase-details.component';
import { MovingPurchaseDetailsComponent } from '../../../../components/moving-purchase-details/moving-purchase-details.component';
import { RatingComponent } from '../../../../components/rating/rating.component';
import { ReminderComponent } from '../../../../components/reminder/reminder.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { SelectAssistancePlanSidebarComponent } from '../../../../components/select-assistance-plan-sidebar/select-assistance-plan-sidebar.component';
import { DataKey, DataStorageService } from '../../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { HomeAssistanceOrderDetail, HomeAssistanceOrdersService } from '../../../../services/home-assistance-orders/home-assistance-orders.service';
import { LocalUser } from '../../../../services/users/users.service';
import { getComplementaryData } from '../../../../utils/home-assistances.utils';

@Component({
  selector: 'app-home-assistance-status',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CommonModule,
    HomeAssistancePlanDetailsSidebarComponent,
    HomeAssistancePurchaseDetailsComponent,
    MovingPurchaseDetailsComponent,
    QRCodeModule,
    RatingComponent,
    ReminderComponent,
    RetryableSectionComponent,
    RouterModule,
    SelectAssistancePlanSidebarComponent,
    TranslocoPipe,
  ],
  templateUrl: './home-assistance-status.page.html',
  styleUrl: './home-assistance-status.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomeAssistanceStatusPage implements OnInit, OnDestroy {
  @ViewChild('assistancePlanDetailsSidebar') assistancePlanDetailsSidebar: HomeAssistancePlanDetailsSidebarComponent;
  errorLoading = false;
  homeAssistanceOrderDetail: HomeAssistanceOrderDetail;
  loading = true;
  userEmail = '';
  private orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private homeAssistanceOrdersService: HomeAssistanceOrdersService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      this.goToMyHomeAssistance();
      return;
    }
    this.orderId = orderId;
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getQrInformation(): string {
    const localUser = this.dataStorageService.getLocal<LocalUser>(DataKey.LocalUser);
    if (localUser) {
      this.userEmail = localUser.email;
      return `${window.location.origin}/home-assistance/request-home-assistance?email=${localUser.email}`;
    }
    throw new Error('User not found');
  }

  goBack(): void {
    this.location.back();
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.homeAssistanceOrdersService.get(this.orderId).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (homeAssistanceOrder) => {
        if (!homeAssistanceOrder) {
          this.goToMyHomeAssistance();
          return;
        }
        this.homeAssistanceOrderDetail = {
          ...homeAssistanceOrder,
          ...getComplementaryData(homeAssistanceOrder),
        };
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('HomeAssistanceStatusPage.initialize()', 'get-home-assistance-order', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  openPlanDetails(): void {
    this.assistancePlanDetailsSidebar.open();
  }

  private goToMyHomeAssistance(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/home-assistance']);
  }
}