import { Injectable } from '@angular/core';
import { Account } from '@homein-accounts-server';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AccountsServiceApi } from './accounts.service.api';
import { AccountsServiceMock } from './accounts.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? AccountsServiceMock : AccountsServiceApi,
})
export abstract class AccountsService {
  abstract getAll(): Observable<Account[]>;
}
