import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { filter, firstValueFrom, take } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ReminderComponent } from '../../../components/reminder/reminder.component';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { BankIdentificationNumbersService } from '../../../services/bank-identification-numbers/bank-identification-numbers.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { validateAndNavigate } from '../../../utils/enrollment.utils';
import { isActive } from '../../../utils/forms.utils';

@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputTextModule,
    KeyFilterModule,
    ReactiveFormsModule,
    ReminderComponent,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './bank-validation.page.html',
  styleUrl: './bank-validation.page.scss',
})
export class BankValidationPage implements OnInit {
  @ViewChild('bankCardContainer', { static: true }) bankCardContainer: ElementRef;
  @ViewChild('bankCardContainerDesktop', { static: true }) bankCardContainerDesktop: ElementRef;
  form: FormGroup;
  loading = false;

  constructor(
    private analyticsService: AnalyticsService,
    private bankIdentificationNumbersService: BankIdentificationNumbersService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private sessionsService: SessionsService,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    this.form = new FormGroup({
      bankIdentificationNumber: new FormControl(null, [Validators.required, Validators.pattern(/^\d{6}$/)]),
    });
  }

  ngOnInit(): void {
    this.bankIdentificationNumberFormControl.valueChanges.subscribe(value => {
      this.updateBankCardSVG(value, this.bankCardContainerDesktop);
      this.updateBankCardSVG(value, this.bankCardContainer);
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get bankIdentificationNumberFormControl(): FormControl { return this.form.controls['bankIdentificationNumber'] as FormControl; }

  goToBecomeCustomer(): void {
    window.open('https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html', '_blank', 'noopener');
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  updateBankCardSVG(bankIdNumber: string, bankCardContainer: ElementRef): void {
    const group1Text = bankIdNumber.slice(0, 4);
    const group2Text = bankIdNumber.slice(4, 6);
    const group1Element = bankCardContainer.nativeElement.querySelector('#group1');
    const group2Element = bankCardContainer.nativeElement.querySelector('#group2');
    if (group1Element) {
      group1Element.textContent = group1Text.padEnd(4, '0');
    }
    if (group2Element) {
      group2Element.textContent = group2Text.padEnd(2, '0');
    }
  }

  async validate(): Promise<void> {
    let step = '';
    try {
      this.loading = true;
      step = 'validate';
      await this.bankIdentificationNumbersService.validate(this.form.getRawValue());
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser?.bankValidated), take(1)));
      step = 'validate-and-navigate';
      await validateAndNavigate(user, this.dataStorageService, this.router);
      this.analyticsService.logUserCreationEvent({ step: 'credit-card-validation' });
      this.toastService.showSuccess({ title: 'You created your account', description: 'We welcome you to Mundo Hogar.' });
    } catch (error) {
      const { data: { cause } } = error as { data: { cause: string; } };
      this.errorReportingService.log('BankValidationPage.validate()', step, error);
      if (cause === 'user-blocked') {
        // TODO: Add handler for when user click contact support
        this.toastService.showError({
          title: 'Suspended account',
          description: 'You have reached the limit of attempts to validate your account, to reactivate it, contact us.',
          actionLabel: 'Contact support',
        });
        await this.sessionsService.signOut();
        await this.router.navigateByUrl('/enrollment/sign-in');
        return;
      } else if (cause === 'bank-identification-number-not-found') {
        this.toastService.showError({ title: 'Failed validation', description: 'The 6 digits entered do not correspond to a Santander card.' });
        return;
      }
      this.toastService.showError({ description: 'Unexpected error', title: 'Unexpected error' });
    } finally {
      this.loading = false;
    }
  }
}
