<section class="mortgage-simulation-data-main-container">
  <div class="mortgage-simulation-data-container">
    <h2 class="title">{{ 'Simulation data' | transloco }}</h2>
    <form [formGroup]="form" class="form-container">
      @if (mortgageSimulation && mortgageSimulation.type === 'housing-cost') {
        <div class="input-container">
          <label for="property-value">{{ 'What is the value of the property you want to buy?' | transloco }}</label>
          <p-inputNumber
            ariaLabelledBy="property-value-help"
            id="property-value"
            inputId="property-value"
            formControlName="propertyValue"
            locale="es-MX"
            mode="currency"
            placeholder="$2,000,000.00 MXN"
            currency="MXN"
            suffix=" MXN"
            [minFractionDigits]="2"
            [maxFractionDigits]="2" />
          @if ((propertyValue.touched || propertyValue.dirty) && propertyValue.errors) {
            <small id="property-value-help" class="p-error">
              @if (propertyValue.errors['min']) {
                {{ 'The minimum amount is X' | transloco: { amount: ( propertyValue.errors['min'].min | currency ) } }} MXN
              } @else if (propertyValue.errors['max']) {
                {{ 'The maximum amount is X' | transloco: { amount: ( propertyValue.errors['max'].max | currency ) } }} MXN
              }
            </small>
          }
        </div>
      } @else if (mortgageSimulation && mortgageSimulation.type === 'amount') {
        <div class="input-container">
          <label for="amount-needed">{{ 'How much money do you need?' | transloco }}</label>
          <p-inputNumber
            ariaLabelledBy="amount-needed-help"
            id="amount-needed"
            inputId="amount-needed"
            formControlName="amountNeeded"
            locale="es-MX"
            mode="currency"
            placeholder="$2,000,000.00 MXN"
            currency="MXN"
            suffix=" MXN"
            [minFractionDigits]="2"
            [maxFractionDigits]="2" />
          @if ((amountNeeded.touched || amountNeeded.dirty) && amountNeeded.errors) {
            <small id="amount-needed-help" class="p-error">
              @if (amountNeeded.errors['min']) {
                {{ 'The minimum amount is X' | transloco: { amount: ( amountNeeded.errors['min'].min | currency ) } }} MXN
              } @else if (amountNeeded.errors['max']) {
                {{ 'The maximum amount is X' | transloco: { amount: ( amountNeeded.errors['max'].max | currency ) } }} MXN
              }
            </small>
          }
        </div>
      }
      <div class="input-container">
        <label for="down-mortgage">{{ 'How much do you want to give as down mortgage?' | transloco }}</label>
        <p-inputNumber
          ariaLabelledBy="down-mortgage-help"
          id="down-mortgage"
          inputId="down-mortgage"
          formControlName="downMortgage"
          locale="es-MX"
          mode="currency"
          placeholder="$200,000.00 MXN"
          currency="MXN"
          suffix=" MXN"
          [minFractionDigits]="2"
          [maxFractionDigits]="2" />
      @if ((!downMortgage.touched || downMortgage.dirty) && downMortgage.errors) {
        <small id="down-mortgage-help" class="p-error">
          @if (downMortgage.errors['valueComparison']?.operator === 'gte') {
            {{ 'The minimum down mortgage is X' | transloco: { amount: (downMortgage.errors['valueComparison'].value | currency) } }} MXN
          } @else if (downMortgage.errors['valueComparison']?.operator === 'lte') {
            {{ 'The maximum down mortgage is X' | transloco: { amount: (downMortgage.errors['valueComparison'].value | currency) } }} MXN
          }
        </small>
      }
      </div>
      <div class="payment-terms-container">
        <h3 class="title">{{ 'In what term do you want to pay?' | transloco }}</h3>
        <div class="radio-buttons-container">
          @for (paymentTerm of paymentTerms; track $index) {
            <div class="radio-button-container">
              <p-radioButton formControlName="paymentTerm" id="payment-term" inputId="payment-term" [value]="paymentTerm" />
              <label class="label" for="payment-term">{{ 'X years' | transloco: { quantity: paymentTerm } }}</label>
            </div>
          }
        </div>
      </div>
    </form>
  </div>
  <div class="right-container">
    @if (mortgageSimulation.type === 'amount') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-amount.svg" alt="{{ 'Mortgage simulation by amount you need illustration' | transloco }}" />
    } @else if (mortgageSimulation.type === 'housing-cost') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-housing-cost.svg" alt="{{ 'Mortgage simulation by housing cost illustration' | transloco }}" />
    }
    <div class="continue-button-container animate__fadeIn">
      <app-button label="Continue" styleClass="primary expand" [disabled]="form.invalid" (action)="continue()"></app-button>
    </div>
  </div>
</section> 