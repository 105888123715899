import { Routes } from '@angular/router';
import { enrollmentGuard } from '../../guards/enrollment.guard';

export default [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'prefix'
  },
  {
    path: 'bank-validation',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./bank-validation/bank-validation.page').then(m => m.BankValidationPage)
  },
  {
    path: 'onboarding',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./onboarding/onboarding.page').then(m => m.OnboardingPage)
  },
  {
    path: 'password-reset',
    loadComponent: () => import('./password-reset/password-reset.page').then(m => m.PasswordResetPage)
  },
  {
    path: 'sign-in',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./sign-in/sign-in.page').then(m => m.SignInPage)
  },
  {
    path: 'sign-up',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./sign-up/sign-up.page').then(m => m.SignUpPage)
  },
  {
    path: 'verify-email',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./verify-email/verify-email.page').then(m => m.VerifyEmailPage)
  },
] satisfies Routes;
