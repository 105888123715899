import { Injectable } from '@angular/core';
import { RemoteConfig } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RemoteConfigsServiceApi } from './remote-configs.service.api';
import { RemoteConfigsServiceMock } from './remote-configs.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? RemoteConfigsServiceMock : RemoteConfigsServiceApi
})
export abstract class RemoteConfigsService {
  abstract get(): Observable<RemoteConfig | null>;
}