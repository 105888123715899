import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { filter, firstValueFrom, take } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ReminderComponent } from '../../../components/reminder/reminder.component';
import { EMAIL_PATTERN } from '../../../constants/validation-patterns.constants';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { SessionProvider, SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { validateAndNavigate } from '../../../utils/enrollment.utils';
import { isActive } from '../../../utils/forms.utils';
import { passwordValidator } from '../../../validators/password.validator';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ReminderComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './sign-up.page.html',
  styleUrl: './sign-up.page.scss',
})
export class SignUpPage implements OnInit {
  form: FormGroup;
  private loadingProvider: SessionProvider | null = null;

  constructor(
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private location: Location,
    private metaTagsService: MetaTagsService,
    private router: Router,
    private sessionsService: SessionsService,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      password: new FormControl('', [Validators.required, passwordValidator]),
    });
  }

  // TODO: Remove and access controls from form property
  get email(): FormControl { return this.form.controls['email'] as FormControl; }
  get password(): FormControl { return this.form.controls['password'] as FormControl; }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  isLoading(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider === provider;
  }

  isLoadingOther(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider !== provider;
  }

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    this.analyticsService.logUserCreationEvent({ step: 'start' });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void  {
    this.location.back();
  }

  openBecomeClient(): void {
    window.open('https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html', '_blank', 'noopener');
  }

  async signUp(provider: SessionProvider): Promise<void> {
    let step = '';
    try {
      this.loadingProvider = provider;
      step = 'sign-up-with-provider';
      if (provider === 'email') {
        await this.sessionsService.signUp({ provider: 'email', data: { email: this.email.value, password: this.password.value } });
      } else {
        await this.sessionsService.signIn({ provider });
      }
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser), take(1)));
      step = 'validate-and-navigate';
      await validateAndNavigate(user, this.dataStorageService, this.router);
      this.analyticsService.logSignUpEvent({ method: provider });
      this.analyticsService.logUserCreationEvent({ step: 'user-credentials' });
    } catch (error) {
      const { code } = error as { code: string; message: string; };
      if (code === 'email-already-in-use') {
        this.toastService.showError({ title: 'Error', description: 'The email is already in use.' });
      } else {
        this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
      }
      this.errorReportingService.log('SignUpPage.signUp()', step, error);
    } finally {
      this.loadingProvider = null;
    }
  }
}
