import { Injectable } from '@angular/core';
import { MortgageSimulationCreateData, MortgageSimulationsRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { MortgageSimulationsService } from './mortgage-simulations.service';

const COLLECTION_PATH = 'mortgageSimulations';

@Injectable()
export class MortgageSimulationsServiceApi implements MortgageSimulationsService {
  private client: CreateTRPCProxyClient<MortgageSimulationsRouter>;

  constructor() {
    this.client = createTRPCProxyClient<MortgageSimulationsRouter>(getTRPCClientOptions(`${environment.apiUrl}/${COLLECTION_PATH}`));
  }

  create(data: MortgageSimulationCreateData): Promise<void> {
    return this.client.create.mutate(data).then();
  }
}