import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { SliderModule } from 'primeng/slider';
import { ButtonComponent } from '../../../components/button/button.component';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { LocalMortgageSimulation, MortgageSimulatorStep } from '../../../models/mortgage-simulation.model';
import { PlatformService } from '../../../services/platform/platform.service';
import { normalizeFloat } from '../../../utils/numbers.utils';

@Component({
  selector: 'app-remodeling-amount',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    FormsModule,
    RouterModule,
    SliderModule,
    TranslocoModule,
  ],
  templateUrl: './remodeling-amount.page.html',
  styleUrl: './remodeling-amount.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RemodelingAmountPage implements OnInit {
  maxRemodelingExtraAmount = 0;
  mortgageSimulation: LocalMortgageSimulation;
  selectedRemodelingExtraAmount = 0;

  constructor(
    private dataStorageService: DataStorageService,
    private platformService: PlatformService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    const currentMortgageSimulation = this.dataStorageService.getLocal<LocalMortgageSimulation>(DataKey.MortgageSimulation);
    const prerequisitesMet = this.stepPrerequisitesMet(currentMortgageSimulation);
    if (!prerequisitesMet) {
      return;
    }
    this.mortgageSimulation = currentMortgageSimulation as LocalMortgageSimulation;
    this.setMaxRemodelingAmount();
    this.selectedRemodelingExtraAmount = this.mortgageSimulation.remodelingExtraAmount || this.maxRemodelingExtraAmount;
    if (this.selectedRemodelingExtraAmount > this.maxRemodelingExtraAmount) {
      this.selectedRemodelingExtraAmount = this.maxRemodelingExtraAmount;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  continue(): void {
    this.dataStorageService.setLocal<LocalMortgageSimulation>(DataKey.MortgageSimulation, {
      ...this.mortgageSimulation,
      remodelingExtraAmount: this.selectedRemodelingExtraAmount,
      stepsCompleted: this.mortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.RemodelingAmount) ?
        this.mortgageSimulation.stepsCompleted :
        [...this.mortgageSimulation.stepsCompleted, MortgageSimulatorStep.RemodelingAmount],
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/mortgage-simulator/contact-data']);
  }

  private setMaxRemodelingAmount(): void {
    const fortyPercentOfMaximumCreditAmount = normalizeFloat(this.getMaximumCreditAmount() * 0.4);
    const creditExcess = normalizeFloat(this.getMaximumCreditAmount() - this.getCreditAmount());
    this.maxRemodelingExtraAmount = creditExcess > fortyPercentOfMaximumCreditAmount ? fortyPercentOfMaximumCreditAmount : creditExcess;
  }

  private stepPrerequisitesMet(currentMortgageSimulation: LocalMortgageSimulation | null): boolean {
    if (!currentMortgageSimulation || !currentMortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.SimulationType)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/mortgage-simulator']);
      return false;
    } else if (!currentMortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.SimulationData)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/mortgage-simulator/simulation-data']);
      return false;
    }
    return true;
  }

  private getMaximumCreditAmount(): number {
    return normalizeFloat(this.mortgageSimulation.propertyValue * 0.9);
  }

  private getCreditAmount(): number {
    return this.mortgageSimulation.type === 'housing-cost' ?
      normalizeFloat(this.mortgageSimulation.propertyValue - this.mortgageSimulation.downMortgage) :
      this.mortgageSimulation.amountNeeded;
  }
}
