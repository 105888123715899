<section class="main-why-mundo-hogar">
  <div class="main-container">
    <div class="left-container">
      <h6 class="above-title">{{ 'What is Mundo Hogar?' | transloco }}</h6>
      <div class="title-container">
        <h2 class="title">{{ 'Everything you need for your home, in one place' | transloco }}</h2>
      </div>
      <div class="subtitle-offers">
        <ul class="offers">
          <li class="offer">{{ 'An exclusive portal for Santander customers' | transloco }}</li>
          <li class="offer">{{ 'A complete digital platform' | transloco }}</li>
          <li class="offer">{{ 'Find the financing you need' | transloco }}</li>
        </ul>
      </div>
      <div class="subtitle-container">
        <p class="subtitle">{{ 'If you are already a Banco Santander customer, activate your account and start enjoying your exclusive benefits.' | transloco }}</p>
        <p class="subtitle-mobile">{{ 'If you are already a Santander customer, activate your account for free.' | transloco }}</p>
      </div>
      <div class="button-container">
        <app-button label="Register me" styleClass="primary expand" routerLink="/enrollment/sign-up" />
      </div>
    </div>
  </div>
</section>
<section class="new-customer-section">
  <div class="container">
    <p class="customer-benefit">{{ 'If you are not a Santander customer yet,' | transloco }} <a class="link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">{{ 'click here' | transloco }}</a><p>
  </div>
</section>
<section class="discover-section">
  <div class="container">
    <div class="left-container">
      <div class="title-container">
        <h2 class="title">{{ 'What can you find in Mundo Hogar?' | transloco }}</h2>
        <hr class="underline">
      </div>
      <div class="image-container">
        <img class="image" src="/assets/images/home-assistance-plans/hero-plan-hogar.png" alt="{{ 'Image of what you can find in Mundo Hogar' | transloco }}"/>
      </div>
      <div class="content">
        <p class="text">{{ 'The remodeling your home needs, furniture purchase, moving, storage, and assistance plans to enjoy your home without worries.' | transloco }}</p>
        <p class="text">{{ 'In addition, you will have unlimited access to mortgage and remodeling project simulators, 100% online and free of charge.' | transloco }}</p>
        <p class="text">{{ 'Exclusive financing for Santander clients.' | transloco }}</p>
      </div>
    </div>
    <div class="right-container">
    <!-- TODO: change to final image-->
      <img class="image" src="/assets/images/home-assistance-plans/hero-plan-hogar.png" alt="{{ 'Image of what you can find in Mundo Hogar' | transloco }}"/>
    </div>
  </div>
</section>
<section class="payment-facilities-section">
  <div class="container">
    <div class="items">
      <div class="item">
        <div class="number-container">
          <span class="number">1</span>
        </div>
        <div class="title-container">
          <h4 class="title">{{ 'Mortgage loans' | transloco }}</h4>
        </div>
        <p class="subtitle">{{ 'Traditional options and a loan that allows financing the purchase and remodeling of a home at once.' | transloco }}</p>
      </div>
      <div class="item">
        <div class="number-container">
          <span class="number">2</span>
        </div>
        <div class="title-container">
          <h4 class="title">{{ 'Consumer Loans' | transloco }}</h4>
        </div>
        <p class="subtitle">{{ 'Up to 30% discount on the rate and terms of up to 60 months (exclusive for purchases on the portal).' | transloco }}</p>
      </div>
      <div class="item">
        <div class="number-container">
          <span class="number">3</span>
        </div>
        <div class="title-container">
          <h4 class="title">{{ 'Credit Card Payment' | transloco }}</h4>
        </div>
        <p class="subtitle">{{ 'Up to 36 months interest free on all services and products for your home' | transloco }}</p>
      </div>
    </div>
  </div>
</section>
<section class="why-platform-section">
  <div class="container">
    <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
    <hr class="underline">
    <div class="description-container">
      <p class="description"> {{ 'Mundo Hogar is your perfect ally to transform and personalize your home, with the support and trust of Banco Santander.' | transloco }}</p>
      <p class="description">{{ 'You will have access to benefits such as' | transloco }}:</p>
    </div>
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon icon-call-center"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Comprehensive support' | transloco }}</h4>
          <span class="description">{{ 'Support in every challenge associated with the home.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Unified and digital experience' | transloco }}</h4>
          <span class="description">{{ 'Everything in one place, in a simple and digital way.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-heart"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Trust and transparency' | transloco }}</h4>
          <span class="description">{{ 'Clear and reliable processes.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-payment-dollar"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Offer customization' | transloco }}</h4>
          <span class="description">{{ 'Solutions tailored to your needs.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-hand-financing"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Unique alternatives financing alternatives' | transloco }}</h4>
          <span class="description">{{ 'Exclusive options.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-money-pouch"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Best prices in the market' | transloco }}</h4>
          <span class="description">{{ 'Guarantee of the best offers.' | transloco }}</span>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="how-mundo-hogar-works-section">
  <div class="container">
    <div class="left-container">
      <img class="image" src="assets/images/assistance-benefits.svg" alt="{{ 'Remodeling benefits image' | transloco }}" />
    </div>
    <div class="right-container">
      <div class="title-container">
        <h2 class="title">{{ 'How does Mundo Hogar work?' | transloco }}</h2>
      </div>
      <div class="items-container">
        <ol class="items">
          <div class="item-wrapper"><i class="icon icon-checkmark-in-a-circle"></i><li class="item">{{ 'Activate your account' | transloco }}: <span class="text">{{ 'Sign up for Mundo Hogar using your Apple, Google or email credentials. You will receive a validation email to activate your account.' | transloco }}</span></li></div>
          <div class="item-wrapper"><i class="icon icon-checkmark-in-a-circle"></i><li class="item">{{ 'Confirm your data' | transloco }}: <span class="text">{{ 'Verify your information to ensure we deliver personalized quotes, quotations, offers and benefits.' | transloco }}</span></li></div>
          <div class="item-wrapper"><i class="icon icon-checkmark-in-a-circle"></i><li class="item">{{ 'Identify yourself as a Santander customer' | transloco }}: <span class="text">{{ 'Enter the first 6 digits of any of your Santander cards to access exclusive benefits.' | transloco }}</span></li></div>
        </ol>
      </div>
    </div>
  </div>
  <div class="ready-text">
    <p class="text">{{ 'Now you are ready to buy products or hire services in Mundo Hogar!' | transloco }}</p>
  </div>
</section>
<section class="service-categories-and-suppliers">
  <div class="container">
    <h2 class="title">{{ 'Categories services and associated suppliers to Mundo Hogar' | transloco }}:</h2>
    <hr class="underline">
    <div class="categories-container">
      @for (category of categories; track $index) {
        <p-card class="category-card">
          <ng-template pTemplate="header">
            <img class="card-img" [src]="category.image" [alt]="category.alt | transloco" />
            <h4 class="title-card">{{ category.title | transloco }}</h4>
          </ng-template>
          <ng-template pTemplate="content">
            <ul class="info-section">
              <li class="text">{{ 'Associated company: X' | transloco: { company: category.company } }}</li>
              @if (category.showMore) {
                <li class="text">{{ 'Services' | transloco }}: {{ category.services | transloco }}</li>
                <li class="text">
                  {{ 'Why we trust in X?' | transloco: { shortCompany: category.shortCompany } }}
                  <ul class="sub-info-section">
                    @for (infoSection of category.trustedInfo; track $index) {
                      <div class="sub-info-list">
                        <i class="icon icon-checkmark-in-a-circle"></i><li class="sub-text">{{ infoSection | transloco }}</li>
                      </div>
                    }
                  </ul>
                </li>
              }
            </ul>
          </ng-template>
          <ng-template pTemplate="footer">
            @if (category.showMore) {
              <app-button class="button" [label]="category.buttonLabel" styleClass="secondary" [href]="category.url" />
            }
            <p-accordion class="accordion" expandIcon="pi pi-chevron-down"
              collapseIcon="pi pi-chevron-up" [activeIndex]="category.showMore ? $index : null">
              <p-accordionTab
                class="accordion-tab"
                [header]="(category.showMore ? 'See less' : 'See more') | transloco"
                iconPos="end"
                (click)="toggleShowMore(category)"
              />
            </p-accordion>
          </ng-template>
        </p-card>
      }
    </div>
  </div>
</section>
