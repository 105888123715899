@if (loading) {
  <div class="spinner-container">
    <p-progressSpinner></p-progressSpinner>
  </div>
} @else if (!loading && error) {
  <div class="retry-container">
    <p class="retry-message">{{ errorMessageTranslationItem.key | transloco: errorMessageTranslationItem.params }}</p>
    <app-button label="Retry" styleClass="primary" (click)="retryAction()"></app-button>
  </div>
} @else if (!loading && !error) {
  <ng-container>
    <ng-content></ng-content>
  </ng-container>
}
