import { AdvertisingSpace } from '@homein-hogar-server';

const PROMOTIONS: AdvertisingSpace[] = [
  {
    buttonLabel: 'Cotizar Hipotecario',
    buttonUrl: '/mortgage-simulator',
    createdAt: new Date(),
    description: null,
    id: 'dko7BGm6pMAx3jcNv7Ft',
    image: 'assets/images/home-carousel-image.jpg',
    status: 'active',
    title: 'Incluye en tu hipoteca un monto extra para remodelar tu nuevo hogar.',
    type: 'promotion',
    updatedAt: new Date(),
  },
  {
    buttonLabel: 'Cotizar Hipotecario',
    buttonUrl: '/mortgage-simulator',
    createdAt: new Date(),
    description: null,
    id: '2Rdqi5G0KzagyFBOEL8q',
    image: 'assets/images/home-carousel-image.jpg',
    status: 'active',
    title: 'Incluye en tu hipoteca un monto extra para remodelar tu nuevo hogar.',
    type: 'promotion',
    updatedAt: new Date(),
  },
  {
    buttonLabel: 'Cotizar Hipotecario',
    buttonUrl: '/mortgage-simulator',
    createdAt: new Date(),
    description: null,
    id: 'pXB4QtxzuG4Z3SewdwiD',
    image: 'assets/images/home-carousel-image.jpg',
    status: 'active',
    title: 'Incluye en tu hipoteca un monto extra para remodelar tu nuevo hogar.',
    type: 'promotion',
    updatedAt: new Date(),
  },
  {
    buttonLabel: 'Cotizar Hipotecario',
    buttonUrl: '/mortgage-simulator',
    createdAt: new Date(),
    description: null,
    id: 'X1IkWUjtTj7R8l49rl0K',
    image: 'assets/images/home-carousel-image.jpg',
    status: 'active',
    title: 'Incluye en tu hipoteca un monto extra para remodelar tu nuevo hogar.',
    type: 'promotion',
    updatedAt: new Date(),
  }
];

const EXCLUSIVE_BENEFITS: AdvertisingSpace[] = [
  {
    buttonLabel: 'Simular remodelacion',
    buttonUrl: '/simulador-de-proyectos',
    createdAt: new Date(),
    description: 'Solo con tus tarjetas Santander',
    id: 'e1khywDSZoCvZi3HE8fA',
    image: 'assets/images/exclusive-benefit.jpg',
    title: 'La remodelacion de tu cocina con hasta un 15% de descuento',
    type: 'exclusive-benefit',
    status: 'active',
    updatedAt: new Date(),
  },
  {
    buttonLabel: 'Simular remodelacion',
    buttonUrl: '/simulador-de-proyectos',
    createdAt: new Date(),
    description: 'Solo con tus tarjetas Santander',
    id: 'yTtmbMnw8YP9PWhiyFC8',
    image: 'assets/images/exclusive-benefit.jpg',
    title: 'La remodelacion de tu cocina con hasta un 15% de descuento',
    type: 'exclusive-benefit',
    status: 'active',
    updatedAt: new Date(),
  },
  {
    buttonLabel: 'Simular remodelacion',
    buttonUrl: '/simulador-de-proyectos',
    createdAt: new Date(),
    description: 'Solo con tus tarjetas Santander',
    id: 'iY7duVThVcP8z4r4DW2L',
    image: 'assets/images/exclusive-benefit.jpg',
    title: 'La remodelacion de tu cocina con hasta un 15% de descuento',
    type: 'exclusive-benefit',
    status: 'active',
    updatedAt: new Date(),
  },
  {
    buttonLabel: 'Simular remodelacion',
    buttonUrl: '/simulador-de-proyectos',
    createdAt: new Date(),
    description: 'Solo con tus tarjetas Santander',
    id: 'obibZNdIgIBMLY5iBdDg',
    image: 'assets/images/exclusive-benefit.jpg',
    title: 'La remodelacion de tu cocina con hasta un 15% de descuento',
    type: 'exclusive-benefit',
    status: 'active',
    updatedAt: new Date(),
  }
];

export const ADVERTISING_SPACES_MOCK: AdvertisingSpace[] = [
  ...PROMOTIONS,
  ...EXCLUSIVE_BENEFITS,
];
