import { Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import {
  AnalyticsItem,
  AnalyticsService,
  BasicEventData,
  LoginEventData,
  PurchaseEventData,
  SearchEventData,
  SignUpEventData,
  UserCreationEventData,
} from './analytics.service';

interface ViewCartItem {
  affiliation?: string;
  coupon?: string;
  creative_name?: string;
  creative_slot?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_id: string;
  item_list_id?: string;
  item_list_name?: string;
  item_name: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  promotion_id?: string;
  promotion_name?: string;
  quantity?: number;
}

@Injectable()
export class AnalyticsServiceApi implements AnalyticsService {

  constructor(
    private analytics: Analytics
  ) {}

  logAddToFavoritesEvent(data: BasicEventData): void {
    const { currency, items, value } = data;
    logEvent(this.analytics, 'add_to_wishlist', {
      currency,
      items: this.itemsParser(items),
      value,
    });
  }

  logAddToShoppingCartEvent(data: BasicEventData): void {
    const { currency, items, value } = data;
    logEvent(this.analytics, 'add_to_cart', {
      currency,
      items: this.itemsParser(items),
      value,
    });
  }

  logBeginCheckoutEvent(data: BasicEventData): void {
    const { currency, items, value } = data;
    logEvent(this.analytics, 'begin_checkout', {
      currency,
      items: this.itemsParser(items),
      value,
    });
  }

  logLoginEvent(data: LoginEventData): void {
    logEvent(this.analytics, 'login', data);
  }

  logPurchaseEvent(data: PurchaseEventData): void {
    const { currency, items, value, transactionId } = data;
    logEvent(this.analytics, 'purchase', {
      currency,
      items: this.itemsParser(items),
      transaction_id: transactionId,
      value,
    });
  }

  logRemoveFromShoppingCartEvent(data: BasicEventData): void {
    const { currency, items, value } = data;
    logEvent(this.analytics, 'remove_from_cart', {
      currency,
      items: this.itemsParser(items),
      value,
    });
  }

  logSearchEvent(data: SearchEventData): void {
    logEvent(this.analytics, 'search', { search_term: data.searchTerm });
  }

  logSignUpEvent(data: SignUpEventData): void {
    logEvent(this.analytics, 'sign_up', data);
  }

  logUserCreationEvent(data: UserCreationEventData): void {
    logEvent(this.analytics, 'user_creation', data);
  }

  logViewItemEvent(data: BasicEventData): void {
    const { currency, items, value } = data;
    logEvent(this.analytics, 'view_item', {
      currency,
      items: this.itemsParser(items),
      value,
    });
  }

  logViewShoppingCartEvent(data: BasicEventData): void {
    const { currency, items, value } = data;
    logEvent(this.analytics, 'view_cart', {
      currency,
      items: this.itemsParser(items),
      value,
    });
  }

  private itemsParser(items: AnalyticsItem[]): ViewCartItem[] {
    return items.map(item => ({
      discount: item.discount ?? 0,
      item_id: item.id,
      item_name: item.name,
      item_category: item.category,
      price: item.price,
      quantity: item.quantity,
    }));
  }
}
