<section class="mortgage-simulator-remodeling-amount-main-container">
  <div class="left-container">
    <h3 class="title">{{ 'Extra amount for remodeling' | transloco }}</h3>
    @if (maxRemodelingExtraAmount === 0) {
      <p class="description">{{ 'With the parameters that you entered, there is no space for an extra amount to remodel.' | transloco }}<a class="link" routerLink="/mortgage-simulator/simulation-data">{{ 'Modify data' | transloco }}</a></p>
    } @else {
      <p class="description">{{ 'Select the amount you want to include to remodel your new home.' | transloco }}</p>
    }
    <div class="slider-container">
      <div class="remodeling-amount-container" [class.disabled]="maxRemodelingExtraAmount === 0">{{ selectedRemodelingExtraAmount | currencyFormatter }}<span class="currency">MXN</span></div>
      <p-slider styleClass="slider" [disabled]="maxRemodelingExtraAmount === 0" [max]="maxRemodelingExtraAmount" [(ngModel)]="selectedRemodelingExtraAmount" />
    </div>
    <div class="account-message-container">
      <i class="icon icon-remodeling-hat"></i>
      <span class="text">{{ 'This amount will be reflected in your Mundo Hogar balance exclusively for remodeling.' | transloco }}</span>
    </div>
  </div>
  <div class="right-container">
    @if (mortgageSimulation.type === 'amount') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-amount.svg" alt="{{ 'Mortgage simulation by amount you need illustration' | transloco }}" />
    } @else if (mortgageSimulation.type === 'housing-cost') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-housing-cost.svg" alt="{{ 'Mortgage simulation by housing cost illustration' | transloco }}" />
    }
    <div class="continue-button-container animate__fadeIn">
      <app-button label="Continue" styleClass="primary expand" (action)="continue()"></app-button>
    </div>
  </div>
</section>