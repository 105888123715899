import { Injectable } from '@angular/core';
import { FormsRouter, GenerateInvoiceData, SubmitConsumerCreditDiscountData, SubmitContactEcommerceFormData, SubmitContactHomeAssistanceFormData, SubmitContactMovingFormData, SubmitContactPlatformFormData, SubmitContactRemodelingFormData } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { SessionsService } from '../sessions/sessions.service';
import { FormsService } from './forms.service';

@Injectable()
export class FormsServiceApi implements FormsService {
  private client: CreateTRPCProxyClient<FormsRouter>;

  constructor(
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<FormsRouter>(getTRPCClientOptions(`${environment.apiUrl}/forms`, () => this.sessionsService.getAccessToken()));
  }

  generateInvoice(data: GenerateInvoiceData): Promise<void> {
    return this.client.generateInvoice.mutate(data).then();
  }

  submitConsumerCreditDiscount(data: SubmitConsumerCreditDiscountData): Promise<void> {
    return this.client.submitConsumerCreditDiscount.mutate(data).then();
  }

  submitContactEcommerce(data: SubmitContactEcommerceFormData): Promise<void> {
    return this.client.submitContactEcommerce.mutate(data).then();
  }

  submitContactHomeAssistance(data: SubmitContactHomeAssistanceFormData): Promise<void> {
    return this.client.submitContactHomeAssistance.mutate(data).then();
  }

  submitContactMoving(data: SubmitContactMovingFormData): Promise<void> {
    return this.client.submitContactMoving.mutate(data).then();
  }

  submitContactPlatform(data: SubmitContactPlatformFormData): Promise<void> {
    return this.client.submitContactPlatform.mutate(data).then();
  }

  submitContactRemodeling(data: SubmitContactRemodelingFormData): Promise<void> {
    return this.client.submitContactRemodeling.mutate(data).then();
  }
}