import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { HomeAssistancePurchaseDetailsComponent } from '../../../../components/home-assistance-purchase-details/home-assistance-purchase-details.component';

@Component({
  selector: 'app-home-assistance-details',
  standalone: true,
  imports: [
    CommonModule,
    HomeAssistancePurchaseDetailsComponent,
  ],
  templateUrl: './home-assistance-details.page.html',
  styleUrl: './home-assistance-details.page.scss',
})
export class HomeAssistanceDetailsPage {}
