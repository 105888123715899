import { Address } from '@homein-hogar-server';
import { USERS_MOCK } from '../users/users.mock';

const userId = USERS_MOCK[0].id;

export const ADDRESSES_MOCK: Address[] = [
  {
    alias: 'Mi Casa',
    country: 'Mexico',
    reference: null,
    createdAt: new Date(),
    id: '8nnzue8b4jUsxAGMASLJ',
    municipality: 'Nezahualcóyotl',
    postalCode: '57170',
    province: 'Bosques de Aragón',
    state: 'Estado de México',
    street: 'Angola',
    streetInternalNumber: null,
    streetNumber: '76',
    updatedAt: new Date(),
    userId,
  }
];
