import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const equalValuesValidator = (matchingControlName: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    const matchingControl = control.root.get(matchingControlName)?.value;

    if (controlValue !== matchingControl) {
      return { equalValues: true };
    }

    return null;
  };
};