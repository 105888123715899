import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage)
  },
  {
    path: 'my-home-assistances',
    loadChildren: () => import('./home-assistance/home-assistance.routes')
  },
  {
    path: 'my-movings',
    loadChildren: () => import('./movings/movings.routes')
  },
  {
    path: 'my-remodelings',
    loadChildren: () => import('./remodelings/remodelings.routes')
  },
  {
    path: 'my-purchases',
    loadChildren: () => import('./ecommerce/purchases.routes')
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.routes')
  },
] satisfies Routes;
