import { PostalCodeLocation } from '@homein-hogar-server';

export const POSTAL_CODE_LOCATIONS_MOCK: PostalCodeLocation[] = [
  {
    createdAt: new Date(),
    id: '01000',
    municipalities: ['Álvaro Obregón'],
    settlements: ['San Ángel'],
    state: 'Ciudad de México',
    updatedAt: new Date(),
  }
];