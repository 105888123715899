import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { SearchEngineProduct } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { TranslocoDecimalPipe, TranslocoPercentPipe } from '@ngneat/transloco-locale';
import { RatingModule } from 'primeng/rating';
import { CustomError } from '../../models/custom-error.model';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../services/favorites/favorites.service';
import { ShoppingCartsService } from '../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../services/toast/toast.service';
import { CONFIG } from '../../constants';

type ProductOfferType = 'sale' | 'super-sale';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyFormatterPipe,
    FormsModule,
    RatingModule,
    RouterModule,
    TranslocoDecimalPipe,
    TranslocoPercentPipe,
    TranslocoPipe,
  ],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss'
})
export class ProductCardComponent implements OnInit {
  @Input() isFavorite = false;
  // TODO: add required: true, to innerId
  @Input() innerId = '';
  @Input() product: SearchEngineProduct;
  addingProductToShoppingCart = false;
  errorAddingProductToShoppingCart = false;
  offerType: ProductOfferType | null = null;
  offerTypeTranslationKey = '';
  togglingFavorite = false;

  constructor(
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private injector: Injector,
    private router: Router,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async addToShoppingCart(): Promise<void> {
    if (this.addingProductToShoppingCart) {
      return;
    }
    // TODO: this service will be removed when analytics circular bug is fixed
    const analyticsService = this.injector.get<AnalyticsService>(AnalyticsService);
    let step = '';
    this.addingProductToShoppingCart = true;
    this.errorAddingProductToShoppingCart = false;
    try {
      step = 'add-product-to-shopping-cart';
      await this.shoppingCartsService.addItem({
        quantity: 1,
        resourceId: this.product.id,
        resourceType: 'product',
      });
      analyticsService.logAddToShoppingCartEvent({
        currency: this.product.currency,
        items: [this.product],
        value: this.product.pricing['debit'].price
      });
      this.toastService.showSuccess({
        title: 'Added a product to shopping cart',
        description: {
          key: '"X" has been added to your shopping cart.',
          params: { name: this.product.name }
        },
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('/ecommerce/shopping-cart');
        },
        actionLabel: 'View shopping cart',
      }, 3000);
    } catch (error) {
      this.errorReportingService.log('ProductComponent.addToShoppingCart()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
      this.errorAddingProductToShoppingCart = true;
    } finally {
      setTimeout(() => {
        this.addingProductToShoppingCart = false;
      }, 200);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async toggleFavorites(): Promise<void> {
    if (this.togglingFavorite) {
      return;
    }
    // TODO: this service will be removed when analytics circular bug is fixed
    const analyticsService = this.injector.get<AnalyticsService>(AnalyticsService);
    let step = '';
    this.togglingFavorite = true;
    try {
      if (this.isFavorite) {
        step = 'remove-from-favorites';
        await this.favoritesService.removeItem({
          resourceId: this.product.id,
          resourceType: 'product',
        });
        this.toastService.showSuccess({
          title: 'Removed a favorite',
          description: {
            key: '"X" has been removed from your favorites list.',
            params: { name: this.product.name }
          },
          action: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigateByUrl('/ecommerce/my-favorites');
          },
          actionLabel: 'See list',
        }, 3000);
        return;
      }
      step = 'add-to-favorites';
      await this.favoritesService.addItem({
        resourceId: this.product.id,
        resourceType: 'product',
      });
      analyticsService.logAddToFavoritesEvent({
        currency: this.product.currency,
        items: [this.product],
        value: this.product.pricing['debit'].price,
      });
      this.toastService.showSuccess({
        title: 'Added a favorite',
        description: {
          key: '"X" has been added to your favorites list.',
          params: { name: this.product.name }
        },
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('/ecommerce/my-favorites');
        },
        actionLabel: 'See list',
      }, 3000);
    } catch (error) {
      if (error instanceof CustomError && error.code === 'max-favorites-reached') {
        this.toastService.showWarning({
          title: 'You reached the limit',
          description: 'You reached the limit of favorites, you can remove some to add new ones.',
          action: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigateByUrl('/ecommerce/my-favorites');
          },
          actionLabel: 'Manage Favorites'
        });
        return;
      }
      this.errorReportingService.log('ProductComponent.toggleFavorites()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
    } finally {
      this.togglingFavorite = false;
    }
  }

  private initialize(): void {
    this.setOfferType();
  }

  private setOfferType(): void {
    if (this.product.discountPercentage >= CONFIG.pricing.ecommerce.superSale) {
      this.offerType = 'super-sale';
    } else if (this.product.discountPercentage >= CONFIG.pricing.ecommerce.sale) {
      this.offerType = 'sale';
    }
    if (this.offerType) {
      this.offerTypeTranslationKey = this.offerType === 'sale' ? 'Sale' : 'Super sale';
    }
  }
}
