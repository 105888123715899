import { Inject, Injectable } from '@angular/core';
import { Account } from '@homein-accounts-server';
import { Observable, of, switchMap } from 'rxjs';
import { where } from '@angular/fire/firestore';
import { FirestoreService } from '../../firestore/firestore.service';
import { UsersService } from '../../users/users.service';
import { AccountsService } from './accounts.service';

const COLLECTION_PATH = 'accounts';

@Injectable()
export class AccountsServiceApi implements AccountsService {
  private accounts: Observable<Account[]>;

  constructor(
    @Inject('accountsServerFirestore') private firestore: FirestoreService,
    private usersService: UsersService,
  ) {
    this.accounts = this.usersService.getCurrentUser().pipe(switchMap((user) => {
      if (user) {
        return this.firestore.query<Account>(COLLECTION_PATH, {
          constraints: [
            where('ownerId', '==', user.id),
            where('status', '==', 'active'),
          ]
        });
      }
      return of([]);
    }));
  }

  getAll(): Observable<Account[]> {
    return this.accounts;
  }
}