import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LeadService } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONFIG } from '../../constants';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { RemodelingOrdersService } from '../../services/remodeling-orders/remodeling-orders.service';
import { ToastService } from '../../services/toast/toast.service';
import { ButtonComponent } from '../button/button.component';

interface FormValues {
  budget: number;
  postalCode: string;
  service: LeadService;
}

@Component({
  selector: 'app-remodeling-quote-sidebar',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    InputTextModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SidebarModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './remodeling-quote-sidebar.component.html',
  styleUrl: './remodeling-quote-sidebar.component.scss'
})

export class RemodelingQuoteSidebarComponent implements OnInit, OnDestroy {
  @Input() currency = CONFIG.defaultCurrency;
  @Input() locale = environment.defaultLocale;
  form: FormGroup<{
    budget: FormControl<number | null>;
    postalCode: FormControl<string | null>;
    service: FormControl<string | null>;
  }>;
  isMobile = false;
  isVisible = false;
  remodelingServices = CONFIG.remodeling.types;
  sendingQuote = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private remodelingOrdersService: RemodelingOrdersService,
    private toastService: ToastService,
  ) {
    this.form = new FormGroup({
      budget: new FormControl<number | null>(null, [Validators.required, Validators.min(1)]),
      postalCode: new FormControl<string | null>(null, [Validators.required, Validators.minLength(CONFIG.postalCodeLength), Validators.maxLength(CONFIG.postalCodeLength)]),
      service: new FormControl<string | null>(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  close(): void {
    this.isVisible = false;
    this.form.reset();
  }

  open(): void {
    this.isVisible = true;
    this.sendingQuote = false;
  }

  async sendQuote(): Promise<void> {
    this.sendingQuote = true;
    try {
      if (this.form.invalid) {
        return;
      }
      const values = this.form.getRawValue() as FormValues;
      this.form.disable();
      await this.remodelingOrdersService.create(values);
      this.toastService.showSuccess({ title: 'Your quote request has been sent successfully', description: 'You will be contacted as soon as possible.' });
    } catch (error) {
      this.toastService.showError({ title: 'Error', description: 'An error occurred while submitting your quote.' });
      this.errorReportingService.log('RemodelingQuoteSidebarComponent.sendQuote()', 'create-remodeling-order', error);
    } finally {
      this.sendingQuote = false;
      this.close();
      this.form.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }
}
