import { Injectable } from '@angular/core';
import { ChargeIntent } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { ChargeIntentsServiceApi } from './charge-intents.service.api';
import { ChargeIntentsServiceMock } from './charge-intents.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? ChargeIntentsServiceMock : ChargeIntentsServiceApi
})
export abstract class ChargeIntentsService {
  abstract get(id: string): Promise<ChargeIntent | null>;
}
