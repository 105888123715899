<div class="sign-up-container">
  <section class="sign-up-section">
    <div class="column left">
      <form [formGroup]="form" class="form-container">
        <app-reminder title="Service exclusively for Santander customers" description="If you are not a customer yet" actionLabel="Become a customer here" styleClass="alert" (action)="openBecomeClient()" />
        <div class="title-container">
          <i class="icon-arrow-left icon" (click)="goBack()"></i>
          <h1 class="title">{{ 'Activate Mundo Hogar account' | transloco }}</h1>
        </div>
        <p class="create-account-with">{{ 'Activate it with your favorite accounts' | transloco }}</p>
        <div class="providers-container">
          <app-button icon="google" styleClass="secondary alternative expand" [disabled]="isLoadingOther('google')" [loading]="isLoading('google')" (click)="signUp('google')" />
          <app-button icon="apple" styleClass="secondary alternative expand" [disabled]="isLoadingOther('apple')" [loading]="isLoading('apple')" (click)="signUp('apple')" />
        </div>
        <p class="create-account-with">{{ 'Or activate it with your email and password' | transloco }}</p>
        <div class="input-container">
          <label for="email">{{ 'Email' | transloco }}*</label>
          <input id="email" type="email" [class.active]="isActive(email)" pInputText [formControl]="email" aria-describedby="email-help" email />
          <small id="email-help" [class.hidden]="!email.touched || !email.errors" class="p-error">{{ 'Enter a valid email.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="password">{{ 'Mundo Hogar Password' | transloco }}*</label>
          <p-password id="password" [class.active]="isActive(email)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" [formControl]="password" aria-describedby="password-help"></p-password>
          @if (password.touched || password.dirty) {
            <small class="input-error" [class.p-error]="password.errors?.['minLength']"><i [ngClass]="{'icon-close': password.errors?.['minLength'], 'icon-checkmark': !password.errors?.['minLength']}"></i> {{ 'Minimum X characters' | transloco: { min: 8 } }}</small>
            <small class="input-error" [class.p-error]="password.errors?.['uppercase']"><i [ngClass]="{'icon-close': password.errors?.['uppercase'], 'icon-checkmark': !password.errors?.['uppercase']}"></i> {{ 'At least one capital letter' | transloco }}</small>
            <small class="input-error" [class.p-error]="password.errors?.['specialCharacter']"><i [ngClass]="{'icon-close': password.errors?.['specialCharacter'], 'icon-checkmark': !password.errors?.['specialCharacter']}"></i> {{ 'At least one special character' | transloco }}</small>
          }
        </div>
        <p class="required-fields">{{ 'Required fields*' | transloco }}</p>
        <app-button label="Start" styleClass="primary expand" [disabled]="!form.valid || isLoadingOther('email')" [loading]="isLoading('email')" (action)="signUp('email')" />
        <p class="account-reminder">{{ 'If you have not yet activated your Mundo Hogar account, click' | transloco }} <a class="link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">{{ 'here' | transloco }}</a></p>
      </form>
    </div>
    <div class="column right">
      <div class="text-container">
        <h2 class="title">{{ 'Relax, we got it' | transloco }}</h2>
        <p class="subtitle">{{ 'Everything you need for your home in one place' | transloco }}</p>
      </div>
    </div>
  </section>
</div>
