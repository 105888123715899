import { Injectable } from '@angular/core';
import { PlatformService } from '../platform/platform.service';
import { environment } from '../../../environments/environment';

export enum DataKey {
  Apportions = 'apportions',
  Favorites = 'favorites',
  LocalUser = 'localUser',
  MockAccounts = 'mockAccounts',
  MockAddresses = 'mockAddresses',
  MockChargeIntents = 'mockChargeIntents',
  MockEcommerceOrders = 'mockEcommerceOrders',
  MockFavorites = 'mockFavorites',
  MockIdToken = 'mockIdToken',
  MockIdTokenResult = 'mockIdTokenResult',
  MockMovingOrders = 'mockMovingOrders',
  MockPaymentIntents = 'mockPaymentIntents',
  MockRemodelingOrders = 'mockRemodelingOrders',
  MockShoppingCarts = 'mockShoppingCarts',
  MockUserId = 'mockUserId',
  MockUsers = 'mockUsers',
  MortgageSimulation = 'mortgageSimulation',
  ProjectSimulation = 'projectSimulation',
  SearchHistory = 'searchHistory',
  ShoppingCart = 'shoppingCart',
  TermsOfServiceAcceptance = 'termsOfServiceAcceptance',
}

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  private data: Record<string, unknown> = {};

  constructor(
    private platformService: PlatformService,
  ) {}

  clear(key: DataKey): void {
    delete this.data[key];
    if (this.platformService.isBrowser()) {
      localStorage.removeItem(key);
    }
  }

  clearAll(): void {
    this.data = {};
    if (this.platformService.isBrowser()) {
      if (environment.usingMocks) {
        Object.keys(localStorage).forEach((key) => {
          if (!key.startsWith('mock')) {
            localStorage.removeItem(key);
          }
        });
      } else {
        localStorage.clear();
      }
    }
  }

  get<T>(key: DataKey): T | null {
    if (this.data[key]) {
      return this.data[key] as T;
    }
    return null;
  }

  getLocal<T>(key: DataKey): T | null {
    if (this.platformService.isBrowser()) {
      const rawData = localStorage.getItem(key);
      if (rawData) {
        this.data[key] = JSON.parse(rawData);
        return this.data[key] as T;
      }
    }
    return null;
  }

  set<T>(key: DataKey, data: T): void {
    this.data[key] = data;
  }

  setLocal<T>(key: DataKey, data: T): void {
    if (this.platformService.isBrowser()) {
      this.data[key] = data;
      localStorage.setItem(key, JSON.stringify(data));
    }
  }
}
