import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  standalone: true,
  styleUrls: ['./circular-progress.component.scss'],
  imports: [
    TranslocoPipe,
  ],
})
export class CircularProgressComponent implements OnChanges {
  @Input({ required: true }) currentStep: number;
  @Input({ required: true }) numberSteps: number;
  @Input() stepSubtitle = '';
  @Input() stepTitle = '';
  protected dashArray = '0, 100';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentStep']) {
      this.updateProgress();
    }
  }

  private updateProgress(): void {
    const progress = (this.currentStep / this.numberSteps) * 100;
    this.dashArray = `${progress}, 100`;
  }
}