import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DataKey, DataStorageService } from '../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../services/error-reporting/error-reporting.service';
import { SessionsService } from '../services/sessions/sessions.service';
import { LocalUser, UsersService } from '../services/users/users.service';
import { PlatformService } from '../services/platform/platform.service';

export const enrollmentGuard = async (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Promise<boolean | UrlTree> => {
  const dataStorageService = inject(DataStorageService);
  const errorReportingService = inject(ErrorReportingService);
  const platformService = inject(PlatformService);
  const router = inject(Router);
  const sessionsService = inject(SessionsService);
  const usersService = inject(UsersService);
  let step = '';
  try {
    step = 'check-if-platform-server';
    if (platformService.isServer()) {
      return false;
    }
    step = 'get-session';
    const session = await firstValueFrom(sessionsService.getSession());
    step = 'get-local-user';
    let localUser = dataStorageService.getLocal<LocalUser>(DataKey.LocalUser);
    if (!localUser) {
      const user = await firstValueFrom(usersService.getCurrentUser());
      if (user) {
        localUser = {
          bankValidated: user.bankValidated,
          email: user.email,
          emailValidated: user.emailValidated,
          enrollmentStatus: user.enrollmentStatus,
          fatherLastName: user.fatherLastName,
          id: user.id,
          motherLastName: user.motherLastName,
          name: user.name,
          phoneNumber: user.phoneNumber,
          profileDataCompleted: user.profileDataCompleted,
        };
        dataStorageService.setLocal(DataKey.LocalUser, localUser);
      }
    }
    step = 'resolve-navigation';
    if (session && localUser?.enrollmentStatus === 'done') {
      return router.parseUrl('/private-site');
    }
    let redirectUrl: UrlTree;
    if (!session) {
      if (['/enrollment/sign-in', '/enrollment/sign-up'].includes(routerStateSnapshot.url)) {
        return true;
      } else if(routerStateSnapshot.url.includes('/enrollment/verify-email')) {
        const redirectTo = encodeURIComponent(routerStateSnapshot.url);
        redirectUrl = router.parseUrl(`/enrollment/sign-in?source=verify-email&redirectTo=${redirectTo}`);
      } else {
        redirectUrl = router.parseUrl('/enrollment/sign-in');
      }
    } else if (!localUser?.emailValidated) {
      redirectUrl = router.parseUrl('/enrollment/verify-email');
    } else if (!localUser?.profileDataCompleted) {
      redirectUrl = router.parseUrl('/enrollment/onboarding');
    } else if (!localUser?.bankValidated) {
      redirectUrl = router.parseUrl('/enrollment/bank-validation');
    } else {
      redirectUrl = router.parseUrl('/enrollment/sign-in');
    }
    return routerStateSnapshot.url.split('?')[0] === redirectUrl?.toString() ? true : redirectUrl;
  } catch (error) {
    errorReportingService.log('enrollmentGuard()', step, error);
    return false;
  }
};
