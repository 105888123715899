import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
  ) {}

  getTranslation(lang: string): Observable<Translation> {
    let url = `/assets/i18n/${lang}.json`;
    if (process?.env?.['PRE_RENDER_TRANSLATIONS_PATH']) {
      url = `https://storage.googleapis.com/${process.env['PRE_RENDER_TRANSLATIONS_PATH']}/${lang}.json`;
    }
    return this.http.get<Translation>(url);
  }
}
