<p-sidebar [(visible)]="visible" [position]="isMobile ? 'bottom' : 'right'" showCloseIcon="false" blockScroll="true" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container" [class.hide]="sendingSimulation">
      <h2 class="title">{{ (simulationSent ? 'Simulation sent' : 'Send simulation') | transloco }}</h2>
      <div class="icon-container">
        <i class="icon icon-close" (click)="close()"></i>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    @if (sendingSimulation) {
      <div class="progress-indicator-container">
        <p-progressSpinner />
        <p class="text">{{ 'Processing...' | transloco }}</p>
      </div>
    } @else {
      <div class="content-container">
        <img class="illustration" src="{{ simulationSent ? '/assets/illustrations/success-check.svg' : '/assets/illustrations/whatsapp-logo-floating-on-phone.svg' }}" alt="{{ (simulationSent ? 'Image success check' : 'Image of WhatsApp logo on phone') | transloco }}" />
        <h3 class="title">{{ (simulationSent ? 'We send your simulation!' : 'We will send your simulation')  | transloco }}</h3>
        <p class="description">{{ (simulationSent ? 'Your simulation was sent by WhatsApp to the following phone number.' : 'Your simulation will be sent by WhatsApp to the following phone number:') | transloco }}</p>
        <p class="phone-number">{{ formattedPhoneNumber }}</p>
        <p class="change-number-container">{{ 'I entered it wrong.' | transloco }} <span class="link" (click)="emitChangePhoneNumber()">{{ 'Change number.' | transloco }}</span></p>
      </div>
    }
  </ng-template>
  <ng-template pTemplate="footer">
    @if (!sendingSimulation) {
      @if (!simulationSent) {
        <div class="action-button-container" [class.hide]="sendingSimulation">
          <app-button label="Send simulation" styleClass="primary expand" (action)="send()"></app-button>
        </div>
      } @else {
        <div class="actions-buttons-container">
          <app-button label="Simulate again" styleClass="secondary" (action)="emitSimulateAgain()"></app-button>
          <app-button label="Go to WhatsApp" styleClass="primary" href="{{ 'https://wa.me/' + platformPhoneNumber }}" target="_blank" rel="noopener"></app-button>
        </div>
      }
    }
  </ng-template>
</p-sidebar>