import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Address, PostalCodeLocation } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { CONFIG } from '../../constants';
import { AddressesService } from '../../services/addresses/addresses.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { ShoppingCartsService } from '../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../services/toast/toast.service';
import { isActive } from '../../utils/forms.utils';
import { ButtonComponent } from '../button/button.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';
import { PostalCodeLocationsService } from '../../services/postal-code-locations/postal-code-locations.service';

interface FormValues {
  alias: string;
  municipality: string;
  postalCode: string;
  reference: string | null;
  settlement: string;
  state: string;
  street: string;
  streetInternalNumber: string | null;
  streetNumber: string;
}

@Component({
  selector: 'app-manage-addresses-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    TranslocoPipe,
    ButtonComponent,
    InputTextModule,
    RetryableSectionComponent,
    ProgressSpinnerModule,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './manage-addresses-sidebar.component.html',
  styleUrl: './manage-addresses-sidebar.component.scss'
})
export class ManageAddressesSidebarComponent implements OnInit, OnDestroy {
  @Input({ required: true }) selectedAddressId: string | null;
  addresses: Address[] = [];
  errorLoadingAddresses = false;
  form: FormGroup<{
    alias: FormControl<string | null>;
    municipality: FormControl<string | null>;
    postalCode: FormControl<string | null>;
    reference: FormControl<string | null>;
    settlement: FormControl<string | null>;
    state: FormControl<string | null>;
    street: FormControl<string | null>;
    streetInternalNumber: FormControl<string | null>;
    streetNumber: FormControl<string | null>;
  }>;
  invalidPostalCode = false;
  isFormVisible = false;
  isMobile = false;
  isVisible = false;
  loadingAddresses = false;
  loadingSpinner = false;
  postalCodeLocation: PostalCodeLocation | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private addressesService: AddressesService,
    private errorReportingService: ErrorReportingService,
    private postalCodeLocationsService: PostalCodeLocationsService,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
  ) {
    this.form = new FormGroup({
      alias: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(32)]),
      municipality: new FormControl<string | null>(null, [Validators.required]),
      postalCode: new FormControl<string | null>(null, [Validators.required, Validators.minLength(CONFIG.postalCodeLength), Validators.maxLength(CONFIG.postalCodeLength)]),
      reference: new FormControl<string | null>(null, [Validators.maxLength(128)]),
      settlement: new FormControl<string | null>(null, [Validators.required]),
      state: new FormControl<string | null>(null, [Validators.required]),
      street: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(64)]),
      streetInternalNumber: new FormControl<string | null>(null, [Validators.maxLength(16)]),
      streetNumber: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(16)]),
    });
    this.populatePostalCodeLocation();
    this.initialize();
  }

  ngOnInit(): void {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  close(): void {
    this.isVisible = false;
    this.isFormVisible = false;
    this.loadingSpinner = false;
    this.form.reset();
  }

  async createAddress(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    let step = '';
    this.loadingSpinner = true;
    try {
      const values = this.form.getRawValue() as FormValues;
      step = 'create-address';
      const addressId = await this.addressesService.create({
        ...values,
        province: values.settlement,
        reference: values.reference || null,
        streetInternalNumber: values.streetInternalNumber || null,
      });
      step = 'select-address';
      await this.selectAddress(addressId);
      this.isFormVisible = false;
      this.toastService.showSuccess({
        title: 'You added a new address',
        description: {
          key: '"X" has been added to your address list.',
          params: { name: values.alias }
        },
      }, 4000);
      this.form.reset();
    } catch (error) {
      this.errorReportingService.log('ManageAddressesSidebarComponent.createAddress()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
    } finally {
      this.loadingSpinner = false;
    }
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  formVisible(): void {
    this.isFormVisible = true;
  }

  getAddresses(): void {
    this.errorLoadingAddresses = false;
    this.loadingAddresses = true;
    this.addressesService.getAll().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (addresses) => {
        this.addresses = addresses;
        if (!this.addresses.length) {
          this.isFormVisible = true;
        }
        this.loadingAddresses = false;
      },
      error: (error) => {
        this.errorReportingService.log('ManageAddressesSidebarComponent.getAddresses()', 'get-shopping-cart', error);
        this.errorLoadingAddresses = true;
        this.loadingAddresses = false;
      }
    });
  }

  initialize(): void {
    this.getAddresses();
  }

  open(): void {
    this.isVisible = true;
  }

  async selectAddress(id: string): Promise<void> {
    await this.shoppingCartsService.updateShippingAddress(id);
    this.isVisible = false;
  }

  show(): void {
    if (!this.addresses.length) {
      this.isFormVisible = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }

  private populatePostalCodeLocation(): void {
    this.form.controls.postalCode.valueChanges.pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (value) => {
        this.invalidPostalCode = false;
        this.postalCodeLocation = null;
        this.form.controls.state.reset();
        this.form.controls.municipality.reset();
        this.form.controls.settlement.reset();
        if (!value || value.length < CONFIG.postalCodeLength) {
          return;
        }
        firstValueFrom(this.postalCodeLocationsService.get(value))
          .then((postalCodeLocation) => {
            if (!postalCodeLocation) {
              this.invalidPostalCode = true;
              return;
            }
            this.postalCodeLocation = postalCodeLocation;
            this.form.patchValue({
              state: postalCodeLocation.state,
              ...(this.postalCodeLocation.municipalities.length === 1 && { municipality: this.postalCodeLocation.municipalities[0] }),
              ...(this.postalCodeLocation.settlements.length === 1 && { settlement: this.postalCodeLocation.settlements[0] }),
            });
            this.form.controls.state.disable();
          })
          .catch((error) => {
            this.errorReportingService.log('ManageAddressesSidebarComponent.populatePostalCodeLocation()', 'get-postal-code-location', error);
            this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
          });
      }
    });
  }
}
