import { Injectable } from '@angular/core';
import { AdvertisingSpace } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdvertisingSpacesServiceApi } from './advertising-spaces.service.api';
import { AdvertisingSpacesServiceMock } from './advertising-spaces.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? AdvertisingSpacesServiceMock : AdvertisingSpacesServiceApi
})
export abstract class AdvertisingSpacesService {
  abstract getExclusiveBenefits(): Observable<AdvertisingSpace[]>;
  abstract getPromotions(): Observable<AdvertisingSpace[]>;
}
