<section class="request-home-assistance-section">
  <div class="main-container">
    <h5 class="title">{{ 'Request your home assistance' | transloco }}</h5>
    <img class="image" src="assets/illustrations/request-assistance.svg" alt="{{ 'Image about a guy asking if you need home assistance' | transloco }}" />
    <app-reminder title="Important" class="reminder" styleClass="alert">
      <span class="subtitle">{{ 'This is your identifier' | transloco }}: {{ email ?? 'correo@correo.com' }}</span>
      <p class="description">{{ 'You will be asked for your identifier in order to attend.' | transloco }}</p>
      <!-- TODO: Add real numbers -->
      <p class="description">{{ 'You can also write to us by WhatsApp' | transloco }} <span class="phone-number">+52 56 6224 7375</span>, {{'or call us at' | transloco }} <span class="phone-number">55 9063 3719</span></p>
    </app-reminder>
    <div class="buttons-container">
      <!-- TODO: Add real numbers -->
      <app-button label="Call us" href="tel:5590633719" styleClass="secondary" icon="phone-call" />
      <app-button label="Write us" href="https://wa.me/521234567890" styleClass="secondary" icon="whatsapp" />
    </div>
  </div>
</section>