import { Account } from '@homein-accounts-server';
import { toCapitalizeCase } from './string.utils';

// TODO: change later on with final values
export function getAccountLabelByType(type: string | null): string {
  switch (type) {
  case 'debit':
    return 'Full availability balance';
  case 'mortgage':
    return 'Remodeling balance';
  default:
    return `Hogar ${toCapitalizeCase(type ?? '')} Balance`;
  }
}


export function sortAccounts(accounts: Account[], typesSorted: string[]): Account[] {
  let sortedAccounts: Account[] = [];
  typesSorted.forEach((type) => {
    const accountsFiltered = accounts.filter((value) => value.type === type);
    sortedAccounts = sortedAccounts.concat(accountsFiltered);
  });
  return sortedAccounts;
}