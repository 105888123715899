<section class="mortgage-simulator-container">
  <div class="go-back-container" (click)="goBack()">
    <i class="icon icon-arrow-left"></i>
    <span class="text">{{ goBackText | transloco }}</span>
  </div>
  <span class="buy-and-renew-home-text">{{ 'Buy and renew your home' | transloco }}</span>
  <div class="stepper-container">
    <h2 class="title">{{ 'Simulate your mortgage credit' | transloco }}</h2>
    <p-stepper [activeStep]="activeStep - 1">
      @for (step of steps; track $index) {
        <p-stepperPanel>
          <ng-template pTemplate="header" let-index="index">
            <div class="step-circle" [class.active]="activeStep - 1 >= index">
              <span class="number">{{ index + 1 }}</span>
            </div>
          </ng-template>
          <!-- TODO: Join separator line with step circles -->
          <ng-template pTemplate="separator" let-index="index">
            <div class="separator" [class.active]="index <= (activeStep - 1) - 1"></div>
          </ng-template>
        </p-stepperPanel>
      }
    </p-stepper>
  </div>
  <router-outlet></router-outlet>  
</section>
