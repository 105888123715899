<section class="mortgage-simulator-contact-data-main-container">
  <div class="left-container">
    <h3 class="title">{{ 'Almost ready! Enter your information' | transloco }}</h3>
    <form class="form-container" [formGroup]="form">
      <div class="input-container">
        <label for="full-name">{{ 'Full name' | transloco }}</label>
        <input type="text" id="full-name" pInputText [formControl]="form.controls.fullName" [class.active]="isActive(form.controls.fullName)" maxLength="108" aria-describedby="full-name-help" >
        @if ((form.controls.fullName.touched || form.controls.fullName.dirty) && form.controls.fullName.errors) {
          <small id="full-name-help" class="p-error">{{ 'Enter a valid full name.' | transloco }}</small>
        }
      </div>
      <div class="input-container">
        <label for="email">{{ 'Email' | transloco }}</label>
        <input id="email" type="email" [class.active]="isActive(form.controls.email)" pInputText [formControl]="form.controls.email" aria-describedby="email-help" />
        @if ((form.controls.email.touched || form.controls.email.dirty) && form.controls.email.errors) {
          <small id="email-help" class="p-error">{{ 'Enter a valid email.' | transloco }}</small>
        }
      </div>
      <div class="input-container">
        <label for="phone-number">{{ 'Phone number' | transloco }}</label>
        <p-inputMask id="phone-number" [class.active]="isActive(form.controls.phoneNumber)" [formControl]="form.controls.phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 999?9" placeholder="+(52) 99 9999 9999" slotChar="" aria-describedby="phone-number-help"></p-inputMask>
        @if ((form.controls.phoneNumber.touched || form.controls.phoneNumber.dirty) && form.controls.phoneNumber.errors) {
          <small class="p-error">{{ 'Enter a valid phone number, it must start with \"52\" and then 10 digits.' | transloco }}</small>
        }
      </div>
    </form>
    <div class="reminder-container">
      <app-reminder styleClass="alert" title="Important" description="The simulation will be sent via WhatsApp, it is important to enter a valid phone number to which you have access."></app-reminder>
    </div>
  </div>
  <div class="right-container">
    @if (mortgageSimulation.type === 'amount') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-amount.svg" alt="{{ 'Mortgage simulation by amount you need illustration' | transloco }}" />
    } @else if (mortgageSimulation.type === 'housing-cost') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-housing-cost.svg" alt="{{ 'Mortgage simulation by housing cost illustration' | transloco }}" />
    }
    <div class="action-button-container animate__fadeIn">
      <app-button label="Send simulation" styleClass="primary expand" [disabled]="form.invalid" (action)="openConfirmPhoneNumberSidebar()"></app-button>
    </div>
  </div>
</section>
@if (form.controls.phoneNumber.value) {
  <app-send-simulation-sidebar #sendSimulationSidebar [phoneNumber]="form.controls.phoneNumber.value" [sendingSimulation]="sendingSimulation" [simulationSent]="simulationSent" (sendSimulation)="sendSimulation()" (simulateAgain)="simulateAgain()"></app-send-simulation-sidebar>
}