import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslationService } from '../services/translation/translation.service';
import { CONFIG } from '../constants';

const currencyToLocaleMapping: Record<string, string> = {
  'MXN': 'es-MX',
  'USD': 'es-MX',
};

@Pipe({
  name: 'currencyFormatter',
  standalone: true,
})
export class CurrencyFormatterPipe implements PipeTransform {

  constructor(
    private translationService: TranslationService,
  ) {}

  transform(value: number, currency?: string): string {
    try {
      const locale = currencyToLocaleMapping[currency ?? CONFIG.defaultCurrency];
      if (!locale) {
        throw new Error(`Locale for currency ${currency} not found`);
      }
      return `${this.translationService.localizeNumber(value, 'currency', locale)} ${!currency || currency === CONFIG.defaultCurrency ? '' : currency}`;
    } catch (error) {
      return `${this.translationService.localizeNumber(value, 'currency', environment.defaultLocale)} ${currency}`;
    }
  }
}
