<p-toast position="top-right">
  <ng-template let-message pTemplate="message">
    <div class="toast-content">
      <div class="texts">
        <span class="title">{{ message.data.title }}</span>
        <span class="description">{{ message.data.description }}</span>
      </div>
      @if (message.data.actionLabel) {
        <span class="action-button" (click)="onClick(message.data.action)">{{ message.data.actionLabel }}</span>
      }
    </div>
  </ng-template>
</p-toast>
