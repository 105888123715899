import { Inject, Injectable } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { Address, AddressesRouter, CreateAddressData } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { AddressesService } from './addresses.service';

const COLLECTION_PATH = 'addresses';

@Injectable()
export class AddressesServiceApi implements AddressesService {
  private client: CreateTRPCProxyClient<AddressesRouter>;

  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<AddressesRouter>(getTRPCClientOptions(`${environment.apiUrl}/${COLLECTION_PATH}`, () => this.sessionsService.getAccessToken()));
  }

  create(data: CreateAddressData): Promise<string> {
    return this.client.create.mutate(data).then(({ id }) => id);
  }

  get(id: string): Observable<Address | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.doc<Address>(`${COLLECTION_PATH}/${id}`);
      }
      return of(null);
    }));
  }

  getAll(): Observable<Address[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<Address>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            orderBy('createdAt', 'desc')
          ]
        });
      }
      return of([]);
    }));
  }
}
