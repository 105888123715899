<section class="my-home-assistances-container">
  <div class="title-container">
    <i class="icon-arrow-left" (click)="goBack()"></i>
    <h2 class="title">{{ 'My home assistance plans' | transloco }}
      @if (homeAssistanceOrdersDetails.length) {
        <span class="total-items">({{ homeAssistanceOrdersDetails.length }})</span>
      }
    </h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (!homeAssistanceOrdersDetails.length) {
      <div class="empty-assistances animate__fadeIn">
        <img class="image" src="/assets/illustrations/generic-empty.svg" [alt]="'You don\'t yet request assistances' | transloco" />
        <h5 class="title">{{ 'No active plans yet' | transloco }}</h5>
        <p class="subtitle">{{ 'Start quoting your assistance plan' | transloco }}</p>
        <app-button label="Choose your plan" styleClass="secondary" href="/home-assistance"></app-button>
      </div>
    } @else {
      <div class="orders-container animate__fadeIn">
      @for (homeAssistanceOrderDetail of homeAssistanceOrdersDetails; track $index) {
        <div class="order-container">
          <div class="products-container">
            <a class="product-container" routerLink="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}">
              @if (homeAssistanceOrderDetail.status === 'active') {
                <div class="product-content">
                  <div class="column first">
                    <div class="right-container">
                      <span class="plan-status active">{{ 'Active' | transloco }}</span>
                      <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
                      <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
                      <span class="description">{{ 'Validity' | transloco }}: {{ homeAssistanceOrderDetail.createdAt | date: 'dd/MM/yyyy' }}</span>
                    </div>
                  </div>
                  <div class="column second">
                    <span class="description-status">{{ 'You have your plan active, if you need assistance please contact us.' | transloco }}</span>
                  </div>
                  <div class="column third" (click)="childEventClicked($event)">
                    <app-button label="See detail" styleClass="secondary" href="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}" />
                  </div>
                  <i class="icon-arrow-right"></i>
                </div>
                <div class="active-order-content" (click)="childEventClicked($event)">
                  <div class="qr-wrapper">
                    <qrcode [qrdata]="getQrInformation()" [width]="110" [margin]="0" errorCorrectionLevel="M"></qrcode>
                  </div>
                  <app-reminder title="Scan to call for assistance" class="reminder" styleClass="alert">
                    <span class="subtitle">{{ 'This is your identifier' | transloco }}: {{ userEmail }}</span>
                    <p class="description">{{ 'You will be asked for your identifier in order to attend.' | transloco }}</p>
                    <!-- TODO: Add real numbers-->
                    <p class="description">{{ 'You can also write to us by WhatsApp' | transloco }} <span class="phone-number">+52 12 3456 7890</span>, {{'or call us at' | transloco }} <span class="phone-number">55 9063 3719</span></p>
                  </app-reminder>
                  <app-reminder title="Important" class="reminder-mobile" styleClass="alert">
                    <span class="subtitle">{{ 'This is your identifier' | transloco }}: {{ userEmail }}</span>
                    <p class="description">{{ 'You will be asked for your identifier in order to attend.' | transloco }}</p>
                    <!-- TODO: Add real numbers-->
                    <p class="description">{{ 'You can also write to us by WhatsApp' | transloco }} <span class="phone-number">+52 12 3456 7890</span>, {{'or call us at' | transloco }} <span class="phone-number">55 9063 3719</span></p>
                  </app-reminder>
                </div>
                <div class="buttons-container">
                <!-- TODO: Add real numbers-->
                  <app-button label="Call us" href="tel:5590633719" target="_blank" styleClass="secondary" icon="phone-call" (click)="selfEventClicked($event)" />
                  <app-button label="Write us" href="https://wa.me/+521234567890" target="_blank" styleClass="secondary" icon="whatsapp" (click)="selfEventClicked($event)" />
                </div>
              } @else if (homeAssistanceOrderDetail.status === 'activation-process') {
                <div class="product-content">
                  <div class="column first">
                    <div class="right-container">
                      <span class="plan-status in-process">{{ 'In process of activation' | transloco }}</span>
                      <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
                      <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
                      <span class="description">{{ 'Validity' | transloco }}: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
                      <span class="description-status">{{ 'Your plan is in the process of being activated. For any questions please contact us.' | transloco }}</span>
                    </div>
                  </div>
                  <div class="column second">
                    <span class="description-status">{{ 'Your plan is in the process of being activated. For any questions please contact us.' | transloco }}</span>
                  </div>
                  <div class="column third" (click)="childEventClicked($event)">
                    <app-button label="See detail" styleClass="secondary" href="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}" />
                  </div>
                  <i class="icon-arrow-right"></i>
                </div>
              } @else if (homeAssistanceOrderDetail.status === 'finished') {
                <div class="product-content">
                  <div class="column first">
                    <div class="right-container">
                      <span class="plan-status done">{{ 'Completed' | transloco }}</span>
                      <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
                      <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
                      <span class="description">{{ 'Validity' | transloco }}: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
                      <span class="description-status">{{ 'Your plan is over.' | transloco }}</span>
                      <span class="description-status">{{ 'You will be able to hire it again by clicking on the reactivate button.' | transloco }}</span>
                    </div>
                  </div>
                  <div class="column second">
                    <span class="description-status">{{ 'Your plan is over.' | transloco }}</span>
                    <span class="description-status">{{ 'You will be able to hire it again by clicking on the reactivate button.' | transloco }}</span>
                  </div>
                  <div class="column third" (click)="childEventClicked($event)">
                    <app-button label="Reactivate plan" styleClass="tertiary" [href]="'/home-assistance/' + homeAssistanceOrderDetail.plan.id" [queryParams]="{ action: 'repurchase', duration: homeAssistanceOrderDetail.duration }" />
                    <app-button label="See detail" styleClass="secondary" href="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}" />
                  </div>
                  <i class="icon-arrow-right"></i>
                </div>
              } @else if (homeAssistanceOrderDetail.status === 'voided') {
                <div class="product-content">
                  <div class="column first">
                    <div class="right-container">
                      <span class="plan-status done">{{ 'Voided' | transloco }}</span>
                      <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
                      <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
                      <span class="description">{{ 'Validity' | transloco }}: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
                      <span class="description-status">{{ 'Your plan has been voided.' | transloco }}</span>
                    </div>
                  </div>
                  <div class="column second">
                    <span class="description-status">{{ 'You will be able to hire it again by clicking on the reactivate button.' | transloco }}</span>
                  </div>
                  <div class="column third" (click)="childEventClicked($event)">
                    <app-button label="Reactivate plan" styleClass="tertiary" [href]="'/home-assistance/' + homeAssistanceOrderDetail.plan.id" [queryParams]="{ action: 'repurchase', duration: homeAssistanceOrderDetail.duration }" />
                    <app-button label="See detail" styleClass="secondary" href="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}" />
                  </div>
                  <i class="icon-arrow-right"></i>
                </div>
              }
            </a>
          </div>
        </div>
      }
    </div>
    }
  </app-retryable-section>
</section>