import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CONFIG } from '../constants';
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export function getEstimatedShippingDates(isPriority: boolean): {
  from: Date;
  to: Date;
} {
  const { priority, regular } = CONFIG.shipping;
  const now = new Date();
  if (isPriority) {
    return {
      from: dayjs(now).add(priority.delay, 'day').toDate(),
      to: dayjs(now).add(priority.delay + priority.time, 'day').toDate(),
    };
  }
  return {
    from: dayjs(now).add(regular.delay, 'day').toDate(),
    to: dayjs(now).add(regular.delay + regular.time, 'day').toDate(),
  };
}