import { Injectable } from '@angular/core';
import { SoftTokensServiceApi } from './soft-tokens.service.api';

@Injectable({
  providedIn: 'root',
  useClass: SoftTokensServiceApi
})
export abstract class SoftTokensService {
  abstract enrollSoftToken(email: string, cPublicX: string, cPublicY: string): Promise<{
    id: string;
    sPublicX: string;
    sPublicY: string;
    salt: string;
  }>;
}
