<section class="main-container">
  <div class="assistance-status-container">
    <div class="title-container">
      <i class="icon-arrow-left" (click)="goBack()"></i>
      <h2 class="title">{{ 'Home assistance plan status' | transloco }}</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (homeAssistanceOrderDetail) {
        <div class="assistance-status-info">
          @if (homeAssistanceOrderDetail.status === 'active') {
            <span class="status">{{ 'Active' | transloco }}</span>
            <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
            <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
            <span class="description-date">{{ 'Validity until' | transloco }}: {{ homeAssistanceOrderDetail.createdAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'Your plan is active. If you need assistance, contact us.' | transloco }}</span>
            <a class="plan-link" (click)="openPlanDetails()">{{ 'See plan conditions' | transloco }}</a>
            <div class="active-order-content">
              <div class="qr-wrapper">
                <qrcode [qrdata]="qrInformation" [width]="110" [margin]="0" errorCorrectionLevel="M"></qrcode>
              </div>
              <app-reminder title="Scan to call for assistance" class="reminder" styleClass="alert">
                <span class="subtitle">{{ 'This is your identifier' | transloco }}: {{ userEmail }}</span>
                <p class="description">{{ 'You will be asked for your identifier in order to attend.' | transloco }}</p>
                <p class="description">{{ 'You can also write to us by WhatsApp' | transloco }} <span class="phone-number">+52 56 6224 7375</span>, {{'or call us at' | transloco }} <span class="phone-number">55 9063 3719</span></p>
              </app-reminder>
              <app-reminder title="Important" class="reminder-mobile" styleClass="alert">
                <span class="subtitle">{{ 'This is your identifier' | transloco }}: {{ userEmail }}</span>
                <p class="description">{{ 'You will be asked for your identifier in order to attend.' | transloco }}</p>
                <!-- TODO F&F: Add real numbers -->
                <p class="description">{{ 'You can also write to us by WhatsApp' | transloco }} <span class="phone-number">+52 56 6224 7375</span>, {{'or call us at' | transloco }} <span class="phone-number">55 9063 3719</span></p>
              </app-reminder>
            </div>
            <div class="buttons-container">
              <!-- TODO F&F: Add real numbers -->
              <app-button label="Call us" href="tel:5590633719" target="_blank" styleClass="secondary" icon="phone-call" />
              <app-button label="Write us" href="https://wa.me/521234567890" target="_blank" styleClass="secondary" icon="whatsapp" />
            </div>
          } @else if (homeAssistanceOrderDetail.status === 'activation-process') {
            <span class="status in-process">{{ 'In process of activation' | transloco }}</span>
            <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
            <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
            <span class="description-date">{{ 'Validity until' | transloco }}: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'Your plan is in the process of being activated. For any questions please contact us.' | transloco }}</span>
            <a class="plan-link" (click)="openPlanDetails()">{{ 'See plan conditions' | transloco }}</a>
          } @else if (homeAssistanceOrderDetail.status === 'finished' ) {
            <span class="status done">{{ 'Completed' | transloco }}</span>
            <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
            <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
            <span class="description-date">{{ 'Validity until' | transloco }}: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'Your plan is over. You will be able to hire it again by clicking on the reactivate button.' | transloco }}</span>
            <div class="secondary-button-container">
              <!-- TODO: Add link-->
              <!--<app-button styleClass="secondary expand" label="Reactivate plan" />-->
            </div>
          } @else if (homeAssistanceOrderDetail.status === 'voided' ) {
            <span class="status done">{{ 'Voided' | transloco }}</span>
            <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
            <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
            <span class="description-date">{{ 'Validity until' | transloco }}: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'Your plan has been voided. You can hire it again by clicking on the reactivate button.' | transloco }}</span>
            <div class="secondary-button-container">
              <!-- TODO: Add link-->
              <!--<app-button styleClass="secondary expand" label="Reactivate plan" />-->
            </div>
          }
        </div>
        <div class="help-links-container">
          <h4 class="title">{{ 'Help with this home assistance plan' | transloco }}</h4>
          <a href="https://ayuda.mundohogar.com.mx/" target="_blank" class="link">{{ 'I have a problem with my home assistance plan' | transloco }}</a>
          <a href="https://ayuda.mundohogar.com.mx/" target="_blank" class="link">{{ 'The payment was charged twice to my card' | transloco }}</a>
        </div>
        <div class="home-assistance-details-navigation-container" routerLink="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}/details">
          <span class="label">{{ 'Purchase details' | transloco }}</span>
          <i class="icon-arrow-right"></i>
        </div>
      }
    </app-retryable-section>
  </div>
  @if (homeAssistanceOrderDetail) {
    <div class="home-assistance-purchase-container">
      <app-home-assistance-purchase-details [homeAssistanceOrderDetail]="homeAssistanceOrderDetail" [showBackButton]="false" />
    </div>
  }
</section>
@if (homeAssistanceOrderDetail) {
  <app-home-assistance-plan-details-sidebar #assistancePlanDetailsSidebar [planId]="homeAssistanceOrderDetail.plan.id" [title]="homeAssistanceOrderDetail.plan.name" />
}
