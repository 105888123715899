import { ParamMap, Router } from '@angular/router';
import { User } from '@homein-hogar-server';
import { DataKey, DataStorageService } from '../services/data-storage/data-storage.service';
import { LocalUser } from '../services/users/users.service';

export async function validateAndNavigate(user: User | null, dataStorageService: DataStorageService, router: Router, queryParamMap: ParamMap, customRedirect?: () => Promise<void>): Promise<void> {
  if (!user) {
    throw new Error('Error getting user');
  }
  dataStorageService.set<LocalUser>(DataKey.LocalUser, {
    bankValidated: user.bankValidated,
    email: user.email,
    emailValidated: user.emailValidated,
    enrollmentStatus: user.enrollmentStatus,
    fatherLastName: user.fatherLastName,
    id: user.id,
    motherLastName: user.motherLastName,
    name: user.name,
    phoneNumber: user.phoneNumber,
    profileDataCompleted: user.profileDataCompleted,
  });
  if (!user.emailValidated) {
    let redirectTo = queryParamMap.get('redirectTo');
    redirectTo = redirectTo ? decodeURIComponent(redirectTo) : null;
    if (redirectTo?.startsWith('/enrollment/verify-email')) {
      await router.navigateByUrl(redirectTo);
      return;
    }
    await router.navigateByUrl('/enrollment/verify-email');
    return;
  }
  if (!user.name || !user.fatherLastName || !user.motherLastName || !user.phoneNumber || !user.birthDate) {
    await router.navigateByUrl('/enrollment/onboarding');
    return;
  }
  if (!user.bankValidated) {
    await router.navigateByUrl('/enrollment/bank-validation');
    return;
  }
  if (customRedirect) {
    await customRedirect();
    return;
  }
  await router.navigateByUrl('/private-site');
}
