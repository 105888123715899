import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { StepperModule } from 'primeng/stepper';
import { DataKey, DataStorageService } from '../../services/data-storage/data-storage.service';
import { PlatformService } from '../../services/platform/platform.service';
import { EMPTY_MORTGAGE_SIMULATION, LocalMortgageSimulation, MortgageSimulatorStep } from '../../models/mortgage-simulation.model';

@Component({
  selector: 'app-mortgage-simulator',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterModule,
    StepperModule,
    TranslocoModule,
  ],
  templateUrl: './mortgage-simulator.page.html',
  styleUrl: './mortgage-simulator.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MortgageSimulatorPage implements OnInit {
  activeStep: MortgageSimulatorStep = MortgageSimulatorStep.SimulationType;
  goBackText = '';
  steps = new Array(4).fill(null);

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private location: Location,
    private router: Router,
    private platformService: PlatformService,
  ) {}

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    this.initializeMortgageSimulation();
    this.setActiveStep(this.router.url);
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.setActiveStep(event.url);
        }
      }
    });
    this.goBackText = window.innerWidth >= 1366 ? 'Back' : 'Simulate your mortgage';
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  private initializeMortgageSimulation(): void {
    let currentMortgageSimulation = this.dataStorageService.getLocal<LocalMortgageSimulation>(DataKey.MortgageSimulation) ?? EMPTY_MORTGAGE_SIMULATION;
    const token = this.activatedRoute.snapshot.queryParams['token'];
    if (token) {
      const { email, fullName, phoneNumber } = jwtDecode<{
        email: string;
        fullName: string;
        phoneNumber: string;
      }>(token);
      const phoneNumberFormatted = (phoneNumber && phoneNumber.startsWith('+')) ? phoneNumber.slice(1) : null;
      currentMortgageSimulation = {
        ...currentMortgageSimulation,
        email: email ?? '',
        fullName: fullName ?? '',
        phoneNumber: phoneNumberFormatted ?? '',
      };
    }
    this.dataStorageService.setLocal<LocalMortgageSimulation>(DataKey.MortgageSimulation, {
      ...currentMortgageSimulation
    });
  }

  private setActiveStep(currentUrl: string): void {
    if (currentUrl.includes('simulation-data')) {
      this.activeStep = MortgageSimulatorStep.SimulationData;
    } else if (currentUrl.includes('remodeling-amount')) {
      this.activeStep = MortgageSimulatorStep.RemodelingAmount;
    } else if(currentUrl.includes('contact-data')) {
      this.activeStep = MortgageSimulatorStep.ContactData;
    } else {
      this.activeStep = MortgageSimulatorStep.SimulationType;
    }
  }
}
