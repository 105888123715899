import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { ImageModule } from 'primeng/image';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { UsersService } from '../../services/users/users.service';
import { ButtonComponent } from '../button/button.component';
import { MenuSidebarComponent } from '../menu-sidebar/menu-sidebar.component';
import { ProductCardComponent } from '../product-card/product-card.component';
import { SessionDependantComponent } from '../session-dependant/session-dependant.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ImageModule,
    MenuSidebarComponent,
    OverlayPanelModule,
    ProductCardComponent,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent extends SessionDependantComponent implements OnInit {
  @ViewChild('overlayPanel') overlayPanel: OverlayPanel;
  firstLoadFinished = false;
  isEcommerce = false;
  isEcommerceSearch = false;
  isHome = true;
  isScrolled = false;
  menuSidebarVisible = false;
  user: User | null = null;
  userNameInitials = '';

  constructor(
    private errorReportingService: ErrorReportingService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private usersService: UsersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHome = event.url === '/';
        this.isEcommerce =  event.url.startsWith('/ecommerce');
        this.isEcommerceSearch = event.url.startsWith('/ecommerce/search');
      }
    });
    if (this.platformService.isServer()) {
      return;
    }
    this.initialize();
  }

  override onSignIn(): void {
    this.ngOnInit();
  }

  override onSignOut(): void {
    this.firstLoadFinished = true;
  }

  closedMenuSidebar(): void {
    this.menuSidebarVisible = false;
  }

  goTo(route: string): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate([route]);
    this.overlayPanel.hide();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.platformService.isServer()) {
      return;
    }
    const scrollY = window.scrollY;
    const header = this.elementRef.nativeElement.querySelector('.header-navbar');
    const logo = this.elementRef.nativeElement.querySelector('.logo');
    if (this.isHome) {
      if (scrollY > 50 && !this.isScrolled) {
        this.renderer.addClass(header, 'scrolled');
        this.renderer.removeClass(logo, 'large');
        this.renderer.addClass(logo, 'small');
        this.isScrolled = true;
      } else if (scrollY <= 50 && this.isScrolled) {
        this.renderer.removeClass(header, 'scrolled');
        this.renderer.removeClass(logo, 'small');
        this.renderer.addClass(logo, 'large');
        this.isScrolled = false;
      }
      return;
    }
    if (scrollY > 0 && !this.isScrolled) {
      this.renderer.addClass(header, 'scrolled');
      this.renderer.removeClass(logo, 'large');
      this.renderer.addClass(logo, 'small');
      this.isScrolled = true;
    } else if (scrollY === 0 && this.isScrolled) {
      this.renderer.removeClass(header, 'scrolled');
      this.isScrolled = false;
    } else if (scrollY === 0 && !this.isScrolled) {
      this.renderer.addClass(logo, 'small');
    }
  }

  showMenuSidebar(): void {
    this.menuSidebarVisible = true;
  }

  signOut(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.sessionsService.signOut();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/']);
    this.overlayPanel.hide();
  }

  private initialize(): void {
    this.usersService.getCurrentUser().subscribe({
      next: (user) => {
        this.user = user;
        this.setUserNameInitials();
        this.firstLoadFinished = true;
      },
      error: (error) => {
        this.errorReportingService.log('NavbarComponent.initialize()', 'get-current-user', error);
        this.firstLoadFinished = true;
      }
    });
  }

  private setUserNameInitials(): void {
    this.userNameInitials = `${this.user?.name?.charAt(0).toLocaleUpperCase() ?? ''}${this.user?.fatherLastName?.charAt(0).toLocaleUpperCase() ?? ''}`;
  }
}
