import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsServiceApi } from './analytics.service.api';
import { AnalyticsServiceMock } from './analytics.service.mock';

export interface AnalyticsItem {
  affiliation?: string;
  brand?: string;
  category?: string;
  category2?: string;
  category3?: string;
  category4?: string;
  category5?: string;
  coupon?: string;
  creativeName?: string;
  creativeSlot?: string;
  discount?: number;
  id: string;
  index?: number;
  itemListId?: string;
  itemListName?: string;
  locationId?: string;
  name: string;
  price?: number;
  promotionId?: string;
  promotionName?: string;
  quantity?: number;
  variant?: string;
}

export interface BasicEventData {
  currency: string;
  items: AnalyticsItem[];
  value: number;
}

export interface LoginEventData {
  method: string;
}

export interface SearchEventData {
  searchTerm: string;
}

export interface PurchaseEventData extends BasicEventData {
  transactionId: string;
}

export interface SignUpEventData {
  method: string;
}

export interface UserCreationEventData {
  step: string;
}

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? AnalyticsServiceMock : AnalyticsServiceApi,
})
export abstract class AnalyticsService {
  abstract logAddToFavoritesEvent(data: BasicEventData): void;
  abstract logAddToShoppingCartEvent(data: BasicEventData): void;
  abstract logBeginCheckoutEvent(data: BasicEventData): void;
  abstract logLoginEvent(data: LoginEventData): void;
  abstract logPurchaseEvent(data: PurchaseEventData): void;
  abstract logRemoveFromShoppingCartEvent(data: BasicEventData): void;
  abstract logSearchEvent(data: SearchEventData): void;
  abstract logSignUpEvent(data: SignUpEventData): void;
  abstract logUserCreationEvent(data: UserCreationEventData): void;
  abstract logViewItemEvent(data: BasicEventData): void;
  abstract logViewShoppingCartEvent(data: BasicEventData): void;
}
