import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export function differencesDays(firstDate: Date, secondDate: Date): number {
  return dayjs(firstDate).diff(dayjs(secondDate), 'days');
}