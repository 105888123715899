import { Routes } from '@angular/router';

export default [
  {
    path: 'my-balance',
    loadComponent: () => import('./my-balance/my-balance.page').then(m => m.MyBalancePage),
  },
  {
    path: 'my-personal-data',
    loadComponent: () => import('./my-personal-data/my-personal-data.page').then(m => m.MyPersonalDataPage),
  },
] satisfies Routes;
