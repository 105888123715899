<div class="reminder-container {{ styleClass }}">
  <i class="icon-information-circle"></i>
  <div class="content">
    <h3 class="title">{{ titleTranslationItem.key | transloco: titleTranslationItem.params }}</h3>
    @if (description && descriptionTranslationItem) {
      <p class="description">{{ descriptionTranslationItem.key | transloco: descriptionTranslationItem.params }}</p>
    } @else {
      <ng-container>
        <ng-content></ng-content>
      </ng-container>
    }
    @if (action && actionTranslationItem) {
      <button class="action" (click)="triggerAction()">{{ actionTranslationItem.key | transloco: actionTranslationItem.params }}</button>
    }
  </div>
</div>
