import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { SidebarModule } from 'primeng/sidebar';
import { SearchInputComponent } from '../search-input/search-input.component';

@Component({
  selector: 'app-search-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SearchInputComponent,
    TranslocoPipe,
    SidebarModule,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './search-sidebar.component.html',
  styleUrl: './search-sidebar.component.scss',
})
export class SearchSidebarComponent {
  @Output() changeQuery = new EventEmitter<string | null>();
  @Output() deleteHistoryClick = new EventEmitter<void>();
  @Input() query: string | null = null;
  @Output() searchClick = new EventEmitter<string>();
  @Input() searchHistory: string[] | null = null;
  @ViewChild('searchInput') searchInput: SearchInputComponent;
  isVisible = false;
  constructor() {}

  changeInputQuery(query: string | null): void {
    this.query = query;
    this.changeQuery.emit(this.query);
  }

  close(): void {
    this.changeQuery.emit(this.query);
    this.isVisible = false;
  }

  deleteSearchHistory(): void {
    this.deleteHistoryClick.emit();
  }

  isOpen(): boolean {
    return this.isVisible;
  }

  open(): void {
    this.isVisible = true;
    this.searchInput.focus();
  }

  searchQuery(query?: string): void {
    this.query = query ?? this.query;
    if (this.query) {
      this.searchClick.emit(this.query);
    }
  }
}
