<div class="circular-progress-container">
  <div class="circular-progress">
    <svg viewBox="0 0 36 36">
      <path class="circle-bg"
            d="M18 2.0845
               a 15.9155 15.9155 0 0 1 0 31.831
               a 15.9155 15.9155 0 0 1 0 -31.831" />
      <path class="circle"
            [attr.stroke-dasharray]="dashArray"
            d="M18 2.0845
               a 15.9155 15.9155 0 0 1 0 31.831
               a 15.9155 15.9155 0 0 1 0 -31.831" />
    </svg>
    <div class="circular-progress-text">{{ 'X of Y' | transloco: { x: currentStep, y: numberSteps } }}</div>
  </div>
  <div class="circular-progress-info">
    <div class="stepTitle">{{ stepTitle }}</div>
    <div class="stepSubtitle">{{ stepSubtitle }}</div>
  </div>
</div>