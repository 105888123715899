import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ButtonComponent } from '../../../components/button/button.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { ToastService } from '../../../services/toast/toast.service';
import { LocalUser } from '../../../services/users/users.service';
import { buildQuery } from '../../../utils/url.utils';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    RouterLink,
    SharedModule,
    TranslocoPipe,
    ProgressSpinnerModule,
    TermsBannerComponent,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnDestroy, OnInit {
  loading = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private metaTagsService: MetaTagsService,
    private movingOrdersService: MovingOrdersService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    if (this.router.url.includes('quote')) {
      this.loading = true;
    }
    this.activatedRoute.queryParams.pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (queryParams) => {
        if (queryParams['action'] === 'quote') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.navigateToQuote();
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async navigateToQuote(): Promise<void> {
    let step = '';
    this.loading = true;
    try {
      step = 'get-current-user';
      const user = this.dataStorageService.getLocal<LocalUser>(DataKey.LocalUser);
      const { appUrl: movingAppUrl, reference: ref } = environment.partners.moving;
      if (!user) {
        step = 'navigate-to-sign-in';
        await this.router.navigate(['enrollment/sign-in'], { queryParams: { origin: 'moving', redirectTo: encodeURIComponent(movingAppUrl) } });
        return;
      }
      const { email, fatherLastName, id, name, phoneNumber } = user;
      const queryParams = {
        email,
        external_reference: this.movingOrdersService.createId(),
        ref,
        user_id: id,
        ...(name && { name }),
        ...(fatherLastName && { last_name: fatherLastName }),
        ...(phoneNumber && { cellphone: phoneNumber.substring(3) }), // TODO: this should be truncated based on the phone country code
      };
      window.location.href = `${movingAppUrl}?${buildQuery(queryParams)}`;
    } catch (error) {
      this.errorReportingService.log('HomePage.navigateToQuote()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
      this.loading = false;
    }
  }
}
