import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SearchServiceApi } from './search.service.api';
import { SearchServiceMock } from './search.service.mock';

export const DOCUMENTS_PER_PAGE_DEFAULT = 10;
export const PAGE_DEFAULT = 1;

export type FilterOperator = '=' | '>' | '<' | '>=' | '<=' | 'in';

export interface Paginable<T> {
  data: T[];
  done: boolean;
  getMore: () => Promise<Paginable<T>>;
  size: number;
  totalItems: number;
}

export interface SearchConfig {
  filters?: SearchFilter[];
  order?: {
    direction?: 'asc' | 'desc';
    field: string;
  } | null;
  pagination: {
    documentsPerPage?: number;
    page?: number;
  };
  query?: {
    fields: string[];
    value: string | null;
  };
}

export interface SearchFilter {
  field: string;
  operator: FilterOperator;
  value: number | string | string[];
}

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? SearchServiceMock : SearchServiceApi,
})
export abstract class SearchService {
  abstract get<T>(collection: string, id: string): Promise<T | null>;
  abstract search<T>(collection: string, config?: SearchConfig): Promise<Paginable<T>>;
}
