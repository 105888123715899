import { Injectable } from '@angular/core';
import * as elliptic from 'elliptic';

@Injectable({
  providedIn: 'root'
})
export class EcdhService {
  private ec: elliptic.ec;

  constructor() {
    this.ec = new elliptic.ec('secp256k1');
  }

  generateKeyPair(): elliptic.ec.KeyPair {
    return this.ec.genKeyPair();
  }

  getPublicValues(keyPair: elliptic.ec.KeyPair): {
    x: string;
    y: string;
  } {
    return {
      x: keyPair.getPublic().getX().toString(16),
      y: keyPair.getPublic().getY().toString(16),
    };
  }
}
