import { Injectable } from '@angular/core';
import { ChargeIntent } from '@homein-hogar-server';
import { firstValueFrom } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { ChargeIntentsService } from './charge-intents.service';

@Injectable()
export class ChargeIntentsServiceMock implements ChargeIntentsService {
  constructor(
    private mockService: MockService,
  ) {}

  get(id: string): Promise<ChargeIntent | null> {
    return firstValueFrom(this.mockService.getChargeIntent(id));
  }
}
