<app-complementary-ecommerce-bar />
<section class="home-container">
  <section class="categories-container">
    <h3 class="title">{{ 'Categories' | transloco }}</h3>
    @if (showCategories) {
      <p-carousel
        contentClass="carousel display-navigation"
        [value]="categories"
        [numVisible]="7"
        [numScroll]="1"
        [showIndicators]="false"
        [responsiveOptions]="[
          { breakpoint: '1365px', numVisible: 7.5, numScroll: 1 },
          { breakpoint: '1050px', numVisible: 6.5, numScroll: 1 },
          { breakpoint: '900px', numVisible: 6, numScroll: 1 },
          { breakpoint: '650px', numVisible: 4.5, numScroll: 1 },
          { breakpoint: '500px', numVisible: 3.5, numScroll: 1 },
          { breakpoint: '450px', numVisible: 2.5, numScroll: 1 },
        ]">
        <ng-template pTemplate="previousicon">
          <div class="navigation-container">
            <i class="icon-arrow-left"></i>
          </div>
        </ng-template>
        <ng-template let-category pTemplate="item">
          <a class="item-container" routerLink="/ecommerce/search" [queryParams]="category.queryParams">
            <img class="category-image" src="assets/images/categories/{{category.image}}" alt="{{ 'X image' | transloco: { value: category.label } }}"/>
            <h4 class="label">{{ category.label }}</h4>
          </a>
        </ng-template>
        <ng-template pTemplate="nexticon">
          <div class="navigation-container">
            <i class="icon-arrow-right"></i>
          </div>
        </ng-template>
      </p-carousel>
    }
  </section>
  <section class="ads-container">
    <h3 class="title">{{ 'Renew your living room' | transloco }}</h3>
    <div class="grid">
      <div class="ad primary"></div>
      <div class="ad secondary">
        <h4 class="title">{{ 'Lamps' | transloco }}</h4>
      </div>
      <div class="ad tertiary">
        <h4 class="title">{{ 'Armchairs' | transloco }}</h4>
      </div>
    </div>
  </section>
  <!-- TODO F&F: merge PR 210 -->
  @if (products1 && products2) {
    <section class="offers-container">
      <h3 class="title">{{ 'Recommended for you' | transloco }}</h3>
      <div class="products-container-desktop">
        <!-- TODO: Populate based on offers -->
        @for (product of products1.data.slice(0, 4); track $index) {
          <app-product-card [product]="product" [isFavorite]="!!favorites['product-' + product.id]" />
        }
      </div>
      <div class="products-container-mobile">
        <p-carousel
            [contentClass]="'carousel'"
            [value]="products1.data"
            [numVisible]="4"
            [numScroll]="1"
            [responsiveOptions]="[
              { breakpoint: '1365px', numVisible: 4.5, numScroll: 1 },
              { breakpoint: '900px', numVisible: 3.5, numScroll: 1 },
              { breakpoint: '650px', numVisible: 2.5, numScroll: 1 },
              { breakpoint: '500px', numVisible: 2.1, numScroll: 1 },
            ]"
            [showIndicators]="false"
            [showNavigators]="false">
          <ng-template let-product pTemplate="item">
            <app-product-card [product]="product" [isFavorite]="!!favorites['product-' + product.id]" />
          </ng-template>
        </p-carousel>
      </div>
    </section>
    <section class="benefits-container">
      <h3 class="title">{{ 'Opportunities' | transloco }}</h3>
      <div class="products-container-desktop">
        @for (product of products2.data.slice(0, 4); track $index) {
          <app-product-card [product]="product" [isFavorite]="!!favorites['product-' + product.id]" />
        }
      </div>
      <div class="products-container-mobile">
        <p-carousel
            [contentClass]="'carousel'"
            [value]="products2.data"
            [numVisible]="4"
            [numScroll]="1"
            [responsiveOptions]="[
              { breakpoint: '1365px', numVisible: 4.5, numScroll: 1 },
              { breakpoint: '900px', numVisible: 3.5, numScroll: 1 },
              { breakpoint: '650px', numVisible: 2.5, numScroll: 1 },
              { breakpoint: '500px', numVisible: 2.1, numScroll: 1 },
            ]"
            [showIndicators]="false"
            [showNavigators]="false">
          <ng-template let-product pTemplate="item">
            <app-product-card [product]="product" [isFavorite]="!!favorites['product-' + product.id]" />
          </ng-template>
        </p-carousel>
      </div>
    </section>
  }
  <!--<section class="products-of-interest">
    <app-products-of-interest [filters]="[{ value: ['highlighted'], operator: 'in', field: 'tags' }]" title="Featured products" />
  </section>-->
</section>
