<main class="main-container">
  <div class="clouds">
    <img class="cloud first" src="assets/images/cloud1.svg" [alt]="'cloud icon' | transloco" loading="lazy" />
    <img class="cloud second" src="assets/images/cloud2.svg" [alt]="'cloud icon' | transloco" loading="lazy" />
    <img class="cloud third" src="assets/images/cloud3.svg" [alt]="'cloud icon' | transloco" loading="lazy" />
    <img class="cloud fourth" src="assets/images/cloud2.svg" [alt]="'cloud icon' | transloco" loading="lazy" />
  </div>
  <section class="main">
    <div class="hero-container">
      <h1 class="main-title">{{ 'Connect with your home in one place' | transloco }}</h1>
      <p class="main-subtitle">{{ 'We welcome you to Mundo Hogar' | transloco }}</p>
      <div class="vertical-boxes-container">
        <a routerLink="/moving" class="vertical-business-container">
          <div class="circle-icon-container">
            <i class="icon-truck"></i>
          </div>
          <span class="vertical-name">{{ 'Moving home' | transloco }}</span>
        </a>
        <a routerLink="/remodeling" class="vertical-business-container">
          <div class="circle-icon-container">
            <i class="icon-house"></i>
          </div>
          <span class="vertical-name">{{ 'Remodel my home' | transloco }}</span>
        </a>
        <a routerLink="/warehouse" class="vertical-business-container">
          <div class="circle-icon-container">
            <i class="icon-box"></i>
          </div>
          <span class="vertical-name">{{ 'Request warehousing' | transloco }}</span>
        </a>
        <a routerLink="/ecommerce" class="vertical-business-container">
          <div class="circle-icon-container">
            <i class="icon-supermarketcart"></i>
          </div>
          <span class="vertical-name">{{ 'Home shopping' | transloco }}</span>
        </a>
        <a routerLink="/home-assistance" class="vertical-business-container">
          <div class="circle-icon-container">
            <i class="icon-phone-support"></i>
          </div>
          <span class="vertical-name">{{ 'Home assistance' | transloco }}</span>
        </a>
      </div>
    </div>
  </section>
</main>
<section class="terms-banner">
  <app-terms-banner page="home" />
</section>
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'All in one place' | transloco }}</h4>
          <span class="description">{{ 'Find everything you need for your home, in the same 100% online portal.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'The best financing' | transloco }}</h4>
          <span class="description">{{ 'Pay in months without interest and with the best payment alternatives to suit your needs.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'More tranquility' | transloco }}</h4>
          <span class="description">{{ 'Qualified suppliers, guaranteed services and secure payment methods for you.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Simple and transparent' | transloco }}</h4>
          <span class="description">{{ 'Manage and track your projects online in just a few clicks.' | transloco }}</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <p class="customer-benefit">{{ 'Exclusive benefits for Santander customers. If you are not a customer yet' | transloco }} <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html">{{ 'click here' | transloco }}.</a><p>
    </div>
  </div>
</article>
<app-retryable-section [error]="errorLoadingAdvertisingSpaces" [loading]="loadingAdvertisingSpaces" (retry)="getAdvertisingSpaces()">
  <section class="promotions">
    @if (promotions.length) {
      <p-carousel class="promotions-carousel"
                  [value]="promotions"
                  [numVisible]="1"
                  [numScroll]="1"
                  [circular]="true"
                  [autoplayInterval]="5000"
                  [showIndicators]="true"
                  [showNavigators]="false">
        <ng-template let-item pTemplate="item">
          <div class="carousel-container">
            <div class="carousel-item">
              <div class="text-content">
                <h3 class="title">{{ item.title }}</h3>
                <app-button [label]="item.buttonLabel" [href]="item.buttonUrl" [translate]="false" styleClass="primary negative" />
              </div>
              <div class="image-container">
                <img class="image" [src]="item.image" alt="{{ item.title }}" loading="lazy" />
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    }
  </section>
</app-retryable-section>
<section class="products-of-interest">
  <app-products-of-interest [filters]="[{ value: ['highlighted'], operator: 'in', field: 'tags' }]" title="Featured products" />
</section>
<section class="opportunities">
  <div class="opportunities-container">
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">{{ 'Apply for your card and pay for everything in 12 months interest free' | transloco }}</h4>
        <app-button label="Request" styleClass="secondary" />
      </div>
      <div class="right-card-container">
        <img class="img" src="assets/images/first-opportunity.jpg" alt="opportunity image" loading="lazy" />
      </div>
    </div>
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">{{ 'Simulate that remodeling that you dream of so much' | transloco }}</h4>
        <app-button label="Simulate" styleClass="secondary" />
      </div>
      <div class="right-card-container">
        <img class="img" src="assets/images/second-opportunity.jpg" alt="opportunity image" loading="lazy" />
      </div>
    </div>
  </div>
</section>
<section class="exclusive-benefits-section">
  <div class="exclusive-benefits-container">
    <div class="title-container">
      <h2 class="title">{{ 'Outstanding benefits for you' | transloco }}</h2>
      <p class="subtitle">{{ 'Exclusive offers for Santander customers' | transloco }}</p>
      <hr class="underline">
    </div>
    <app-retryable-section [error]="errorLoadingAdvertisingSpaces" [loading]="loadingAdvertisingSpaces" (retry)="getAdvertisingSpaces()">
      @if (exclusiveBenefits.length) {
        <p-carousel #carousel class="carousel-exclusive-benefits"
          [value]="exclusiveBenefits"
          [numVisible]="3.125"
          [numScroll]="1"
          [autoplayInterval]="5000"
          [showIndicators]="true"
          [showNavigators]="false"
          [responsiveOptions]="[
            { breakpoint: '1365px', numVisible: 4.125, numScroll: 1 },
            { breakpoint: '1250px', numVisible: 3.875, numScroll: 1 },
            { breakpoint: '1150px', numVisible: 3.5, numScroll: 1 },
            { breakpoint: '1050px', numVisible: 3.2, numScroll: 1 },
            { breakpoint: '950px', numVisible: 2.7, numScroll: 1 },
            { breakpoint: '850px', numVisible: 2.5, numScroll: 1 },
            { breakpoint: '750px', numVisible: 2.2, numScroll: 1 },
            { breakpoint: '650px', numVisible: 1.8, numScroll: 1 },
            { breakpoint: '550px', numVisible: 1.5, numScroll: 1 },
            { breakpoint: '450px', numVisible: 1.3, numScroll: 1 },
            { breakpoint: '400px', numVisible: 1.2, numScroll: 1 },
          ]">
          <ng-template let-item pTemplate="item">
            <div class="card" (mouseenter)="stopAutoplay(carousel)" (mouseleave)="startAutoplay(carousel)">
              <div class="image" [ngStyle]="{'background-image': 'url(' + item.image + ')'}">
              </div>
              <div class="card-content">
                <h6 class="title">{{ item.title }}</h6>
                <div class="icon-text">
                  <i class="icon-circle-checkmark"></i>
                  <span class="benefit-text">{{ item.description }}</span>
                </div>
              </div>
              <div class="card-information">
                <hr class="divider">
                <div class="button-container">
                  <app-button [label]="item.buttonLabel" [href]="item.buttonUrl" [translate]="false" styleClass="primary dark" />
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      }
    </app-retryable-section>
  </div>
</section>
<article class="faq-article">
  <h2 class="title">{{ 'FAQ' | transloco }}</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq">
      <p-accordionTab class="accordion-tab" header="{{ 'What is Mundo Hogar?' | transloco }}" iconPos="end">
        <p>
          {{ 'Mundo Hogar is a digital ecosystem that connects home services in one place (one stop shop), including the best financing and delivery of services by certified providers, in a transparent, simple and agile way.' | transloco }}
        </p>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'How does Mundo Hogar work?' | transloco }}" iconPos="end">
        <ol>
          <li>{{ 'Enter to' | transloco }} <a routerLink="/">www.mundohogar.com.mx</a></li>
          <li>{{ 'Create your user and validate your portal account' | transloco }}</li>
          <li>{{ 'Access the services and products that Mundo Hogar offers you' | transloco }}</li>
        </ol>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'Can I use these benefits without being a Santander customer?' | transloco }}" iconPos="end">
        <p>
          {{ 'No, Mundo Hogar benefits are exclusive for Santander customers.' | transloco }}
        </p>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">{{ 'Visit our' | transloco }} <a class="link" href="https://ayuda.homein.mx/es/" target="_blank">{{ 'Help center' | transloco }}</a> {{ 'for more information' | transloco }}</h5>
</article>
