<section class="main">
  <div class="main-container">
    <div class="right-side">
      <div class="title-container">
        <h1 class="title">{{ 'Beat the unexpected at home' | transloco }}</h1>
      </div>
      <div class="button-container">
        <app-button label="Review plans" styleClass="primary" icon="home-assistance" (action)="goToPlans()" />
      </div>
    </div>
    <div class="left-side">
      <img class="main-image" src="assets/images/home-assistance-main.svg" [alt]="'Home assistance image' | transloco" />
    </div>
  </div>
</section>
<section>
  <app-terms-banner page="home-assistance" />
</section>
<section class="assistance-request-section">
  <div class="container">
    <div class="title-container">
      <h2 class="title">{{ 'Book your assistance in a few clicks' | transloco }}</h2>
      <hr class="underline">
    </div>
    <div class="request-container">
      <ul class="requests">
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>{{ 'Choose the ideal plan for you and your home' | transloco }}</li>
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>{{ 'Contract it 100% online and pay as you prefer' | transloco }}</li>
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>{{ 'Relax, you can now request assistance 24/7!' | transloco }}</li>
      </ul>
      <div class="button-container">
        <app-button label="Choose plan" styleClass="secondary expand" (action)="goToPlans()" />
      </div>
    </div>
  </div>
</section>
<section class="exclusive-benefits-section" id="exclusiveBenefitsSection">
  <div class="exclusive-benefits-container">
    <div class="title-container">
      <h2 class="title">{{ 'Which plan will you choose today?' | transloco }}</h2>
      <hr class="underline">
    </div>
    <app-retryable-section [error]="errorLoading" [loading]="loading" (retry)="initialize()">
      <p-carousel class="carousel-exclusive-benefits"
                  [value]="homeAssistancePlans"
                  [numVisible]="3"
                  [numScroll]="1"
                  [autoplayInterval]="0"
                  [showIndicators]="true"
                  [showNavigators]="false"
                  [responsiveOptions]="[
                    { breakpoint: '900px', numVisible: 2.6, numScroll: 1 },
                    { breakpoint: '800px', numVisible: 2.2, numScroll: 1 },
                    { breakpoint: '700px', numVisible: 1.8, numScroll: 1 },
                    { breakpoint: '600px', numVisible: 1.6, numScroll: 1 },
                    { breakpoint: '500px', numVisible: 1.3, numScroll: 1 },
                    { breakpoint: '400px', numVisible: 1.2, numScroll: 1 },
                  ]">
        <ng-template let-plan pTemplate="item">
          <div class="card">
            <div class="image" [ngStyle]="{'background-image': 'url(/assets/images/home-assistance-plans/card-' + plan.id + '.png)'}">
              @if (plan.recommended) {
                <span class="offer-type">{{ 'Best value' | transloco }}</span>
              }
            </div>
            <div class="card-content">
              <h6 class="title">{{ plan.name }}</h6>
              <h5 class="pricing">{{ 'Starting at' | transloco }} {{ plan.pricing.external['1-month']['debit'].price | currencyFormatter: plan.currency }}<span class="currency">{{ plan.currency }} </span><span class="currency">{{ 'Monthly' | transloco }}</span></h5>
              <div class="icon-text">
                <i class="icon-checkmark-in-a-circle"></i>
                <span class="benefit-text">{{ plan.description }}</span>
              </div>
            </div>
            <div class="card-information">
              <hr class="divider">
              <div class="button-container">
                <app-button label="View plan" [href]="'/home-assistance/' + plan.id" styleClass="primary" />
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </app-retryable-section>
  </div>
</section>
<section class="assistance-benefits">
  <div class="container">
    <div class="left-container">
      <img class="image" src="assets/images/assistance-benefits.svg" [alt]="'Assistance benefits image' | transloco" />
    </div>
    <div class="right-container">
      <div class="title-container">
        <h2 class="title">{{ 'Benefits of contracting assistance' | transloco }}</h2>
      </div>
      <div class="benefits-container">
        <ol class="benefits">
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>{{ 'Solutions that provide well-being and peace of mind' | transloco }}</li>
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>{{ 'Anytime, anywhere' | transloco }}</li>
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>{{ 'Protect every space in your home and the ones you love the most' | transloco }}</li>
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>{{ 'Make your life easier by leaving your home in our hands' | transloco }}</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="why-trust-assistance-section">
  <div class="container">
    <div class="left-container">
      <div class="title-container">
        <span class="title">{{ 'Why do we rely on' | transloco }}<img class="ike-logo" [alt]="'Ike logo' | transloco" src="assets/images/ike-logo-final.svg"> ?</span>
        <hr class="underline">
      </div>
    </div>
    <div class="right-container">
      <div class="trust-content">
        <i class="icon icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">{{ '17 years with you' | transloco }}</h4>
          <p class="description">{{ 'We have been with you, your family and your pets for 17 years, offering solutions that give you peace of mind in every unexpected event at home.' | transloco }}</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">{{ '2 million users' | transloco }}</h4>
          <p class="description">{{ 'More than 2 million users confirm our commitment to quality and professionalism.' | transloco }}</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">{{ '26,000 monthly services' | transloco }}</h4>
          <p class="description">{{ 'Every month, more than 26,000 people rely on us to solve their household needs quickly and efficiently, ensuring that your home is always in perfect condition.' | transloco }}</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">{{ '+ of 1,000 providers' | transloco }}</h4>
          <p class="description">{{ 'We have a network of more than 1,000 providers ready to help you at any time, guaranteeing fast and effective assistance at your home.' | transloco }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- TODO: this part will be refactoring as a angular component-->
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'All in one place' | transloco }}</h4>
          <span class="description">{{ 'Find everything you need for your home, in the same 100% online portal.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'The best financing' | transloco }}</h4>
          <span class="description">{{ 'Pay in months without interest and with the best payment alternatives to suit your needs.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'More tranquility' | transloco }}</h4>
          <span class="description">{{ 'Qualified suppliers, guaranteed services and secure payment methods for you.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Simple and transparent' | transloco }}</h4>
          <span class="description">{{ 'Manage and track your projects online in just a few clicks.' | transloco }}</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <!-- TODO: Change later the link -->
      <p class="customer-benefit">{{ 'Exclusive benefits for Santander customers. If you are not a customer yet' | transloco }} <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank">{{ 'click here' | transloco }}</a><p>
    </div>
  </div>
</article>
<article class="faq-article">
  <h2 class="title">{{ 'FAQ' | transloco }}</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
      <p-accordionTab class="accordion-tab" header="{{ 'What is Mundo Hogar assistance?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'They are efficient solutions to any emergency, unforeseen event or inconvenience that may arise in your home or for your pets.' | transloco }}</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'What is a Mundo Hogar assistance plan?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'It is an assistance program that offers coverage for inconveniences or unforeseen events that you may have to solve during the term of your plan. Check the events included in each of the available plans.' | transloco }}</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'What is an event and event limit?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'An event is each time you need to use any of the services included in your assistance plan. Event limits are the number of times you can use a service in the same period.' | transloco }}</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'What services are included in my assistance plan?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'Mundo Hogar has the right plans to protect what matters most to you:' | transloco }} <a class="link" routerLink="/home-assistance/plan-hogar">Plan Hogar, </a><a class="link" routerLink="/home-assistance/plan-mascotas">Plan Mascotas</a>, <a class="link" routerLink="/home-assistance/plan-hogar-mascotas">Plan Hogar + Mascotas</a>.</p>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">{{ 'Visit our' | transloco }} <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">{{ 'Help center' | transloco }}</a> {{ 'for more information' | transloco }}</h5>
</article>