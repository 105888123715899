<section class="main-container">
  <div class="remodeling-status-container">
    <div class="title-container">
      <i class="icon icon-arrow-left" (click)="goBack()"></i>
      <h2 class="title">{{ 'Remodeling status' | transloco }}</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (order) {
        <div class="remodeling-status-info">
          @if (order.status === 'lead-created') {
            <span class="status active">{{ 'Request received' | transloco }}</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">{{ 'We have received your details. We will call you soon to gather more information about your project.' | transloco }}</span>
          } @else if (order.status === 'pending') {
            @if (!order.paid || order.pendingChargeIntentId) {
              <span class="status active">{{ 'All set, pay your budget' | transloco }}</span>
              <span class="name">{{ order.additionalData['opportunityName'] }}</span>
              <span class="status-description">{{ 'Your quote has been sent! Pay it to confirm and schedule the start of the work.' | transloco }}</span>
              <div class="secondary-button-container">
                <app-button styleClass="secondary expand" label="Pay budget" href="/payment-gateway/checkout" [queryParams]="{
                        resourceIds: [order.pendingChargeIntentId],
                        resourceType: 'charge-intent',
                        source: 'remodeling',
                      }" />
              </div>
            } @else if (!order.contractSigned) {
              <span class="status active">{{ 'Now all that is missing is your signature' | transloco }}</span>
              <span class="name">{{ order.additionalData['opportunityName'] }}</span>
              <span class="status-description">{{ 'Your quote is paid for! Sign it to confirm and schedule the start of the work.' | transloco }}</span>
            }
          } @else if (order.status === 'waiting') {
            <span class="status active">{{ 'All set!' | transloco }}</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">{{ 'Your project is scheduled to start on DD/MM/AAAA' | transloco: { date: order.startAt | date: 'dd/MM/yyyy'  } }}</span>
          } @else if (order.status === 'in-progress') {
            @if (!order.pendingChargeIntentId) {
              <span class="status active">{{ 'Project on site' | transloco }}</span>
              <span class="name">{{ order.additionalData['opportunityName'] }}</span>
              <span class="status-description">{{ 'Your project has started on DD/MM/AAAA' | transloco: { date: order.startAt | date: 'dd/MM/yyyy' } }}</span>
            } @else {
              <span class="status pending">{{ 'Pending payment' | transloco }}</span>
              <span class="name">{{ order.additionalData['opportunityName'] }}</span>
              <span class="status-description">{{ 'You have a pending payment' | transloco }}</span>
              <div class="secondary-button-container">
                <app-button styleClass="secondary expand" label="Pay budget" href="/payment-gateway/checkout" [queryParams]="{
                        resourceIds: [order.pendingChargeIntentId],
                        resourceType: 'charge-intent',
                        source: 'remodeling',
                      }" />
              </div>
            }
          } @else if (order.status === 'done') {
            <span class="status">{{ 'Completed project' | transloco }}</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">{{ 'Your project has ended on DD/MM/AAAA' | transloco: { date: order.endAt | date: 'dd/MM/yyyy' } }}</span>
            <span class="status-description">{{ 'Help us improve the Mundo Hogar experience.' | transloco }}</span>
            <!-- TODO: Enable when implementing page to evaluate experience
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Evaluate experience" href="/" />
            </div>
            -->
          } @else if (order.status === 'cancelled') {
            <span class="status pending">{{ 'Project cancelled' | transloco }}</span>
            <span class="name">{{ order.additionalData['opportunityName'] }}</span>
            <span class="status-description">{{ 'We regret to cancel your project.' | transloco }}</span>
            <span class="status-description">{{ 'Please let us know how we can improve the service.' | transloco }}</span>
            <!-- TODO: Enable when implementing page to evaluate experience
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Evaluate experience" href="/" />
            </div>
            -->
          }
        </div>
        <div class="help-links-container">
          <h4 class="title">{{ 'Help with this remodeling' | transloco }}</h4>
          <a href="https://ayuda.homein.mx/es/" target="_blank" class="link">{{ 'I have a problem with my remodeling' | transloco }}</a>
          <a href="https://ayuda.homein.mx/es/" target="_blank" class="link">{{ 'The payment was charged twice to my card' | transloco }}</a>
        </div>
        <div class="remodeling-details-navigation-container" routerLink="/private-site/my-remodelings/{{ order.id }}/details">
          <span class="label">{{ 'Purchase details' | transloco }}</span>
          <i class="icon icon-arrow-right"></i>
        </div>
      }
    </app-retryable-section>
  </div>
  @if (showRemodelingPurchaseDetail) {
    <div class="remodeling-purchase-container">
      <app-remodeling-purchase-details [order]="order" [showBackButton]="false" />
    </div>
  }
</section>