import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { HomeAssistancePlan } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { HomeAssistancePlansService } from '../../services/home-assistance-plans/home-assistance-plans.service';
import { openExternalLink } from '../../utils/link.utils';
import { ButtonComponent } from '../button/button.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-home-assistance-plan-details-sidebar',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    SidebarModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './home-assistance-plan-details-sidebar.component.html',
  styleUrl: './home-assistance-plan-details-sidebar.component.scss'
})
export class HomeAssistancePlanDetailsSidebarComponent implements OnInit {
  @Input({ required: true }) planId: string;
  @Input({ required: true }) title: string;
  errorLoading = false;
  homeAssistancePlan: HomeAssistancePlan;
  isMobile = false;
  isVisible = false;
  loading = true;

  constructor(
    private errorReportingService: ErrorReportingService,
    private homeAssistancePlansService: HomeAssistancePlansService,
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
    this.onResize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  close(): void {
    this.isVisible = false;
  }

  downloadFile(): void {
    openExternalLink(this.homeAssistancePlan.termsAndConditionsUrl);
  }

  async initialize(): Promise<void> {
    this.loading = true;
    this.errorLoading = false;
    try {
      const homeAssistancePlan = await this.homeAssistancePlansService.get(this.planId);
      if (!homeAssistancePlan) {
        throw new Error('Home assistance plan not found');
      }
      this.homeAssistancePlan = homeAssistancePlan;
    } catch (error) {
      this.errorReportingService.log('HomeAssistancePlanDetailsSidebarComponent.initialize()', 'get-home-assistance-plan', error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

  open(): void {
    this.isVisible = true;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }
}