import { Injectable } from '@angular/core';
import {
  AnalyticsService,
  BasicEventData,
  LoginEventData,
  PurchaseEventData,
  SearchEventData,
  SignUpEventData,
  UserCreationEventData,
} from './analytics.service';

@Injectable()
export class AnalyticsServiceMock implements AnalyticsService {
  constructor() {}

  logAddToFavoritesEvent(data: BasicEventData): void {
    console.log(`Analytic event add_to_wishlist with data: ${JSON.stringify(data)}`);
  }

  logAddToShoppingCartEvent(data: BasicEventData): void {
    console.log(`Analytic event add_to_cart with data: ${JSON.stringify(data)}`);
  }

  logBeginCheckoutEvent(data: BasicEventData): void {
    console.log(`Analytic event begin_checkout with data: ${JSON.stringify(data)}`);
  }

  logLoginEvent(data: LoginEventData): void {
    console.log(`Analytic event login with data: ${JSON.stringify(data)}`);
  }

  logPurchaseEvent(data: PurchaseEventData): void {
    console.log(`Analytic event purchase with data: ${JSON.stringify(data)}`);
  }

  logRemoveFromShoppingCartEvent(data: BasicEventData): void {
    console.log(`Analytic event remove_from_cart with data: ${JSON.stringify(data)}`);
  }

  logSearchEvent(data: SearchEventData): void {
    console.log(`Analytic event search with data: ${JSON.stringify(data)}`);
  }

  logSignUpEvent(data: SignUpEventData): void {
    console.log(`Analytic event sign_up with data: ${JSON.stringify(data)}`);
  }

  logUserCreationEvent(data: UserCreationEventData): void {
    console.log(`Analytic event user_creation with data: ${JSON.stringify(data)}`);
  }

  logViewItemEvent(data: BasicEventData): void {
    console.log(`Analytic event view_item with data: ${JSON.stringify(data)}`);
  }

  logViewShoppingCartEvent(data: BasicEventData): void {
    console.log(`Analytic event view_cart with data: ${JSON.stringify(data)}`);
  }
}
