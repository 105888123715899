import { Injectable } from '@angular/core';
import { ChargeIntent, ChargeIntentsRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { SessionsService } from '../sessions/sessions.service';
import { ChargeIntentsService } from './charge-intents.service';

@Injectable()
export class ChargeIntentsServiceApi implements ChargeIntentsService {
  private client: CreateTRPCProxyClient<ChargeIntentsRouter>;

  constructor(
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<ChargeIntentsRouter>(getTRPCClientOptions(`${environment.apiUrl}/myChargeIntents`, () => this.sessionsService.getAccessToken()));
  }

  get(id: string): Promise<ChargeIntent | null> {
    return this.client.get.query({ id });
  }
}
