import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcommercePurchaseDetailsComponent } from '../../../../components/ecommerce-purchase-details/ecommerce-purchase-details.component';

@Component({
  selector: 'app-purchase-details',
  standalone: true,
  imports: [
    CommonModule,
    EcommercePurchaseDetailsComponent,
  ],
  templateUrl: './purchase-details.page.html',
  styleUrl: './purchase-details.page.scss',
})
export class PurchaseDetailsPage {}
