import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-home-assistances/my-home-assistances.page').then(m => m.MyHomeAssistancesPage)
  },
  {
    path: ':orderId',
    loadComponent: () => import('./home-assistance-status/home-assistance-status.page').then(m => m.HomeAssistanceStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('./home-assistance-details/home-assistance-details.page').then(m => m.HomeAssistanceDetailsPage)
  }
] satisfies Routes;
