<div class="verify-email-container">
  <section>
    <img src="/assets/illustrations/security-token.svg" [alt]="'Verify your email illustration' | transloco" />
    <h1>{{ 'Verify your email' | transloco }}</h1>
    <app-retryable-section [loading]="loading" [error]="error" (retry)="initialize()">
      <p>{{ 'We send a verification link to your email:' | transloco }}</p>
      <p><strong>{{ user?.email }}</strong></p>
      <p class="email-not-arrived">{{ 'Email has not arrived' | transloco }}</p>
      @if (canSendEmail) {
        <app-button label="Resend email" styleClass="tertiary" [loading]="sending" (click)="sendEmail()" />
      } @else {
        <app-button [label]="{ key: 'Resend (X)', params: { timeLeft: timeLeftLabel } }" styleClass="tertiary" [disabled]="true" />
      }
    </app-retryable-section>
  </section>
</div>
