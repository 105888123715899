import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MovingPurchaseDetailsComponent } from '../../../../components/moving-purchase-details/moving-purchase-details.component';

@Component({
  selector: 'app-moving-details',
  standalone: true,
  imports: [
    CommonModule,
    MovingPurchaseDetailsComponent,
  ],
  templateUrl: './moving-details.page.html',
  styleUrl: './moving-details.page.scss',
})
export class MovingDetailsPage {}
