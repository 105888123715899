import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Carousel } from 'primeng/carousel';
import calendarSettings from '../assets/i18n/calendar-settings.json';
import {
  ComplementaryEcommerceBarComponent
} from './components/complementary-ecommerce-bar/complementary-ecommerce-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ToastComponent } from './components/toast/toast.component';
import { DataKey, DataStorageService } from './services/data-storage/data-storage.service';
import { ErrorReportingService } from './services/error-reporting/error-reporting.service';
import { MetaTagsService } from './services/meta-tags/meta-tags.service';
import { PlatformService } from './services/platform/platform.service';
import { LocalUser, UsersService } from './services/users/users.service';

@Component({
  standalone: true,
  imports: [
    ButtonModule,
    ComplementaryEcommerceBarComponent,
    FooterComponent,
    NavbarComponent,
    RouterModule,
    ToastComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isPaymentGatewayRoute = false;
  constructor(
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private metaTagsService: MetaTagsService,
    private platformService: PlatformService,
    private primeNgConfig: PrimeNGConfig,
    private router: Router,
    private usersService: UsersService,
  ) {
    if (this.platformService.isBrowser()) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (!event.url.startsWith('/ecommerce/search')) {
            window.scrollTo(0, 0);
          }
          this.isPaymentGatewayRoute = event.url.startsWith('/payment-gateway/');
        }
      });
    }

    // this is for Carousel vertical scroll on mobile
    Carousel.prototype.onTouchMove = () => {};
  }

  ngOnInit(): void {
    this.primeNgConfig.setTranslation(calendarSettings);
    this.initialize();
  }

  private initialize(): void {
    this.metaTagsService.setMetaTags();
    this.usersService.getCurrentUser().subscribe({
      next: (user) => {
        if (user) {
          this.dataStorageService.setLocal<LocalUser>(DataKey.LocalUser, {
            bankValidated: user.bankValidated,
            email: user.email,
            emailValidated: user.emailValidated,
            enrollmentStatus: user.enrollmentStatus,
            fatherLastName: user.fatherLastName,
            id: user.id,
            motherLastName: user.motherLastName,
            name: user.name,
            phoneNumber: user.phoneNumber,
            profileDataCompleted: user.profileDataCompleted,
          });
        } else {
          this.dataStorageService.clear(DataKey.LocalUser);
        }
      },
      error: (error) => this.errorReportingService.log('AppComponent.initialize()', 'get-current-user', error)
    });
  }
}