import { Inject, Injectable } from '@angular/core';
import { limit, orderBy, where } from '@angular/fire/firestore';
import { EcommerceOrder } from '@homein-hogar-server';
import { map, Observable, of, switchMap } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { EcommerceOrdersService } from './ecommerce-orders.service';

const COLLECTION_PATH = 'ecommerceOrders';

@Injectable()
export class EcommerceOrdersServiceApi implements EcommerceOrdersService {
  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {}

  get(id: string): Observable<EcommerceOrder | null> {
    return this.firestore.doc<EcommerceOrder>(`${COLLECTION_PATH}/${id}`);
  }

  getAll(): Observable<EcommerceOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<EcommerceOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            orderBy('createdAt', 'desc')
          ]
        });
      }
      return of([]);
    }));
  }

  getByPaymentIntentId(id: string): Observable<EcommerceOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<EcommerceOrder>(COLLECTION_PATH, {
          constraints: [
            where('paymentIntentId', '==', id),
            where('userId', '==', session.userId),
            orderBy('createdAt', 'desc'),
            limit(1)
          ]
        }).pipe(map((orders) => orders.length ? orders[0] : null));
      }
      return of(null);
    }));
  }

  getLastInProgress(): Observable<EcommerceOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<EcommerceOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', '==', 'in-progress'),
            orderBy('createdAt', 'desc'),
            limit(1)
          ]
        }).pipe(map((orders) => orders.length ? orders[0] : null));
      }
      return of(null);
    }));
  }
}
