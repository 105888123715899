import { areEqualObjects, isObject } from './object.utils';

export function chunk<T>(arr: T[], size: number): T[][] {
  return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) => arr.slice(i * size, i * size + size));
}

export function areEqualArrays<T>(first: T[] | null, second: T[] | null): boolean {
  if (!first && !second && first === second) {
    return true;
  }
  if (!Array.isArray(first) || !Array.isArray(second)) {
    return false;
  }
  first.sort();
  second.sort();
  return first.length === second.length && first.every((value, i) => {
    if (isObject(value)) {
      return areEqualObjects(value, second[i]);
    }
    return value === second[i];
  });
}

export function randomize<T>(value: T[]): T[] {
  const array = value;
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}


