import { HomeAssistanceOrder } from '@homein-hogar-server';
import { toCapitalizeCase } from './string.utils';

function getMembershipLabel(membership?: string): string {
  return membership ? `${toCapitalizeCase(membership)} membership` : '';
}

export function getComplementaryData(order: HomeAssistanceOrder): {
  membershipLabel: string;
} {
  return {
    membershipLabel: getMembershipLabel(order.additionalData?.['membership']),
  };
}
