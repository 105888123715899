import { CONFIG } from '../constants';
import { AnalyticsItem } from '../services/analytics/analytics.service';
import { ShoppingCartItemProduct } from '../services/shopping-carts/shopping-carts.service';

export function parseAnalyticsItems(items: ShoppingCartItemProduct[]): { currency: string; items: AnalyticsItem[]; } {
  const currency = items.length && items[0].resource ? items[0].resource.currency : CONFIG.defaultCurrency;
  const parsedItems = [];
  for (const item of items) {
    if (!item.resource) {
      continue;
    }
    parsedItems.push({
      brand: item.resource?.brand,
      discount: item.resource?.discount,
      id: item.resource?.id,
      name: item.resource?.name,
      price: item.resource?.price,
      quantity: item.quantity,
    });
  }
  return { currency, items: parsedItems };
}