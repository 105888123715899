import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Address, SearchEngineProduct } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { RippleModule } from 'primeng/ripple';
import { combineLatest, firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ComplementaryEcommerceBarComponent } from '../../../components/complementary-ecommerce-bar/complementary-ecommerce-bar.component';
import { FilterSidebarComponent } from '../../../components/filter-sidebar/filter-sidebar.component';
import { ManageAddressesSidebarComponent } from '../../../components/manage-addresses-sidebar/manage-addresses-sidebar.component';
import { ProductsOfInterestComponent } from '../../../components/products-of-interest/products-of-interest.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { AddressesService } from '../../../services/addresses/addresses.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { ShoppingCartDetail, ShoppingCartsService } from '../../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../../services/toast/toast.service';
import { LocalUser } from '../../../services/users/users.service';
import { parseAnalyticsItems } from '../../../utils/parse.utils';

@Component({
  selector: 'app-shopping-cart',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ComplementaryEcommerceBarComponent,
    CurrencyFormatterPipe,
    FilterSidebarComponent,
    ManageAddressesSidebarComponent,
    ProductsOfInterestComponent,
    RetryableSectionComponent,
    RippleModule,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './shopping-cart.page.html',
  styleUrl: './shopping-cart.page.scss',
})
export class ShoppingCartPage implements OnInit, OnDestroy {
  @ViewChild('manageAddressesSidebar') manageAddressesSidebar: ManageAddressesSidebarComponent;
  addingProduct = false;
  address: Address | null = null;
  currency = '';
  errorLoadingShoppingCart = false;
  loadingShoppingCart = false;
  products: SearchEngineProduct[] = [];
  removingProduct = false;
  selectedItemIds: string[] = [];
  shoppingCartDetail: ShoppingCartDetail | null = null;
  shoppingCartId: string;
  private isMobile = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private analyticsService: AnalyticsService,
    private addressesService: AddressesService,
    private dataStorageService: DataStorageService,
    private elementRef: ElementRef,
    private errorReportingService: ErrorReportingService,
    private renderer: Renderer2,
    private router: Router,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async addProduct(product: SearchEngineProduct): Promise<void> {
    if (this.addingProduct || this.removingProduct) {
      return;
    }
    this.addingProduct = true;
    try {
      await this.shoppingCartsService.addItem({
        quantity: 1,
        resourceId: product.id,
        resourceType: 'product',
      });
      this.toastService.showSuccess({
        title: 'Added a product to shopping cart',
        description: {
          key: '"X" has been added to your shopping cart.',
          params: { name: product.name }
        },
      }, 3000);
      this.analyticsService.logAddToShoppingCartEvent({
        currency: product.currency,
        items: [product],
        value: product.price,
      });
    } catch (error) {
      this.errorReportingService.log('ShoppingCartPage.addProduct()', 'add-product', error);
    } finally {
      this.addingProduct = false;
    }
  }

  getShoppingCart(): void {
    this.loadingShoppingCart = true;
    this.errorLoadingShoppingCart = false;
    const subscriptions = combineLatest([
      this.shoppingCartsService.get(),
      this.dataStorageService.get<LocalUser>(DataKey.LocalUser),
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: async ([shoppingCart, localUser]) => {
        this.shoppingCartDetail = shoppingCart;
        this.selectedItemIds = [];
        if (this.shoppingCartDetail) {
          const { currency, items } = parseAnalyticsItems(this.shoppingCartDetail.items);
          this.currency = currency;
          if (this.shoppingCartDetail.shippingAddressId) {
            this.address = await firstValueFrom(this.addressesService.get(this.shoppingCartDetail.shippingAddressId));
          }
          this.analyticsService.logViewShoppingCartEvent({
            currency,
            items,
            value: this.shoppingCartDetail.subtotal,
          });
          // Later on we should be able to select some items to buy instead of all of them
          this.selectedItemIds = this.shoppingCartDetail.items
            .filter((item) => item.resourceType === 'product')
            .map((item) => item.resourceId);
        }
        if (localUser) {
          this.shoppingCartId = localUser.id;
        }
        this.loadingShoppingCart = false;
      },
      error: (error) => {
        this.errorReportingService.log('ShoppingCartPage.getShoppingCart()', 'get-shopping-cart', error);
        this.errorLoadingShoppingCart = true;
        this.shoppingCartDetail = null;
        this.loadingShoppingCart = false;
        subscriptions.unsubscribe();
      }
    });
  }

  async goToCheckout(): Promise<void> {
    const localUser = await firstValueFrom(this.dataStorageService.get<LocalUser>(DataKey.LocalUser));
    if (!localUser) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/enrollment/sign-in'], {
        queryParams: {
          redirectTo: '/ecommerce/shopping-cart',
        }
      });
      return;
    }
    if (!this.address && localUser?.enrollmentStatus === 'done') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.openManageAddresses();
      return;
    }
    if (localUser?.enrollmentStatus === 'pending') {
      this.toastService.showWarning({
        title: 'Finalize your registration',
        description: 'To access all the exclusive benefits that Mundo Hogar has for you, you need to complete your registration.' ,
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['/enrollment/sign-in'], {
            queryParams: {
              redirectTo: '/ecommerce/shopping-cart',
            }
          });
        },
        actionLabel: 'Finish registration',
      }, 3000);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/payment-gateway/checkout'], {
      queryParams: {
        resourceIds: this.selectedItemIds,
        resourceType: 'product',
        source: 'ecommerce',
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.isMobile = window.innerWidth < 900;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (!this.isMobile || !this.shoppingCartDetail?.items.length) {
      return;
    }
    const scrollY = window.scrollY;
    const payButton = this.elementRef.nativeElement.querySelector('.floating-pay-order');
    const staticButton = this.elementRef.nativeElement.querySelector('.pay-order-button-container');
    const staticButtonRect = staticButton.getBoundingClientRect();
    const isStaticButtonVisible = staticButtonRect.top <= window.innerHeight;
    if (scrollY >= 0 && !isStaticButtonVisible) {
      this.renderer.setStyle(payButton, 'transform', 'translateY(0%)');
    } else {
      this.renderer.setStyle(payButton, 'transform', 'translateY(100%)');
    }
  }

  async openManageAddresses() {
    const localUser = await firstValueFrom(this.dataStorageService.get<LocalUser>(DataKey.LocalUser));
    if (localUser?.enrollmentStatus === 'done') {
      this.manageAddressesSidebar.open();
      return;
    } else if (localUser?.enrollmentStatus === 'pending') {
      this.toastService.showWarning({
        title: 'Finalize your registration',
        description: 'To access all the exclusive benefits that Mundo Hogar has for you, you need to complete your registration.' ,
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['enrollment/sign-in'], {
            queryParams: {
              redirectTo: '/ecommerce/shopping-cart',
            }
          });
        },
        actionLabel: 'Finish registration',
      }, 3000);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/enrollment/sign-in'], {
      queryParams: {
        redirectTo: '/ecommerce/shopping-cart',
      }
    });
  }

  async removeAllProducts(item: {
    quantity: number;
    resource: SearchEngineProduct | null;
  }): Promise<void> {
    this.removingProduct = true;
    try {
      const { resource, quantity } = item;
      if (!resource) {
        throw new Error('Resource not found');
      }
      const { currency, items } = parseAnalyticsItems(Array(quantity).fill(item));
      await this.shoppingCartsService.removeItem({
        quantity,
        resourceId: resource.id,
        resourceType: 'product',
      });
      this.analyticsService.logRemoveFromShoppingCartEvent({
        currency,
        items,
        value: resource.price * quantity,
      });
    } catch (error) {
      this.errorReportingService.log('ShoppingCartPage.removeAllProducts()', 'remove-all-products', error);
    } finally {
      this.removingProduct = false;
    }
  }

  async removeProduct(product: SearchEngineProduct, quantity: number = 1): Promise<void> {
    if (this.addingProduct || this.removingProduct) {
      return;
    }
    this.removingProduct = true;
    try {
      await this.shoppingCartsService.removeItem({
        quantity,
        resourceId: product.id,
        resourceType: 'product',
      });
      this.toastService.showSuccess({
        title: 'Removed a product from shopping cart',
        description: {
          key: '"X" has been removed from your shopping cart.',
          params: { name: product.name }
        },
      }, 3000);
      this.analyticsService.logRemoveFromShoppingCartEvent({
        currency: product.currency,
        items: [product],
        value: product.price,
      });
    } catch (error) {
      this.errorReportingService.log('ShoppingCartPage.removeProduct()', 'remove-product', error);
    } finally {
      this.removingProduct = false;
    }
  }

  private initialize(): void {
    this.getShoppingCart();
  }
}
