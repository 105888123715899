import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    InputGroupModule,
    InputTextModule,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
})
export class SearchInputComponent implements OnDestroy, OnInit {
  @Output() backButtonClick = new EventEmitter<void>();
  @Output() changeQuery = new EventEmitter<string | null>();
  @Output() deleteHistoryClick = new EventEmitter<void>();
  @ViewChild('input') input: ElementRef;
  @Output() inputClick = new EventEmitter<void>();
  @ViewChild('overlay') overlay: ElementRef;
  @Input() query: string | null = null;
  @Output() searchClick = new EventEmitter<string>();
  @Input() searchHistory: string[] | null = null;
  @Input() showBackButton = false;
  isOverlayVisible = false;
  private stopOutsideOverlayClickListener: () => void;

  constructor(
    private renderer: Renderer2,
  ) {}

  ngOnDestroy(): void {
    this.stopOutsideOverlayClickListener();
  }

  ngOnInit(): void {
    this.stopOutsideOverlayClickListener = this.renderer.listen('window', 'click', (event) => {
      if (this.isOverlayVisible &&
        event.target !== this.input.nativeElement &&
        event.target !== this.overlay.nativeElement &&
        event.target.parentElement !== this.overlay.nativeElement) {
        this.isOverlayVisible = false;
        this.changeQuery.emit(this.query);
      }
      if (event.target === this.input.nativeElement) {
        this.inputClick?.emit();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  clearQuery(): void {
    this.query = null;
    this.changeQuery.emit(this.query);
  }

  deleteSearchHistory(): void {
    this.deleteHistoryClick.emit();
  }

  focus(): void {
    setTimeout(() => this.input.nativeElement.focus(), 150);
  }

  goBack(): void {
    this.backButtonClick?.emit();
    this.changeQuery.emit(this.query);
  }

  searchQuery(query?: string): void {
    this.query = query ?? this.query;
    if (this.query) {
      this.searchClick.emit(this.query);
      this.isOverlayVisible = false;
    }
  }

  showOverlay(): void {
    this.isOverlayVisible = true;
  }
}
