import { Injectable } from '@angular/core';
import { ValidateBankIdentificationNumberData } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { BankIdentificationNumbersServiceApi } from './bank-identification-numbers.service.api';
import { BankIdentificationNumbersServiceMock } from './bank-identification-numbers.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? BankIdentificationNumbersServiceMock : BankIdentificationNumbersServiceApi
})
export abstract class BankIdentificationNumbersService {
  abstract validate(data: ValidateBankIdentificationNumberData): Promise<{ code: string; message: string; }>;
}
