import { Injectable } from '@angular/core';
import { EcommerceOrder } from '@homein-hogar-server';
import { map, Observable } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { EcommerceOrdersService } from './ecommerce-orders.service';

@Injectable()
export class EcommerceOrdersServiceMock implements EcommerceOrdersService {
  constructor(
    private mockService: MockService,
  ) {}

  get(id: string): Observable<EcommerceOrder | null> {
    return this.mockService.getEcommerceOrder(id);
  }

  getAll(): Observable<EcommerceOrder[]> {
    return this.mockService.getEcommerceOrders();
  }

  getByPaymentIntentId(id: string): Observable<EcommerceOrder | null> {
    return this.mockService.getEcommerceOrders().pipe(map((ecommerceOrders) => ecommerceOrders.find((ecommerceOrder) => ecommerceOrder.paymentIntentId === id) ?? null));
  }

  getLastInProgress(): Observable<EcommerceOrder | null> {
    return this.mockService.getLastEcommerceOrderInProgress();
  }
}
