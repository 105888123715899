import { Injectable } from '@angular/core';
import { Address, CreateAddressData } from '@homein-hogar-server';
import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { SessionsService } from '../sessions/sessions.service';
import { AddressesService } from './addresses.service';

@Injectable()
export class AddressesServiceMock implements AddressesService {
  constructor(
    private mockService: MockService,
    private sessionsService: SessionsService,
  ) {}

  async create(data: CreateAddressData): Promise<string> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      return await this.mockService.createAddress({
        ...data,
        country: 'Mexico',
        userId: session.userId
      });
    }
    return Promise.reject({ code: 'unauthorized', message: 'Unauthorized.' });
  }

  get(id: string): Observable<Address | null> {
    return this.mockService.getAddress(id);
  }

  getAll(): Observable<Address[]> {
    return this.sessionsService.getSession().pipe(switchMap(session => {
      if (session) {
        return this.mockService.getAddresses().pipe(map((addresses) => {
          return addresses.filter((address) => address.userId === session.userId);
        }));
      }
      return of([]);
    }));
  }
}
