@if (page === 'private-site') {
  @if (shouldAskForTermsOfServiceAcceptance) {
    <div class="private-site-container">
      <div class="private-content-container">
        <div class="content">
          <i class="icon-circle-checkmark icon"></i>
          <div class="disclaimer-text-container">
            <div class="header-content">
              <i class="icon-circle-checkmark icon"></i>
              <span class="disclaimer">{{ 'Services provided by Homein and associated suppliers.' | transloco }}</span>
            </div>
            <span class="sub-disclaimer">{{ 'Banco Santander is not responsible for the services offered in Mundo Hogar.' | transloco }}</span>
            <a class="clickeable-text" routerLink="/what-is">{{ 'Learn more' | transloco }}</a>
          </div>
        </div>
        <div class="footer">
          <app-button label="Understood" (action)="hideBanner()" />
        </div>
      </div>
    </div>
  }
} @else {
  <div class="homein-services">
    <div class="services-container">
      <div class="disclaimer-container">
        <div class="first-content">
          <i class="icon-circle-checkmark"></i>
          @if (['home-assistance', 'moving', 'remodeling', 'warehouse'].includes(page)) {
            <p class="text-container">
              <span class="disclaimer">{{ 'Service provided by' | transloco }}</span>
              @if (page === 'warehouse') {
                <img src="assets/images/mudango-logo.svg" class="moving-logo logo" [alt]="'Mudango logo' | transloco" />
                <span class="disclaimer">{{ 'the N°1 warehouse company in Mexico' | transloco }}</span>
              } @else if (page === 'moving'){
                <img src="assets/images/mudango-logo.svg" class="moving-logo logo" [alt]="'Mudango logo' | transloco" />
                <span class="disclaimer">{{ 'the N°1 moving company in Mexico' | transloco }}</span>
              } @else if (page === 'remodeling') {
                <img src="assets/images/atr-logo.svg" class="atr-logo logo" [alt]="'Aquí tu remodelación logo' | transloco" />
                <span class="disclaimer">{{ 'the N°1 remodeling company in Mexico' | transloco }}</span>
              } @else if (page === 'home-assistance') {
                <img src="assets/images/ike-logo-final.svg" class="ike-logo logo" [alt]="'Ike logo' | transloco" />
                <span class="disclaimer">{{ 'the N°1 assistance company in Mexico' | transloco }}</span>
              }
            </p>
          } @else {
            <p class="text-container">
              <span class="disclaimer">{{ 'Services provided by Homein and associated suppliers.' | transloco }}</span>
            </p>
          }
        </div>
        <div class="second-content">
          <span class="sub-disclaimer">{{ 'Banco Santander is not responsible for the services offered in Mundo Hogar.' | transloco }}</span>
          <a class="clickeable-text" routerLink="/what-is">{{ 'Learn more' | transloco}}</a>
        </div>
      </div>
    </div>
  </div>
}