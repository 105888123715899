<section class="main">
  <div class="main-container">
    <div class="left-side"></div>
    <div class="right-side">
      <div class="title-container">
        <h2 class="title">{{ 'Do you have an unforeseen event at home?' | transloco }}</h2>
        <p class="subtitle">{{ 'We know that any inconvenience in your home is a headache. That\'s why we recommend this plan.' | transloco }}</p>
      </div>
      <div class="button-container">
        <app-button label="I am interested" styleClass="primary" (action)="openSelectPlan()" />
      </div>
    </div>
  </div>
</section>
<section class="terms-banner">
  <app-terms-banner page="home-assistance" />
</section>
<section class="assistance-request-section">
  <div class="container">
    <div class="title-container">
      <h2 class="title">{{ 'Request your assistance quickly' | transloco }}</h2>
      <hr class="underline">
    </div>
    <div class="request-container">
      <ul class="requests">
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>{{ 'Nationwide coverage and 24/7 channels' | transloco }}</li>
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>{{ 'Enjoy your home without fear of unforeseen events' | transloco }}</li>
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>{{ 'Relax, you can now request assistance 24/7!' | transloco }}</li>
      </ul>
    </div>
  </div>
</section>
<section class="benefits-plan-section">
  <div class="container">
    <div class="title-container">
      <h2 class="title">{{ 'This plan has benefits such as:' | transloco }}</h2>
      <hr class="underline">
    </div>
    <div class="benefits-container">
      <div class="benefit">
        <img class="image" src="/assets/images/hogar-plumbing.svg" [alt]="'Plumbing image' | transloco" />
        <div class="content">
          <h4 class="label">{{ 'Plumbing' | transloco }}</h4>
        </div>
      </div>
      <div class="benefit">
        <img class="image" src="/assets/images/hogar-electricity.svg" [alt]="'Electricity image' | transloco" />
        <div class="content">
          <h4 class="label">{{ 'Electricity' | transloco }}</h4>
        </div>
      </div>
      <div class="benefit">
        <img class="image" src="/assets/images/hogar-glassworks.svg" [alt]="'Glassworks image' | transloco" />
        <div class="content">
          <h4 class="label">{{ 'Glassworks' | transloco }}</h4>
        </div>
      </div>
      <div class="benefit">
        <img class="image" src="/assets/images/many-more.svg" [alt]="'Many more image' | transloco" />
        <div class="content">
          <h4 class="label">{{ 'Many more' | transloco }}</h4>
        </div>
      </div>
    </div>
    <div class="details">
      <span class="detail">{{ 'You will be able to see the details of this plan' | transloco }} <a class="here" (click)="openPlanDetails()">{{ 'HERE' | transloco }}</a>.</span>
    </div>
    <div class="button-container">
      <app-button label="Contract service" styleClass="secondary" (action)="openSelectPlan()" />
    </div>
  </div>
</section>
<section class="hogar-plus-pet-section">
  <div class="container">
    <div class="left-side"></div>
    <div class="right-side">
      <div class="title-container">
        <h2 class="title">{{ 'Did you know that this plan could be even better?' | transloco }}</h2>
        <p class="subtitle">{{ 'To also protect your best friends, you can find the Pets Plan in conjunction with the Home Plan. Where you can insure them and get the best benefits.' | transloco }}</p>
      </div>
      <div class="button-container">
        <app-button label="Review Plan Hogar + Mascotas" styleClass="secondary" routerLink="/home-assistance/plan-hogar-mascotas" />
      </div>
    </div>
  </div>
</section>
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'All in one place' | transloco }}</h4>
          <span class="description">{{ 'Find everything you need for your home, in the same 100% online portal.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'The best financing' | transloco }}</h4>
          <span class="description">{{ 'Pay in months without interest and with the best payment alternatives to suit your needs.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'More tranquility' | transloco }}</h4>
          <span class="description">{{ 'Qualified suppliers, guaranteed services and secure payment methods for you.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Simple and transparent' | transloco }}</h4>
          <span class="description">{{ 'Manage and track your projects online in just a few clicks.' | transloco }}</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <!-- TODO: Change later the link -->
      <p class="customer-benefit">{{ 'Exclusive benefits for Santander customers. If you are not a customer yet' | transloco }} <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank">{{ 'click here' | transloco }}</a><p>
    </div>
  </div>
</article>
<article class="faq-article">
  <h2 class="title">{{ 'FAQ' | transloco }}</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
      <p-accordionTab class="accordion-tab" header="{{ 'What is Mundo Hogar assistance?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'They are efficient solutions to any emergency, unforeseen event or inconvenience that may arise in your home or for your pets.' | transloco }}</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'What is a Mundo Hogar assistance plan?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'It is an assistance program that offers coverage for inconveniences or unforeseen events that you may have to solve during the term of your plan. Check the events included in each of the available plans.' | transloco }}</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'What is an event and event limit?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'An event is each time you need to use any of the services included in your assistance plan. Event limits are the number of times you can use a service in the same period.' | transloco }}</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'What services are included in my assistance plan?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'Mundo Hogar has the right plans to protect what matters most to you:' | transloco }} <a class="link" routerLink="/home-assistance/plan-hogar">Plan Hogar, </a><a class="link" routerLink="/home-assistance/plan-mascotas">Plan Mascotas</a>, <a class="link" routerLink="/home-assistance/plan-hogar-mascotas">Plan Hogar + Mascotas</a>.</p>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">{{ 'Visit our' | transloco }} <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">{{ 'Help center' | transloco }}</a> {{ 'for more information' | transloco }}</h5>
</article>
<app-home-assistance-plan-details-sidebar #assistancePlanDetailsSidebar planId="plan-hogar" title="Plan Hogar" />
<app-select-assistance-plan-sidebar #selectAssistancePlanSidebar planId="plan-hogar" title="Plan Hogar" />