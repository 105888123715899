const categoryLabels: Record<string, string> = {
  'accesorios-audio-y-sonido': 'Accesorios',
  'accesorios-fotografia': 'Accesorios',
  'accesorios-tv-y-video': 'Accesorios',
  'adiestramiento-y-repelentes': 'Adiestramiento y Repelentes',
  'albercas': 'Albercas',
  'amplificadores': 'Amplificadores',
  'aparatos-de-ejercicio': 'Aparatos de ejercicio',
  'artefactos-para-el-hogar': 'Artefactos para el Hogar',
  'asadores': 'Asadores',
  'audifonos-y-manos-libres': 'Audífonos y manos libres',
  'audio-para-auto': 'Audio para auto',
  'audio-y-sonido': 'Audio y sonido',
  'aves': 'Aves',
  'bano': 'Baño',
  'banos': 'Baños',
  'binoculares-y-telescopios': 'Binoculares y Telescopios',
  'blancos': 'Blancos',
  'bocinas': 'Bocinas',
  'brincolines-albercas-y-plazas': 'Brincolines, albercas y plazas',
  'calefaccion-y-refrigeracion-al-aire-libre': 'Calefacción y refrigeración al aire libre',
  'camaras-analogicas': 'Cámaras Analógicas',
  'camaras-digitales': 'Cámaras Digitales',
  'carpas': 'Carpas',
  'carpinteria': 'Carpintería',
  'climatizacion': 'Climatización',
  'cocina': 'Cocina',
  'colchones-y-boxes': 'Colchones y Boxes',
  'comedor-y-cocina': 'Comedor y Cocina',
  'computo-y-tabletas': 'Computo y tabletas',
  'consolas-y-mezcladoras': 'Consolas y Mezcladoras',
  'construccion': 'Construcción',
  'construccion-y-ferreteria': 'Construcción y Ferretería',
  'control-de-plagas': 'Control de plagas',
  'decoracion': 'Decoración',
  'decoracion-al-aire-libre': 'Decoración al aire libre',
  'deporte-en-el-hogar': 'Deporte en el hogar',
  'dispositivos-streaming': 'Dispositivos Streaming',
  'drones': 'Drones',
  'electricidad': 'Electricidad',
  'electrodomesticos': 'Electrodomésticos',
  'entretenimiento-y-tecnologia': 'Entretenimiento y Tecnología',
  'equinos': 'Equinos',
  'fregaderos': 'Fregaderos',
  'gatos': 'Gatos',
  'generadores-y-energia-portatil': 'Generadores y energía portátil',
  'go-pro': 'Go-Pro',
  'hamacas': 'Hamacas',
  'herramientas': 'Herramientas',
  'hora-de-dormir': 'Hora de dormir',
  'hora-del-bano': 'Hora del baño',
  'iluminacion': 'Iluminación',
  'iluminacion-de-exteriores': 'Iluminación de exteriores',
  'instrumentos-musicales': 'Instrumentos musicales',
  'jardineria': 'Jardineria',
  'lavado-y-secado-de-ropa': 'Lavado y Secado de Ropa',
  'lavavajillas': 'Lavavajillas',
  'limpieza': 'Limpieza',
  'limpieza-y-organizacion-del-hogar': 'Limpieza y Organización del Hogar',
  'macetas': 'Macetas',
  'mascotas': 'Mascotas',
  'maternidad-y-bebe': 'Maternidad y bebé',
  'mejora-de-hogar': 'Mejora de Hogar',
  'mejora-de-hogar-limpieza-y-conservacion': 'Limpieza y Conservación',
  'mincomponentes-y-receptores': 'Minicomponentes y Receptores',
  'muebles-y-decoracion': 'Muebles y Decoración',
  'muebles-limpieza-y-conservacion': 'Limpieza y Conservación',
  'muebles-para-bebe': 'Muebles para bebé',
  'muebles-para-jardin': 'Muebles para jardín',
  'navidad': 'Navidad',
  'ninos-y-juguetes': 'Niños y juguetes',
  'para-cocinar': 'Para cocinar',
  'patio-y-jardin': 'Patio y jardín',
  'peces': 'Peces',
  'perros': 'Perros',
  'pisos-y-alfombras': 'Pisos y Alfombras',
  'plomeria': 'Plomería',
  'proteccion-y-seguridad': 'Protección y seguridad',
  'proyectores-y-pantallas': 'Proyectores y Pantallas',
  'recamaras': 'Recámaras',
  'refacciones': 'Refacciones',
  'refacciones-y-accesorios': 'Refacciones y Accesorios',
  'reflex-semiprofesionales': 'Réflex/Semiprofesionales',
  'refrigeracion': 'Refrigeración',
  'reproductores-bluray-y-dvds': 'Reproductores BluRay y DVD\'s',
  'reptiles-y-anfibios': 'Reptiles y Anfibios',
  'roedores': 'Roedores',
  'salas-y-family-room': 'Salas y Family room',
  'sombrillas': 'Sombrillas',
  'soportes': 'Soportes',
  'teatro-en-casa': 'Teatro en casa',
  'televisiones': 'Televisiones',
  'tv-y-video': 'TV y video',
  'videocamaras': 'Videocámaras',
  'videojuegos': 'Videojuegos'
};
const categoryTree: Record<string, string[]> = {
  'tv-y-video': [
    'televisiones',
    'dispositivos-streaming',
    'proyectores-y-pantallas',
    'reproductores-bluray-y-dvds',
    'soportes',
    'accesorios-tv-y-video',
    'refacciones',
  ],
  'audio-y-sonido': [
    'audifonos-y-manos-libres',
    'teatro-en-casa',
    'amplificadores',
    'audio-para-auto',
    'bocinas',
    'consolas-y-mezcladoras',
    'mincomponentes-y-receptores',
    'accesorios-audio-y-sonido',
  ],
  'entretenimiento-y-tecnologia': [
    'videocamaras',
    'camaras-analogicas',
    'camaras-digitales',
    'reflex-semiprofesionales',
    'drones',
    'go-pro',
    'accesorios-fotografia',
    'binoculares-y-telescopios',
    'computo-y-tabletas',
    'videojuegos',
    'instrumentos-musicales',
  ],
  'muebles-y-decoracion': [
    'salas-y-family-room',
    'comedor-y-cocina',
    'colchones-y-boxes',
    'recamaras',
    'bano',
    'muebles-limpieza-y-conservacion',
    'decoracion',
    'banos',
    'limpieza-y-organizacion-del-hogar',
    'cocina',
    'blancos',
    'navidad',
    'iluminacion',
  ],
  'mejora-de-hogar': [
    'pisos-y-alfombras',
    'proteccion-y-seguridad',
    'fregaderos',
    'mejora-de-hogar-limpieza-y-conservacion',
  ],
  'electrodomesticos': [
    'electrodomesticos',
    'artefactos-para-el-hogar',
    'refrigeracion',
    'para-cocinar',
    'lavado-y-secado-de-ropa',
    'lavavajillas',
    'climatizacion',
    'refacciones-y-accesorios',
  ],
  'patio-y-jardin': [
    'muebles-para-jardin',
    'sombrillas',
    'hamacas',
    'carpas',
    'macetas',
    'asadores',
    'albercas',
    'decoracion-al-aire-libre',
    'iluminacion-de-exteriores',
    'control-de-plagas',
    'generadores-y-energia-portatil',
    'calefaccion-y-refrigeracion-al-aire-libre',
    'jardineria',
  ],
  'mascotas': [
    'aves',
    'equinos',
    'gatos',
    'peces',
    'perros',
    'reptiles-y-anfibios',
    'roedores',
    'adiestramiento-y-repelentes',
  ],
  'deporte-en-el-hogar': [
    'aparatos-de-ejercicio',
  ],
  'construccion-y-ferreteria': [
    'electricidad',
    'herramientas',
    'plomeria',
    'carpinteria',
    'construccion',
  ],
  'limpieza': [
    'limpieza',
  ],
  'maternidad-y-bebe': [
    'hora-del-bano',
    'hora-de-dormir',
    'muebles-para-bebe',
  ],
  'ninos-y-juguetes': [
    'brincolines-albercas-y-plazas',
  ],
};


export const CONFIG = {
  defaultCurrency: 'MXN',
  ecommerce: {
    categories: {
      labels: categoryLabels,
      tree: categoryTree,
    },
  },
  maxFavoriteItems: 16,
  maxShoppingCartItems: 16,
  paymentOptions: ['debit', 'credit', 'credit-36', 'credit-24', 'credit-18', 'credit-12', 'credit-6', 'credit-3'] as const,
  postalCodeLength: 5, // TODO F&F: remove or add/define all the country specific validations here
  pricing: {
    ecommerce: {
      superSale: 0.5,
      sale: 0.35,
    }
  },
  shipping: {
    priority: {
      delay: 2,
      time: 5,
    },
    regular: {
      delay: 5,
      time: 10,
    },
  },
  remodeling: {
    types: [
      { label: 'Baño', value: 'bathroom' },
      { label: 'Cocina', value: 'kitchen' },
      { label: 'Remodelación integral', value: 'full-building-renovation' },
      { label: 'Otro', value: 'other' },
    ]
  },
  tax: 0.16,
};