<section class="project-simulator-container">
  <div>
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">{{ 'Back' | transloco }}</span>
      <span class="mobile-text">{{ 'Simulate your project' | transloco }} </span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">{{ 'Simulates the cost of the project you have in mind' | transloco }}</h5>
      <app-circular-progress [currentStep]="5" [numberSteps]="6" stepTitle="{{ 'Remodeling surface' | transloco }}" stepSubtitle="{{ 'Next: We will send the simulation!' | transloco }}" />
    </div>
    <div class="bottom-container animate__fadeIn">
      @if (projectSimulation && projectSimulation.type === 'bathroom') {
        <form [formGroup]="bathroomForm" class="project-simulation-types-container">
          <div formArrayName="surfaceAreaDetail">
            <span class="title">{{ 'Enter the surface area of your bathrooms' | transloco }}</span>
            @for (control of bathroomForm.controls.surfaceAreaDetail.controls; let i = $index; track control) {
              <div class="input-container">
                <label class="subtitle" [for]="'bathroom-' + i">{{ 'Bathroom surface X (m²)' | transloco: { number: i + 1 } }}</label>
                <p-inputMask [id]="'bathroom-' + i" [formControlName]="i" mask="9?9 m²" slotChar="" placeholder="{{ 'Surface area in m²' | transloco }}"></p-inputMask>
                  @if (control.touched && control.invalid) {
                    @if (control.errors?.['required']) {
                      <small class="error-message">{{ 'This field is required' | transloco }}</small>
                    }
                    @if (control.errors?.['min']) {
                      <small class="error-message">{{ 'Value must be greater than X' | transloco: { value: control.errors?.['min'].min } }}</small>
                    }
                  }
              </div>
            }
          </div>
        </form>
      }
      @if (projectSimulation && projectSimulation.type === 'kitchen') {
        <form [formGroup]="kitchenForm" class="project-simulation-types-container">
          <h2 class="title">{{ 'Enter the surface area of the renovation' | transloco }}</h2>
            <div class="input-container">
            <label for="kitchen">{{ 'Kitchen surface' | transloco }}</label>
              <p-inputMask id="kitchen" formControlName="surfaceArea" mask="9?9 m²" slotChar="" placeholder="{{ 'Surface area in m²' | transloco }}"></p-inputMask>
            @if (kitchenForm.controls.surfaceArea.touched && kitchenForm.controls.surfaceArea.invalid) {
              @if (kitchenForm.controls.surfaceArea.errors?.['required']) {
                <small class="error-message">{{ 'This field is required' | transloco }}</small>
              }
              @if (kitchenForm.controls.surfaceArea.errors?.['min']) {
                <small class="error-message">{{ 'Value must be greater than X' | transloco: { value: kitchenForm.controls.surfaceArea.errors?.['min'].min } }}</small>
              }
            }
          </div>
        </form>
      }
      @if (projectSimulation && projectSimulation.type === 'integral-remodeling') {
        <form [formGroup]="integralRemodelingForm" class="project-simulation-types-container">
          <h2 class="title">{{ 'Enter the surface area of the renovation' | transloco }}</h2>
          <div class="input-container">
            <label for="surfaceArea">{{ 'Surface to be remodelled' | transloco }}</label>
            <p-inputMask
              id="surfaceArea"
              formControlName="surfaceArea"
              mask="9?9 m²"
              slotChar=""
              placeholder="{{ 'Surface area in m²' | transloco }}"
            >
            </p-inputMask>
            @if (integralRemodelingForm.controls.surfaceArea.touched && integralRemodelingForm.controls.surfaceArea.invalid) {
              @if (integralRemodelingForm.controls.surfaceArea.errors?.['required']) {
                <small class="error-message">{{ 'This field is required' | transloco }}</small>
              }
              @if (integralRemodelingForm.controls.surfaceArea.errors?.['min']) {
                <small class="error-message">{{ 'Value must be greater than X' | transloco: { value: integralRemodelingForm.controls.surfaceArea.errors?.['min'].min } }}</small>
              }
            }
          </div>
          <div class="input-container">
            <label for="numberOfBathrooms">{{ 'Number of bathrooms' | transloco }}</label>
            <p-inputNumber class="input" id="numberOfBathrooms" formControlName="numberOfBathrooms" />
            @if (integralRemodelingForm.controls.numberOfBathrooms.touched && integralRemodelingForm.controls.numberOfBathrooms.invalid) {
              @if (integralRemodelingForm.controls.numberOfBathrooms.errors?.['required']) {
                <small class="error-message">{{ 'This field is required' | transloco }}</small>
              }
              @if (integralRemodelingForm.controls.numberOfBathrooms.errors?.['min']) {
                <small class="error-message">{{ 'Value must be greater than X' | transloco: { value: integralRemodelingForm.controls.numberOfBathrooms.errors?.['min'].min } }}</small>
              }
            }
          </div>
          <div class="input-container">
            <label for="numberOfRooms">{{ 'Number of rooms' | transloco }}</label>
            <p-inputNumber class="input" id="numberOfRooms" formControlName="numberOfRooms" />
            @if (integralRemodelingForm.controls.numberOfRooms.touched && integralRemodelingForm.controls.numberOfRooms.invalid) {
              @if (integralRemodelingForm.controls.numberOfRooms.errors?.['required']) {
                <small class="error-message">{{ 'This field is required' | transloco }}</small>
              }
              @if (integralRemodelingForm.controls.numberOfRooms.errors?.['min']) {
                <small class="error-message">{{ 'Value must be greater than X' | transloco: { value: integralRemodelingForm.controls.numberOfRooms.errors?.['min'].min } }}</small>
              }
            }
          </div>
        </form>
      }
      <div class="right-container">
        <img class="illustration animate__fadeIn" [src]="selectedImage" alt="{{ 'Illustration of the selected option' | transloco }}" />
        <div class="continue-button-container">
          <app-button label="Continue" styleClass="primary expand" (action)="onSubmit()" [disabled]="isInvalid" />
        </div>
      </div>
    </div>
  </div>
</section>