import { Injectable } from '@angular/core';
import { SubmitContactFormData } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { FormsServiceApi } from './forms.service.api';
import { FormsServiceMock } from './forms.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? FormsServiceMock : FormsServiceApi,
})
export abstract class FormsService {
  abstract submitContact(data: SubmitContactFormData): Promise<string>;
}
