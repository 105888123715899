import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FavoriteItem, SearchEngineProduct } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { CarouselModule } from 'primeng/carousel';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../services/favorites/favorites.service';
import { PlatformService } from '../../services/platform/platform.service';
import { ProductsService } from '../../services/products/products.service';
import { SearchFilter } from '../../services/search/search.service';
import { TranslationItem, TranslationService } from '../../services/translation/translation.service';
import { ProductCardComponent } from '../product-card/product-card.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-products-of-interest',
  standalone: true,
  imports: [
    CarouselModule,
    CommonModule,
    ProductCardComponent,
    RetryableSectionComponent,
    TranslocoPipe,
  ],
  templateUrl: './products-of-interest.component.html',
  styleUrl: './products-of-interest.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ProductsOfInterestComponent implements OnDestroy, OnInit {
  @Input() filters: SearchFilter[] | null = null;
  // TODO: add later on: add required true on innerId
  @Input() innerId = '';
  @Input() title: string | TranslationItem = 'You might also be interested';
  errorLoadingProducts = false;
  favorites: Record<string, FavoriteItem> = {};
  loadingProducts = false;
  products: SearchEngineProduct[] = [];
  titleTranslationItem: TranslationItem;
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private platformService: PlatformService,
    private productsService: ProductsService,
    private translationService: TranslationService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    this.initialize();
    this.getProducts();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getProducts(): void {
    this.loadingProducts = true;
    this.errorLoadingProducts = false;
    combineLatest([
      this.productsService.search(null, { filters: this.filters }),
      this.favoritesService.get()
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: ([search, favorite]) => {
        this.products = search.data;
        this.products = this.products.map((value, index) => ({
          ...value,
          index: index + 1
        }));
        this.favorites = {};
        if (favorite) {
          favorite.items.forEach((item) => this.favorites[`${item.resourceType}-${item.resourceId}`] = item);
        }
        this.loadingProducts = false;
      },
      error: (error) => {
        this.errorReportingService.log('ProductsOfInterestComponent.getProductsOfInterest()', 'get-products-of-interest', error);
        this.products = [];
        this.errorLoadingProducts = true;
        this.loadingProducts = false;
      }
    });
  }

  private initialize(): void {
    this.titleTranslationItem = this.translationService.getTranslationItem(this.title);
  }
}
