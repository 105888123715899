<div class="ecommerce-complementary-bar">
  <div class="search-input-container">
    <app-search-input [query]="query" (inputClick)="openSearch()"></app-search-input>
  </div>
  <div class="main-container">
    <div class="main-wrapper">
      <div class="left-controls">
        @if (showFilters) {
          <button class="custom-button-clear filters" (click)="openFilters()">{{ 'Filters' | transloco }}
            <i class="icon icon-filter" [class.p-overlay-badge]="hasActiveFilters">
              @if (hasActiveFilters) {
                <p-badge></p-badge>
              }
            </i>
          </button>
        } @else if (showBackButton) {
          <button class="custom-button-clear back" (click)="goBack()"><i class="pi pi-angle-left icon"></i></button>
        }
      </div>
      <app-search-input [query]="query" [searchHistory]="searchHistory" (searchClick)="search($event)" (deleteHistoryClick)="deleteSearchHistory()" (changeQuery)="changeQuery($event)"/>
      <div class="right-controls">
        @if (showSearchIcon) {
          <button class="custom-button-clear search" (click)="openSearch()"><i class="icon-search icon"></i></button>
        }
        <a class="custom-button-clear" routerLink="/private-site/my-purchases"><i class="icon-shopping-bag icon"></i></a>
        <a class="custom-button-clear" routerLink="/ecommerce/my-favorites">
          <i class="icon-heart icon" [class.p-overlay-badge]="hasFavorites">
            @if (hasFavorites) {
              <p-badge></p-badge>
            }
          </i>
        </a>
        <a class="custom-button-clear" routerLink="/ecommerce/shopping-cart">
          <i class="icon-supermarketcart icon cart" [class.p-overlay-badge]="shoppingCartDetail && shoppingCartDetail.totalItems">
            @if (shoppingCartDetail && shoppingCartDetail.totalItems) {
              <p-badge [value]="shoppingCartDetail.totalItems.toString()"></p-badge>
            }
          </i>
        </a>
      </div>
    </div>
  </div>
  <div class="ecommerce-bar-spacer"></div>
  <ng-container>
    <ng-content></ng-content>
  </ng-container>
</div>
<app-filter-sidebar #filterSidebar [filtersApplied]="activatedFilters" (applyFiltersClick)="filter($event)" />
<app-search-sidebar #searchSidebar [query]="query" [searchHistory]="searchHistory" (searchClick)="search($event)" (deleteHistoryClick)="deleteSearchHistory()" (changeQuery)="changeQuery($event)" />