import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';

const MAX_SEARCH_HISTORY_ITEMS = 5;

@Injectable({
  providedIn: 'root',
})
export class SearchHistoryService {
  private searchHistory: string[];
  private searchHistorySubject = new BehaviorSubject<string[]>([]);

  constructor(
    private dataStorageService: DataStorageService,
  ) {
    this.searchHistory = this.dataStorageService.getLocal<string[]>(DataKey.SearchHistory) ?? [];
    this.searchHistorySubject.next(this.searchHistory);
  }

  create(query: string): Promise<string> {
    return new Promise((resolve) => {
      this.searchHistory = this.dataStorageService.getLocal<string[]>(DataKey.SearchHistory) ?? [];
      this.searchHistory = this.searchHistory.filter((entry) => entry !== query);
      if (this.searchHistory.length === MAX_SEARCH_HISTORY_ITEMS) {
        this.searchHistory.pop();
      }
      this.searchHistory.unshift(query);
      this.dataStorageService.setLocal(DataKey.SearchHistory, this.searchHistory);
      this.searchHistorySubject.next(this.searchHistory);
      resolve(query);
    });
  }

  getAll(): Observable<string[]> {
    return this.searchHistorySubject.asObservable();
  }

  removeAll(): Promise<void> {
    return new Promise((resolve) => {
      this.searchHistory = [];
      this.dataStorageService.setLocal(DataKey.SearchHistory, this.searchHistory);
      this.searchHistorySubject.next(this.searchHistory);
      resolve();
    });
  }
}
