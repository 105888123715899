<p-sidebar class="remodeling-quote-sidebar" [(visible)]="isVisible" [position]="isMobile ? 'bottom' : 'right'" [blockScroll]="true" [showCloseIcon]="false" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="title">{{ 'Project quote form' | transloco }}</span>
      <button class="custom-button-clear close" (click)="close()"><i class="icon-close"></i></button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="remodeling-quote-container">
      <div class="form-container">
        <form [formGroup]="form" class="form">
          <div class="input-container">
            <label for="budget">{{ 'What is your approximate budget?' | transloco }}</label>
            <p-inputNumber class="input" id="budget" formControlName="budget" inputId="budget" mode="currency" currency="{{ currency }}" placeholder="$" [locale]="locale" [minFractionDigits]="0" />
          </div>
          <div class="input-container">
            <label class="label" for="service">{{ 'What type of remodeling do you want?' | transloco }}</label>
            <p-dropdown class="input" id="service" formControlName="service" [options]="remodelingServices" optionLabel="label" optionValue="value" placeholder="{{ 'Select a remodeling type' | transloco }}" />
          </div>
          <div class="input-container">
            <label class="label" for="postal-code">{{ 'Postal code' | transloco }}</label>
            <input class="input" pInputText id="postal-code" formControlName="postalCode" maxlength="5" />
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="button-container">
      <app-button class="button" label="Send request" styleClass="primary" [disabled]="form.invalid" [loading]="sendingQuote" (action)="sendQuote()" />
    </div>
  </ng-template>
</p-sidebar>