import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MovingOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { CurrencyFormatterPipe } from '../../../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { MovingOrdersService } from '../../../../services/moving-orders/moving-orders.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { openExternalLink } from '../../../../utils/link.utils';

@Component({
  selector: 'app-my-movings',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './my-movings.page.html',
  styleUrl: './my-movings.page.scss',
})
export class MyMovingsPage implements OnInit, OnDestroy {
  errorLoading = false;
  loading = true;
  movingOrders: MovingOrder[] = [];
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private location: Location,
    private movingOrdersService: MovingOrdersService,
    private toastService: ToastService
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  goToChat(movingOrder: MovingOrder): void {
    if (!movingOrder.additionalData['chatUrl']) {
      this.errorReportingService.log('MovingStatusPage.goToChat()', 'go-to-chat', new Error('chatUrl empty or not defined'));
      this.toastService.showWarning({ title: 'Chat not available', description: 'Unable to access the chat at this time' }, 3000);
      return;
    }
    openExternalLink(movingOrder.additionalData['chatUrl']);
  }

  goToReserveTheDate(movingOrder: MovingOrder): void {
    if (!movingOrder.additionalData['quoteUrl']) {
      this.errorReportingService.log('MovingStatusPage.goToReserveTheDate()', 'go-to-reserve-the-date', new Error('quoteUrl empty or not defined'));
      this.toastService.showWarning({ title: 'Reservation date not available', description: 'Unable to access the reserve date at this time' }, 3000);
      return;
    }
    openExternalLink(movingOrder.additionalData['quoteUrl']);
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.movingOrdersService.getAll().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (movingOrders) => {
        this.movingOrders = movingOrders;
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('MyMovingsPage.initialize()', 'get-all-moving-orders', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  childEventClicked(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }
}
