import { Inject, Injectable } from '@angular/core';
import { AddFavoriteItemData, Favorite, FavoritesRouter, RemoveFavoriteItemData, SearchEngineProduct } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { BehaviorSubject, firstValueFrom, from, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { getUpdatedFavoriteItems, MAX_FAVORITES_ITEMS } from '../../utils/favorite.utils';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { FirestoreService } from '../firestore/firestore.service';
import { SearchService } from '../search/search.service';
import { SessionsService } from '../sessions/sessions.service';
import { FavoriteDetail, FavoritesService, LocalFavorite } from './favorites.service';

@Injectable()
export class FavoritesServiceApi implements FavoritesService {
  private client: CreateTRPCProxyClient<FavoritesRouter>;
  private favoriteDetail: Observable<FavoriteDetail | null>;
  private localFavorite = new BehaviorSubject(this.getLocalFavorite());

  constructor(
    private dataStorageService: DataStorageService,
    @Inject('appFirestore') private firestore: FirestoreService,
    private searchService: SearchService,
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<FavoritesRouter>(getTRPCClientOptions(`${environment.apiUrl}/favorites`, () => this.sessionsService.getAccessToken()));
    this.favoriteDetail = this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.doc<FavoriteDetail>(`favorites/${session.userId}`).pipe(switchMap((favorite) => this.getChildren(favorite)));
      }
      return this.localFavorite.asObservable().pipe(switchMap((favorite) => this.getChildren(favorite)));
    }));
  }

  async addItem(data: AddFavoriteItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      return this.client.addItem.mutate(data).then();
    }
    let localFavorite = this.getLocalFavorite();
    if (!localFavorite) {
      localFavorite = {
        items: [{
          ...data,
          addedAt: new Date(),
        }],
      };
    } else {
      localFavorite = {
        ...localFavorite,
        items: getUpdatedFavoriteItems({
          action: 'add',
          items: localFavorite.items,
          addItemData: data,
        }),
      };
    }
    this.dataStorageService.setLocal<LocalFavorite>(DataKey.Favorites, localFavorite);
    this.localFavorite.next(localFavorite);
  }

  get(): Observable<FavoriteDetail | null> {
    return this.favoriteDetail;
  }

  hasFavorite(resourceType: string, resourceId: string): Observable<boolean> {
    return this.favoriteDetail.pipe(map((favorite) => !!favorite?.items.some((item) => item.resourceType === resourceType && item.resourceId === resourceId)));
  }

  hasFavoriteItems(): Observable<boolean> {
    return this.favoriteDetail.pipe(map((favorites) => !!favorites?.items.length));
  }

  async removeItem(data: RemoveFavoriteItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      return this.client.removeItem.mutate(data).then();
    }
    let localFavorite = this.getLocalFavorite();
    if (!localFavorite) {
      localFavorite = {
        items: [],
      };
    } else {
      localFavorite = {
        ...localFavorite,
        items: getUpdatedFavoriteItems({
          action: 'remove',
          items: localFavorite.items,
          removeItemData: data,
        }),
      };
    }
    this.dataStorageService.setLocal<LocalFavorite>(DataKey.Favorites, localFavorite);
    this.localFavorite.next(localFavorite);
  }

  private getChildren(favorite: Favorite | LocalFavorite | null): Observable<FavoriteDetail | null> {
    if (!favorite) {
      return of(null);
    }
    return from(this.searchService.search<SearchEngineProduct>('products', {
      filters: [
        {
          field: 'id',
          operator: 'in',
          value: favorite.items.map((item) => item.resourceId),
        }
      ],
      pagination: {
        documentsPerPage: MAX_FAVORITES_ITEMS,
      },
    })).pipe(map((paginable) => {
      const detailItems = favorite.items.map((item) => {
        if (item.resourceType === 'product') {
          return {
            ...item,
            resource: paginable.data.find((product) => product.id === item.resourceId) ?? null,
          };
        }
        return {
          ...item,
          resource: null,
        };
      }).filter((item) => item.resource);
      return {
        items: detailItems,
      };
    }));
  }

  private getLocalFavorite(): LocalFavorite | null {
    return this.dataStorageService.getLocal<LocalFavorite>(DataKey.Favorites);
  }
}
