import { Injectable } from '@angular/core';
import { SoftTokensRouter } from '@homein-soft-token-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { SoftTokensService } from './soft-tokens.service';

@Injectable({
  providedIn: 'root'
})
export class SoftTokensServiceApi implements SoftTokensService {
  private client: CreateTRPCProxyClient<SoftTokensRouter>;

  constructor() {
    this.client = createTRPCProxyClient<SoftTokensRouter>(getTRPCClientOptions(`${environment.apiUrlSoftTokenServer}/softTokens`));
  }

  async enrollSoftToken(email: string, cPublicX: string, cPublicY: string): Promise<{
    id: string;
    sPublicX: string;
    sPublicY: string;
    salt: string;
  }> {
    return await this.client.create.mutate({ uid: email, cPublicX, cPublicY });
  }
}
