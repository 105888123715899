/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export function areEqualObjects(first: any, second: any): boolean {
  if (!first || !second) {
    return first === second;
  }
  const firstProps = Object.getOwnPropertyNames(first);
  const secondProps = Object.getOwnPropertyNames(second);
  if (firstProps.length != secondProps.length) {
    return false;
  }
  for (let i = 0; i < firstProps.length; i++) {
    const firstValue = first[firstProps[i]];
    const secondValue = second[firstProps[i]];
    const isObjects = isObject(firstValue) && isObject(secondValue);
    if (isObjects && !areEqualObjects(firstValue, secondValue) || !isObjects && firstValue !== secondValue) {
      return false;
    }
  }
  return true;
}
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export function isObject(object: any): boolean {
  return object != null && typeof object === 'object';
}
