import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { TranslationItem, TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'app-reminder',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './reminder.component.html',
  styleUrl: './reminder.component.scss',
})
export class ReminderComponent implements OnChanges {
  @Output() action = new EventEmitter<void>();
  @Input() actionLabel: string | TranslationItem;
  @Input() description: string | TranslationItem;
  @Input() styleClass: string = 'info';
  @Input({ required: true }) title: string | TranslationItem;
  actionTranslationItem: TranslationItem;
  descriptionTranslationItem: TranslationItem;
  titleTranslationItem: TranslationItem;

  constructor(
    private translationService: TranslationService,
  ) {}

  ngOnChanges(): void {
    this.initialize();
  }

  triggerAction(): void {
    this.action?.emit();
  }

  private initialize(): void {
    if (this.action) {
      this.actionTranslationItem = this.translationService.getTranslationItem(this.actionLabel);
    }
    if (this.description) {
      this.descriptionTranslationItem = this.translationService.getTranslationItem(this.description);
    }
    this.titleTranslationItem = this.translationService.getTranslationItem(this.title);
  }
}
