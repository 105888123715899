import { Injectable } from '@angular/core';
import { EcommerceOrder } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EcommerceOrdersServiceApi } from './ecommerce-orders.service.api';
import { EcommerceOrdersServiceMock } from './ecommerce-orders.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? EcommerceOrdersServiceMock : EcommerceOrdersServiceApi,
})
export abstract class EcommerceOrdersService {
  abstract get(id: string): Observable<EcommerceOrder | null>;
  abstract getAll(): Observable<EcommerceOrder[]>;
  abstract getByPaymentIntentId(id: string): Observable<EcommerceOrder | null>;
  abstract getLastInProgress(): Observable<EcommerceOrder | null>;
}
