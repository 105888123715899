import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DataKey, DataStorageService } from '../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../services/error-reporting/error-reporting.service';
import { PlatformService } from '../services/platform/platform.service';
import { SessionsService } from '../services/sessions/sessions.service';
import { LocalUser, UsersService } from '../services/users/users.service';

export const sessionGuard = async (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Promise<boolean | UrlTree> => {
  const dataStorageService = inject(DataStorageService);
  const errorReportingService = inject(ErrorReportingService);
  const platformService = inject(PlatformService);
  const router = inject(Router);
  const sessionsService = inject(SessionsService);
  const usersService = inject(UsersService);
  let step = '';
  try {
    step = 'check-if-platform-server';
    if (platformService.isServer()) {
      return false;
    }
    const redirectTo = encodeURIComponent(routerStateSnapshot.url);
    const queryParams = `?redirectTo=${redirectTo}`;
    step = 'get-session';
    const session = await firstValueFrom(sessionsService.getSession());
    step = 'get-local-user';
    let localUser = dataStorageService.getLocal<LocalUser>(DataKey.LocalUser);
    if (!localUser) {
      const user = await firstValueFrom(usersService.getCurrentUser());
      if (user) {
        localUser = {
          bankValidated: user.bankValidated,
          email: user.email,
          emailValidated: user.emailValidated,
          enrollmentStatus: user.enrollmentStatus,
          fatherLastName: user.fatherLastName,
          id: user.id,
          motherLastName: user.motherLastName,
          name: user.name,
          phoneNumber: user.phoneNumber,
          profileDataCompleted: user.profileDataCompleted,
        };
        dataStorageService.setLocal(DataKey.LocalUser, localUser);
      } else {
        return router.parseUrl(`/enrollment/sign-in${queryParams}`);
      }
    }
    step = 'resolve-navigation';
    if (session && localUser.enrollmentStatus === 'done') {
      return true;
    }
    return router.parseUrl(`/enrollment/sign-in${queryParams}`);
  } catch (error) {
    errorReportingService.log('sessionGuard()', step, error);
    return false;
  }
};
