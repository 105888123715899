<div class="onboarding-container">
  <section class="onboarding-section">
    <div class="column left">
      <h1 class="title">{{ 'Almost there! Fill in your data' | transloco }}</h1>
      <form [formGroup]="form" class="form-container">
        <div class="input-container">
          <label for="name">{{ 'Name' | transloco }}*</label>
          <input type="text" id="name" [class.active]="isActive(this.form.controls.name)" pInputText [formControl]="this.form.controls.name" maxLength="36" aria-describedby="name-help"/>
          <small id="name-help" [class.hidden]="!this.form.controls.name.touched || !this.form.controls.name.errors" class="p-error input-error">{{ 'Enter a valid name.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="father-last-name">{{ 'First last name' | transloco }}*</label>
          <input type="text" id="father-last-name" [class.active]="isActive(this.form.controls.fatherLastName)" pInputText [formControl]="this.form.controls.fatherLastName" maxLength="36" aria-describedby="father-last-name-help"/>
          <small id="father-last-name-help" [class.hidden]="!this.form.controls.fatherLastName.touched || !this.form.controls.fatherLastName.errors" class="p-error input-error">{{ 'Enter a valid first last name.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="mother-last-name">{{ 'Second last name' | transloco }}*</label>
          <input type="text" id="mother-last-name" [class.active]="isActive(this.form.controls.motherLastName)" pInputText [formControl]="this.form.controls.motherLastName" maxLength="36" aria-describedby="mother-last-name-help"/>
          <small id="mother-last-name-help" [class.hidden]="!this.form.controls.motherLastName.touched || !this.form.controls.motherLastName.errors" class="p-error input-error">{{ 'Enter a valid second last name.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="phone-number">{{ 'Mobile phone number' | transloco }}*</label>
          <p-inputMask id="phone-number" [class.active]="isActive(this.form.controls.phoneNumber)" [formControl]="this.form.controls.phoneNumber" [unmask]="true" [autoClear]="false" mask="+(99) 99 9999 9999" placeholder="+(52) 99 9999 9999" slotChar="" aria-describedby="phone-number-help"></p-inputMask>
          <small [class.hidden]="!this.form.controls.phoneNumber.touched || !this.form.controls.phoneNumber.errors" class="p-error input-error">{{ 'Enter a valid phone number, it must start with \"52\" and then 10 digits.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="birth-date">{{ 'Birth date' | transloco }}*</label>
          <p-calendar id="birth-date" [class.active]="isActive(this.form.controls.birthDate)" [formControl]="this.form.controls.birthDate" [maxDate]="maxDateBirthDate" [defaultDate]="maxDateBirthDate" dateFormat="dd/mm/yy" aria-describedby="birth-date-help"></p-calendar>
          <small [class.hidden]="this.form.controls.birthDate.pristine || !this.form.controls.birthDate.touched || !this.form.controls.birthDate.errors" class="p-error input-error">{{ 'Enter a valid birth date.' | transloco }}</small>
        </div>
        <p class="required-fields">{{ 'Required fields*' | transloco }}</p>
        <p class="legal-text">{{ 'By clicking the "Save data" button you agree to our' | transloco }} <a class="link" href="https://ayuda.homein.mx/politicas-de-privacidad" target="_blank" rel="noopener">{{ 'Privacy policies' | transloco }}</a> {{ 'and' | transloco }} <a class="link" href="https://ayuda.homein.mx/terminos-y-condiciones" target="_blank" rel="noopener">{{ 'Terms and conditions' | transloco }}.</a></p>
        <app-button label="Save data" styleClass="primary expand" [disabled]="!form.valid || saving" [loading]="saving" (action)="save()" />
      </form>
    </div>
    <div class="column right">
      <div class="text-container">
        <h2 class="title">Mundo Hogar</h2>
        <p class="subtitle">{{ 'You are just a few steps away from activating your account' | transloco }}</p>
      </div>
    </div>
  </section>
</div>
