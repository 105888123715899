import { EventEmitter, Injectable } from '@angular/core';
import { IdTokenResult } from '@angular/fire/auth';
import { Account } from '@homein-accounts-server';
import { SendPasswordRecoveryData, User } from '@homein-hogar-server';
import * as faker from 'faker';
import { firstValueFrom, Observable } from 'rxjs';
import { CONFIG } from '../../constants';
import { triggerRandomError } from '../../utils/mock.utils';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { MockService } from '../mock/mock.service';
import { Session, SessionsService } from './sessions.service';

@Injectable()
export class SessionsServiceMock implements SessionsService {
  private onSignInEventEmitter = new EventEmitter<void>();
  private onSignOutEventEmitter = new EventEmitter<void>();
  private session: Observable<Session | null>;

  constructor(
    private dataStorageService: DataStorageService,
    private mockService: MockService,
  ) {
    this.session = new Observable((subscriber) => {
      const idToken = this.dataStorageService.getLocal<string>(DataKey.MockIdToken);
      const idTokenResult = this.dataStorageService.getLocal<IdTokenResult>(DataKey.MockIdTokenResult);
      if (idToken && idTokenResult) {
        subscriber.next({
          claims: idTokenResult.claims,
          token: idToken,
          userId: idTokenResult.claims['app_user_id'] as string
        });
        this.onSignInEventEmitter.emit();
      } else {
        subscriber.next(null);
        this.onSignOutEventEmitter.emit();
      }
    });
  }

  getAccessToken(): Promise<string | null> {
    return firstValueFrom(this.getSession()).then((session) => session?.token || null);
  }

  getSession(): Observable<Session | null> {
    return this.session;
  }

  onSignIn(): EventEmitter<void> {
    return this.onSignInEventEmitter;
  }

  onSignOut(): EventEmitter<void> {
    return this.onSignOutEventEmitter;
  }

  async sendEmailVerification(): Promise<void> {
    let step = '';
    try {
      step = 'send-verification-email';
      const session = await firstValueFrom(this.getSession());
      if (!session) {
        throw new Error('Session not found');
      }
      const user = await firstValueFrom(this.mockService.getUser(session.userId));
      if (!user) {
        throw new Error('User not found');
      }
      await this.mockService.updateUser(user.id, {
        lastEmailVerificationSentAt: new Date(),
      });
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.verifyEmail('123456');
      }, 5000);
    } catch (error) {
      return Promise.reject({ code: 'unknown', message: 'Unknown error', error, step });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendPasswordRecovery(data: SendPasswordRecoveryData): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 1000);
    });
  }

  async signIn(options: { provider: 'apple' } | { provider: 'google' } | { data: { email: string; password: string; }; provider: 'email'; }): Promise<void> {
    try {
      if (options.provider !== 'email') {
        throw new Error('Provider not supported');
      }
      const users = await firstValueFrom(this.mockService.getUsers());
      const user = users.find((mock) => mock.email === options.data.email);
      if (!user) {
        return Promise.reject({ code: 'invalid-credential', message: 'Invalid credential.' });
      }
      await this.mockService.updateUser(user.id, {
        lastSignInAt: new Date(),
      });
      this.createSession(user.id);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  signOut(): Promise<void> {
    this.dataStorageService.clear(DataKey.LocalUser);
    this.dataStorageService.clear(DataKey.MockIdToken);
    this.dataStorageService.clear(DataKey.MockIdTokenResult);
    this.dataStorageService.clear(DataKey.MockUserId);
    this.onSignOutEventEmitter.emit();
    return Promise.resolve();
  }

  async signUp(options: { data: { email: string; password: string; }; provider: 'email'; }): Promise<void> {
    let step = '';
    try {
      if (options.provider !== 'email') {
        throw new Error('Provider not supported');
      }
      const users = await firstValueFrom(this.mockService.getUsers());
      const existingUser = users.find((mock) => mock.email === options.data.email);
      if (existingUser) {
        return Promise.reject({ code: 'auth/email-already-exists', message: 'Email already exists.' });
      }
      const newUser: Omit<User, 'createdAt' | 'id' | 'updatedAt'> = {
        bankValidated: false,
        birthDate: null,
        email: options.data.email,
        emailValidated: false,
        enrollmentStatus: 'pending',
        fatherLastName: null,
        lastEmailVerificationSentAt: null,
        lastSignInAt: null,
        motherLastName: null,
        name: null,
        phoneNumber: null,
        profileDataCompleted: false,
        status: 'active',
        statusDetail: null,
        uid: faker.random.alphaNumeric(20),
      };
      step = 'create-user';
      const userId = await this.mockService.createUser(newUser);
      step = 'create-accounts';
      const defaultValues: Omit<Account, 'createdAt' | 'id' | 'type' | 'updatedAt'> = {
        additionalData: null,
        balance: 0,
        blockedBalance: 0,
        currency: CONFIG.defaultCurrency,
        maxBalance: null,
        ownerId: userId,
        ownerType: 'user',
        status: 'active',
        transactionId: null,
        usageRules: null,
      };
      const accounts = await Promise.allSettled([
        this.mockService.createAccount({
          ...defaultValues,
          type: 'debit',
        }),
        this.mockService.createAccount({
          ...defaultValues,
          type: 'mortgage',
        })
      ]);
      if (accounts[0].status === 'rejected' || accounts[1].status === 'rejected') {
        throw new Error('Error creating accounts');
      }
      step = 'create-session';
      this.createSession(userId);
      step = 'send-email-verification';
      await this.sendEmailVerification();
    } catch (error) {
      return Promise.reject({ code: 'unknown', message: 'Unknown error', error, step });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatePassword(password: string, resetCode?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 1000);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async verifyEmail(token: string): Promise<void> {
    let step = '';
    try {
      step = 'verify-email';
      const session = await firstValueFrom(this.getSession());
      if (!session) {
        throw new Error('Session not found');
      }
      const user = await firstValueFrom(this.mockService.getUser(session.userId));
      if (!user) {
        throw new Error('User not found');
      }
      await this.mockService.updateUser(user.id, {
        emailValidated: true,
        enrollmentStatus: user.bankValidated && user.profileDataCompleted ? 'done' : 'pending',
      });
    } catch (error) {
      return Promise.reject({ code: 'unknown', message: 'Unknown error', error, step });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  verifyPasswordResetCode(code: string): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 1000);
    });
  }

  private createSession(userId: string): void {
    const idToken = faker.random.alphaNumeric(128);
    const authTime = new Date();
    const expirationDate = new Date(authTime.getTime());
    expirationDate.setDate(expirationDate.getDate() + 7);
    const idTokenResult: IdTokenResult = {
      authTime: authTime.toISOString(),
      expirationTime: expirationDate.toISOString(),
      issuedAtTime: new Date().toISOString(),
      signInProvider: 'password',
      signInSecondFactor: null,
      token: idToken,
      claims: {
        app_user_id: userId,
      },
    };
    this.dataStorageService.setLocal(DataKey.MockIdToken, idToken);
    this.dataStorageService.setLocal(DataKey.MockIdTokenResult, idTokenResult);
    this.dataStorageService.setLocal(DataKey.MockUserId, userId);
    this.onSignInEventEmitter.emit();
  }
}
