import { Routes } from '@angular/router';
import { sessionGuard } from './guards/session.guard';

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
  },
  {
    path: 'contact',
    loadComponent: () => import('./pages/contact/contact.page').then(m => m.ContactPage),
  },
  {
    path: 'loading',
    loadComponent: () => import('./pages/loading/loading.page').then(m => m.LoadingPage),
  },
  {
    path: 'home-assistance',
    loadChildren: () => import('./pages/home-assistance/home-assistance.routes'),
  },
  {
    path: 'ecommerce',
    loadChildren: () => import('./pages/ecommerce/ecommerce.routes'),
  },
  {
    path: 'enrollment',
    loadChildren: () => import('./pages/enrollment/enrollment.routes'),
  },
  {
    path: 'mortgage-simulator',
    loadChildren: () => import('./pages/mortgage-simulator/mortgage-simulator.routes'),
  },
  {
    path: 'moving',
    loadChildren: () => import('./pages/moving/moving.routes'),
  },
  {
    path: 'payment-gateway',
    canActivate: [sessionGuard],
    loadChildren: () => import('./pages/payment-gateway/payment-gateway.routes'),
  },
  {
    path: 'private-site',
    canActivate: [sessionGuard],
    loadChildren: () => import('./pages/private-site/private-site.routes'),
  },
  {
    path: 'project-simulator',
    loadChildren: () => import('./pages/project-simulator/project-simulator.routes'),
  },
  {
    path: 'remodeling',
    loadChildren: () => import('./pages/remodeling/remodeling.routes'),
  },
  {
    path: 'warehouse',
    loadChildren: () => import('./pages/warehouse/warehouse.routes'),
  },
  {
    path: 'what-is',
    loadChildren: () => import('./pages/what-is-mundo-hogar/what-is-mundo-hogar.routes'),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];
