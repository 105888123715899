<p-sidebar class="filter-sidebar" [(visible)]="isVisible" [position]="isMobile ? 'bottom' : 'right'" [blockScroll]="true" [showCloseIcon]="false" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="title">{{ 'Sort and filter' | transloco }}</span>
      <button class="custom-button-clear close" (click)="cancel()"><i class="icon-close"></i></button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="filters-container">
      @if(!defaultFilters) {
        <div class="active-filters">
          @if (form.controls.max.value && form.controls.min.value) {
            <div class="chip" (click)="resetControls([form.controls.max, form.controls.min])">
              <i class="icon-cancel-fill"></i>
              <p class="label">{{ form.controls.min.value || 0 | currencyFormatter: currency }} - {{ form.controls.max.value || 0 | currencyFormatter: currency }} {{ currency }}</p>
            </div>
          } @else if (form.controls.max.value) {
            <div class="chip" (click)="resetControls(form.controls.max)">
              <i class="icon-cancel-fill"></i>
              <p class="label">{{ 'To' | transloco }} {{ form.controls.max.value || 0 | currencyFormatter: currency }} {{ currency }}</p>
            </div>
          } @else if (form.controls.min.value) {
            <div class="chip" (click)="resetControls(form.controls.min)">
              <i class="icon-cancel-fill"></i>
              <p class="label">{{ 'From' | transloco }} {{ form.controls.min.value || 0 | currencyFormatter: currency }}</p>
            </div>
          }
          @if (form.controls.sortBy.value?.field !== 'default') {
            <div class="chip" (click)="resetControls(form.controls.sortBy, sortOptionDefault)">
              <i class="icon-cancel-fill"></i>
              <p class="label">{{ getSortByLabel(form.controls.sortBy.value) | transloco }}</p>
            </div>
          }
          @if (form.controls.ratings.value?.length) {
            @for (rating of form.controls.ratings.value; track $index) {
              <div class="chip" (click)="deleteValue(form.controls.ratings, rating)">
                <i class="icon-cancel-fill"></i>
                <p-rating class="label rating" [readonly]="true" [cancel]="false" [ngModel]="rating"></p-rating>
              </div>
            }
          }
          @if (form.controls.discounts.value?.length) {
            @for (discount of form.controls.discounts.value; track $index) {
              <div class="chip" (click)="deleteValue(form.controls.discounts, discount)">
                <i class="icon-cancel-fill"></i>
                <p class="label">{{ 'X% or more' | transloco: { quantity: discount * 100 } }}</p>
              </div>
            }
          }
        </div>
      }
      <p-accordion class="accordion-filters">
        <form [formGroup]="form" class="form">
          <p-accordionTab class="accordion-tab" header="{{ 'Sort' | transloco }}" iconPos="end">
            @for (option of sortOptions; track $index) {
              <div class="option">
                <label [for]="option.label" class="ml-2">{{ option.label | transloco }}</label>
                <p-radioButton class="radio-button" [inputId]="option.label" [value]="option.value" formControlName="sortBy"></p-radioButton>
              </div>
            }
          </p-accordionTab>
          <p-accordionTab class="accordion-tab" header="{{ 'Price range' | transloco }}" iconPos="end">
            <div class="input-filter-container">
              <label for="minPrice">{{ 'From' | transloco }}*</label>
              <p-inputNumber class="input" id="minPrice" formControlName="min" inputId="min-price" mode="currency" currency="{{ currency }}" placeholder="$" [locale]="locale" [minFractionDigits]="0"/>
            </div>
            <div class="input-filter-container">
              <label for="maxPrice">{{ 'To' | transloco }}*</label>
              <p-inputNumber class="input" id="maxPrice" formControlName="max" inputId="max-price" mode="currency" currency="{{ currency }}" placeholder="$" [locale]="locale" [minFractionDigits]="0"/>
            </div>
          </p-accordionTab>
          <p-accordionTab class="accordion-tab" header="{{ 'Discounts' | transloco }}" iconPos="end">
            @for (discount of discountOptions; track $index) {
              <div class="option">
                <label>{{ 'X% or more' | transloco: { quantity: discount * 100 } }}</label>
                <p-checkbox class="checkbox" [formControl]="form.controls.discounts" [value]="discount"></p-checkbox>
              </div>
            }
          </p-accordionTab>
          <!-- TODO: disabled until we have products with rating -->
          <!--<p-accordionTab class="accordion-tab" header="{{ 'Customer ratings' | transloco }}" iconPos="end">
            @for (rating of ratingOptions; track $index) {
              <div class="option">
                <div class="rating-container">
                  <p-rating class="rating" [readonly]="true" [cancel]="false" [ngModel]="rating" [ngModelOptions]="{ standalone: true }"></p-rating>
                  <span class="text">{{ 'or more' | transloco }}</span>
                </div>
                <p-checkbox class="checkbox" [formControl]="form.controls.ratings" [value]="rating"></p-checkbox>
              </div>
            }
          </p-accordionTab>-->
        </form>
      </p-accordion>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="filter-sidebar-buttons-container">
      <app-button label="Delete all" styleClass="secondary" [disabled]="defaultFilters && !changedFilters" (action)="deleteFiltersAndClose()" />
      <app-button label="Apply filters" styleClass="primary" [disabled]="!changedFilters || form.invalid" (action)="applyFilters()" />
    </div>
  </ng-template>
</p-sidebar>