import { Injectable } from '@angular/core';
import { CreateRemodelingOrderData, RemodelingOrder } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RemodelingOrdersServiceApi } from './remodeling-orders.service.api';
import { RemodelingOrdersServiceMock } from './remodeling-orders.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? RemodelingOrdersServiceMock : RemodelingOrdersServiceApi,
})
export abstract class RemodelingOrdersService {
  abstract create(data: CreateRemodelingOrderData): Promise<string>;
  abstract get(id: string): Observable<RemodelingOrder | null>;
  abstract getAll(): Observable<RemodelingOrder[]>;
  abstract getLastInProgress(): Observable<RemodelingOrder | null>;
}
