import { Injectable } from '@angular/core';
import { HomeAssistanceOrder } from '@homein-hogar-server';
import { map, Observable, of, switchMap } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { SessionsService } from '../sessions/sessions.service';
import { HomeAssistanceOrdersService } from './home-assistance-orders.service';

@Injectable()
export class HomeAssistanceOrdersServiceMock implements HomeAssistanceOrdersService {
  constructor(
    private mockService: MockService,
    private sessionsService: SessionsService,
  ) {}

  get(id: string): Observable<HomeAssistanceOrder | null> {
    return this.mockService.getHomeAssistanceOrder(id);
  }

  getAll(): Observable<HomeAssistanceOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap(session => {
      if (session) {
        return this.mockService.getHomeAssistanceOrders().pipe(map((homeAssistanceOrders) => {
          return homeAssistanceOrders.filter((homeAssistanceOrder) => homeAssistanceOrder.userId === session.userId);
        }));
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<HomeAssistanceOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap(session => {
      if (session) {
        return this.mockService.getHomeAssistanceOrders().pipe(map((homeAssistanceOrders) => {
          return homeAssistanceOrders.filter((homeAssistanceOrder) => homeAssistanceOrder.status === 'active' && homeAssistanceOrder.userId === session.userId)[0] ?? null;
        }));
      }
      return of(null);
    }));
  }
}
