import { Injectable } from '@angular/core';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { NumberStyles, TranslocoLocaleService } from '@ngneat/transloco-locale';
import { firstValueFrom } from 'rxjs';

export interface TranslationItem {
  key: string;
  params?: HashMap;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(
    private translocoLocaleService: TranslocoLocaleService,
    private translocoService: TranslocoService,
  ) {}

  getCurrencySymbol(locale?: string): string | null {
    return this.translocoLocaleService.getCurrencySymbol(locale) ?? null;
  }

  getTranslationItem(item: string | TranslationItem): TranslationItem {
    if (typeof item === 'string') {
      return { key: item };
    }
    return item;
  }

  localizeNumber(value: number, style: NumberStyles, locale?: string | undefined, options?: Intl.NumberFormatOptions): string {
    return this.translocoLocaleService.localizeNumber(value, style, locale, options);
  }

  async setActiveLanguage(lang: string): Promise<void> {
    const availableLanguages = this.translocoService.getAvailableLangs();
    if (!availableLanguages.some((availableLang) => availableLang === lang)) {
      throw new Error(`Language ${lang} is not supported`);
    }
    this.translocoService.setActiveLang(lang);
    await firstValueFrom(this.translocoService.load(lang));
  }

  translate(item: string | TranslationItem): string {
    const translationItem = this.getTranslationItem(item);
    return this.translocoService.translate(translationItem.key, translationItem.params);
  }
}
