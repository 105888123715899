<p-sidebar [(visible)]="visible" styleClass="sidebar-container" position="left" blockScroll="false" showCloseIcon="false" (onHide)="close()">
  <ng-template pTemplate="headless">
    <div class="title-container">
      <h1 class="title">Mundo Hogar</h1>
      <p-button class="button" id="btn-link-ediU4_Ad" icon="pi pi-times" [rounded]="true" [text]="true" severity="secondary" (click)="close()" />
    </div>
    <p-scrollPanel [style]="scrollPanelConfig" class="scrollpanel">
      <p-accordion class="menu-sidebar" [multiple]="true" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
        <p-accordionTab class="accordion-tab-home" [class.selected]="currentPage === 'home'" iconPos="end">
          <ng-template pTemplate="header">
            <a class="accordion-header" [routerLink]="'/private-site'">
              <i class="icon icon-house"></i>
              <span class="category-title">{{ 'My Home' | transloco }}</span>
            </a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'my-profile'" class="accordion-tab" [class.selected]="currentPage === 'my-profile'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-account-user"></i>
              <span class="category-title">{{ 'My profile' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/private-site/profile/my-balance" id="btn-link-q_fJP4qb">{{ 'Balance' | transloco }}</a>
            <p-accordionTab class="subcategory-title" header="{{ 'Services and shopping' | transloco }}" iconPos="end" [selected]="true">
                <a class="selectable" routerLink="/private-site/my-home-assistances" id="btn-link-ZqLwM8x1">{{ 'My home assistance plans' | transloco }}</a>
                <a class="selectable" routerLink="/warehouse" id="btn-link-AcFjQ7p8">{{ 'My warehouses' | transloco }}</a>
                <a class="selectable" routerLink="/private-site/my-purchases" id="btn-link-YxErF5o9">{{ 'My purchases' | transloco }}</a>
                <a class="selectable" routerLink="/private-site/my-movings" id="btn-link-CxLzK6v2">{{ 'My movings' | transloco }}</a>
                <a class="selectable" routerLink="/private-site/my-remodelings" id="btn-link-PxYfD3g4">{{ 'My remodeling' | transloco }}</a>
            </p-accordionTab>
            <a class="selectable" routerLink="/private-site/profile/my-personal-data" id="btn-link-FxBvG1q3">{{ 'Personal data' | transloco }}</a>
          </ng-template>
        </p-accordionTab>
        <p-divider class="divider" />
        <p-accordionTab [selected]="currentPage === 'remodeling'" class="accordion-tab" [class.selected]="currentPage === 'remodeling'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-remodeling"></i>
              <span class="category-title">{{ 'Remodeling' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/remodeling" id="btn-link-QvNjD7x9">{{ 'Go to remodeling' | transloco }}</a>
            <a class="selectable" routerLink="/project-simulator" id="btn-link-WbMfH4r5">{{ 'Simulate remodeling' | transloco }}</a>
            <a class="selectable" routerLink="/remodeling" [queryParams]="{ action: 'quote' }" id="btn-link-VdKhJ2u1">{{ 'Quote a remodeling' | transloco }}</a>
            <a class="selectable" routerLink="/private-site/my-remodelings" id="btn-link-ZnLxK9m3">{{ 'My remodeling' | transloco }}</a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'moving'" class="accordion-tab" [class.selected]="currentPage === 'moving'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-truck"></i>
              <span class="category-title">{{ 'Movings' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/moving" id="btn-link-TbGjN8o5">{{ 'Go to movings' | transloco }}</a>
            <a class="selectable" routerLink="/moving" [queryParams]="{ action: 'quote' }" id="btn-link-XpNjQ4v7">{{ 'Quote my movings' | transloco }}</a>
            <a class="selectable" routerLink="/private-site/my-movings" id="btn-link-RcFxL2d8">{{ 'My movings' | transloco }}</a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'ecommerce'" class="accordion-tab" [class.selected]="currentPage === 'ecommerce'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-supermarketcart"></i>
              <span class="category-title">{{ 'Shopping for home' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/ecommerce" id="btn-link-KfHvC9x2">{{ 'Go to shopping' | transloco }}</a>
            <a class="selectable" routerLink="/ecommerce/my-favorites" id="btn-link-PzLfG7o9">{{ 'My favorites' | transloco }}</a>
            <a class="selectable" routerLink="/ecommerce/shopping-cart" id="btn-link-UgNvR3y1">{{ 'My shopping cart' | transloco }}</a>
            <a class="selectable" routerLink="/private-site/my-purchases" id="btn-link-WxJfK5z4">{{ 'My purchases' | transloco }}</a>
            <p-accordionTab class="subcategory-title" header="{{ 'Categories' | transloco }}" iconPos="end">
              @for (category of categories; track $index) {
                <p-accordionTab class="sub-subcategory-title" [header]="category.label" iconPos="end">
                  @for (children of category.children; track $index) {
                    <a class="sub-subcategory-text selectable" routerLink="/ecommerce/search" [queryParams]="children.queryParams">{{ children.label }}</a>
                  }
                </p-accordionTab>
              }
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'warehouse'" class="accordion-tab" [class.selected]="currentPage === 'warehouse'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-warehouses"></i>
              <span class="category-title">{{ 'Rent warehouse' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/warehouse" id="btn-link-cs3eEkuw">{{ 'Go to warehouses' | transloco }}</a>
            <a class="selectable" routerLink="/warehouse" id="btn-link-3mcpSezl">{{ 'My warehouses' | transloco }}</a>
            <a class="selectable" routerLink="/warehouse" id="btn-link-Bz_GOMtT">{{ 'Quote my warehouses' | transloco }}</a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'home-assistance'" class="accordion-tab" [class.selected]="currentPage === 'home-assistance'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-home-assistance"></i>
              <span class="category-title">{{ 'Home assistance' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/home-assistance" id="btn-link-dUIc_slp">{{ 'Go to home assistance' | transloco }}</a>
            <a class="selectable" routerLink="/private-site/my-home-assistances" id="btn-link-Psys3dBp">{{ 'My home assistance plans' | transloco }}</a>
            <p-accordionTab class="subcategory-title" header="{{ 'Quote my home assistance plan' | transloco }}" iconPos="end">
              <a class="selectable" routerLink="/home-assistance/plan-hogar" id="btn-link-3emmUbJb">Plan Hogar</a>
              <a class="selectable" routerLink="/home-assistance/plan-mascotas" id="btn-link-gl9Qd06c">Plan Mascotas</a>
              <a class="selectable" routerLink="/home-assistance/plan-hogar-mascotas" id="btn-link-PFFG_qWB">Plan Hogar + Mascotas</a>
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'finance-and-insurance'" class="accordion-tab" [class.selected]="currentPage === 'finance-and-insurance'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-hand-plant"></i>
              <span class="category-title">{{ 'Finance and insurance' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" href="https://www.santander.com.mx/personas/creditos-hipotecarios.html" target="_blank" rel="noopener" id="btn-link-L9LtQe8Q">{{ 'Santander mortgage' | transloco }}</a>
            <a class="selectable" href="https://www.santander.com.mx/personas/creditos-personales.html" target="_blank" rel="noopener" id="btn-link-eTO-lzef">{{ 'Credit consume Santander' | transloco }}</a>
            <a class="selectable" href="https://www.santander.com.mx/personas/tarjetas-de-credito.html" target="_blank" rel="noopener" id="btn-link-Sn29E2O5">{{ 'Credit card Santander' | transloco }}</a>
            <a class="selectable" href="https://www.santander.com.mx/personas/seguros.html" target="_blank" rel="noopener" id="btn-link-M5qsLOeZ">{{ 'Santander insurance' | transloco }}</a>
            <p-accordionTab class="subcategory-title" header="{{ 'Simulators' | transloco }}" iconPos="end">
<!--              <a class="selectable" routerLink="/mortgage-simulator">{{ 'Simulate mortgage' | transloco }}</a>-->
              <a class="selectable" routerLink="/project-simulator" id="btn-link-uTqM7wJQ">{{ 'Remodeling simulator' | transloco }}</a>
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'help-center'" class="accordion-tab" [class.selected]="currentPage === 'help-center'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-help-circle"></i>
              <span class="category-title">{{ 'Help center' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/" target="_blank" id="btn-link-G1SsRzu9">{{ 'Go to help center' | transloco }}</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/preguntas-frecuentes" target="_blank" id="btn-link-SWrWyW5i">{{ 'FAQ' | transloco }}</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank" id="btn-link-0bsSyfj3">{{ 'Privacy policies' | transloco }}</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank" id="btn-link-t7gBo3FW">{{ 'Terms and conditions' | transloco }}</a>
            <a class="selectable" routerLink="/complaints-and-returns" id="btn-link-JXqiUDC0">{{ 'Complaints and returns' | transloco }}</a>
            <a class="selectable" routerLink="/contact" id="btn-link-OzLdlMXR">{{ 'Contact' | transloco }}</a>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </p-scrollPanel>
  </ng-template>
</p-sidebar>