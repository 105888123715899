import { Injectable } from '@angular/core';
import {
  AddShoppingCartItemData,
  EcommercePricingExternal,
  RemoveShoppingCartItemData,
  SearchEngineProduct,
  ShoppingCart,
} from '@homein-hogar-server';
import { BehaviorSubject, firstValueFrom, from, map, Observable, of, switchMap } from 'rxjs';
import { CONFIG } from '../../constants';
import { getUpdatedShoppingCartItems } from '../../utils/shopping-cart.utils';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { MockService } from '../mock/mock.service';
import { SearchService } from '../search/search.service';
import { SessionsService } from '../sessions/sessions.service';
import { LocalShoppingCart, ShoppingCartDetail, ShoppingCartsService } from './shopping-carts.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartsServiceMock implements ShoppingCartsService {
  private localShoppingCart = new BehaviorSubject(this.getLocalShoppingCart());
  private shoppingCartDetail: Observable<ShoppingCartDetail | null>;

  constructor(
    private dataStorageService: DataStorageService,
    private mockService: MockService,
    private searchService: SearchService,
    private sessionsService: SessionsService,
  ) {
    this.shoppingCartDetail = this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.mockService.getShoppingCart(session.userId).pipe(switchMap((shoppingCart) => {
          return this.getMockedChildren(shoppingCart);
        }));
      }
      return this.localShoppingCart.asObservable().pipe(switchMap((localShoppingCart) => {
        return this.getMockedChildren(localShoppingCart);
      }));
    }));
  }

  async addItem(data: AddShoppingCartItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const shoppingCart = await firstValueFrom(this.mockService.getShoppingCart(session.userId));
      if (!shoppingCart) {
        return this.mockService.createShoppingCart({
          currency: CONFIG.defaultCurrency,
          items: [{
            ...data,
            addedAt: new Date(),
          }],
          id: session.userId,
          shippingAddressId: null,
          userId: session.userId,
        }).then();
      }
      return this.mockService.updateShoppingCart(session.userId, {
        items: getUpdatedShoppingCartItems({
          items: shoppingCart.items,
          action: 'add',
          addItemData: data,
        }),
      });
    }
    let localShoppingCart = this.getLocalShoppingCart();
    if (!localShoppingCart) {
      localShoppingCart = {
        currency: CONFIG.defaultCurrency,
        items: [{
          ...data,
          addedAt: new Date(),
        }],
        shippingAddressId: null,
      };
    } else {
      localShoppingCart = {
        ...localShoppingCart,
        items: getUpdatedShoppingCartItems({
          items: localShoppingCart.items,
          action: 'add',
          addItemData: data,
        }),
      };
    }
    this.dataStorageService.setLocal<LocalShoppingCart>(DataKey.ShoppingCart, localShoppingCart);
    this.localShoppingCart.next(localShoppingCart);
  }

  get(): Observable<ShoppingCartDetail | null> {
    return this.shoppingCartDetail;
  }

  async removeItem(data: RemoveShoppingCartItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const shoppingCart = await firstValueFrom(this.mockService.getShoppingCart(session.userId));
      if (!shoppingCart) {
        return this.mockService.createShoppingCart({
          currency: CONFIG.defaultCurrency,
          id: session.userId,
          items: [],
          shippingAddressId: null,
          userId: session.userId,
        }).then();
      }
      return this.mockService.updateShoppingCart(session.userId, {
        items: getUpdatedShoppingCartItems({
          action: 'remove',
          items: shoppingCart.items,
          removeItemData: data,
        }),
      });
    }
    let localShoppingCart = this.getLocalShoppingCart();
    if (!localShoppingCart) {
      localShoppingCart = {
        currency: CONFIG.defaultCurrency,
        items: [{
          ...data,
          addedAt: new Date(),
        }],
        shippingAddressId: null,
      };
    } else {
      localShoppingCart = {
        ...localShoppingCart,
        items: getUpdatedShoppingCartItems({
          action: 'remove',
          items: localShoppingCart.items,
          removeItemData: data,
        }),
      };
    }
    this.dataStorageService.setLocal<LocalShoppingCart>(DataKey.ShoppingCart, localShoppingCart);
    this.localShoppingCart.next(localShoppingCart);
  }

  async updateShippingAddress(shippingAddressId: string): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      await this.mockService.updateShoppingCart(session.userId, { shippingAddressId });
    }
    this.dataStorageService.set<LocalShoppingCart>(DataKey.ShoppingCart, {
      ...this.getLocalShoppingCart() as LocalShoppingCart,
      shippingAddressId,
    });
  }

  private getLocalShoppingCart(): LocalShoppingCart | null {
    return this.dataStorageService.getLocal<LocalShoppingCart>(DataKey.ShoppingCart);
  }

  private getMockedChildren(shoppingCart: ShoppingCart | LocalShoppingCart | null): Observable<ShoppingCartDetail | null> {
    if (!shoppingCart) {
      return of(null);
    }
    return from(this.searchService.search<SearchEngineProduct>('products', {
      filters: [
        {
          field: 'id',
          operator: 'in',
          value: shoppingCart.items.map((item) => item.resourceId),
        }
      ],
      pagination: {
        documentsPerPage: CONFIG.maxShoppingCartItems,
      },
    })).pipe(map((paginable) => {
      let subtotal = 0;
      let discounts = 0;
      let originalShippingCost = 0;
      let shippingCost = 0;
      const pricing: EcommercePricingExternal = {};
      const detailItems = shoppingCart.items.map((item) => {
        if (item.resourceType === 'product') {
          const resource = paginable.data.find((product) => product.id === item.resourceId) ?? null;
          if (resource) {
            shippingCost += item.quantity * resource.pricing['debit'].shippingCost;
            subtotal += item.quantity * resource.pricing['debit'].price;
            discounts += item.quantity * (resource.pricing['debit'].originalPrice - resource?.pricing['debit'].price);
            originalShippingCost += item.quantity * resource.pricing['debit'].originalShippingCost;
            CONFIG.paymentOptions.forEach((option) => {
              if (!pricing[option]) {
                pricing[option] = { ...resource.pricing[option] };
              } else {
                pricing[option].monthlyPayment += item.quantity * resource.pricing[option].monthlyPayment;
                pricing[option].originalPrice += item.quantity * resource.pricing[option].originalPrice;
                pricing[option].originalShippingCost += item.quantity * resource.pricing[option].originalShippingCost;
                pricing[option].price += item.quantity * resource.pricing[option].price;
                pricing[option].shippingCost += item.quantity * resource.pricing[option].shippingCost;
              }
            });
            return {
              ...item,
              resource,
            };
          }
        }
        return {
          ...item,
          resource: null,
        };
      }).filter((item) => item.resource);
      return {
        ...shoppingCart,
        discounts,
        originalShippingCost,
        items: detailItems,
        pricing,
        shippingCost,
        subtotal,
        totalItems: detailItems.reduce((totalItems, item) => totalItems + item.quantity, 0),
      };
    }));
  }
}
