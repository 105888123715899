import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { SharedModule } from 'primeng/api';
import { ButtonComponent } from '../../../components/button/button.component';
import { ReminderComponent } from '../../../components/reminder/reminder.component';

@Component({
  selector: 'app-request-home-assistance',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ReminderComponent,
    RouterLink,
    SharedModule,
    TranslocoPipe,
  ],
  templateUrl: './request-home-assistance.page.html',
  styleUrl: './request-home-assistance.page.scss',
})
export class RequestHomeAssistancePage implements OnInit {
  email: string | null;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getEmailFromSearchBar();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getEmailFromSearchBar(): void {
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
  }
}
