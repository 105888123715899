<section class="mortgage-simulation-type-main-container">
  <div class="mortgage-simulation-types-container">
    <h2 class="title">{{ 'How do you want to simulate your mortgage?' | transloco }}</h2>
    @for (simulationType of simulationTypes; track $index) {
      <div class="mortgage-simulation-type-container" [class.selected]="simulationType.selected" (click)="selectSimulationType(simulationType)">
        <i class="icon {{ simulationType.icon }}"></i>
        <span class="text">{{ simulationType.translationKey | transloco }}</span>
      </div>
    }
  </div>
  <div class="right-container">
    @if (simulationTypeSelected === 'amount') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-amount.svg" alt="{{ 'Mortgage simulation by amount you need illustration' | transloco }}" />
    } @else if (simulationTypeSelected === 'housing-cost') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-housing-cost.svg" alt="{{ 'Mortgage simulation by housing cost illustration' | transloco }}" />
    }
    <div class="continue-button-container animate__fadeIn">
      <app-button label="Continue" styleClass="primary expand" [disabled]="!simulationTypeSelected" (action)="continue()"></app-button>
    </div>
  </div>
</section>
