<app-complementary-ecommerce-bar [query]="query" [filters]="filters" [order]="order" [showFilters]="!!products.length || !!filters?.length">
  @if (!loadingProductSearch && !errorLoadingProductSearch) {
    <div class="product-search-headers" [class.empty-results]="!products.length">
      @if (products.length) {
        @if (query) {
          <span class="title">{{ 'X results for:' | transloco: { quantity: paginable.totalItems } }}</span>
          <span class="query">{{ query }}</span>
        } @else {
          <span class="title">{{ 'X results:' | transloco: { quantity: paginable.totalItems } }}</span>
        }
      } @else {
        @if (query) {
          <span class="title">{{ 'No results found for:' | transloco }}</span>
          <span class="query">{{ query }}</span>
        } @else {
          <span class="title">{{ 'No results found' | transloco }}</span>
        }
      }
    </div>
  }
</app-complementary-ecommerce-bar>
<div class="product-search-container">
  <section class="product-search-section">
    <app-retryable-section class="button" [error]="errorLoadingProductSearch || errorLoadingMoreProducts" [loading]="loadingProductSearch" (retry)="getProductSearch()">
      @if (hasPreviousItems) {
        <div class="see-previous-container">
          <app-button class="button" label="See previous" styleClass="secondary dark" [loading]="loadingPreviousProducts" (action)="getPrevious()" />
        </div>
      }
      <div class="products-container">
        @for (product of products; track $index) {
          <div class="product-card-container">
            <app-product-card [product]="product" [isFavorite]="!!favorites['product-' + product.id]"></app-product-card>
          </div>
        }
      </div>
      @if (paginable && products.length) {
        <div class="see-more-container">
          <span class="message">{{ 'Displaying X of Y products' | transloco: { products: products.length, total: paginable.totalItems } }}</span>
          <p-progressBar [value]="percentageProductsLoaded" [showValue]="false" styleClass="progress-bar"></p-progressBar>
          @if (!paginable.done) {
            <app-button class="button" label="See more" styleClass="secondary dark" [loading]="loadingMoreProducts" (action)="getMore()" />
          }
        </div>
      }
    </app-retryable-section>
  </section>
  @if (!products.length) {
    <section class="products-of-interest-section">
      <!-- TODO: IMPLEMENT PRODUCTS-CARROUSEL WHEN IS MERGED -->
      <app-products-of-interest title="Offers of the month"></app-products-of-interest>
    </section>
  }
</div>
