import { Injectable } from '@angular/core';
import * as faker from 'faker';
import { FormsService } from './forms.service';

@Injectable()
export class FormsServiceMock implements FormsService {
  constructor() {}

  async submitContact(): Promise<string> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(faker.random.alphaNumeric(20));
      }, 2000);
    });
  }
}