import { Route } from '@angular/router';

export default [
  {
    path: '',
    redirectTo: 'step-1',
    pathMatch: 'prefix'
  },
  {
    path: 'step-1',
    loadComponent: () => import('./step-1/step-1.page').then(m => m.Step1Page),
  },
  {
    path: 'step-2',
    loadComponent: () => import('./step-2/step-2.page').then(m => m.Step2Page),
  },
  {
    path: 'step-3',
    loadComponent: () => import('./step-3/step-3.page').then(m => m.Step3Page),
  },
  {
    path: 'step-4',
    loadComponent: () => import('./step-4/step-4.page').then(m => m.Step4Page),
  },
  {
    path: 'step-5',
    loadComponent: () => import('./step-5/step-5.page').then(m => m.Step5Page),
  },
  {
    path: 'step-6',
    loadComponent: () => import('./step-6/step-6.page').then(m => m.Step6Page),
  },
  {
    path: 'simulate-again',
    loadComponent: () => import('./simulate-again/simulate-again.page').then(m => m.SimulateAgainPage),
  },
] satisfies Route[];