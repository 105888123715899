import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { filter, firstValueFrom, interval, Subject, Subscription, take, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { validateAndNavigate } from '../../../utils/enrollment.utils';

const MINIMUM_TIME_BETWEEN_EMAIL_VERIFICATION_SENDS = 1000 * 60 * 5;

interface Timer {
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    MessageModule,
    ProgressSpinnerModule,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './verify-email.page.html',
  styleUrl: './verify-email.page.scss',
})
export class VerifyEmailPage implements OnInit, OnDestroy {
  canSendEmail = false;
  error = false;
  loading = false;
  sending = false;
  timeLeftLabel = '';
  timerSubscription: Subscription | null = null;
  user: User | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private sessionsService: SessionsService,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams['token'];
    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.verifyEmail(token);
    } else {
      this.initialize();
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  initialize(): void {
    this.loading = true;
    this.error = false;
    this.usersService.getCurrentUser().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (user) => {
        this.user = user;
        if (!user || user.emailValidated) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['/enrollment']);
          return;
        }
        if (user.lastEmailVerificationSentAt) {
          const timePassed = Date.now() - user.lastEmailVerificationSentAt.getTime();
          if (timePassed < MINIMUM_TIME_BETWEEN_EMAIL_VERIFICATION_SENDS) {
            this.canSendEmail = false;
            this.startTimer(timePassed);
          } else {
            this.canSendEmail = true;
          }
        } else {
          this.canSendEmail = true;
        }
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('VerifyEmailPage.loadInitialData()', 'get-current-user', error);
        this.loading = false;
        this.error = true;
      }
    });
  }

  async sendEmail(): Promise<void> {
    this.sending = true;
    try {
      await this.sessionsService.sendEmailVerification();
      this.canSendEmail = false;
      this.startTimer(0);
      this.toastService.showSuccess({ title: 'Email sent', description: 'Verification email has been sent again.' });
    } catch (error) {
      this.errorReportingService.log('VerifyEmailPage.sendEmail()', 'send-email-verification', error);
      this.toastService.showError({ title: 'Error', description: 'An error occurred while sending the verification email. Please try again later.' });
    } finally {
      this.sending = false;
    }
  }

  async verifyEmail(token: string): Promise<void> {
    this.error = false;
    this.loading = true;
    let step = '';
    try {
      step = 'verify-email';
      await this.sessionsService.verifyEmail(token);
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser?.emailValidated), take(1)));
      step = 'validate-and-navigate';
      await validateAndNavigate(user, this.dataStorageService, this.router, this.activatedRoute.snapshot.queryParamMap);
      this.analyticsService.logUserCreationEvent({ step: 'verify-email' });
      this.toastService.showSuccess({ title: 'Email verified', description: 'You have successfully verified your email address.' });
    } catch (error) {
      this.errorReportingService.log('VerifyEmailPage.verifyEmail()', step, error);
      this.toastService.showError({ title: 'Error', description: 'An error occurred while verifying your email. Please try again later.' });
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  private startTimer(timePassed: number): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    const timeLeft = MINIMUM_TIME_BETWEEN_EMAIL_VERIFICATION_SENDS - timePassed;
    const timer = {
      minutes: Math.floor(timeLeft / 1000 / 60),
      seconds: Math.floor(timeLeft / 1000) % 60,
    };
    this.updateTimeLeftLabel(timer);
    this.timerSubscription = interval(1000).subscribe({
      next: () => {
        timer.seconds--;
        if (timer.seconds < 0) {
          timer.seconds = 59;
          timer.minutes--;
        }
        if (timer.minutes < 0) {
          timer.minutes = 0;
          timer.seconds = 0;
          this.canSendEmail = true;
        }
        this.updateTimeLeftLabel(timer);
      }
    });
  }

  private updateTimeLeftLabel(timer: Timer): void {
    this.timeLeftLabel = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`;
  }
}
