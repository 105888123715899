<section class="purchase-details-container">
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (paymentIntents) {
      <div class="payment-details">
        <h3 class="title">{{ 'Payment details' | transloco }}</h3>
        <div class="apportions-container">
          @for (paymentIntent of paymentIntents; track $index) {
            @for (apportion of paymentIntent.apportions.internal; track $index) {
              <div class="apportion">
                <div class="logo checkout-account"></div>
                <div class="info">
                  <span class="amount">{{ apportion.amount | currencyFormatter: paymentIntent.currency }} {{ paymentIntent.currency }}</span>
                  <span class="name">{{ getAccountLabelByType(apportion.fromAccountType) | transloco }}</span>
                  <span class="date-and-id">{{ paymentIntent.createdAt | date: 'dd/MM/yyyy' }} | #{{ apportion.fromAccountId }}</span>
                  <span class="status">{{ 'Payment approved' | transloco }}</span>
                </div>
              </div>
            }
            @for (apportion of paymentIntent.apportions.external; track $index) {
              <div class="apportion">
                <div class="logo {{ apportion.channel }}"></div>
                <div class="info">
                  <span class="amount">{{ apportion.amount | currencyFormatter: paymentIntent.currency }} {{ paymentIntent.currency }}</span>
                  @if (apportion.option === 'credit') {
                    @if (apportion.installments > 1) {
                      <span class="name">{{ 'Credit card' | transloco }} | ****{{ paymentIntent.additionalData?.[apportion.channel]['cardNumber'] }}</span>
                      <span class="name">{{ 'X months of' | transloco: { months: apportion.installments } }} {{ (apportion.amount / apportion.installments) | currencyFormatter: paymentIntent.currency }} {{ paymentIntent.currency }}</span>
                    } @else {
                      <span class="name">{{ 'Credit card' | transloco }} | ****{{ paymentIntent.additionalData?.[apportion.channel]['cardNumber'] }}</span>
                    }
                  } @else if (apportion.option === 'debit') {
                    <span class="name">{{ 'Debit card' | transloco }} | ****{{ paymentIntent.additionalData?.[apportion.channel]['cardNumber'] }}</span>
                  }
                  <span class="date-and-id">{{ paymentIntent.createdAt | date: 'dd/MM/yyyy' }} | #{{ paymentIntent.additionalData?.[apportion.channel]['transaction'] }}</span>
                  <span class="status">{{ 'Payment approved' | transloco }}</span>
                </div>
              </div>
            }
          }
        </div>
        <div class="total">
          <span class="label">{{ 'Total' | transloco }}</span>
          <span class="amount">{{ total | currencyFormatter: currency }} {{ currency }}</span>
        </div>
      </div>
    }
  </app-retryable-section>
</section>