import { Injectable } from '@angular/core';
import { UpdateUserData, User } from '@homein-hogar-server';
import { firstValueFrom, Observable, of } from 'rxjs';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { MockService } from '../mock/mock.service';
import { UsersService } from './users.service';

@Injectable()
export class UsersServiceMock implements UsersService {
  constructor(
    private dataStorageService: DataStorageService,
    private mockService: MockService,
  ) {
  }

  get(id: string): Observable<User | null> {
    return this.mockService.getUser(id);
  }

  getCurrentUser(): Observable<User | null> {
    const userId = this.dataStorageService.getLocal<string>(DataKey.MockUserId);
    if (!userId) {
      return of(null);
    }
    return this.mockService.getUser(userId);
  }

  async updateUser(data: UpdateUserData): Promise<{ code: string; message: string }> {
    const user = await firstValueFrom(this.getCurrentUser());
    if (!user) {
      return Promise.reject({ code: 'user-not-found', message: 'User not found.' });
    }
    await this.mockService.updateUser(user.id, {
      ...data,
      enrollmentStatus: user.bankValidated && user.emailValidated ? 'done' : 'pending',
      profileDataCompleted: true,
    });
    return { code: 'success', message: 'User updated successfully.' };
  }
}
