@if (products.length) {
  <section class="products-of-interest-container">
    <h3 class="title">{{ titleTranslationItem.key | transloco: titleTranslationItem.params }}</h3>
    <app-retryable-section [error]="errorLoadingProducts" [loading]="loadingProducts" (retry)="getProducts()">
      <p-carousel class="products-of-interest-carousel"
                  [contentClass]="'carousel display-navigation'"
                  [value]="products"
                  [numVisible]="4"
                  [numScroll]="1"
                  [responsiveOptions]="[
                    { breakpoint: '1365px', numVisible: 3.2, numScroll: 1 },
                    { breakpoint: '1200px', numVisible: 2.7, numScroll: 1 },
                    { breakpoint: '1100px', numVisible: 2.6, numScroll: 1 },
                    { breakpoint: '1000px', numVisible: 2.4, numScroll: 1 },
                    { breakpoint: '900px', numVisible: 2.2, numScroll: 1 },
                    { breakpoint: '800px', numVisible: 3.2, numScroll: 1 },
                    { breakpoint: '650px', numVisible: 3, numScroll: 1 },
                    { breakpoint: '550px', numVisible: 2.5, numScroll: 1 },
                    { breakpoint: '500px', numVisible: 2.1, numScroll: 1 },
                  ]"
                  [showIndicators]="false"
                  [showNavigators]="true">
        <ng-template pTemplate="previousicon">
          <div class="navigation-container">
            <i class="icon-arrow-left"></i>
          </div>
        </ng-template>
        <ng-template let-product pTemplate="item">
          <app-product-card class="product-card" [product]="product" [isFavorite]="!!favorites['product-' + product.id]"></app-product-card>
        </ng-template>
        <ng-template pTemplate="nexticon">
          <div class="navigation-container">
            <i class="icon-arrow-right"></i>
          </div>
        </ng-template>
      </p-carousel>
    </app-retryable-section>
  </section>
}
