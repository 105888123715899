import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FavoriteItem, SearchEngineProduct } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { BadgeModule } from 'primeng/badge';
import { CarouselModule } from 'primeng/carousel';
import { Subject, takeUntil } from 'rxjs';
import { ComplementaryEcommerceBarComponent } from '../../../components/complementary-ecommerce-bar/complementary-ecommerce-bar.component';
import { ProductCardComponent } from '../../../components/product-card/product-card.component';
import { ProductsOfInterestComponent } from '../../../components/products-of-interest/products-of-interest.component';
import { CONFIG } from '../../../constants';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../../services/favorites/favorites.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../../services/products/products.service';
import { Paginable } from '../../../services/search/search.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    BadgeModule,
    CarouselModule,
    CommonModule,
    ComplementaryEcommerceBarComponent,
    ProductCardComponent,
    RouterModule,
    TranslocoPipe,
    ProductsOfInterestComponent
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
})
export class HomePage implements OnInit, OnDestroy {
  categories: {
    code: string;
    image: string;
    label: string;
    queryParams: Record<string, string>;
  }[];
  errorLoadingFavorites = false;
  favorites: Record<string, FavoriteItem> = {};
  loadingFavorites = true;
  mainBanner = [
    {
      code: 'primary',
      image: 'ads-one.png',
      label: '',
      queryParams: {
        filters: JSON.stringify({ categories: { value: 'salas-y-family-room', operator: 'in', field: 'categories' } }),
      }
    },
    {
      code: 'secondary',
      image: 'ads-two.png',
      label: 'Salas y TV',
      queryParams: {
        filters: JSON.stringify({ categories: { value: 'tv-y-video', operator: 'in', field: 'categories' } }),
      }
    },
    {
      code: 'tertiary',
      image: 'ads-three.png',
      label: 'Recámaras',
      queryParams: {
        filters: JSON.stringify({ categories: { value: 'recamaras', operator: 'in', field: 'categories' } }),
      }
    }
  ];
  products1: Paginable<SearchEngineProduct>;
  products2: Paginable<SearchEngineProduct>;
  showCategories = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private metaTagsService: MetaTagsService,
    private productsService: ProductsService,
  ) {
    this.setEcommerceCategories();
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // TODO F&F: merge PR 210 / refactor if needed
  private initialize(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    // TODO: Handle view loading state and maybe combine the two calls
    this.productsService.search('mesa comedor').then((products) => {
      this.products1 = products;
    }).catch((error) => {
      this.errorReportingService.log('HomePage.initialize()', 'get-all-products', error);
    });
    this.productsService.search('comedor', {
      order: {
        field: 'discountPercentage'
      }
    }).then((products) => {
      this.products2 = products;
    }).catch((error) => {
      this.errorReportingService.log('HomePage.initialize()', 'get-all-products', error);
    });
    this.favoritesService.get().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (favorite) => {
        this.favorites = {};
        if (favorite) {
          favorite.items.forEach((item) => this.favorites[`${item.resourceType}-${item.resourceId}`] = item);
        }
        this.loadingFavorites = false;
      },
      error: (error) => {
        this.errorReportingService.log('HomePage.initialize()', 'get-all-favorites', error);
        this.loadingFavorites = false;
        this.errorLoadingFavorites = true;
      },
    });
    // TODO: Find a better way to avoid the the double row of categories when the carousel is rendered
    setTimeout(() => {
      this.showCategories = true;
    }, 100);
  }

  private setEcommerceCategories(): void {
    const { tree, labels } = CONFIG.ecommerce.categories;
    this.categories = Object.keys(tree).map((code) => ({
      code,
      label: labels[code],
      image: `${code}.png`,
      queryParams: {
        filters: JSON.stringify({ categories: { value: [code], operator: 'in', field: 'categories' } }),
      },
    }));
  }
}
