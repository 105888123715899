import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslationItem, TranslationService } from '../translation/translation.service';

interface ToastConfig {
  action?: () => void;
  actionLabel?: string | TranslationItem;
  description: string | TranslationItem;
  title: string | TranslationItem;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private messageService: MessageService,
    private translationService: TranslationService,
  ) {}

  showError(config: ToastConfig, duration?: number): void {
    this.showRaw('error', config, duration);
  }

  showInfo(config: ToastConfig, duration?: number): void {
    this.showRaw('info', config, duration);
  }

  showSuccess(config: ToastConfig, duration?: number): void {
    this.showRaw('success', config, duration);
  }

  showWarning(config: ToastConfig, duration?: number): void {
    this.showRaw('warn', config, duration);
  }

  private showRaw(severity: string, config: ToastConfig, duration: number = 6000): void {
    const { action, actionLabel, description, title } = config;
    let actionLabelTranslation: string | null = null;
    if (actionLabel) {
      actionLabelTranslation = this.translationService.translate(actionLabel);
    }
    const descriptionTranslation = this.translationService.translate(description);
    const titleTranslation = this.translationService.translate(title);
    this.messageService.add({
      data: {
        actionLabel: actionLabelTranslation,
        action,
        description: descriptionTranslation,
        title: titleTranslation,
      },
      life: duration,
      severity
    });
  }
}
