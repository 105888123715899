import { Injectable } from '@angular/core';
import { HomeAssistanceOrder, HomeAssistanceOrderStatus } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HomeAssistanceOrdersServiceApi } from './home-assistance-orders.service.api';
import { HomeAssistanceOrdersServiceMock } from './home-assistance-orders.service.mock';

export type HomeAssistanceOrderDetailStatus = HomeAssistanceOrderStatus | 'activation-process';
export type HomeAssistanceOrderDetail = Omit<HomeAssistanceOrder, 'status'> & {
  membershipLabel: string;
  status: HomeAssistanceOrderDetailStatus;
};

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? HomeAssistanceOrdersServiceMock : HomeAssistanceOrdersServiceApi,
})
export abstract class HomeAssistanceOrdersService {
  abstract get(id: string): Observable<HomeAssistanceOrder | null>;
  abstract getAll(): Observable<HomeAssistanceOrder[]>;
  abstract getLastInProgress(): Observable<HomeAssistanceOrder | null>;
}
