import { Injectable } from '@angular/core';
import { triggerRandomError } from '../../utils/mock.utils';
import { FormsService } from './forms.service';

@Injectable()
export class FormsServiceMock implements FormsService {
  constructor() {}

  generateInvoice(): Promise<void> {
    return this.commonMock();
  }

  submitConsumerCreditDiscount(): Promise<void> {
    return this.commonMock();
  }

  submitContactEcommerce(): Promise<void> {
    return this.commonMock();
  }

  submitContactHomeAssistance(): Promise<void> {
    return this.commonMock();
  }

  submitContactMoving(): Promise<void> {
    return this.commonMock();
  }

  submitContactPlatform(): Promise<void> {
    return this.commonMock();
  }

  submitContactRemodeling(): Promise<void> {
    return this.commonMock();
  }

  private commonMock(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 2000);
    });
  }
}