import { Inject, Injectable } from '@angular/core';
import { limit, orderBy, where } from '@angular/fire/firestore';
import { MovingOrder } from '@homein-hogar-server';
import { map, Observable, of, switchMap } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { MovingOrdersService } from './moving-orders.service';

const COLLECTION_PATH = 'movingOrders';

@Injectable()
export class MovingOrdersServiceApi implements MovingOrdersService {
  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {}

  createId(): string {
    return this.firestore.createId(COLLECTION_PATH);
  }

  get(id: string): Observable<MovingOrder | null> {
    return this.firestore.doc<MovingOrder>(`${COLLECTION_PATH}/${id}`);
  }

  getAll(): Observable<MovingOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<MovingOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', '!=', 'initializing'),
            orderBy('createdAt', 'desc')
          ]
        });
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<MovingOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<MovingOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', 'in', ['pending-confirmation-visit', 'pending-payment', 'waiting-confirmation', 'waiting-moving']),
            orderBy('createdAt', 'desc'),
            limit(1),
          ],
        }).pipe(map((movingOrders) => movingOrders.length ? movingOrders[0] : null));
      }
      return of(null);
    }));
  }
}
