<app-retryable-section [error]="errorLoading" [loading]="isLoading" (retry)="initialize()">
  <section class="contact-container">
    <div class="left-section">
      <h1 class="section-title">
        <i class="icon icon-arrow-left" (click)="goBack()"></i>
        <span>{{ 'Help form' | transloco }}</span>
      </h1>
      @if (!showSuccessScreen) {
        <div class="not-logged">
          <div class="content">
            <div class="container">
              <h4 class="title">Hola 👋</h4>
              <p class="subtitle">Lamentamos que estés teniendo inconvenientes.<br>¡Te ayudaremos a solucionarlo!</p>
              @if (!user) {
                <span class="help-account">Si tienes cuenta Mundo Hogar, <a class="link" (click)="goToSignIn()">ingresa aquí</a></span>
              }
            </div>
          </div>
        </div>
        <form [formGroup]="form" class="form-container">
          <div class="input-container">
            <label for="name">{{ 'Name' | transloco }}*</label>
            <input id="name" type="text" pInputText formControlName="name" aria-describedby="name-help" [class.disabled]="form.controls.name.disabled" [class.input]="form.controls.name.enabled" />
            <small id="name-help" [class.hidden]="!form.controls.name.touched || !form.controls.name.errors" class="p-error input-error">{{ 'Enter a valid name.' | transloco }}</small>
          </div>
          <div class="input-container">
            <label for="last-name">{{ 'Last name' | transloco }}*</label>
            <input id="last-name" type="text" pInputText formControlName="lastName" aria-describedby="last-name-help" [class.disabled]="form.controls.lastName.disabled" [class.input]="form.controls.lastName.enabled" />
            <small id="last-name-help" [class.hidden]="!form.controls.lastName.touched || !form.controls.lastName.errors" class="p-error input-error">{{ 'Enter a valid last name.' | transloco }}</small>
          </div>
          <div class="input-container">
            @if (user?.emailValidated) {
              <div class="email-container">
                <label for="email" class="label">{{ 'Email' | transloco }}*</label>
                <div class="verified-container" [class.disabled]="form.controls.email.disabled">
                  <i class="icon icon-checkmark"></i>
                  <small class="email-verified" [class.check]="user?.emailValidated">{{ 'Verified' | transloco }}</small>
                </div>
              </div>
            } @else {
              <label for="email">{{ 'Email' | transloco }}*</label>
            }
            <input id="email" type="email" pInputText formControlName="email" aria-describedby="email-help" [class.disabled]="form.controls.email.disabled" [class.input]="form.controls.email.enabled" />
            <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error input-error">{{ 'Enter a valid email.' | transloco }}</small>
          </div>
          <div class="input-container">
            <label for="phone-number">{{ 'Phone number' | transloco }}*</label>
            <p-inputMask id="phone-number" formControlName="phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" [class.disabled]="form.controls.phoneNumber.disabled" [class.input-mask]="form.controls.phoneNumber.enabled" />
            <small id="phone-number-help" [class.hidden]="!form.controls.phoneNumber.touched || !form.controls.phoneNumber.errors" class="p-error input-error">{{ 'Enter a valid phone number, it must start with \"52\" and then 10 digits.' | transloco }}</small>
          </div>
          <div class="input-container">
            <label for="message">{{ 'Message' | transloco }}</label>
            <textarea class="message" id="message" rows="3" pInputText formControlName="message"></textarea>
          </div>
          <app-button label="Send form" class="button" styleClass="primary expand" [disabled]="!form.valid" [loading]="sendingForm" (action)="onSubmit()" />
        </form>
      } @else {
        <div class="success-container">
          <img src="assets/images/success-img.svg" alt="{{ 'Your form has been sent' | transloco }}">
          <h1 class="title">{{ 'Your form has been sent' | transloco }}</h1>
          <span class="text">{{ 'We will contact you promptly to help you in this process' | transloco }}</span>
          <app-button label="Back home" class="back" styleClass="secondary expand" routerLink="/" />
        </div>
      }
    </div>
    <div class="right-section">
      <div class="text-container">
        <h2 class="title">Mundo Hogar</h2>
        <p class="subtitle">{{ 'We are in contact with you to support you in your dreams' | transloco }}</p>
      </div>
    </div>
  </section>
</app-retryable-section>