<section class="main">
  <div class="main-container">
    <div class="left-container">
      <div class="title-container">
        <h1 class="title">{{ 'A safe space for what matters to you' | transloco }}</h1>
      </div>
      <div class="button-container">
        <app-button label="Coming soon" styleClass="primary" [disabled]="true" icon="warehouses" pTooltip="{{ 'At the moment we do not have this option available.' | transloco }}" tooltipPosition="bottom" />
      </div>
    </div>
    <div class="right-container">
      <img class="main-image" src="assets/images/warehouse-main.svg" [alt]="'Warehouse main image' | transloco" />
    </div>
  </div>
</section>
<section class="terms-banner">
  <app-terms-banner page="warehouse" />
</section>