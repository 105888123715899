import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Account } from '@homein-accounts-server';
import {
  EcommerceOrder,
  EcommerceOrderItem,
  HomeAssistanceOrder,
  MovingOrder,
  RemodelingOrder,
} from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { CarouselModule } from 'primeng/carousel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { AccountsService } from '../../../services/accounts-server/accounts/accounts.service';
import { EcommerceOrdersService } from '../../../services/ecommerce-orders/ecommerce-orders.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { HomeAssistanceOrdersService } from '../../../services/home-assistance-orders/home-assistance-orders.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { RemodelingOrdersService } from '../../../services/remodeling-orders/remodeling-orders.service';
import { sortAccounts } from '../../../utils/accounts.utils';

type MyHomeCarouselItemType = 'balances' | 'ecommerce' | 'home-assistance' | 'movings' | 'remodeling';
type MyHomeCarouselItemData = Account[] | MyHomeCarouselEcommerceOrder | MovingOrder | HomeAssistanceOrder | RemodelingOrder | null;

interface MyHomeCarouselEcommerceOrder {
  order: EcommerceOrder | null;
  orderItemToDisplay: EcommerceOrderItem | null;
}

interface MyHomeCarouselItem {
  data: MyHomeCarouselItemData;
  header: MyHomeCarouselItemHeader;
  type: MyHomeCarouselItemType;
}

interface MyHomeCarouselItemHeader {
  icon: string;
  title: string;
}

interface PromotionsCarouselItem {
  buttonLabel: string;
  description: string;
  image: string;
  text: string;
}

interface ShortcutMenuItem {
  active: boolean;
  icon: string;
  label: string;
  link?: string;
  subItems?: ShortcutMenuItemSubItem[];
}

interface ShortcutMenuItemSubItem {
  isExternal?: boolean;
  label: string;
  link: string;
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    ButtonComponent,
    CarouselModule,
    CommonModule,
    CurrencyFormatterPipe,
    PanelMenuModule,
    ProgressSpinnerModule,
    RetryableSectionComponent,
    RouterLink,
    TermsBannerComponent,
    TranslocoModule
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnDestroy, OnInit {
  accounts: Account[] = [];
  errorLoading = false;
  hasBalance = false;
  loading = false;
  myHomeCarouselItems: MyHomeCarouselItem[] = [];
  promotionsCarouselItems: PromotionsCarouselItem[] = [];
  shortcutMenu: ShortcutMenuItem[] = [];
  private isMobile = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private accountsService: AccountsService,
    private ecommerceOrdersService: EcommerceOrdersService,
    private errorReportingService: ErrorReportingService,
    private homeAssistanceOrdersService: HomeAssistanceOrdersService,
    private movingOrdersService: MovingOrdersService,
    private remodelingOrdersService: RemodelingOrdersService
  ) {
    this.shortcutMenu = [
      {
        active: true,
        icon: 'icon-house',
        label: 'My Home',
        subItems: [
          {
            label: 'My remodeling',
            link: '/private-site/my-remodelings'
          },
          {
            label: 'My movings',
            link: '/private-site/my-movings'
          },
          {
            label: 'My purchases',
            link: '/private-site/my-purchases'
          },
          {
            label: 'My warehouses',
            link: '/warehouse'
          },
          {
            label: 'My home assistance plans',
            link: '/private-site/my-home-assistances'
          },
          {
            label: 'My balances and movements',
            link: '/private-site/profile/my-balance'
          }
        ],
      },
      {
        active: false,
        icon: 'icon-truck',
        label: 'Hire a service',
        subItems: [
          {
            label: 'Remodel your home',
            link: '/remodeling'
          },
          {
            label: 'Moving home',
            link: '/moving'
          },
          {
            label: 'Shopping for home',
            link: '/ecommerce'
          },
          {
            label: 'Rent warehouse',
            link: '/warehouse'
          },
          {
            label: 'Assistance for my home',
            link: '/home-assistance'
          }
        ],
      },
      {
        active: false,
        icon: 'icon-supermarketcart',
        label: 'Finance and insurance',
        subItems: [
          {
            isExternal: true,
            label: 'Credit consume Santander',
            link: 'https://www.santander.com.mx/personas/creditos-personales.html'
          },
          {
            isExternal: true,
            label: 'Santander mortgage',
            link: 'https://www.santander.com.mx/personas/creditos-hipotecarios.html'
          },
          {
            isExternal: true,
            label: 'Santander insurance',
            link: 'https://www.santander.com.mx/personas/seguros.html'
          },
          {
            isExternal: true,
            label: 'Credit card Santander',
            link: 'https://www.santander.com.mx/personas/tarjetas-de-credito.html'
          },
        ]
      },
      // TODO F&F: add links
      {
        active: false,
        icon: 'icon-help-circle',
        label: 'Help center',
        subItems: [
          {
            isExternal: true,
            label: 'Go to help center',
            link: 'https://ayuda.mundohogar.com.mx/es/'
          },
          {
            isExternal: true,
            label: 'FAQ',
            link: 'https://ayuda.mundohogar.com.mx/preguntas-frecuentes'
          },
          {
            isExternal: true,
            label: 'Privacy policies',
            link: 'https://ayuda.mundohogar.com.mx/aviso-de-privacidad'
          },
          {
            isExternal: true,
            label: 'Terms and conditions',
            link: 'https://ayuda.mundohogar.com.mx/terminos-y-condiciones'
          },
          {
            label: 'Complaints and returns',
            link: '/complaints-and-returns'
          },
          {
            label: 'Contact',
            link: '/contact'
          },
        ],
      }];
    this.promotionsCarouselItems = Array(5).fill({
      buttonLabel: 'Conocer ofertas',
      description: 'Solo por el mes de Junio todas las categorías de productos con hasta un 35% de descuento.',
      image: 'assets/images/home-carousel-image.jpg',
      text: 'Tenemos todo para tu nueva sala'
    });
    this.isMobile = window.innerWidth <= 500;
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  accountHasBalance(account: Account): boolean {
    return account.balance > 0;
  }

  initialize(): void {
    this.loading = true;
    this.errorLoading = false;
    combineLatest([
      this.accountsService.getAll(),
      this.ecommerceOrdersService.getLastInProgress(),
      this.movingOrdersService.getLastInProgress(),
      this.homeAssistanceOrdersService.getLastInProgress(),
      this.remodelingOrdersService.getLastInProgress(),
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: ([accounts, ecommerceOrder, movingOrder, homeAssistanceOrder, remodelingOrder]) => {
        this.myHomeCarouselItems = [];
        this.accounts = sortAccounts(accounts, ['debit', 'mortgage']);
        this.hasBalance = this.accounts.some(this.accountHasBalance);
        this.myHomeCarouselItems = [
          {
            data: this.accounts,
            header: {
              icon: 'icon-investment-fund',
              title: 'Balances'
            },
            type: 'balances'
          },
          {
            data: ecommerceOrder ? {
              order: ecommerceOrder,
              orderItemToDisplay: ecommerceOrder.status === 'in-progress' ? ecommerceOrder.items[0] : null
            } : null,
            header: {
              icon: 'icon-supermarketcart',
              title: 'Purchases'
            },
            type: 'ecommerce'
          },
          {
            data: movingOrder,
            header: {
              icon: 'icon-truck',
              title: 'Movings'
            },
            type: 'movings'
          },
          {
            data: homeAssistanceOrder,
            header: {
              icon: 'icon-phone-support',
              title: 'Assistance plans'
            },
            type: 'home-assistance'
          },
          {
            data: remodelingOrder,
            header: {
              icon: 'icon-remodeling-hat',
              title: 'Remodelings'
            },
            type: 'remodeling'
          }
        ];
        this.setMyHomeCarouselItemsSorted();
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('HomePage.initialize()', 'get-verticals-data', error);
        this.errorLoading = true;
        this.loading = false;
      }
    });
  }

  showSubMenu(item: ShortcutMenuItem): void {
    this.shortcutMenu = this.shortcutMenu.map((value) => ({ ...value, active: value.label === item.label ? !item.active : false }));
  }

  private setMyHomeCarouselItemsSorted(): void {
    const truthyValues = this.myHomeCarouselItems.filter((item) => item.data);
    const nullValues = this.myHomeCarouselItems.filter((item) => !item.data);
    this.myHomeCarouselItems = truthyValues.concat(nullValues);
    if (this.isMobile) {
      this.myHomeCarouselItems = this.myHomeCarouselItems.filter((item) => item.type !== 'balances');
    }
  }
}
