import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonComponent } from '../../../components/button/button.component';

interface Category {
  alt: string;
  buttonLabel: string;
  company: string;
  image: string;
  services: string;
  shortCompany: string;
  showMore: boolean;
  title: string;
  trustedInfo: string[];
  url: string;
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CardModule,
    CarouselModule,
    CommonModule,
    RouterLink,
    SharedModule,
    TooltipModule,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomePage {
  activeIndex: number | null = null;
  categories: Category[] = [
    {
      alt: 'Remodeling main image',
      buttonLabel: 'Simulate remodeling',
      company: 'Aquí Tu Remodelación (ATR)',
      image: 'assets/images/remodeling-main.svg',
      services: 'Integral remodelings, kitchen and bathrooms.',
      shortCompany: 'ATR',
      showMore: false,
      title: 'Remodeling',
      trustedInfo: [
        'Agreed deadlines and costs.',
        'Experts with more than 60 training courses per year.',
        'Technical support after remodeling',
        'More than 24 states and 100 franchisees.'
      ],
      url: '/project-simulator',
    },
    {
      alt: 'Moving main image',
      buttonLabel: 'Moving quote',
      company: 'MUDANGO',
      image: 'assets/images/moving-main.svg',
      services: 'Local moving and safe warehousing.',
      shortCompany: 'MUDANGO',
      showMore: false,
      title: 'Movings and warehouse',
      trustedInfo: [
        '30,000 anually movings.',
        '96% customer satisfaction.',
        'Presence in the main cities and areas of the country.',
        'First 100% online moving company in Mexico, Colombia and Chile.'
      ],
      url: '/moving',
    },
    {
      alt: 'Home assistance image',
      buttonLabel: 'Review plans',
      company: 'IKE',
      image: 'assets/images/home-assistance-main.svg',
      services: 'Plumbing, electricity, glass, and pet assistance.',
      shortCompany: 'IKE',
      showMore: false,
      title: 'Home assistances and pets',
      trustedInfo: [
        '30 years with banks and insurers.',
        'National, 24/7 assistance.',
        '900,000 services in Mexico y 1.7 million in LATAM.',
        'Trajectory: Founded in 1988, presence in 4 countries'
      ],
      url: '/home-assistance',
    },
    {
      alt: 'Home shopping image',
      buttonLabel: 'Go to shopping',
      company: 'SEARS',
      image: 'assets/images/home-shopping.svg',
      services: 'Sale of white goods, appliances, furniture and decoration.',
      shortCompany: 'SEARS',
      showMore: false,
      title: 'Home shopping',
      trustedInfo: [
        'Home specialized: More than 15,000 quality products.',
        'Capacity: More than 90 stores nationwide.',
        'Marketplace: Advanced technology, coverage and distribution.',
        'Trajectory: Part of the Sanborns Group, in Mexico since 1947.'
      ],
      url: '/ecommerce',
    }
  ];

  constructor() {}

  toggleShowMore(category: Category): void {
    category.showMore = !category.showMore;
  }
}
