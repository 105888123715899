import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [ButtonModule, CommonModule, ToastModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent {
  constructor(
    private messageService: MessageService,
  ) {}

  onClick(action: () => void): void {
    this.messageService.clear();
    if (action) {
      action();
    }
  }
}
