<section class="main">
  <div class="main-container">
    <div class="right-side">
      <div class="title-container">
        <h1 class="title">{{ 'Include in your mortgage the remodeling you dream about' | transloco }}</h1>
      </div>
      <div class="button-container">
        <app-button label="Simulate project" styleClass="primary" icon="house" href="/project-simulator" />
      </div>
    </div>
    <div class="left-side">
      <img class="main-image" src="assets/images/remodeling-main.svg" [alt]="'Remodeling main image' | transloco" />
    </div>
  </div>
</section>
<section class="terms-banner">
  <app-terms-banner page="remodeling" />
</section>
<section class="remodeling-benefits">
  <div class="container">
    <div class="left-container">
      <img class="image" src="assets/images/assistance-benefits.svg" [alt]="'Remodeling benefits image' | transloco" />
    </div>
    <div class="right-container">
      <div class="title-container">
        <h2 class="title">{{ 'Why remodeling at Mundo Hogar?' | transloco }}</h2>
      </div>
      <div class="benefits-container">
        <ol class="benefits">
          <li class="benefit"><i class="icon-bill"></i>{{ 'Get a quote and plan your remodel with no surprises!' | transloco }}</li>
          <li class="benefit"><i class="icon-clock"></i>{{ 'Choose the financing that fits your needs' | transloco }}</li>
          <li class="benefit"><i class="icon-price-award"></i>{{ 'Make your life easier by leaving your home in our hands' | transloco }}</li>
        </ol>
        <app-button label="Free quote" styleClass="secondary" (action)="openRemodelingQuoteSidebar()" />
      </div>
    </div>
  </div>
</section>
<section class="opportunities">
  <div class="opportunities-container">
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">{{ 'Apply for your card and pay for everything in 12 months interest free' | transloco }}</h4>
      <!-- TODO: add link -->
        <app-button label="Request" styleClass="secondary" />
      </div>
      <div class="right-card-container">
        <!-- TODO: change alt of images and also images. -->
        <img class="img" src="assets/images/first-opportunity.jpg" alt="{{'Opportunity image' | transloco }}" loading="lazy" />
      </div>
    </div>
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">{{ 'Simulate that remodeling that you dream of so much' | transloco }}</h4>
        <!-- TODO: add link -->
        <app-button label="Simulate" styleClass="secondary" />
      </div>
      <div class="right-card-container">
        <!-- TODO: change alt of images and also images. -->
        <img class="img" src="assets/images/second-opportunity.jpg" alt="{{'Opportunity image' | transloco }}" loading="lazy" />
      </div>
    </div>
  </div>
</section>
<section class="why-trust-section">
  <div class="container">
    <div class="left-container">
      <div class="title-container">
        <span class="title">{{ 'Why do we rely on' | transloco }}<img class="remodeling-logo" alt="{{ 'Aquí tu remodelación logo' | transloco }}" src="assets/images/atr-logo.svg">?</span>
        <hr class="underline">
      </div>
    </div>
    <div class="right-container">
      <div class="trust-content">
        <i class="icon-clock"></i>
        <div class="trust">
          <h4 class="title">{{ 'Budget without surprises' | transloco }}</h4>
          <p class="description">{{ 'Your remodeling without worrying about unforeseen events or changes in the agreed costs.' | transloco }}</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon-bill"></i>
        <div class="trust">
          <h4 class="title">{{ 'Meeting deadlines' | transloco }}</h4>
          <p class="description">{{ 'In order for you to enjoy your home as planned, completion dates are set out in the contract.' | transloco }}</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon-price-award"></i>
        <div class="trust">
          <h4 class="title">{{ 'Selected providers' | transloco }}</h4>
          <p class="description">{{ 'The best experts and providers selected for you.' | transloco }}</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon-care-hand"></i>
        <div class="trust">
          <h4 class="title">{{ 'Up to 2 years warranty' | transloco }}</h4>
          <p class="description">{{ 'Technical support for post-refurbishment incidents.' | transloco }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="quote-free-section">
  <div class="container">
    <span class="text">{{ 'If you would like to get a free quote for the remodeling of your dream home,' | transloco }} <a class="link" (click)="openRemodelingQuoteSidebar()">{{ 'click here' | transloco }}</a></span>
  </div>
</section>
<section class="remodeling-cost-section">
  <div class="container">
    <div class="left-container">
      <div class="text-container">
        <h5 class="title">{{ 'How much does the remodeling you have in mind cost?' | transloco }}</h5>
        <p class="description">{{ 'Simulate 100% online, takes 3 minutes' | transloco }}:</p>
        <ol class="items">
          <li class="item">{{ 'Enter your property information' | transloco }}</li>
          <li class="item">{{ 'Select the type of remodeling' | transloco }}</li>
          <li class="item">{{ 'Enter the m2 and that\'s it!' | transloco }}</li>
        </ol>
      </div>
      <div class="button-container">
        <app-button label="Simulate for free" styleClass="primary expand" href="/project-simulator" />
      </div>
    </div>
  </div>
</section>
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'All in one place' | transloco }}</h4>
          <span class="description">{{ 'Find everything you need for your home, in the same 100% online portal.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'The best financing' | transloco }}</h4>
          <span class="description">{{ 'Pay in months without interest and with the best payment alternatives to suit your needs.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'More tranquility' | transloco }}</h4>
          <span class="description">{{ 'Qualified suppliers, guaranteed services and secure payment methods for you.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Simple and transparent' | transloco }}</h4>
          <span class="description">{{ 'Manage and track your projects online in just a few clicks.' | transloco }}</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <!-- TODO: Change later the link -->
      <p class="customer-benefit">{{ 'Exclusive benefits for Santander customers. If you are not a customer yet' | transloco }} <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank">{{ 'click here' | transloco }}</a>.<p>
    </div>
  </div>
</article>
<!-- TODO: Change this FAQS to final text -->
<article class="faq-article">
  <h2 class="title">{{ 'FAQ' | transloco }}</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq">
      <p-accordionTab class="accordion-tab" header="{{ 'What are Assistance Plans?' | transloco }}" iconPos="end">
        <p>{{ 'Mundo Hogar is a digital ecosystem that connects home services in one place (one stop shop), including the best financing and delivery of services by certified providers, in a transparent, simple and agile way.' | transloco }}</p>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'How does Assistance Plans work?' | transloco }}" iconPos="end">
        <ol>
          <li>{{ 'Enter to' | transloco }} <a routerLink="/">www.mundohogar.com.mx</a></li>
          <li>{{ 'Create your user and validate your portal account' | transloco }}</li>
          <li>{{ 'Access the services and products that Mundo Hogar offers you' | transloco }}</li>
        </ol>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'Can I use these benefits without being a Santander customer?' | transloco }}" iconPos="end">
        <p>{{ 'No, Mundo Hogar benefits are exclusive for Santander customers.' | transloco }}</p>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'What is the difference between Assistance and Insurance?' | transloco }}" iconPos="end">
        <p>{{ 'No, Mundo Hogar benefits are exclusive for Santander customers.' | transloco }}</p>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">{{ 'Visit our' | transloco }} <a class="link" href="https://ayuda.homein.mx/es/" target="_blank">{{ 'Help center' | transloco }}</a> {{ 'for more information' | transloco }}</h5>
</article>
<app-remodeling-quote-sidebar #remodelingQuoteSidebar></app-remodeling-quote-sidebar>