@if (loading) {
  <main class="moving-loading-page">
    <p-progressSpinner></p-progressSpinner>
  </main>
} @else {
  <section class="main">
    <div class="main-container">
      <div class="right-side">
        <div class="title-container">
          <h1 class="title">{{ 'Stress-free moves to your new home' | transloco }}</h1>
        </div>
        <div class="button-container">
          <app-button label="Moving quote" (action)="navigateToQuote()" styleClass="primary" icon="truck" />
        </div>
      </div>
      <div class="left-side">
        <img class="main-image" src="assets/images/moving-main.svg" [alt]="'Moving main image' | transloco ">
      </div>
    </div>
  </section>
  <section class="terms-banner">
    <app-terms-banner page="moving" />
  </section>
  <section class="moving-benefits">
    <div class="container">
      <div class="left-container">
        <img src="assets/images/moving-steps.svg" class="moving-image" alt="moving steps image">
      </div>
      <div class="right-container">
        <div class="title-container">
          <h2 class="title">{{ 'Your next move in just 3 steps' | transloco }}</h2>
        </div>
        <div class="benefits-container">
          <ol class="benefits">
            <li class="benefit">{{ 'Enter the data for your move. It will only take two minutes.' | transloco }}</li>
            <li class="benefit">{{ 'Select the type of service you need.' | transloco }}</li>
            <li class="benefit">{{ 'All set, we will take care of the rest.' | transloco }}</li>
          </ol>
        </div>
        <div class="button-container">
          <app-button label="Free quote" (action)="navigateToQuote()" styleClass="secondary expand" />
        </div>
      </div>
    </div>
  </section>
  <section class="moving-banner-section">
    <div class="banner-container">
      <div class="banner">
        <div class="left-card-container">
          <h4 class="title">{{ 'We accompany you with Mudango to your new life' | transloco }}</h4>
          <app-button label="See more" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
        <div class="right-card-container">
          <img class="img" src="assets/images/moving-banner-1.png" alt="{{ 'Moving banner image' | transloco }}" loading="lazy" />
        </div>
      </div>
      <div class="banner">
        <div class="left-card-container">
          <h4 class="title">{{ 'Simulate that remodeling that you dream of so much' | transloco }}</h4>
          <app-button label="Simulate" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
        <div class="right-card-container">
          <img class="img second-banner" src="assets/images/moving-banner-2.png" alt="{{ 'Moving banner image' | transloco }}" loading="lazy" />
        </div>
      </div>
    </div>
  </section>
  <section class="coverage-section">
    <div class="container">
      <div class="left-container">
        <div class="title-container">
          <h2 class="title">{{ 'We are in the main cities of Mexico' | transloco }}</h2>
        </div>
        <div class="left-button-container">
          <app-button label="Check your address" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
      </div>
      <div class="right-container">
        <ul class="cities-list">
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Ciudad de México</span>
          </li>
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Guadalajara</span>
          </li>
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Monterrey</span>
          </li>
        </ul>
        <p class="more-info">{{ 'and more... consult and quote your move' | transloco }}</p>
      </div>
      <div class="button-container">
        <app-button label="Check your address" (action)="navigateToQuote()" styleClass="secondary" />
      </div>
    </div>
  </section>
  <section class="why-trust-moving-section">
    <div class="container">
      <div class="left-container">
        <div class="title-container">
          <span class="title">{{ 'Why do we rely on' | transloco }}<img class="moving-logo" alt="{{ 'Mudango logo' | transloco }}" src="assets/images/mudango-logo.svg">?</span>
          <hr class="underline">
        </div>
      </div>
      <div class="right-container">
        <div class="trust-content">
          <i class="icon-radar"></i>
          <div class="trust">
            <h4 class="title">{{ 'Focused on you' | transloco }}</h4>
            <p class="description">{{ 'No-surprise quote tailored to your needs.' | transloco }}</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-king"></i>
          <div class="trust">
            <h4 class="title">{{ 'Experience and capacity' | transloco }}</h4>
            <p class="description">{{ 'More than 30,000 moves per year in the main cities of the region.' | transloco }}</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-countryside"></i>
          <div class="trust">
            <h4 class="title">{{ 'Local coverage' | transloco }}</h4>
            <p class="description">{{ 'Presence in the main cities and areas of the country.' | transloco }}</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-steps"></i>
          <div class="trust">
            <h4 class="title">{{ 'Trajectory' | transloco }}</h4>
            <p class="description">{{ 'Pioneers in online moving since 2017 with presence in Mexico, Colombia and Chile.' | transloco }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <article class="why-platform-article">
    <div class="title-container">
      <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
      <hr class="underline">
      <div class="benefits-container">
        <div class="benefit">
          <i class="icon-monitor"></i>
          <div class="benefit-content">
            <h4 class="label">{{ 'All in one place' | transloco }}</h4>
            <span class="description">{{ 'Find everything you need for your home, in the same 100% online portal.' | transloco }}</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-jewel"></i>
          <div class="benefit-content">
            <h4 class="label">{{ 'The best financing' | transloco }}</h4>
            <span class="description">{{ 'Pay in months without interest and with the best payment alternatives to suit your needs.' | transloco }}</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-security-profile"></i>
          <div class="benefit-content">
            <h4 class="label">{{ 'More tranquility' | transloco }}</h4>
            <span class="description">{{ 'Qualified suppliers, guaranteed services and secure payment methods for you.' | transloco }}</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-thumbs-up"></i>
          <div class="benefit-content">
            <h4 class="label">{{ 'Simple and transparent' | transloco }}</h4>
            <span class="description">{{ 'Manage and track your projects online in just a few clicks.' | transloco }}</span>
          </div>
        </div>
      </div>
      <div class="become-a-customer">
        <p class="customer-benefit">{{ 'Exclusive benefits for Santander customers. If you are not a customer yet' | transloco }} <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener" target="_blank">{{ 'click here' | transloco }}</a><p>
      </div>
    </div>
  </article>
  <article class="faq-article">
    <h2 class="title">{{ 'FAQ' | transloco }}</h2>
    <div class="faq-container">
      <p-accordion [multiple]="true" class="accordion-faq">
        <p-accordionTab class="accordion-tab" header="{{ 'What is Mundo Hogar?' | transloco }}" iconPos="end">
          <p>
            {{ 'Mundo Hogar is a digital ecosystem that connects home services in one place (one stop shop), including the best financing and delivery of services by certified providers, in a transparent, simple and agile way.' | transloco }}
          </p>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="{{ 'How does Mundo Hogar work?' | transloco }}" iconPos="end">
          <ol>
            <li>{{ 'Enter to' | transloco }} <a routerLink="/">www.mundohogar.com.mx</a></li>
            <li>{{ 'Create your user and validate your portal account' | transloco }}</li>
            <li>{{ 'Access the services and products that Mundo Hogar offers you' | transloco }}</li>
          </ol>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="{{ 'Can I use these benefits without being a Santander customer?' | transloco }}" iconPos="end">
          <p>
            {{ 'No, Mundo Hogar benefits are exclusive for Santander customers.' | transloco }}
          </p>
        </p-accordionTab>
      </p-accordion>
    </div>
    <h5 class="help-content">{{ 'Visit our' | transloco }} <a class="link" href="https://ayuda.homein.mx/es/" target="_blank">{{ 'Help center' | transloco }}</a> {{ 'for more information' | transloco }}</h5>
  </article>
}
