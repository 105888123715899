<section class="password-reset-main-container">
  <div class="left-column">
    @if (displayNewPasswordView && !updatedPassword) {
      <div class="new-password-content-container">
        <h2 class="title">{{ 'Reset password' | transloco }}</h2>
        <p class="description">{{ 'Enter the new password for your Mundo Hogar account' | transloco }}</p>
        <form [formGroup]="newPasswordForm" class="form-container">
          <div class="input-container">
            <label for="password">{{ 'New password Mundo Hogar' | transloco }}</label>
            <p-password id="password" [class.active]="isActive(newPasswordForm.controls.password)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" formControlName="password" aria-describedby="password-help"></p-password>
            @if (newPasswordForm.controls.password.touched || newPasswordForm.controls.password.dirty) {
              <small class="input-error" [class.p-error]="newPasswordForm.controls.password.errors?.['minLength']"><i [ngClass]="{'icon-close': newPasswordForm.controls.password.errors?.['minLength'], 'icon-checkmark': !newPasswordForm.controls.password.errors?.['minLength']}"></i> {{ 'Minimum X characters' | transloco: { min: 8 } }}</small>
              <small class="input-error" [class.p-error]="newPasswordForm.controls.password.errors?.['uppercase']"><i [ngClass]="{'icon-close': newPasswordForm.controls.password.errors?.['uppercase'], 'icon-checkmark': !newPasswordForm.controls.password.errors?.['uppercase']}"></i> {{ 'At least one capital letter' | transloco }}</small>
              <small class="input-error" [class.p-error]="newPasswordForm.controls.password.errors?.['specialCharacter']"><i [ngClass]="{'icon-close': newPasswordForm.controls.password.errors?.['specialCharacter'], 'icon-checkmark': !newPasswordForm.controls.password.errors?.['specialCharacter']}"></i> {{ 'At least one special character' | transloco }}</small>
            }
          </div>
          <div class="input-container">
            <label for="repeated-password">{{ 'Re-enter the new password' | transloco }}</label>
            <p-password id="repeated-password" [class.active]="isActive(newPasswordForm.controls.repeatedPassword)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" formControlName="repeatedPassword" aria-describedby="repeated-password-help"></p-password>
            @if ((newPasswordForm.controls.repeatedPassword.touched || newPasswordForm.controls.repeatedPassword.dirty) && newPasswordForm.controls.repeatedPassword.errors?.['equalValues']) {
              <small class="input-error" [class.p-error]="newPasswordForm.controls.repeatedPassword.errors?.['equalValues']"><i [ngClass]="{'icon-close': newPasswordForm.controls.repeatedPassword.errors?.['equalValues'], 'icon-checkmark': !newPasswordForm.controls.repeatedPassword.errors?.['equalValues']}"></i> {{ 'Passwords do not match' | transloco }}</small>
            }
          </div>
          <div class="action-button-container">
            <app-button label="Reset password" styleClass="primary expand" [loading]="updatingPassword" [disabled]="newPasswordForm.invalid" (action)="update()"></app-button>
          </div>
        </form>
      </div>
    } @else if (!displayNewPasswordView && !updatedPassword) {
      <div class="password-recovery-content-container">
        <h2 class="title">{{ 'Forgot password?' | transloco }}</h2>
        <p class="description">{{ 'Enter your account email to send instructions' | transloco }}</p>
        <form class="form-container" [formGroup]="emailForm">
          <div class="input-container">
            <label for="email">{{ 'Email' | transloco }}</label>
            <input id="email" type="email" [class.active]="isActive(emailForm.controls.email)" [class.disabled]="recoveringPassword" pInputText formControlName="email" />
          </div>
          <div class="action-button-container">
            <app-button label="Send instructions" styleClass="primary expand" [loading]="recoveringPassword" [disabled]="emailForm.invalid" (action)="recover()"></app-button>
          </div>
        </form>
      </div>
    } @else {
      <div class="updated-password-content-container">
        <img class="image" src="/assets/illustrations/success-check.svg" [alt]="'Image success check' | transloco">
        <h4 class="title">{{ 'You now have a new password!' | transloco }}</h4>
        <p class="description">{{ 'Enter your Mundo Hogar account with your new password' | transloco }}</p>
        <div class="action-button-container">
          <app-button label="Enter" styleClass="primary expand" href="/enrollment/sign-in"></app-button>
        </div>
      </div>
    }
  </div>
  <div class="right-column">
    <div class="text-container">
      <h3 class="title">{{ (displayNewPasswordView || updatedPassword ? 'We love to see you happy' : 'Enjoy with Mundo Hogar') | transloco }}</h3>
      <p class="description">{{ (displayNewPasswordView || updatedPassword ? 'You almost regain access to your account' : 'Everything for your home in one place') | transloco }}</p>
    </div>
    <img class="image" src="/assets/images/woman-laughing.jpg" [alt]="'Image recover password' | transloco" />
  </div>
</section>