import { Injectable } from '@angular/core';
import { RemoteConfig } from '@homein-hogar-server';
import { delay, Observable, of } from 'rxjs';
import { REMOTE_CONFIGS } from './remote-configs.mock';
import { RemoteConfigsService } from './remote-configs.service';

@Injectable()
export class RemoteConfigsServiceMock implements RemoteConfigsService {
  constructor() {}

  get(): Observable<RemoteConfig | null> {
    return of(REMOTE_CONFIGS).pipe(delay(1000));
  }
}