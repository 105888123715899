import { Inject, Injectable } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  addDoc,
  collection,
  doc,
  documentId,
  Firestore,
  getCountFromServer,
  getFirestore,
  onSnapshot,
  query,
  QueryConstraint,
  serverTimestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { chunk } from '../../utils/array.utils';

const MAX_QUERY_IN_IDS = 10;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  private firestore: Firestore;

  constructor(@Inject('appName') protected appName?: string) {
    this.firestore = getFirestore(getApp(appName));
  }

  count(path: string, ...constraints: QueryConstraint[]): Promise<number> {
    return getCountFromServer(query(collection(this.firestore, path), ...constraints)).then((countSnapshot) => countSnapshot.data().count);
  }

  create<T>(path: string, data: T): Promise<string> {
    return addDoc(collection(this.firestore, path), {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    }).then((document) => document.id);
  }

  createId(path: string): string {
    return doc(collection(this.firestore, path)).id;
  }

  doc<T>(path: string): Observable<T | null> {
    return new Observable((subscriber) => {
      const unsubscribe = onSnapshot(doc(this.firestore, path), (document) => {
        if (!document.exists || !document.data()) {
          subscriber.next(null);
          return;
        }
        const data = document.data();
        subscriber.next({
          ...this.changeTimestampsToDate(data),
          id: document.id
        } as T);
        subscriber.add(unsubscribe);
      }, (error) => {
        subscriber.error(error);
      }, () => {
        subscriber.complete();
      });
    });
  }

  docs<T>(path: string, ids: string[]): Observable<T[]> {
    return new Observable((subscriber) => {
      const data: T[] = [];
      let idsProcessed = 0;
      chunk(ids, MAX_QUERY_IN_IDS).map((idsChunk) => {
        const unsubscribe = onSnapshot(query(collection(this.firestore, path), where(documentId(), 'in', idsChunk)), (querySnapshot) => {
          idsProcessed += idsChunk.length;
          querySnapshot.docs.map((document) => {
            data.push({
              ...this.changeTimestampsToDate(document.data()),
              id: document.id
            });
          });
          if (ids.length === idsProcessed) {
            if (data.length !== ids.length) {
              subscriber.error('Some documents were not found');
            } else {
              subscriber.next(data);
            }
          }
        }, (error) => subscriber.error(error), () => subscriber.complete());
        subscriber.add(unsubscribe);
      });
    });
  }

  query<T>(ref: string, params: {
    childField?: string;
    constraints?: QueryConstraint[];
  }): Observable<T[]> {
    return new Observable((subscriber) => {
      const unsubscribe = onSnapshot(query(collection(this.firestore, ref), ...(params?.constraints || [])), (querySnapshot) => {
        const data = querySnapshot.docs.map((document) => {
          return {
            ...this.changeTimestampsToDate<T>(document.data()),
            id: document.id
          } as T & {
            _childDocumentReference?: {
              id: string;
              path: string;
            }
          };
        });
        if (data.length && data[0]._childDocumentReference) {
          let index = 0;
          const parentPath = data[0]._childDocumentReference.path;
          const ids: string[] = [];
          data.forEach((d) => {
            if (d._childDocumentReference) {
              ids.push(d._childDocumentReference.id);
            }
          });
          chunk(ids, MAX_QUERY_IN_IDS).map((idsChunk) => {
            const unsubscribeChild = onSnapshot(query(collection(this.firestore, parentPath), where(documentId(), 'in', idsChunk)), (childQuerySnapshot) => {
              childQuerySnapshot.docs.map((document) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (data[index] as any)[params.childField as string] = {
                  ...this.changeTimestampsToDate(document.data()),
                  id: document.id
                };
                ++index;
                if (index === data.length) {
                  subscriber.next(data);
                }
              });
            }, (error) => subscriber.error(error));
            subscriber.add(unsubscribeChild);
          });
        } else {
          subscriber.next(data);
        }
        subscriber.add(unsubscribe);
      }, (error) => {
        subscriber.error(error);
      }, () => {
        subscriber.complete();
      });
    });
  }

  update(ref: string, data: object): Promise<void> {
    return updateDoc(doc(this.firestore, ref), {
      ...data,
      updatedAt: serverTimestamp()
    });
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  private changeTimestampsToDate<T>(obj: any): T {
    if (!obj) {
      return obj;
    }
    const changedObject = Object.assign({}, obj);
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        if (obj[key].constructor.name === 'Date') {
          return;
        } else if (obj[key].constructor.name === 'Array') {
          /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
          changedObject[key] = (obj[key] as any[]).map((o) => {
            if (o && typeof o === 'object') {
              return this.changeTimestampsToDate(o);
            }
            return o;
          });
        } else if (obj[key].constructor.name === 'Timestamp' || obj[key].constructor.name === '_Timestamp' || typeof obj[key].toDate === 'function') {
          changedObject[key] = obj[key].toDate();
        } else if (obj[key].constructor.name === 'GeoPoint') {
          changedObject[key] = {
            latitude: obj[key].latitude,
            longitude: obj[key].longitude,
          };
        } else {
          changedObject[key] = this.changeTimestampsToDate(obj[key]);
        }
      }
    });
    return changedObject as T;
  }
}
