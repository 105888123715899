<section class="ecommerce-purchase-details-container">
  <div class="title-container">
    @if (showBackButton) {
      <i class="icon-arrow-left" (click)="goBack()"></i>
    }
    <h2 class="title">{{ 'Purchase detail' | transloco }}</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (ecommerceOrder) {
      <div class="total-container">
        <div class="purchase-date-and-order-id">{{ ecommerceOrder.createdAt | date: 'dd/MM/yyyy' }} | #{{ ecommerceOrder.id }}</div>
        <div class="purchase-breakdown">
          <span class="label">{{ 'Products' | transloco }}</span>
          <span class="value">{{ ecommerceOrder.subtotal | currencyFormatter: ecommerceOrder.currency }} {{ ecommerceOrder.currency }}</span>
        </div>
        <div class="purchase-breakdown">
          <span class="label">{{ 'Discounts' | transloco }}</span>
          <span class="value">-{{ ecommerceOrder.discounts | currencyFormatter: ecommerceOrder.currency }} {{ ecommerceOrder.currency }}</span>
        </div>
        <div class="purchase-breakdown">
          <!-- TODO: handle more types of shipping -->
          <span class="label">{{ 'Shipping (Free - Cliente Santander)' | transloco }}</span>
          <span class="value" [class.strike]="!ecommerceOrder.shippingCost">{{ ecommerceOrder.originalShippingCost | currencyFormatter: ecommerceOrder.currency }} {{ ecommerceOrder.currency }}</span>
        </div>
        <div class="separator"></div>
        <div class="total">
          <div class="label">{{ 'Total' | transloco }}</div>
          <div class="value">{{ ecommerceOrder.totalAmount | currencyFormatter: ecommerceOrder.currency }} {{ ecommerceOrder.currency }}</div>
        </div>
      </div>
      <app-purchase-payment-details [paymentIntentIds]="[ecommerceOrder.paymentIntentId]" />
      <div class="shipping-container">
        <h2 class="title">{{ 'Shipping details' | transloco }}</h2>
        <div class="address-container">
          <div class="icon-container">
            <i class="icon-truck"></i>
          </div>
          <div class="info">
            <span>{{ ecommerceOrder.shippingAddress.street }} {{ ecommerceOrder.shippingAddress.streetNumber }}</span>
            <span>{{ ecommerceOrder.shippingAddress.province }}, {{ ecommerceOrder.shippingAddress.municipality }}</span>
          </div>
        </div>
        <div class="products-container">
          <p-accordion class="accordion-purchase-items">
            <p-accordionTab class="accordion-items" header="{{ 'Purchase items (X items)' | transloco: { quantity: ecommerceOrder.items.length } }}" iconPos="end">
              @for (item of ecommerceOrder.items; track item) {
                <div class="product-container">
                  <img class="image" [src]="item.thumbnail" alt="{{ 'Product image' | transloco }}" loading="lazy" />
                  <div class="info">
                    <span class="above">{{ item.name }}</span>
                    <span class="under">{{ item.price | currencyFormatter: ecommerceOrder.currency }} {{ ecommerceOrder.currency }} | {{ item.quantity }} u.</span>
                  </div>
                </div>
              }
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    }
  </app-retryable-section>
</section>