import { SearchEngineProduct } from '@homein-hogar-server';

// TODO F&F: add more products
export const PRODUCTS_MOCK: SearchEngineProduct[] = [
  {
    additionalData: null,
    brand: 'Stokes, Harvey and Windler',
    categories: ['home-office'],
    comments: 0,
    currency: 'MXN',
    description: 'Ergonomic executive chair upholstered in bonded black leather and PVC padded seat and back for all-day comfort and support',
    dimensions: { depth: 1, height: 1, weight: 20, width: 1 },
    discountAmount: 597,
    discountPercentage: 0.07760301572858443,
    ean: 'eaeaean',
    id: 't0h8tk8xehwfw2xpyk79',
    images: ['https://picsum.photos/800', 'https://picsum.photos/800', 'https://picsum.photos/800'],
    name: 'Unbranded Plastic Bacon',
    originalPrice: 7693,
    partnerProductId: 'NNGKCF',
    price: 7096,
    pricing: {
      // TODO F&F: add more realistic pricing values
      debit: {
        discountAmount: 0,
        discountPercentage: 0,
        monthlyPayment: 7096,
        monthlyShippingPayment: 0,
        originalShippingCost: 600,
        price: 7096,
        shippingCost: 0,
        term: 1,
      },
      credit: {
        discountAmount: 0,
        discountPercentage: 0,
        monthlyPayment: 7096,
        monthlyShippingPayment: 0,
        originalShippingCost: 600,
        price: 7096,
        shippingCost: 0,
        term: 1,
      },
      'credit-3': {
        discountAmount: 0,
        discountPercentage: 0,
        monthlyPayment: 7096,
        monthlyShippingPayment: 0,
        originalShippingCost: 600,
        price: 7096,
        shippingCost: 0,
        term: 3,
      },
      'credit-6': {
        discountAmount: 0,
        discountPercentage: 0,
        monthlyPayment: 7096,
        monthlyShippingPayment: 0,
        originalShippingCost: 600,
        price: 7096,
        shippingCost: 0,
        term: 6,
      },
      'credit-12': {
        discountAmount: 0,
        discountPercentage: 0,
        monthlyPayment: 7096,
        monthlyShippingPayment: 0,
        originalShippingCost: 600,
        price: 7096,
        shippingCost: 0,
        term: 12,
      },
      'credit-18': {
        discountAmount: 0,
        discountPercentage: 0,
        monthlyPayment: 7096,
        monthlyShippingPayment: 0,
        originalShippingCost: 600,
        price: 7096,
        shippingCost: 0,
        term: 18,
      },
      'credit-24': {
        discountAmount: 0,
        discountPercentage: 0,
        monthlyPayment: 7096,
        monthlyShippingPayment: 0,
        originalShippingCost: 600,
        price: 7096,
        shippingCost: 0,
        term: 24,
      },
      'credit-36': {
        discountAmount: 0,
        discountPercentage: 0,
        monthlyPayment: 7096,
        monthlyShippingPayment: 0,
        originalShippingCost: 600,
        price: 7096,
        shippingCost: 0,
        term: 36,
      }
    },
    rating: 5,
    sku: 'FYGHTPJPD0',
    slug: 'Stokes, Harvey and Windler-t0h8tk8xehwfw2xpyk79',
    stock: 10,
    status: 'active',
    tags: [],
    thumbnails: [ 'https://picsum.photos/300' ],
    updatedAt: 1727377641356,
  }
];
