import { CommonModule, Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { RemodelingOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { firstValueFrom, Subject } from 'rxjs';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { PlatformService } from '../../services/platform/platform.service';
import { RemodelingOrdersService } from '../../services/remodeling-orders/remodeling-orders.service';
import { PurchasePaymentDetailsComponent } from '../purchase-payment-details/purchase-payment-details.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-remodeling-purchase-details',
  standalone: true,
  imports: [
    AccordionModule,
    CommonModule,
    CurrencyFormatterPipe,
    PurchasePaymentDetailsComponent,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './remodeling-purchase-details.component.html',
  styleUrl: './remodeling-purchase-details.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RemodelingPurchaseDetailsComponent implements OnInit, OnDestroy {
  @Input() order: RemodelingOrder;
  @Input() showBackButton = true;
  errorLoading = false;
  loading = true;
  private orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private location: Location,
    private remodelingOrdersService: RemodelingOrdersService,
    private platformService: PlatformService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      this.goToMyRemodelings();
      return;
    }
    this.orderId = orderId;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void  {
    this.location.back();
  }

  async initialize(): Promise<void> {
    this.errorLoading = false;
    this.loading = true;
    try {
      if (!this.order) {
        const order = await firstValueFrom(this.remodelingOrdersService.get(this.orderId));
        if (!order) {
          throw new Error('Remodeling order not found');
        }
        this.order = order;
      }
    } catch (error) {
      this.errorReportingService.log('RemodelingPurchaseDetailsComponent.initialize()', 'get-remodeling-order', error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

  private goToMyRemodelings(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/my-remodelings']);
  }
}