import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-purchases/my-purchases.page').then(m => m.MyPurchasesPage)
  },
  {
    path: ':orderId',
    loadComponent: () => import('./purchase-status/purchase-status.page').then(m => m.PurchaseStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('./purchase-details/purchase-details.page').then(m => m.PurchaseDetailsPage)
  }
] satisfies Routes;
