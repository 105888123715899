import { Route } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage),
  },
  {
    path: 'shopping-cart',
    loadComponent: () => import('./shopping-cart/shopping-cart.page').then(m => m.ShoppingCartPage),
  },
  {
    path: 'search',
    loadComponent: () => import('./product-search/product-search.page').then(m => m.ProductSearchPage),
  },
  {
    path: 'my-favorites',
    loadComponent: () => import('./my-favorites/my-favorites.page').then(m => m.MyFavoritesPage),
  },
  {
    path: 'products/:slug',
    loadComponent: () => import('./product-detail/product-detail.page').then(m => m.ProductDetailPage),
  }
] satisfies Route[];