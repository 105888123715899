import { Injectable } from '@angular/core';
import { SearchEngineProduct } from '@homein-hogar-server';
import { Paginable, SearchService } from '../search/search.service';
import {
  BASIC_FILTER,
  PRODUCT_SEARCH_DEFAULT_FIELDS,
  PRODUCTS_PER_PAGE_DEFAULT,
  ProductSearchConfig,
  ProductsService,
} from './products.service';

@Injectable()
export class ProductsServiceApi implements ProductsService {
  constructor(
    private searchService: SearchService,
  ) {}

  get(id: string): Promise<SearchEngineProduct | null> {
    return this.searchService.get('products', id);
  }

  search(query: string | null, config?: ProductSearchConfig): Promise<Paginable<SearchEngineProduct>> {
    return this.searchService.search<SearchEngineProduct>('products', {
      filters: config?.filters ? [...BASIC_FILTER, ...config.filters]: BASIC_FILTER,
      query: {
        value: query,
        fields: PRODUCT_SEARCH_DEFAULT_FIELDS,
      },
      pagination: {
        documentsPerPage: config?.pagination?.documentsPerPage ?? PRODUCTS_PER_PAGE_DEFAULT,
        page: config?.pagination?.page ?? 1,
      },
      order: config?.order,
    });
  }
}