<section class="personal-data-container">
  <div class="left-section">
    <h1 class="section-title">
      <i class="icon icon-arrow-left" (click)="goBack()"></i>
      <span>{{ 'Personal data' | transloco }}</span>
    </h1>
    <app-retryable-section [error]="errorLoading" [loading]="isLoading" (retry)="initialize()">
      <form [formGroup]="form" class="form-container">
        <div class="input-container">
          <label for="name">{{ 'Name' | transloco }}*</label>
          <input class="input" id="name" type="text" pInputText formControlName="name" aria-describedby="name-help" />
          <small id="name-help" [class.hidden]="!form.controls.name.touched || !form.controls.name.errors" class="p-error input-error">{{ 'Enter a valid name.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="father-last-name">{{ 'Father last name' | transloco }}*</label>
          <input class="input" type="text" id="father-last-name" pInputText formControlName="fatherLastName" aria-describedby="father-last-name-help" />
          <small id="father-last-name-help" [class.hidden]="!form.controls.fatherLastName.touched || !form.controls.fatherLastName.errors" class="p-error input-error">{{ 'Enter a valid father last name.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="mother-last-name">{{ 'Mother last name' | transloco }}*</label>
          <input class="input" type="text" id="mother-last-name" pInputText formControlName="motherLastName" aria-describedby="mother-last-name-help" />
          <small id="mother-last-name-help" [class.hidden]="!form.controls.motherLastName.touched || !form.controls.motherLastName.errors" class="p-error input-error">{{ 'Enter a valid mother last name.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="birth-date">{{ 'Birth date' | transloco }}*</label>
          <p-calendar class="input" id="birth-date" dateFormat="dd/mm/yy" [maxDate]="maxDateBirthDate" [defaultDate]="user?.birthDate!" formControlName="birthDate" aria-describedby="birth-date-help" class="birth-date" />
          <small id="birth-date-help" [class.hidden]="!form.controls.birthDate.touched || !form.controls.birthDate.errors" class="p-error input-error">{{ 'Enter a valid birth date.' | transloco }}</small>
        </div>
        <div class="input-container">
          @if (user?.emailValidated) {
            <div class="email-container">
              <label for="email" class="label">{{ 'Email' | transloco }}*</label>
              <div class="verified-container" [class.disabled]="form.controls.email.disabled">
                <i class="icon icon-checkmark"></i>
                <small class="email-verified" [class.check]="user?.emailValidated">{{ 'Verified' | transloco }}</small>
              </div>
            </div>
          } @else {
            <label for="email">{{ 'Email' | transloco }}*</label>
          }
          <input id="email" type="email" pInputText formControlName="email" aria-describedby="email-help" [class.disabled]="form.controls.email.disabled" />
          <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error input-error">{{ 'Enter a valid email.' | transloco }}</small>
        </div>
        <div class="input-container">
          <label for="phone-number">{{ 'Phone number' | transloco }}*</label>
          <p-inputMask id="phone-number" formControlName="phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 9999" aria-describedby="phone-number-help" [class.disabled]="form.controls.phoneNumber.disabled" [class.input-mask]="!form.controls.phoneNumber.disabled" />
          <small id="phone-number-help" [class.hidden]="!form.controls.phoneNumber.touched || !form.controls.phoneNumber.errors" class="p-error input-error">{{ 'Enter a valid phone number, it must start with \"52\" and then 10 digits.' | transloco }}</small>
        </div>
        <app-button label="Save changes" class="send" styleClass="primary expand" [disabled]="!isValidForm" [loading]="sendingForm" (action)="onSubmit()" />
      </form>
    </app-retryable-section>
  </div>
  <div class="right-section">
    <div class="text-container">
      <h2 class="title">Mundo Hogar</h2>
      <p class="subtitle">{{ 'A place that connects the experiences that your home needs' | transloco }}</p>
    </div>
  </div>
</section>