import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PaymentIntent } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { combineLatest, firstValueFrom, Subject } from 'rxjs';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { PaymentIntentsService } from '../../services/payment-intents/payment-intents.service';
import { PlatformService } from '../../services/platform/platform.service';
import { getAccountLabelByType } from '../../utils/accounts.utils';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-purchase-payment-details',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './purchase-payment-details.component.html',
  styleUrl: './purchase-payment-details.component.scss',
})
export class PurchasePaymentDetailsComponent implements OnInit, OnDestroy {
  @Input() paymentIntentIds: string[] | null;
  currency: string;
  errorLoading = false;
  loading = true;
  paymentIntents: PaymentIntent[];
  total = 0;
  protected getAccountLabelByType = getAccountLabelByType;
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private paymentIntentsService: PaymentIntentsService,
    private platformService: PlatformService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async initialize(): Promise<void> {
    this.errorLoading = false;
    this.loading = true;
    let step = '';
    try {
      step = 'get-payment-intent';
      this.paymentIntentIds = this.paymentIntentIds?.filter((id) => id) ?? null;
      if (!this.paymentIntentIds || !this.paymentIntentIds.length) {
        throw new Error('Payment intent id not provided');
      }
      const paymentIntents = await firstValueFrom(combineLatest(this.paymentIntentIds.map((paymentIntentId) => this.paymentIntentsService.get(paymentIntentId))));
      this.paymentIntents = paymentIntents.filter((paymentIntent) => paymentIntent) as PaymentIntent[];
      if (this.paymentIntents.length !== this.paymentIntentIds.length) {
        throw new Error('Payment intent not found');
      }
      this.total = this.paymentIntents.reduce((amount, paymentIntent) => amount + paymentIntent.amount, 0);
      this.currency = this.paymentIntents[0].currency;
    } catch (error) {
      this.errorReportingService.log('PurchasePaymentDetailsComponent.initialize()', step, error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

}