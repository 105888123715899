import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AdvertisingSpace } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { LottieComponent } from 'ngx-lottie';
import { AccordionModule } from 'primeng/accordion';
import { Carousel, CarouselModule } from 'primeng/carousel';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../components/button/button.component';
import { ProductsOfInterestComponent } from '../../components/products-of-interest/products-of-interest.component';
import { RetryableSectionComponent } from '../../components/retryable-section/retryable-section.component';
import { TermsBannerComponent } from '../../components/terms-banner/terms-banner.component';
import { AdvertisingSpacesService } from '../../services/advertising-spaces/advertising-spaces.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { PlatformService } from '../../services/platform/platform.service';
import { randomize } from '../../utils/array.utils';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    LottieComponent,
    ProductsOfInterestComponent,
    RetryableSectionComponent,
    RouterLink,
    TermsBannerComponent,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnInit {
  @ViewChild('carousel') carousel: Carousel;
  errorLoadingAdvertisingSpaces = false;
  exclusiveBenefits: AdvertisingSpace[] = [];
  loadingAdvertisingSpaces = false;
  promotions: AdvertisingSpace[] = [];

  constructor(
    private advertisingSpacesService: AdvertisingSpacesService,
    private errorReportingService: ErrorReportingService,
    private metaTagsService: MetaTagsService,
    private platformService: PlatformService,
  ) {}

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async getAdvertisingSpaces(): Promise<void> {
    this.loadingAdvertisingSpaces = true;
    this.errorLoadingAdvertisingSpaces = false;
    try {
      const [exclusiveBenefits, promotions] = await Promise.allSettled([
        firstValueFrom(this.advertisingSpacesService.getExclusiveBenefits()),
        firstValueFrom(this.advertisingSpacesService.getPromotions()),
      ]);
      this.exclusiveBenefits = exclusiveBenefits.status === 'fulfilled' ? exclusiveBenefits.value : [];
      this.promotions = randomize(promotions.status === 'fulfilled' ? promotions.value : []);
    } catch (error) {
      this.errorReportingService.log('HomePage.getAdvertisingSpaces()', 'get-advertising-spaces', error);
      this.errorLoadingAdvertisingSpaces = true;
    } finally {
      this.loadingAdvertisingSpaces = false;
    }
  }

  startAutoplay(carousel: Carousel): void {
    carousel.startAutoplay();
  }

  stopAutoplay(carousel: Carousel): void {
    carousel.stopAutoplay();
  }

  private initialize(): void {
    this.metaTagsService.setMetaTags();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.getAdvertisingSpaces();
  }
}
