import { Injectable } from '@angular/core';
import { Account } from '@homein-accounts-server';
import { map, Observable, of, switchMap } from 'rxjs';
import { MockService } from '../../mock/mock.service';
import { UsersService } from '../../users/users.service';
import { AccountsService } from './accounts.service';

@Injectable()
export class AccountsServiceMock implements AccountsService {
  private accounts: Observable<Account[]>;

  constructor(
    private mockService: MockService,
    private usersService: UsersService,
  ) {
    this.accounts = this.usersService.getCurrentUser().pipe(switchMap((user) => {
      if (user) {
        return this.mockService.getAccounts().pipe(map((accounts) => {
          return accounts.filter((account) => account.ownerId === user.id && account.status === 'active');
        }));
      }
      return of([]);
    }));
  }

  getAll(): Observable<Account[]> {
    return this.accounts;
  }
}
