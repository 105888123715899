import { Route } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage),
  },
  {
    path: 'plan-hogar',
    loadComponent: () => import('./plan-hogar/plan-hogar.page').then(m => m.PlanHogarPage),
  },
  {
    path: 'plan-mascotas',
    loadComponent: () => import('./plan-mascotas/plan-mascotas.page').then(m => m.PlanMascotasPage),
  },
  {
    path: 'plan-hogar-mascotas',
    loadComponent: () => import('./plan-hogar-mascotas/plan-hogar-mascotas.page').then(m => m.PlanHogarMascotasPage),
  },
  {
    path: 'request-home-assistance',
    loadComponent: () => import('./request-home-assistance/request-home-assistance.page').then(m => m.RequestHomeAssistancePage),
  },
] satisfies Route[];