import { ChargeIntent } from '@homein-hogar-server';
import { USERS_MOCK } from '../users/users.mock';
/*
import { PRODUCTS_MOCK } from '../products/products.mock';
import { USERS_MOCK } from '../users/users.mock';

const product = PRODUCTS_MOCK[0];
*/
const user = USERS_MOCK[0];

export const CHARGE_INTENTS_MOCK: ChargeIntent[] = [
  {
    createdAt: new Date(),
    externalReference: 'fJLHtjLQNx4ZvuBRTEEt',
    id: 'vDRlEBEwEvehnuYxNTWF',
    items: [
      {
        id: 'fJLHtjLQNx4ZvuBRTEEt',
        currency: 'MXN',
        description: null,
        quantity: 1,
        title: 'Luis reforma de cocina',
        unitPrice: 7000,
      }
    ],
    notificationUrl: null,
    paymentGatewayUrl: 'http://localhost:4200/payment-gateway/checkout?source=remodeling&resourceType=charge-intent&resourceIds=vDRlEBEwEvehnuYxNTWF',
    status: 'pending',
    toAccountId: 'NeFrdPDICOCDvdnNICsL',
    transactionId: null,
    updatedAt: new Date(),
    userId: user.id,
  },
  {
    createdAt: new Date(),
    externalReference: 'Yr6Hsnixndgvp6XQrAlj',
    id: 'JaqtPea7UWU4lVfmWCzS',
    items: [
      {
        id: 'Yr6Hsnixndgvp6XQrAlj',
        currency: 'MXN',
        description: null,
        quantity: 1,
        title: 'Luis reforma de baño',
        unitPrice: 8000,
      }
    ],
    notificationUrl: null,
    paymentGatewayUrl: 'http://localhost:4200/payment-gateway/checkout?source=remodeling&resourceType=charge-intent&resourceIds=JaqtPea7UWU4lVfmWCzS',
    status: 'paid',
    toAccountId: 'NeFrdPDICOCDvdnNICsL',
    transactionId: null,
    updatedAt: new Date(),
    userId: user.id,
  },
  {
    createdAt: new Date(),
    externalReference: 'yYM8OqQ7LOCi5rOETHrp',
    id: 'm1SmyBKCgTKwtmrDzvB8',
    items: [
      {
        id: 'yYM8OqQ7LOCi5rOETHrp',
        currency: 'MXN',
        description: null,
        quantity: 1,
        title: 'Luis reforma de baño',
        unitPrice: 3000,
      }
    ],
    notificationUrl: null,
    paymentGatewayUrl: 'http://localhost:4200/payment-gateway/checkout?source=remodeling&resourceType=charge-intent&resourceIds=m1SmyBKCgTKwtmrDzvB8',
    status: 'paid',
    toAccountId: 'NeFrdPDICOCDvdnNICsL',
    transactionId: null,
    updatedAt: new Date(),
    userId: user.id,
  }
  // TODO F&F: fix this mock
  /* {
    createdAt: new Date(),
    externalReference: null,
    id: 'vDRlEBEwEvehnuYxNTWF',
    items: [
      {
        id: product.id,
        quantity: 1,
        unitPrice: product.price,
        currency: product.currency,
        description: product.description,
        title: product.name,
      }
    ],
    notificationUrl: null,
    paymentGatewayUrl: 'http://localhost:4200/payment-gateway/checkout?source=partner&chargeIntentId=vDRlEBEwEvehnuYxNTWF',
    status: 'pending',
    toAccountId: 'NeFrdPDICOCDvdnNICsL',
    transactionId: null,
    updatedAt: new Date(),
    userId: user.id,
  }*/
];
