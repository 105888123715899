import { Injectable } from '@angular/core';
import { CreateProjectSimulationData, ProjectSimulationsRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { ProjectSimulationsService } from './project-simulations.service';

const COLLECTION_PATH = 'projectSimulations';

@Injectable()
export class ProjectSimulationsServiceApi implements ProjectSimulationsService {
  private client: CreateTRPCProxyClient<ProjectSimulationsRouter>;

  constructor() {
    this.client = createTRPCProxyClient<ProjectSimulationsRouter>(getTRPCClientOptions(`${environment.apiUrl}/${COLLECTION_PATH}`));
  }

  create(data: CreateProjectSimulationData): Promise<void> {
    return this.client.create.mutate(data).then();
  }
}