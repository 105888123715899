<section class="moving-purchase-details-container">
  <div class="title-container">
    @if (showBackButton) {
      <i class="icon-arrow-left" (click)="goBack()"></i>
    }
    <h2 class="title">{{ 'Purchase detail' | transloco }}</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (movingOrder) {
      <div class="total-container">
        <div class="purchase-detail">{{ movingOrder.createdAt | date: 'dd/MM/yyyy' }} | #{{ movingOrder.id }}</div>
        <div class="products-value">
          <span class="label">{{ 'Reservation' | transloco }}</span>
          <span class="value">{{ movingOrder.subtotal | currencyFormatter: movingOrder.currency }} {{ movingOrder.currency }}</span>
        </div>
        <div class="products-value">
          <span class="label">{{ 'Discounts' | transloco }}</span>
          <span class="value">-{{ movingOrder.discounts | currencyFormatter: movingOrder.currency }} {{ movingOrder.currency }}</span>
        </div>
        <div class="separator"></div>
        <div class="total">
          <div class="label">{{ 'Total' | transloco }}</div>
          <div class="value">{{ movingOrder.totalAmount | currencyFormatter: movingOrder.currency }} {{ movingOrder.currency }}</div>
        </div>
      </div>
      <div class="app-purchase-payment">
        @if (movingOrder.paymentIntentId) {
          <app-purchase-payment-details [paymentIntentIds]="[movingOrder.paymentIntentId]" />
        }
      </div>
      <div class="moving-container">
        <h2 class="title">{{ 'Moving details' | transloco }}</h2>
        <div class="address-container">
          <div class="address-wrapper">
            <div class="icon-container">
              <i class="icon-location-start"></i>
            </div>
            <div class="info">
              <span class="address">{{ startAddress.firstLine }}</span>
              <span class="address">{{ startAddress.secondLine }}</span>
            </div>
          </div>
          <div class="address-wrapper">
            <div class="icon-container">
              <i class="icon-location-end"></i>
            </div>
            <div class="info">
              <span class="address">{{ endAddress.firstLine }}</span>
              <span class="address">{{ endAddress.secondLine }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="moving-items-container">
        <p-accordion class="accordion-moving-items">
            <p-accordionTab class="accordion-items" header="{{ 'Moving items (X items)' | transloco: { quantity: movingOrder.items.length } }}" iconPos="end">
            @for (item of movingOrder.items; track $index) {
              <div class="item">
                <span class="name">{{ item.name }}</span>
                <span class="quantity">{{ item.quantity }} u.</span>
              </div>
            }
          </p-accordionTab>
        </p-accordion>
      </div>
    }
  </app-retryable-section>
</section>