import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FavoriteItem, SearchEngineProduct } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { BadgeModule } from 'primeng/badge';
import { ProgressBarModule } from 'primeng/progressbar';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ComplementaryEcommerceBarComponent } from '../../../components/complementary-ecommerce-bar/complementary-ecommerce-bar.component';
import { ProductCardComponent } from '../../../components/product-card/product-card.component';
import { ProductsOfInterestComponent } from '../../../components/products-of-interest/products-of-interest.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { SearchInputComponent } from '../../../components/search-input/search-input.component';
import { SearchSidebarComponent } from '../../../components/search-sidebar/search-sidebar.component';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../../services/favorites/favorites.service';
import { ProductsService } from '../../../services/products/products.service';
import { Paginable, SearchFilter } from '../../../services/search/search.service';
import { areEqualArrays } from '../../../utils/array.utils';
import { areEqualObjects } from '../../../utils/object.utils';

@Component({
  selector: 'app-product-search',
  standalone: true,
  imports: [
    BadgeModule,
    ButtonComponent,
    CommonModule,
    ComplementaryEcommerceBarComponent,
    ProductCardComponent,
    ProductsOfInterestComponent,
    ProgressBarModule,
    RetryableSectionComponent,
    RouterModule,
    SearchInputComponent,
    SearchSidebarComponent,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './product-search.page.html',
  styleUrl: './product-search.page.scss',
})
export class ProductSearchPage implements OnInit, OnDestroy {
  errorLoadingMoreProducts = false;
  errorLoadingPreviousProducts = false;
  errorLoadingProductSearch = false;
  favorites: Record<string, FavoriteItem> = {};
  filters: SearchFilter[] | null;
  hasPreviousItems = false;
  loadingMoreProducts = false;
  loadingPreviousProducts = false;
  loadingProductSearch = false;
  missingPreviousPage: number | null;
  order: { direction: 'asc' | 'desc'; field: string; } | null;
  page: number;
  paginable: Paginable<SearchEngineProduct>;
  percentageProductsLoaded = 0;
  products: SearchEngineProduct[] = [];
  query: string | null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private productsService: ProductsService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (params) => {
        const query = params['query'] ?? null;
        const filters = params['filters'] ? Object.keys(JSON.parse(params['filters'])).map((key) => JSON.parse(params['filters'])[key] as SearchFilter) : null;
        const order = params['order'] ? JSON.parse(params['order']) : null;
        this.page = params['page'] ? +params['page'] : 1;
        if (this.query !== query || !areEqualArrays(this.filters, filters) || !areEqualObjects(this.order, order)) {
          if (this.query !== query) {
            this.query = query;
          }
          if (!areEqualArrays(this.filters, filters)) {
            this.filters = filters;
          }
          if (!areEqualObjects(this.order, order)) {
            this.order = order;
          }
          this.getProductSearch();
        }
      }
    });
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getMore(): void {
    if (this.loadingMoreProducts) {
      return;
    }
    this.loadingMoreProducts = true;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.paginable.getMore()
      .then((search) => {
        this.paginable = search;
        this.page = this.page + 1;
        this.products = [ ...this.products, ...search.data ];
        this.updateProductsValues();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['/ecommerce/search'], {
          queryParams: {
            page: this.page,
            ...(this.query && { query: this.query }),
            ...(this.filters && { filters: JSON.stringify(this.filters) }),
            ...(this.order && { order: JSON.stringify(this.order) }),
          }
        });
      })
      .catch((error) => {
        this.errorReportingService.log('ProductSearchPage.getMore()', 'paginable-more', error);
        this.errorLoadingMoreProducts = true;
      })
      .finally(() => this.loadingMoreProducts = false);
  }

  getPrevious(): void {
    if (this.loadingPreviousProducts) {
      return;
    }
    this.loadingPreviousProducts = true;
    this.missingPreviousPage = this.page - Math.ceil(this.products.length / this.paginable.size);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.productsService.search(this.query, {
      pagination: {
        page: this.missingPreviousPage,
      },
      filters: this.filters,
      order: this.order,
    })
      .then((search) => {
        this.missingPreviousPage = this.missingPreviousPage === 1 ? this.missingPreviousPage - 1 : null;
        this.products = [ ...search.data, ...this.products ];
        this.updateProductsValues();
      })
      .catch((error) => {
        this.errorReportingService.log('ProductSearchPage.getPrevious()', 'get-product-search', error);
        this.errorLoadingPreviousProducts = true;
      })
      .finally(() => this.loadingPreviousProducts = false);
  }

  getProductSearch(): void {
    this.loadingProductSearch = true;
    this.products = [];
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.productsService.search(this.query, {
      pagination: {
        page: this.page,
      },
      filters: this.filters,
      order: this.order,
    })
      .then((search) => {
        this.paginable = search;
        this.products = [ ...this.products, ...search.data ];
        this.updateProductsValues();
      })
      .catch((error) => {
        this.products = [];
        this.errorLoadingProductSearch = true;
        this.errorReportingService.log('ProductSearchPage.getProductSearch()', 'get-product-search', error);
      })
      .finally(() => this.loadingProductSearch = false);
  }

  private updateProductsValues(): void {
    this.hasPreviousItems = this.paginable && this.paginable.totalItems > 0 && this.paginable.totalItems > this.paginable.size && this.paginable.totalItems !== this.products.length && this.products.length < this.page * this.paginable.size;
    this.percentageProductsLoaded = this.products.length / this.paginable.totalItems * 100;
  }

  private initialize(): void {
    this.favoritesService.get().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (favorite) => {
        this.favorites = {};
        if (favorite) {
          favorite.items.forEach((item) => this.favorites[`${item.resourceType}-${item.resourceId}`] = item);
        }
      },
      error: (error) => this.errorReportingService.log('ProductSearchPage.initialize()', 'get-all-favorites', error),
    });
  }
}
