<section class="my-movings-container">
  <div class="title-container">
    <i class="icon-arrow-left" (click)="goBack()"></i>
    <h2 class="title">{{ 'My movings' | transloco }}
      @if (movingOrders.length) {
        <span class="total-items">({{ movingOrders.length }})</span>
      }
    </h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (!movingOrders.length) {
      <div class="empty-movings animate__fadeIn">
        <img class="image" src="/assets/illustrations/empty-movings.svg" [alt]="'You don\'t have moving illustration' | transloco" />
        <h5 class="title">{{ 'You have no scheduled/quoted moves yet' | transloco }}</h5>
        <p class="subtitle">{{ 'Quote your next moving' | transloco }}</p>
        <app-button label="Go to movings" styleClass="secondary" href="/moving"></app-button>
      </div>
    } @else {
      <div class="orders-container animate__fadeIn">
      @for (movingOrder of movingOrders; track $index) {
        <div class="order-container">
          <div class="products-container">
            <a class="product-container" routerLink="/private-site/my-movings/{{ movingOrder.id }}">
              @if (movingOrder.status === 'pending-payment') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status pending">{{ 'Pending payment' | transloco }}</span>
                    <span class="description">{{ 'Service to be selected' | transloco }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">{{ 'To continue you must pay for the service' | transloco }}</span>
                    <div class="button-container">
                      <app-button label="Go to reserve the date" styleClass="secondary" (action)="goToReserveTheDate(movingOrder)" />
                    </div>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">{{ 'You need to make a payment to reserve your moving date' | transloco }}</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Go to reserve the date" styleClass="tertiary" (action)="goToReserveTheDate(movingOrder)" />
                  <app-button label="See moving" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'waiting-confirmation') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status pending">{{ 'Waiting confirmation' | transloco }}</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">{{ 'Your moving request is being processed' | transloco }}</span>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">{{ 'We will notify you as soon as your moving request is ready to continue' | transloco }}</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="See moving" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'pending-confirmation-visit') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status pending">{{ 'Pending visit' | transloco }}</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">{{ 'To continue you must coordinate the inspection visit' | transloco }}</span>
                    <div class="button-container">
                      <app-button label="Coordinate visit in the chat" styleClass="secondary" (action)="goToChat(movingOrder)" />
                    </div>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">{{ 'You need to coordinate the inspection visit in the chat. In this chat you can manage everything about your move.' | transloco}}</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Coordinate visit in the chat" styleClass="tertiary" (action)="goToChat(movingOrder)" />
                  <app-button label="See moving" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'waiting-moving') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status">{{ 'All set' | transloco }}</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">{{ 'All set for your move' | transloco }}</span>
                    <div class="button-container">
                      <app-button label="Go to chat" styleClass="secondary" (action)="goToChat(movingOrder)" />
                    </div>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">{{ 'Everything ready for your move. You can visit our' | transloco }} <a class="tips-page" routerLink="/">{{ 'tips and recommendations page.' | transloco }}</a></span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Go to chat" styleClass="tertiary" (action)="goToChat(movingOrder)" />
                  <app-button label="See moving" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'done') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status done">{{ 'Completed' | transloco }}</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">{{ 'Your move took place on' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">{{ 'Your move has been completed, if you have any questions you can contact the support chat' | transloco }}</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Go to chat" styleClass="tertiary" (action)="goToChat(movingOrder)" />
                  <app-button label="See moving" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'voided') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status done">{{ 'Voided' | transloco }}</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">{{ 'Your move has been voided, if you have any questions you can contact the support chat' | transloco }}</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Go to chat" styleClass="tertiary" (action)="goToChat(movingOrder)" />
                  <app-button label="See moving" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              }
              <i class="icon-arrow-right"></i>
            </a>
          </div>
        </div>
      }
    </div>
    }
  </app-retryable-section>
</section>