<section class="my-purchases-container">
  <div class="title-container">
    <i class="icon icon-arrow-left" (click)="goBack()"></i>
    <h2 class="title">{{ 'My purchases' | transloco }}
      @if (ecommerceOrders.length) {
        <span class="total-items">({{ ecommerceOrders.length }})</span>
      }
    </h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (!ecommerceOrders.length) {
      <div class="empty-purchases animate__fadeIn">
        <img class="image" src="/assets/illustrations/empty-purchases.svg" [alt]="'You don\'t have purchases illustration' | transloco" />
        <h5 class="title">{{ 'No purchases yet' | transloco }}</h5>
        <p class="subtitle">{{ 'Start exploring hogar shopping' | transloco }}</p>
        <app-button label="Go to hogar shopping" styleClass="secondary" href="/ecommerce"></app-button>
      </div>
    } @else {
      <div class="orders-container animate__fadeIn">
        @for (order of ecommerceOrders; track $index) {
          <div class="order-container">
            <div class="header">
              <span class="creation-date">{{ order.createdAt | date: 'dd/MM/yyyy' }}</span>
              <!-- TODO: Add logic to repurchase. This link is displayed only when one product was purchased -->
              <a class="repurchase-link single">{{ 'Repurchase' | transloco }}</a>
            </div>
            <div class="products-container">
              @for (item of order.items; track $index) {
                <div class="product-container" routerLink="/private-site/my-purchases/{{ order.id }}" [queryParams]="{ itemId: item.id }">
                  <div class="column first">
                    <img [src]="item.thumbnail" alt="{{ 'Product image' | transloco }}" loading="lazy" />
                    <div class="right-container">
                      @if (order.status === 'cancelled') {
                        <span class="shipping-status done">{{ 'Cancelled' | transloco }}</span>
                        <span class="shipping-status-description">{{ 'Your order has been cancelled, contact us for more information' | transloco }}</span>
                      } @else {
                        @if (['in-process', 'collected'].includes(order.shippingStatus)) {
                          <span class="shipping-status active">{{ 'In preparation' | transloco }}</span>
                          @if (order.shippingDate) {
                            <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: order.shippingDate | date: 'dd/MM/yyyy' } }}</span>
                          } @else {
                            <span class="delivery-date">{{ 'Arrives between X and Y' | transloco: { dateFrom: order.estimatedShippingDate.from | date: 'dd/MM/yyyy', dateTo: order.estimatedShippingDate.to | date: 'dd/MM/yyyy' } }}</span>
                          }
                          <span class="shipping-status-description">{{ 'We are preparing your order' | transloco }}</span>
                        } @else if (order.shippingStatus === 'in-transit') {
                          <span class="shipping-status active">{{ 'In transit' | transloco }}</span>
                          @if (order.shippingDate) {
                            <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: order.shippingDate | date: 'dd/MM/yyyy' } }}</span>
                          } @else {
                            <span class="delivery-date">{{ 'Arrives between X and Y' | transloco: { dateFrom: order.estimatedShippingDate.from | date: 'dd/MM/yyyy', dateTo: order.estimatedShippingDate.to | date: 'dd/MM/yyyy' } }}</span>
                          }
                          <span class="shipping-status-description">{{ 'Your order is in your way' | transloco }}</span>
                        } @else if (order.shippingStatus === 'delivered') {
                          <span class="shipping-status done">{{ 'Delivered' | transloco }}</span>
                          <span class="delivery-date">{{ 'Arrived on X' | transloco: { date: (order.shippingDate | date: 'dd/MM/yyyy') } }}</span>
                          <span class="shipping-status-description">{{ 'Your order has been delivered' | transloco }}</span>
                        } @else if (order.shippingStatus === 'exception-of-delivery') {
                          <span class="shipping-status delayed">{{ 'Temporarily delayed' | transloco }}</span>
                          <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: (order.shippingDate | date: 'dd/MM/yyyy') } }}</span>
                          <span class="shipping-status-description">{{ 'Your order is temporarily delayed while in transit due to unavoidable circumstances' | transloco }}</span>
                        }
                      }
                    </div>
                  </div>
                  <div class="column second">
                    <span>{{ item.quantity }} u. | {{ (item.quantity * item.price) | currencyFormatter: order.currency }} {{ order.currency }}</span>
                  </div>
                  <div class="column third">
                    <!-- TODO: Add logic to repurchase. This link is displayed only when one product was purchased -->
                    <!-- <app-button label="Repurchase" styleClass="tertiary" /> -->
                    <app-button label="See purchase" styleClass="secondary" href="/private-site/my-purchases/{{ order.id }}" [queryParams]="{ itemId: item.id }" />
                  </div>
                  <i class="icon icon-arrow-right"></i>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </app-retryable-section>
</section>