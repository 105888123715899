<footer>
  <div class="footer-container">
    <div class="logo"></div>
    <div class="content-container">
      <div class="section">
        <div class="title">{{ 'What do you want to do?' | transloco }}</div>
        <ul>
          <li><a routerLink="/moving">{{ 'Moving home' | transloco }}</a></li>
          <li><a routerLink="/remodeling">{{ 'Remodel my home' | transloco }}</a></li>
          <li><a routerLink="/ecommerce">{{ 'Home shopping' | transloco }}</a></li>
          <li><a routerLink="/home-assistance">{{ 'Home assistance' | transloco }}</a></li>
          <li><a routerLink="/warehouse">{{ 'Request warehousing' | transloco }}</a></li>
        </ul>
      </div>
      <div class="section">
        <h2 class="title">{{ 'Operation' | transloco }}</h2>
        <ul>
          <li><a href="https://ayuda.homein.mx/que-es-mundo-hogar" target="_blank">{{ 'What is Mundo Hogar?' | transloco }}</a></li>
        </ul>
      </div>
      <div class="section">
        <h2 class="title">{{ 'Help' | transloco }}</h2>
        <ul>
          <li><a href="https://ayuda.homein.mx/es/" target="_blank">{{ 'Go to help center' | transloco }}</a></li>
          <li><a href="https://ayuda.homein.mx/es/preguntas-frecuentes" target="_blank">{{ 'FAQ' | transloco }}</a></li>
          <li><a href="https://ayuda.homein.mx/politicas-de-privacidad" target="_blank">{{ 'Privacy policies' | transloco }}</a></li>
          <li><a href="https://ayuda.homein.mx/terminos-y-condiciones" target="_blank">{{ 'Terms and conditions' | transloco }}</a></li>
          <li><a href="https://ayuda.homein.mx/legales" target="_blank">{{ 'Claims and returns' | transloco }}</a></li>
          <li><a routerLink="/contact">{{ 'Contact' | transloco }}</a></li>
        </ul>
      </div>
      <div class="section">
        <div class="title">{{ 'Not a Santander customer?' | transloco }}</div>
        <ul>
          <li><a href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">{{ 'I want to be a customer' | transloco }}</a></li>
        </ul>
      </div>
      <!-- TODO: enable this later on -->
      <!--<div class="section">
        <div class="title">{{ 'Follow us on social networks' | transloco }}</div>
        <div class="social">
          <a href="https://www.facebook.com/" target="_blank" rel="noopener"><i class="icon-facebook"></i></a>
          <a href="https://www.instagram.com/" target="_blank" rel="noopener"><i class="icon-instagram"></i></a>
        </div>
      </div>-->
    </div>
  </div>
</footer>
