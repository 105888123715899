import { MovingOrder } from '@homein-hogar-server';
import { CONFIG } from '../../constants';
import { USERS_MOCK } from '../users/users.mock';

export const MOVING_ORDERS_MOCK: MovingOrder[] = [
  {
    additionalData: {
      chatUrl: null,
      confirmationVisitDate: null,
      serviceType: 'Mudango Full',
      quoteUrl: 'https://www.mudango.com',
    },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 0,
    endAddress: 'C. Génova 75, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    id: 'z72MhFejjgdUbVcONyrM',
    items: [
      { name: 'Sofá ecocuero café', quantity: 1 },
      { name: 'Sillas de comedor negras', quantity: 4 },
      { name: 'Mesa de centro café', quantity: 1 }
    ],
    movingDate: new Date(),
    paymentIntentId: null,
    startAddress: 'Av. P.º de la Reforma 222, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    status: 'pending-payment',
    subtotal: 0,
    tax: 0,
    totalAmount: 0,
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: {
      chatUrl: 'https://www.mudango.com',
      confirmationVisitDate: new Date(),
      serviceType: 'Mudango Plus',
      quoteUrl: 'https://www.mudango.com',
    },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 20,
    endAddress: 'C. Génova 75, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    id: 'bTbxBen3OxEXncIbNWFf',
    items: [
      { name: 'Sofá ecocuero café', quantity: 1 },
      { name: 'Sillas de comedor negras', quantity: 4 },
      { name: 'Mesa de centro café', quantity: 1 }
    ],
    movingDate: new Date(),
    paymentIntentId: '5kRr3kd4mXFOGLHH2i2t',
    startAddress: 'Av. P.º de la Reforma 222, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    status: 'done',
    subtotal: 50,
    tax: 8,
    totalAmount: 200,
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: {
      chatUrl: 'https://www.mudango.com',
      confirmationVisitDate: new Date(),
      serviceType: 'Mudango Lite',
      quoteUrl: 'https://www.mudango.com',
    },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 50,
    endAddress: 'C. Génova 75, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    id: '8Dz0Y4oel39r3uaJ06Qy',
    items: [
      { name: 'Sofá ecocuero café', quantity: 1 },
      { name: 'Sillas de comedor negras', quantity: 4 },
      { name: 'Mesa de centro café', quantity: 1 }
    ],
    movingDate: new Date(),
    paymentIntentId: 'nmfYcRBovOiji1VIRabw',
    startAddress: 'Av. P.º de la Reforma 222, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    status: 'waiting-moving',
    subtotal: 120,
    tax: 19.2,
    totalAmount: 300,
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: {
      chatUrl: 'https://www.mudango.com',
      confirmationVisitDate: null,
      serviceType: 'Mudango Black',
      quoteUrl: 'https://www.mudango.com',
    },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 7,
    endAddress: 'C. Génova 75, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    id: 'ThiyfHvCOQoWFhu59gtR',
    items: [
      { name: 'Sofá ecocuero café', quantity: 1 },
      { name: 'Sillas de comedor negras', quantity: 4 },
      { name: 'Mesa de centro café', quantity: 1 }
    ],
    movingDate: new Date(),
    paymentIntentId: '0M3mZOnbsfrgYGafqiQp',
    startAddress: 'Av. P.º de la Reforma 222, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    status: 'pending-confirmation-visit',
    subtotal: 90,
    tax: 14.4,
    totalAmount: 200,
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: {
      chatUrl: 'https://www.mudango.com',
      confirmationVisitDate: null,
      serviceType: 'Mudango Black',
      quoteUrl: 'https://www.mudango.com',
    },
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 231,
    endAddress: 'C. Génova 75, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    id: 'ThiyfHvCOQoWFhu59gtR',
    items: [
      { name: 'Sofá ecocuero café', quantity: 1 },
      { name: 'Sillas de comedor negras', quantity: 4 },
      { name: 'Mesa de centro café', quantity: 1 }
    ],
    movingDate: new Date(),
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    startAddress: 'Av. P.º de la Reforma 222, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico',
    status: 'pending-confirmation-visit',
    subtotal: 1100.00,
    tax: 78.33,
    totalAmount: 1178.33,
    updatedAt: new Date(),
    userId: '6xQGvcIhSwSkSH938uaC',
  },
];