<header class="header-navbar" [class.not-home]="!isHome" [class.no-shadow]="isEcommerce" [class.no-fixed]="isEcommerceSearch">
  <div class="header-container">
    <div class="nav-container">
      <nav class="navbar">
        <app-button innerId="btn-Bx5pnWMP" label="Menu" styleClass="secondary dark" icon="hamburger-menu" (action)="showMenuSidebar()" />
        <app-button innerId="btn-Ldp9gqaB" label="What is Mundo Hogar?" styleClass="tertiary dark" routerLink="/what-is-mundo-hogar" />
      </nav>
      <div class="logo-container">
        <a class="link" routerLink="/" id="btn-link-t826lA0j"><div class="logo animate__fadeIn"></div></a>
      </div>
      <div class="right-container" [class.show]="firstLoadFinished" [class.animate__fadeIn]="firstLoadFinished">
        @if (user && user.enrollmentStatus === 'done') {
          <div class="avatar-container">
            <div class="user-info">
              <p class="greeting">{{ 'Hi' | transloco }} 👋</p>
              <p class="user-name">{{ user.name }}</p>
            </div>
            <div class="avatar" (click)="overlayPanel.toggle($event)" id="btn-link-VsmwzkAU">{{ userNameInitials }}</div>
          </div>
        } @else {
          @if (user && user.enrollmentStatus === 'pending') {
            <app-button innerId="btn-epQDZCd_" label="Finish registration" styleClass="secondary" routerLink="/enrollment" />
          } @else {
            <app-button innerId="btn-qifipqQG" label="Sign in" styleClass="primary" routerLink="/enrollment/sign-in" />
          }
        }
      </div>
    </div>
  </div>
</header>
<div class="header-spacer" [class.no-spacer]="isEcommerceSearch"></div>
<app-menu-sidebar [visible]="menuSidebarVisible" (closed)="closedMenuSidebar()" id="btn-link-nDL8kpzv"/>
<p-overlayPanel #overlayPanel>
  <ng-template pTemplate="content">
    <app-button innerId="btn-lC9969JT" label="My Home" (action)="goTo('/private-site')" styleClass="tertiary dark" />
    <app-button innerId="btn-6awjUhOU" label="My favorites" (action)="goTo('/ecommerce/my-favorites')" styleClass="tertiary dark" />
    <app-button innerId="btn-_RfJ2APO" label="My shopping cart" (action)="goTo('/ecommerce/shopping-cart')" styleClass="tertiary dark" />
    <app-button innerId="btn-RROcWIVC" label="My personal data" (action)="goTo('/private-site/profile/my-personal-data')" styleClass="tertiary dark" />
    <app-button innerId="btn-AO7vpEa7" label="Sign out" (action)="signOut()" styleClass="tertiary dark" />
  </ng-template>
</p-overlayPanel>