import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

interface MetaConfig {
  description: string;
  keywords: string;
  'og:description': string;
  'og:image': string;
  'og:locale': string;
  'og:title': string;
  'og:type': string;
  robots: string;
  title: string;
}

const baseConfig: MetaConfig = {
  description: 'Portal exclusivo para clientes de Banco Santander que reúne en un mismo lugar beneficios únicos en servicios para el hogar.',
  keywords: 'credito hipotecario, hipotecario, mudanzas, simulador hipotecario, remodelacion, remodelacion de cocinas, cotizar hipotecario, asistencia',
  'og:description': 'Portal exclusivo para clientes de Banco Santander que reúne en un mismo lugar beneficios únicos en servicios para el hogar.',
  'og:image': 'https://storage.googleapis.com/santandermx-ha-prod-public/misc/og-image-default.jpg',
  'og:locale': 'es_MX',
  'og:title': 'Mundo Hogar | Conecta con tu hogar en un solo lugar',
  'og:type': 'website',
  robots: 'index, follow',
  title: 'Mundo Hogar | Conecta con tu hogar en un solo lugar',
};

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {
  constructor(
    private meta: Meta,
    private title: Title,
  ) {}

  setMetaTags(config?: Partial<MetaConfig>): void {
    const finalConfig = config ?? baseConfig;
    Object.keys(finalConfig).forEach((key) => {
      const value = finalConfig[key as keyof MetaConfig];
      if (key === 'title') {
        this.title.setTitle(value!);
      } else {
        this.meta.updateTag({ name: key, content: value! });
      }
    });
  }
}