<p-sidebar class="invoicing-sidebar" [(visible)]="isVisible" [position]="isMobile ? 'bottom' : 'right'" [blockScroll]="true" [showCloseIcon]="false" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="title">{{ 'Generate invoice' | transloco }}</span>
      <button class="custom-button-clear close" (click)="close()"><i class="icon-close"></i></button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <app-retryable-section [loading]="loadingUserData" [error]="errorLoadingUserData" (retry)="getUserData()">
      <div class="invoicing-container">
        <div class="form-container">
          <form [formGroup]="form" class="form">
            <div class="input-container select-container">
              <label class="label">{{ 'Select type of person' | transloco }}</label>
              @for (option of personTypeOptions; track $index) {
                <div class="option">
                  <label [for]="option.label">{{ option.label | transloco }}</label>
                  <p-radioButton class="radio-button" [inputId]="option.label" [value]="option.value" [formControl]="form.controls.personType"></p-radioButton>
                </div>
              }
            </div>
            <span class="disclaimer">{{ 'First confirm your details to generate the invoice' | transloco }}</span>
            <div class="input-container">
              <label class="label" [class.disabled]="form.controls.name.disabled" for="name">{{ 'Name(s)' | transloco }}*</label>
              <input class="input" pInputText id="name" [formControl]="form.controls.name" aria-describedby="name-help" />
              <small id="name-help" [class.hidden]="!form.controls.name.touched || !form.controls.name.errors" class="p-error input-error">{{ 'Enter a valid name.' | transloco }}</small>
            </div>
            @if (form.controls.personType.value === 'natural-person') {
              <div class="input-container">
                <label class="label" [class.disabled]="form.controls.fatherLastName.disabled" for="father-last-name">{{ 'Father last name' | transloco }}*</label>
                <input class="input" pInputText id="father-last-name" [formControl]="form.controls.fatherLastName" aria-describedby="father-last-name-help" />
                <small id="father-last-name-help" [class.hidden]="!form.controls.fatherLastName.touched || !form.controls.fatherLastName.errors" class="p-error input-error">{{ 'Enter a valid father last name.' | transloco }}</small>
              </div>
              <div class="input-container">
                <label class="label" [class.disabled]="form.controls.motherLastName.disabled" for="mother-last-name">{{ 'Mother last name' | transloco }}*</label>
                <input class="input" pInputText id="mother-last-name" [formControl]="form.controls.motherLastName" aria-describedby="mother-last-name-help" />
                <small id="mother-last-name-help" [class.hidden]="!form.controls.motherLastName.touched || !form.controls.motherLastName.errors" class="p-error input-error">{{ 'Enter a valid mother last name.' | transloco }}</small>
              </div>
            }
            <div class="input-container">
              <label class="label" [class.disabled]="form.controls.email.disabled" for="email">{{ 'Email' | transloco }}*</label>
              <input class="input" pInputText id="email" type="email" [formControl]="form.controls.email" aria-describedby="email-help" />
              <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error input-error">{{ 'Enter a valid email.' | transloco }}</small>
            </div>
            <span class="modify-data-option" (click)="modifyContactData()">{{ 'Modify contact data' | transloco }}</span>
            @if (form.controls.personType.value === 'legal-entity') {
              <div class="input-container">
                <label class="label" for="company-name">{{ 'Company name' | transloco }}*</label>
                <input class="input" pInputText id="company-name" [formControl]="form.controls.companyName" aria-describedby="company-name-help" />
                <small id="company-name-help" [class.hidden]="!form.controls.companyName.touched || !form.controls.companyName.errors" class="p-error input-error">{{ 'Enter a valid company name.' | transloco }}</small>
              </div>
            }
            <div class="input-container">
              <label class="label" for="rfc">RFC*</label>
              <input class="input" pInputText id="rfc" [formControl]="form.controls.rfc" placeholder="ABCD1234567X8" aria-describedby="rfc-help" maxlength="13" />
              <small id="rfc-help" [class.hidden]="!form.controls.rfc.touched || !form.controls.rfc.errors" class="p-error input-error">{{ 'Enter a valid rfc.' | transloco }}</small>
            </div>
            <div class="input-container">
              <label class="label" [class.disabled]="form.controls.orderId.disabled" for="order-id">{{ 'Order id' | transloco }}*</label>
              <input class="input" pInputText id="order-id" [formControl]="form.controls.orderId" />
            </div>
            <div class="input-container">
              <label class="label" for="postal-code">{{ 'Postal code' | transloco }}*</label>
              <input class="input" pInputText id="postal-code" [formControl]="form.controls.postalCode" maxlength="5" aria-describedby="postal-code-help" />
              <small id="postal-code-help" [class.hidden]="!form.controls.postalCode.touched || !form.controls.postalCode.errors" class="p-error input-error">{{ 'Enter a valid postal code.' | transloco }}</small>
            </div>
            <div class="input-container">
              <label class="label" for="tax-regime">{{ 'Tax regime' | transloco }}*</label>
              <p-dropdown class="input dropdown" id="tax-regime" [formControl]="form.controls.taxRegime" [options]="taxRegimeOptions" optionLabel="label" optionValue="value" placeholder="{{ 'Select a tax regime' | transloco }}" aria-describedby="tax-regime-help" />
              <small id="tax-regime-help" [class.hidden]="!form.controls.taxRegime.touched || !form.controls.taxRegime.errors" class="p-error input-error">{{ 'Enter a valid tax regime.' | transloco }}</small>
            </div>
            <div class="input-container">
              <label class="label" for="type">{{ 'CFDI use' | transloco }}*</label>
              <p-dropdown class="input dropdown" id="type" [formControl]="form.controls.cfdiUse" [options]="cfdiUseOptions" optionLabel="label" optionValue="value" placeholder="{{ 'Select a use' | transloco }}" aria-describedby="cfdi-use-help" />
              <small id="cfdi-use-help" [class.hidden]="!form.controls.cfdiUse.touched || !form.controls.cfdiUse.errors" class="p-error input-error">{{ 'Enter a valid cfdi use.' | transloco }}</small>
            </div>
          </form>
        </div>
      </div>
    </app-retryable-section>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="button-container">
      <app-button class="button" label="Cancel" styleClass="secondary" (action)="close()" />
      <app-button class="button" label="Send request" styleClass="primary" [disabled]="form.invalid" [loading]="sendingInvoice" (action)="sendInvoice()" />
    </div>
  </ng-template>
</p-sidebar>