import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { TranslocoPercentPipe } from '@ngneat/transloco-locale';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SidebarModule } from 'primeng/sidebar';
import { distinctUntilChanged, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CONFIG } from '../../constants';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { areEqualArrays } from '../../utils/array.utils';
import { areEqualObjects } from '../../utils/object.utils';
import { ButtonComponent } from '../button/button.component';

export interface FiltersSideBar {
  discountPercentage: number | null;
  max: number | null;
  min: number | null;
  rating: number | null;
  sortBy: SortValue | null;
}

interface SortValue {
  direction: 'asc' | 'desc';
  field: string;
}

@Component({
  selector: 'app-filter-sidebar',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CheckboxModule,
    CommonModule,
    CurrencyFormatterPipe,
    FormsModule,
    InputNumberModule,
    RadioButtonModule,
    RatingModule,
    ReactiveFormsModule,
    SidebarModule,
    TranslocoPipe,
    TranslocoPercentPipe
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './filter-sidebar.component.html',
  styleUrl: './filter-sidebar.component.scss'
})
export class FilterSidebarComponent implements OnChanges, OnDestroy {
  @Output() applyFiltersClick = new EventEmitter<FiltersSideBar>();
  @Input() currency = CONFIG.defaultCurrency;
  @Input() filtersApplied: FiltersSideBar & { discounts?: number[] };
  @Input() locale = environment.defaultLocale;
  changedFilters = false;
  defaultFilters = true;
  discountOptions = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7];
  form: FormGroup<{
    discounts: FormControl<number[] | null>;
    max: FormControl<number | null>;
    min: FormControl<number | null>;
    ratings: FormControl<number[] | null>;
    sortBy: FormControl<SortValue | null>;
  }>;
  isMobile = false;
  isVisible = false;
  ratingOptions = [4];
  sortOptionDefault: SortValue;
  sortOptions: { label: string; value: SortValue }[] = [
    { label: 'Best match', value: { field: 'default', direction: 'desc' } },
    { label: 'Price from highest to lowest', value: { field: 'price', direction: 'desc' } },
    { label: 'Price from lowest to highest', value: { field: 'price', direction: 'asc' } },
  ];
  private viewDestroyed = new Subject<void>();

  constructor() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filtersApplied'] && changes['filtersApplied'].firstChange) {
      const { discountPercentage, max, sortBy, min, rating } = changes['filtersApplied'].currentValue as FiltersSideBar;
      this.form.patchValue({
        ...(discountPercentage && { discounts: [discountPercentage] }),
        ...(max && { max }),
        ...(min && { min }),
        ...(rating && { ratings: [rating] }),
        ...(sortBy && { sortBy }),
      });
      this.filtersApplied = this.getActiveFilters();
    }
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  applyFilters(): void {
    this.filtersApplied = this.getActiveFilters();
    this.applyFiltersClick.emit(this.filtersApplied);
    this.close();
    this.changedFilters = false;
  }

  cancel(): void {
    this.close();
    const { discountPercentage, rating, min, max, sortBy } = this.filtersApplied;
    this.form.patchValue({ max, min, sortBy, ratings: rating ? [rating] : [], discounts: discountPercentage ? [discountPercentage] : [] });
  }

  close(): void {
    this.isVisible = false;
  }

  deleteFilters(): void {
    this.form.patchValue({
      discounts: [],
      max: null,
      min: null,
      ratings: [],
      sortBy: this.sortOptionDefault,
    });
    this.filtersApplied = this.getActiveFilters();
    this.applyFiltersClick.emit(this.filtersApplied);
  }

  deleteFiltersAndClose(): void {
    this.close();
    this.deleteFilters();
  }

  deleteValue<T>(control: FormControl<T[] | null>, value: T): void {
    const values = control.value;
    const valuesFiltered = values ? values.filter((v) => v !== value) : [];
    control.patchValue(valuesFiltered);
  }

  getSortByLabel(sortValue: SortValue | null): string {
    return this.sortOptions.find(({ value }) => value.field === sortValue?.field && value.direction === sortValue?.direction)?.label ?? '';
  }

  open(): void {
    this.isVisible = true;
  }

  resetControls<T>(controls: FormControl | FormControl[], value?: T): void {
    if (Array.isArray(controls)) {
      controls.forEach((control) => {
        control.patchValue(value ?? null);
      });
    } else {
      controls.patchValue(value ?? null);
    }
  }

  validFilterChanges(): void {
    const { discountPercentage, discounts, max, min, rating, sortBy } = this.getActiveFilters();
    this.defaultFilters = !discountPercentage && !max && !min && !rating && areEqualObjects(sortBy, this.sortOptionDefault);
    this.changedFilters =
      this.filtersApplied.discountPercentage !== discountPercentage ||
      this.filtersApplied.max !== max ||
      this.filtersApplied.min !== min ||
      this.filtersApplied.rating !== rating ||
      !areEqualArrays(this.filtersApplied?.discounts ?? null, discounts) ||
      !areEqualObjects(this.filtersApplied.sortBy, sortBy);
  }

  private getActiveFilters(): FiltersSideBar & { discounts: number[] } {
    const { discounts, max, min, ratings, sortBy } = this.form.getRawValue();
    const discountPercentage = discounts?.length ? Math.min(...discounts) : null;
    const rating = ratings?.length ? Math.min(...ratings) : null;
    return { discountPercentage, discounts: discounts ?? [], max, min, rating, sortBy };
  }

  private initialize(): void {
    this.sortOptionDefault = this.sortOptions[0].value;
    this.form = new FormGroup({
      discounts: new FormControl<number[] | null>([]),
      max: new FormControl<number | null>(null, [Validators.min(0)]),
      min: new FormControl<number | null>(null, [Validators.min(0)]),
      ratings: new FormControl<number[]>([]),
      sortBy: new FormControl<SortValue | null>(this.sortOptionDefault),
    });
    this.filtersApplied = this.getActiveFilters();
    const maxPriceControl = this.form.controls.max;
    const minPriceControl = this.form.controls.min;
    minPriceControl.valueChanges.pipe(distinctUntilChanged()).subscribe({
      next: (minPrice) => {
        maxPriceControl.setValidators([Validators.min(minPrice ?? 0)]);
        maxPriceControl.updateValueAndValidity();
        this.validFilterChanges();
      },
    });
    maxPriceControl.valueChanges.pipe(distinctUntilChanged()).subscribe({
      next: (maxPrice) => {
        if (maxPrice) {
          minPriceControl.setValidators([Validators.min(0), Validators.max(maxPrice)]);
        } else {
          minPriceControl.clearValidators();
          minPriceControl.setValidators([Validators.min(0)]);
        }
        minPriceControl.updateValueAndValidity();
        this.validFilterChanges();
      },
    });
    this.form.controls.discounts.valueChanges.pipe(distinctUntilChanged()).subscribe({ next: () => this.validFilterChanges() });
    this.form.controls.ratings.valueChanges.pipe(distinctUntilChanged()).subscribe({ next: () => this.validFilterChanges() });
    this.form.controls.sortBy.valueChanges.pipe(distinctUntilChanged()).subscribe({ next: () => this.validFilterChanges() });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }
}
