import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CreateProjectSimulationData, ProjectSimulationQuality } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { InputMask, InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { StepperModule } from 'primeng/stepper';
import { ButtonComponent } from '../../../components/button/button.component';
import { CircularProgressComponent } from '../../../components/circular-progress/circular-progress.component';
import { ReminderComponent } from '../../../components/reminder/reminder.component';
import { SendSimulationSidebarComponent } from '../../../components/send-simulation-sidebar/send-simulation-sidebar.component';
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from '../../../constants/validation-patterns.constants';
import { LocalProjectSimulation } from '../../../models/local-project-simulation.model';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ProjectSimulationsService } from '../../../services/project-simulations/project-simulations.service';
import { ToastService } from '../../../services/toast/toast.service';
import { isActive } from '../../../utils/forms.utils';
import { getImageByType } from '../../../utils/project-simulator.utils';

@Component({
  selector: 'app-step-6',
  standalone: true,
  imports: [
    ButtonComponent,
    CircularProgressComponent,
    CommonModule,
    FormsModule,
    InputTextModule,
    InputMaskModule,
    ReactiveFormsModule,
    ReminderComponent,
    RouterModule,
    SendSimulationSidebarComponent,
    StepperModule,
    TranslocoModule,
  ],
  templateUrl: './step-6.page.html',
  styleUrl: './step-6.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class Step6Page implements OnInit {
  @ViewChild('phoneNumberInput') phoneNumberInput: InputMask;
  @ViewChild('sendSimulationSidebar') sendSimulationSidebar: SendSimulationSidebarComponent;
  form: FormGroup<{
    email: FormControl<string | null>;
    fullName: FormControl<string | null>;
    phoneNumber: FormControl<string | null>;
  }>;
  projectSimulation: LocalProjectSimulation | null;
  selectedImage = '';
  sendingSimulation = false;
  simulationSent = false;

  constructor(
    private dataStorageService: DataStorageService,
    private location: Location,
    private projectSimulationService: ProjectSimulationsService,
    private router: Router,
    private toastService: ToastService,
  ) {
    this.form = new FormGroup({
      email: new FormControl<string | null>(null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      fullName: new FormControl<string | null>(null, [Validators.required]),
      phoneNumber: new FormControl<string | null>('52', [Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN)]),
    });
  }

  ngOnInit(): void {
    this.projectSimulation = this.dataStorageService.getLocal<LocalProjectSimulation>(DataKey.ProjectSimulation);
    if (!this.projectSimulation || !this.projectSimulation.completedSteps.includes(1) || !this.projectSimulation.completedSteps.includes(2) || !this.projectSimulation.completedSteps.includes(3) || !this.projectSimulation.completedSteps.includes(4) || !this.projectSimulation.completedSteps.includes(5)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/project-simulator/step-1']);
      return;
    }
    this.selectedImage = getImageByType(this.projectSimulation.type);
    this.form.patchValue({
      email: this.projectSimulation.email,
      fullName: this.projectSimulation.fullName,
      phoneNumber: this.projectSimulation.phoneNumber || this.form.controls.phoneNumber.value,
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  changePhoneNumber(): void {
    this.form.controls.phoneNumber.patchValue(null);
    setTimeout(() => this.phoneNumberInput.focus(), 150);
  }

  goBack(): void {
    this.location.back();
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  onSubmit(): void {
    const { email, fullName, phoneNumber } = this.form.getRawValue();
    if (!fullName || !email || !phoneNumber) {
      return;
    }
    const currentSimulation = this.dataStorageService.getLocal<LocalProjectSimulation>(DataKey.ProjectSimulation);
    if (!currentSimulation) {
      return;
    }
    if (!currentSimulation.completedSteps.includes(6)) {
      currentSimulation.completedSteps.push(6);
    }
    const simulation = {
      ...currentSimulation,
      email,
      fullName,
      phoneNumber,
    };
    this.projectSimulation = simulation;
    this.dataStorageService.setLocal(DataKey.ProjectSimulation, simulation);
    this.sendSimulationSidebar.open();
  }

  async sendSimulation(): Promise<void> {
    this.sendingSimulation = true;
    try {
      if (!this.projectSimulation) {
        return;
      }
      const {
        accessories,
        email,
        executionTimeFrame,
        fullName,
        phoneNumber,
        postalCode,
        propertyType,
        surfaceArea,
        type,
      } = this.projectSimulation;
      if (!type || !['bathroom', 'integral-remodeling', 'kitchen'].includes(type)) {
        return;
      }
      let additionalData: {
        numberOfBathrooms: number;
        surfaceAreaDetail: number[];
        surfaces: ProjectSimulationQuality;
        type: 'bathroom';
      } | {
        numberOfBathrooms: number;
        numberOfRooms: number;
        surfaces: ProjectSimulationQuality;
        type: 'integral-remodeling';
      } | {
        counterTop: ProjectSimulationQuality;
        type: 'kitchen';
      } | null = null;
      if (type === 'bathroom') {
        additionalData = {
          type,
          numberOfBathrooms: this.projectSimulation.numberOfBathrooms,
          surfaceAreaDetail: this.projectSimulation.surfaceAreaDetail,
          surfaces: this.projectSimulation.surfaces,
        };
      } else if (type === 'integral-remodeling') {
        additionalData = {
          type,
          numberOfBathrooms: this.projectSimulation.numberOfBathrooms,
          numberOfRooms: this.projectSimulation.numberOfRooms,
          surfaces: this.projectSimulation.surfaces,
        };
      } else if (type === 'kitchen') {
        additionalData = {
          type,
          counterTop: this.projectSimulation.counterTop,
        };
      }

      if (!accessories || !executionTimeFrame || !propertyType || !additionalData) {
        return;
      }

      const data: CreateProjectSimulationData = {
        accessories,
        email,
        executionTimeFrame,
        fullName,
        phoneNumber: `+${phoneNumber}`,
        postalCode,
        propertyType,
        surfaceArea,
        ...additionalData,
      };
      await this.projectSimulationService.create(data);
      this.toastService.showSuccess({ title: 'Simulation sent', description: 'We have sent the simulation via WhatsApp to the number provided.' });
      this.simulationSent = true;
    } catch (error) {
      this.toastService.showError({ title: 'Something went wrong', description: 'Your simulation could not be sent, please try again.' });
      this.simulationSent = false;
    } finally {
      this.sendingSimulation = false;
    }
  }

  simulateAgain(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/project-simulator/step-1'], {
      queryParams: {
        action: 'simulate-again'
      }
    });
  }
}
