import { Environment } from '../environment.interface';

export const environment: Environment = {
  accountsServerFirebaseConfig: {
    apiKey: 'AIzaSyCSarJlYIXqBHIldpO5bLduUBcpCgZG038',
    appId: '1:922086213934:web:154b0865767840996e52bf',
    authDomain: 'santandermx-as-dev.firebaseapp.com',
    messagingSenderId: '922086213934',
    projectId: 'santandermx-as-dev',
    storageBucket: 'santandermx-as-dev.appspot.com',
  },
  apiUrl: 'https://dev.api.homein.mx',
  apiUrlAccountsServer: 'https://dev.accounts-server.homein.mx',
  apiUrlSoftTokenServer: 'https://dev.soft-token-server.homein.mx',
  defaultLocale: 'es-MX',
  firebaseConfig: {
    apiKey: 'AIzaSyCf3xHfuxqVT16rVoP-fsqPWfM5pIk1dio',
    appId: '1:756336661481:web:eb3ead454764ceb3aaf178',
    authDomain: 'santandermx-ha-dev.firebaseapp.com',
    measurementId: 'G-WJWL0RP4FN',
    messagingSenderId: '756336661481',
    projectId: 'santandermx-ha-dev',
    storageBucket: 'santandermx-ha-dev.appspot.com',
  },
  googleTagManagerId: 'GTM-TF9CDS8H',
  mundoHogarPhoneNumber: '+5215541643097',
  name: 'dev',
  partners: {
    moving: {
      appUrl: 'https://luchosabeingles.dev.mudango.com/cotizar/mudanza',
      reference: 'homein',
    }
  },
  production: false,
  typesenseConfig: {
    apiKey: 'blMA3AHUp7jBtCrw4G88IPeUbT3pywmf',
    url: 'https://dev.search.homein.mx',
  },
  usingMocks: false,
};
