import { Injectable } from '@angular/core';
import { triggerRandomError } from '../../utils/mock.utils';
import { ProjectSimulationsService } from './project-simulations.service';

@Injectable()
export class ProjectSimulationsServiceMock implements ProjectSimulationsService {

  constructor() {}

  async create(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 2000);
    });
  }
}