import { Inject, Injectable } from '@angular/core';
import { PostalCodeLocation } from '@homein-hogar-server';
import { Observable, of, switchMap } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { PostalCodeLocationsService } from './postal-code-locations.service';

const COLLECTION_PATH = 'postalCodeLocations';

@Injectable()
export class PostalCodeLocationsServiceApi implements PostalCodeLocationsService {
  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {}

  get(id: string): Observable<PostalCodeLocation | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.doc<PostalCodeLocation>(`${COLLECTION_PATH}/${id}`);
      }
      return of(null);
    }));
  }
}
