import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MortgageSimulationPaymentTerm } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { MAXIMUM_PROPERTY_VALUE, MINIMUM_PROPERTY_VALUE } from '../../../constants/mortgage-simulator.constants';
import { LocalMortgageSimulation, MortgageSimulatorStep } from '../../../models/mortgage-simulation.model';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { valueComparison } from '../../../validators/value-comparison.validator';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { normalizeFloat } from '../../../utils/numbers.utils';

@Component({
  selector: 'app-simulation-data',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    InputNumberModule,
    RadioButtonModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  templateUrl: './simulation-data.page.html',
  styleUrl: './simulation-data.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SimulationDataPage implements OnInit, OnDestroy {
  form: FormGroup;
  mortgageSimulation: LocalMortgageSimulation;
  paymentTerms: MortgageSimulationPaymentTerm[] = [20, 15, 10];
  private viewDestroyed = new Subject<void>();

  constructor(
    private dataStorageService: DataStorageService,
    private platformService: PlatformService,
    private router: Router,
  ) {
    this.form = new FormGroup({
      downMortgage: new FormControl(null, [Validators.required, Validators.min(0.01)]),
      paymentTerm: new FormControl(null, [Validators.required]),
    });
  }

  get amountNeeded(): FormControl { return this.form.controls['amountNeeded'] as FormControl; }
  get downMortgage(): FormControl { return this.form.controls['downMortgage'] as FormControl; }
  get paymentTerm(): FormControl { return this.form.controls['paymentTerm'] as FormControl; }
  get propertyValue(): FormControl { return this.form.controls['propertyValue'] as FormControl; }


  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  continue(): void {
    if (this.form.invalid) {
      return;
    }
    this.dataStorageService.setLocal<LocalMortgageSimulation>(DataKey.MortgageSimulation, {
      ...this.mortgageSimulation,
      amountNeeded: this.mortgageSimulation.type === 'amount' ? parseFloat(this.amountNeeded.value) : 0,
      downMortgage: parseFloat(this.downMortgage.value),
      paymentTerm: this.paymentTerm.value,
      propertyValue: this.getPropertyValue(),
      stepsCompleted: this.mortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.SimulationData) ?
        this.mortgageSimulation.stepsCompleted :
        [...this.mortgageSimulation.stepsCompleted, MortgageSimulatorStep.SimulationData],
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/mortgage-simulator/remodeling-amount']);
  }

  private addDynamicControls(): void {
    switch (this.mortgageSimulation.type) {
    case 'housing-cost':
      this.form.addControl('propertyValue', new FormControl(this.mortgageSimulation.propertyValue || null, [Validators.required, Validators.min(MINIMUM_PROPERTY_VALUE), Validators.max(MAXIMUM_PROPERTY_VALUE)]));
      this.propertyValue.valueChanges.pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: () => {
          this.downMortgage.updateValueAndValidity();
        }
      });
      this.downMortgage.addValidators([valueComparison('propertyValue', 'gte', 10), valueComparison('propertyValue', 'lte', 90)]);
      this.downMortgage.updateValueAndValidity();
      break;
    case 'amount':
      this.form.addControl('amountNeeded', new FormControl(this.mortgageSimulation.amountNeeded || null, [Validators.required, Validators.min(normalizeFloat(MINIMUM_PROPERTY_VALUE * 0.1)), Validators.max(normalizeFloat(MAXIMUM_PROPERTY_VALUE * 0.9))]));
      this.amountNeeded.valueChanges.pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: () => {
          this.downMortgage.updateValueAndValidity();
        }
      });
      this.downMortgage.addValidators([this.downMortgageComparedToAmountNeeded('gte', 10), this.downMortgageComparedToAmountNeeded('lte', 90)]);
      this.downMortgage.updateValueAndValidity();
      break;
    }
  }

  private async stepPrerequisitesMet(currentMortgageSimulation: LocalMortgageSimulation | null): Promise<boolean> {
    if (!currentMortgageSimulation || !currentMortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.SimulationType)) {
      await this.router.navigate(['/mortgage-simulator']);
      return false;
    }
    return true;
  }

  private async initialize(): Promise<void> {
    const currentMortgageSimulation = this.dataStorageService.getLocal<LocalMortgageSimulation>(DataKey.MortgageSimulation);
    const shouldContinue = await this.stepPrerequisitesMet(currentMortgageSimulation);
    if (!shouldContinue) {
      return;
    }
    this.mortgageSimulation = currentMortgageSimulation!;
    this.downMortgage.patchValue(this.mortgageSimulation.downMortgage || null);
    this.paymentTerm.patchValue(this.mortgageSimulation.paymentTerm);
    this.addDynamicControls();
  }

  private downMortgageComparedToAmountNeeded(operator: 'gte' | 'lte', percentage: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = 0;
      const amountNeededValue = this.amountNeeded.value;
      const downMortgageValue = control.value;

      if (!downMortgageValue || !amountNeededValue) {
        return null;
      }

      value = normalizeFloat((parseFloat(amountNeededValue) * 0.1) / ((100 - percentage) / 100));

      if (operator === 'gte') {
        return downMortgageValue >= value ? null : { valueComparison: { operator, value } };
      }

      return downMortgageValue <= value ? null : { valueComparison: { operator, value } };
    };
  }

  private getPropertyValue(): number {
    return this.mortgageSimulation.type === 'housing-cost' ?
      parseFloat(this.propertyValue.value) :
      normalizeFloat(parseFloat(this.amountNeeded.value) + parseFloat(this.downMortgage.value));
  }
}
