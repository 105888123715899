import { Component } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [
    ProgressSpinnerModule
  ],
  templateUrl: './loading.page.html',
  styleUrl: './loading.page.scss',
})
export class LoadingPage {}
