import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';
import { filter, firstValueFrom, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { EMAIL_PATTERN } from '../../../constants/validation-patterns.constants';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { SessionProvider, SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { validateAndNavigate } from '../../../utils/enrollment.utils';
import { isActive } from '../../../utils/forms.utils';
import { buildQuery } from '../../../utils/url.utils';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    RetryableSectionComponent,
    RouterLink,
    TabViewModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sign-in.page.html',
  styleUrl: './sign-in.page.scss',
})
export class SignInPage implements OnInit {
  form: FormGroup;
  private loadingProvider: SessionProvider | null = null;

  constructor(
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private metaTagsService: MetaTagsService,
    private movingOrdersService: MovingOrdersService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionsService: SessionsService,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
    });
  }

  // TODO: Remove and access controls from form property
  get email(): FormControl { return this.form.controls['email'] as FormControl; }
  get password(): FormControl { return this.form.controls['password'] as FormControl; }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  isLoading(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider === provider;
  }

  isLoadingOther(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider !== provider;
  }

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    const source = this.route.snapshot.queryParamMap.get('source');
    if (source === 'verify-email') {
      this.toastService.showWarning({
        title: 'Finish registration',
        description: 'To continue with your verification, please sign in.',
      });
    }
  }

  async signIn(provider: 'apple' | 'email' | 'google'): Promise<void> {
    let step = '';
    try {
      this.loadingProvider = provider;
      step = `sign-in-with-provider-${provider}`;
      provider === 'email' ?
        await this.sessionsService.signIn({ provider, data: this.form.getRawValue() }) :
        await this.sessionsService.signIn({ provider });
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser), take(1)));
      step = 'validate-and-navigate';
      await validateAndNavigate(user, this.dataStorageService, this.router, () => this.customNavigate(user));
      this.analyticsService.logLoginEvent({ method: provider });
    } catch (error) {
      const { code } = error as { code: string; message: string; };
      if (code === 'invalid-credential') {
        this.toastService.showError({ title: 'Error', description: 'The email or password is incorrect.' });
      } else {
        this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
      }
      this.errorReportingService.log('SignInPage.signIn()', step, error);
    } finally {
      this.loadingProvider = null;
    }
  }

  private async customNavigate(user: User | null): Promise<void> {
    if (!user) {
      return;
    }
    let redirectTo = this.route.snapshot.queryParamMap.get('redirectTo');
    redirectTo = redirectTo ? decodeURIComponent(redirectTo) : null;
    if (!redirectTo) {
      await this.router.navigateByUrl('/private-site');
      return;
    }
    const navigationOrigin = this.route.snapshot.queryParamMap.get('origin');
    let queryParams!: string;
    if (navigationOrigin === 'moving') {
      const { email, fatherLastName, id, name, phoneNumber } = user;
      const { reference: ref } = environment.partners.moving;
      queryParams = buildQuery({
        email,
        external_reference: this.movingOrdersService.createId(),
        ref,
        user_id: id,
        ...(name && { name }),
        ...(fatherLastName && { last_name: fatherLastName }),
        ...(phoneNumber && { cellphone: phoneNumber.substring(3) }), // TODO: this should be truncated based on the phone country code
      });
    }
    if (redirectTo.startsWith('/')) {
      await this.router.navigateByUrl(redirectTo);
    } else {
      const params = queryParams ? `?${queryParams}` : '';
      window.location.href = `${redirectTo}${params}`;
    }
  }
}
