import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { DataKey, DataStorageService } from '../../services/data-storage/data-storage.service';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-terms-banner',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './terms-banner.component.html',
  styleUrl: './terms-banner.component.scss',
})
export class TermsBannerComponent implements OnInit {
  @Input({ required: true }) page: 'home' | 'home-assistance' | 'moving' | 'private-site' | 'remodeling' | 'warehouse';
  shouldAskForTermsOfServiceAcceptance = true;

  constructor(
    private dataStorageService: DataStorageService,
  ) {}

  ngOnInit(): void {
    this.shouldAskForTermsOfServiceAcceptance = !this.dataStorageService.getLocal(DataKey.TermsOfServiceAcceptance);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  hideBanner(): void {
    this.dataStorageService.setLocal(DataKey.TermsOfServiceAcceptance, true);
    this.shouldAskForTermsOfServiceAcceptance = false;
  }

}