@if (href) {
  @if (internalLink) {
    <a class="hm-button {{ styleClass }}" [routerLink]="href" [queryParams]="queryParams">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  } @else {
    <a class="hm-button {{ styleClass }}" [href]="href" [target]="target" [rel]="rel">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  }
} @else {
  <button class="hm-button {{ styleClass }}" [disabled]="disabled" (click)="triggerAction()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}
<ng-template #content>
  @if (loading) {
    <i class="pi pi-spin pi-spinner"></i>
  } @else if (icon && label) {
    <i class="icon-{{ icon }} text"></i>{{ labelTranslationItem.key | transloco: labelTranslationItem.params }}
  } @else if (icon) {
    <i class="icon-{{ icon }}"></i>
  } @else if (translate) {
    {{ labelTranslationItem.key | transloco: labelTranslationItem.params }}
  } @else {
    {{ label }}
  }
</ng-template>