<section class="main-container">
  <div class="moving-status-container">
    <div class="title-container">
      <i class="icon-arrow-left" (click)="goBack()"></i>
      <h2 class="title">{{ 'Moving status' | transloco }}</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (movingOrder) {
        <div class="moving-status-info">
          @if (movingOrder.status === 'pending-payment') {
            <span class="status pending">{{ 'Pending payment' | transloco }}</span>
            <span class="description">{{ 'Service to be selected' | transloco }}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description-date">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'You need to make a payment to reserve your moving date' | transloco }}</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Go to reserve the date" (action)="goToReserveTheDate(movingOrder)" />
            </div>
          } @else if (movingOrder.status === 'waiting-confirmation') {
            <span class="status pending">{{ 'Waiting confirmation' | transloco }}</span>
            <span class="description">{{ movingOrder.additionalData['serviceType']}}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description date">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'We will notify you as soon as your moving request is ready to continue' | transloco }}</span>
          } @else if (movingOrder.status === 'pending-confirmation-visit') {
            <span class="status pending">{{ 'Pending visit' | transloco }}</span>
            <span class="description">{{ movingOrder.additionalData['serviceType']}}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description date">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'To continue you must coordinate the inspection visit' | transloco }}</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Go to chat" (action)="goToChat(movingOrder)" />
            </div>
          } @else if (movingOrder.status === 'waiting-moving') {
            <span class="status">{{ 'All set' | transloco }}</span>
            <span class="description">{{ movingOrder.additionalData['serviceType']}}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description-date">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'All set for your move' | transloco }}</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Go to chat" (action)="goToChat(movingOrder)" />
            </div>
          } @else if (movingOrder.status === 'done') {
            <span class="status done">{{ 'Completed' | transloco }}</span>
            <span class="description">{{ movingOrder.additionalData['serviceType']}}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description-date">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'Your move took place on' | transloco }} {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Go to chat" (action)="goToChat(movingOrder)" />
            </div>
          } @else if (movingOrder.status === 'voided') {
            <span class="status done">{{ 'Voided' | transloco }}</span>
            <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description-date">{{ 'Moving day' | transloco }}: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">{{ 'Your move has been voided, if you have any questions you can contact the support chat' | transloco }}</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Go to chat" (action)="goToChat(movingOrder)" />
            </div>
          }
        </div>
        <div class="moving-detail-container">
          <div class="moving-items-container">
            <p-accordion class="accordion-moving-items">
              <p-accordionTab class="accordion-items" header="{{ 'Moving items (X items)' | transloco: { quantity: movingOrder.items.length } }}" iconPos="end">
                @for (item of movingOrder.items; track $index) {
                  <div class="item">
                    <span class="name">{{ item.name }}</span>
                    <span class="quantity">{{ item.quantity }} u.</span>
                  </div>
                }
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="help-links-container">
          <h4 class="title">{{ 'Help with this purchase' | transloco }}</h4>
          <a href="https://ayuda.homein.mx/es/" target="_blank" class="link">{{ 'I have a problem with my purchase' | transloco }}</a>
          <a href="https://ayuda.homein.mx/es/" target="_blank" class="link">{{ 'The payment was charged twice to my card' | transloco }}</a>
        </div>
        <div class="moving-details-navigation-container" routerLink="/private-site/my-movings/{{ movingOrder.id }}/details">
          <span class="label">{{ 'Purchase details' | transloco }}</span>
          <i class="icon-arrow-right"></i>
        </div>
      }
    </app-retryable-section>
  </div>
  @if (movingOrder && movingOrder.status !== 'pending-payment') {
    <div class="moving-purchase-container">
      <app-moving-purchase-details [movingOrder]="movingOrder" [showBackButton]="false" />
    </div>
  }
</section>