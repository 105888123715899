import { Injectable } from '@angular/core';
import { MortgageSimulationCreateData } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { MortgageSimulationsServiceMock } from './mortgage-simulations.service.mock';
import { MortgageSimulationsServiceApi } from './mortgage-simulations.service.api';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? MortgageSimulationsServiceMock : MortgageSimulationsServiceApi
})
export abstract class MortgageSimulationsService {
  abstract create(data: MortgageSimulationCreateData): Promise<void>;
}
