<section class="private-site-main-container">
  <section class="shortcut-menu-container">
    <h2 class="title">{{ 'Shortcuts' | transloco }}</h2>
    <div class="shortcut-menu">
      @for (item of shortcutMenu; track $index) {
        <div class="root-menu-container">
          <a class="header-container" [routerLink]="item.link" [class.active]="item.active" (click)="showSubMenu(item)">
            <i class="icon {{ item.icon }}" [class.active]="item.active"></i>
            <span class="label">{{ item.label | transloco }}</span>
            @if (item.subItems?.length) {
              <i class="submenu-icon icon-arrow-left" [class.active]="item.active"></i>
            }
          </a>
          @if (item.active && item.subItems) {
            <div class="submenu-container" [class.active]="item.active">
              @for (subItem of item.subItems; track $index) {
                @if (subItem.isExternal) {
                  <a class="link" [href]="subItem.link" target="_blank" rel="noopener">{{ subItem.label | transloco }}</a>
                } @else {
                  <a class="link" [routerLink]="subItem.link">{{ subItem.label | transloco }}</a>
                }
              }
            </div>
          }
        </div>
      }
    </div>
  </section>
  <section class="right-container">
    <app-terms-banner page="private-site" />
    <section class="my-home-container">
      <h2 class="title">{{ 'My Home' | transloco }}</h2>
      <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
        <div class="card-container isolated">
          <div class="header-container">
            <i class="icon icon-investment-fund"></i>
            <h3 class="title">{{ 'Balances' | transloco }}</h3>
          </div>
          <div class="content">
            <div class="accounts-container">
              @for (account of accounts; track $index) {
                <div class="account animate__fadeIn">
                  @if (account.type === 'debit') {
                    <h4 class="label" [class.highlighted]="accountHasBalance(account)">{{ 'Full availability balance' | transloco }}</h4>
                  } @else if (account.type === 'mortgage') {
                    <h4 class="label" [class.highlighted]="accountHasBalance(account)">{{ 'Remodeling balance' | transloco }}</h4>
                  }
                  <p class="balance">{{ account.balance | currencyFormatter }} {{ account.currency }}</p>
                </div>
              }
            </div>
            @if (hasBalance) {
              <a class="link" routerLink="/private-site/profile/my-balance">{{ 'My balances and movements' | transloco }}</a>
            }
            <!-- TODO: enable mortgage when it is due-->
            <!-- @else {-->
            <!-- <a class="link" routerLink="/mortgage-simulator">{{ 'Simulate your remodeling mortgage here' | transloco }}</a>-->
            <!-- }-->
          </div>
        </div>
        <p-carousel contentClass="carousel-my-home"
          [value]="myHomeCarouselItems"
          [numVisible]="2.7"
          [numScroll]="1"
          [responsiveOptions]="[
            { breakpoint: '1366px', numVisible: 2.7, numScroll: 1 },
            { breakpoint: '1300px', numVisible: 2.5, numScroll: 1 },
            { breakpoint: '1250px', numVisible: 2.3, numScroll: 1 },
            { breakpoint: '1200px', numVisible: 2.1, numScroll: 1 },
            { breakpoint: '1150px', numVisible: 1.9, numScroll: 1 },
            { breakpoint: '1100px', numVisible: 1.6, numScroll: 1 },
            { breakpoint: '1000px', numVisible: 2.8, numScroll: 1 },
            { breakpoint: '850px', numVisible: 2.4, numScroll: 1 },
            { breakpoint: '750px', numVisible: 2.1, numScroll: 1 },
            { breakpoint: '650px', numVisible: 1.9, numScroll: 1 },
            { breakpoint: '600px', numVisible: 1.6, numScroll: 1 },
            { breakpoint: '500px', numVisible: 1.4, numScroll: 1 },
            { breakpoint: '450px', numVisible: 1.3, numScroll: 1 },
            { breakpoint: '400px', numVisible: 1.2, numScroll: 1 }
          ]"
          [showIndicators]="true"
          [showNavigators]="false">
          <ng-template let-item pTemplate="item">
            <div class="card-container  animate__fadeIn" [class.balances]="item.type === 'balances'">
              <div class="header-container">
                <i class="icon {{ item.header.icon }}"></i>
                <h3 class="title">{{ item.header.title | transloco }}</h3>
              </div>
              <div class="content">
                @if (item.type === 'balances') {
                  <div class="balances-container">
                    <div class="accounts-container">
                      @for (account of item.data; track $index) {
                        <div class="account">
                          @if (account.type === 'debit') {
                            <h4 class="label" [class.highlighted]="accountHasBalance(account)">{{ 'Full availability balance' | transloco }}</h4>
                          } @else if (account.type === 'mortgage') {
                            <h4 class="label" [class.highlighted]="accountHasBalance(account)">{{ 'Remodeling balance' | transloco }}</h4>
                          }
                          <p class="balance">{{ account.balance | currencyFormatter }} {{ account.currency }}</p>
                        </div>
                      }
                    </div>
                  <!-- TODO: enable mortgage when it is due-->
                  <!-- <a class="link" routerLink="/mortgage-simulator">{{ 'Simulate your financing here' | transloco }}</a>-->
                  </div>
                } @else if (item.type === 'ecommerce') {
                  <div class="purchase-container">
                    @if (item.data) {
                      <div class="content-header">
                        <span class="left">{{ 'Paid on X' | transloco: { date: item.data.order.createdAt | date: 'dd/MM/yyyy' } }}</span>
                        <span class="right">{{ 'In progress' | transloco }}</span>
                      </div>
                      @if (item.data.orderItemToDisplay) {
                        <div class="item-container">
                          <img class="image" [src]="item.data.orderItemToDisplay.thumbnail" alt="{{ 'Product image' | transloco }}" />
                          <div class="right-container">
                            <span class="shipping-status">{{ 'In preparation' | transloco }}</span>
                            @if (item.data.order.shippingDate) {
                              <span class="shipping-date">{{ 'Arrives on X' | transloco: { date: item.data.order.shippingDate | date: 'dd/MM/yyyy' } }}</span>
                            } @else {
                              <span class="shipping-date">{{ 'Arrives between X and Y' | transloco: { dateFrom: item.data.order.estimatedShippingDate.from | date: 'dd/MM/yyyy', dateTo: item.data.order.estimatedShippingDate.to | date: 'dd/MM/yyyy' } }}</span>
                            }
                          </div>
                        </div>
                      }
                      @if (item.data.order.items.length === 2) {
                        <span class="other-products-text">{{ 'and other product...' | transloco }}</span>
                      } @else if (item.data.order.items.length > 2) {
                        <span class="other-products-text">{{ 'and other X products...' | transloco: { quantity: item.data.order.items.length - 1 } }}</span>
                      }
                      <a class="link" routerLink="/private-site/my-purchases">{{ 'Go to my purchases' | transloco }}</a>
                    } @else {
                      <div class="empty-state">
                        <img class="image" src="/assets/illustrations/bird-on-sofa.svg" alt="{{ 'You have no active purchases' | transloco }}" />
                        <span class="text">{{ 'You have no current orders' | transloco }}</span>
                        <a class="link" routerLink="/ecommerce">{{ 'Start shopping with 36 MWI' | transloco }}</a>
                      </div>
                    }
                  </div>
                } @else if (item.type === 'movings') {
                  <div class="movings-container">
                    @if (item.data) {
                      <div class="content-header">
                        <!-- TODO: Set date based on order payment -->
                        <!--<span class="left">{{ 'Paid on X' | transloco: { date: '06/03/2024' } }}</span>-->
                        <span class="left"></span>
                        <span class="right">{{ 'In progress' | transloco }}</span>
                      </div>
                      <div class="info-container">
                        @if (item.data.status === 'pending-payment') {
                          <span class="status pending">{{ 'Pending payment' | transloco }}</span>
                        } @else if (item.data.status === 'waiting-confirmation') {
                          <span class="status">{{ 'Waiting confirmation' | transloco }}</span>
                        } @else if (item.data.status === 'pending-confirmation-visit') {
                          <span class="status pending">{{ 'Pending visit' | transloco }}</span>
                        } @else if (item.data.status === 'waiting-moving') {
                          <span class="status">{{ 'All set' | transloco }}</span>
                        }
                        <span class="description">{{ item.data.additionalData.serviceType }}</span>
                        @if (item.data.items.length === 1) {
                          <span class="description">{{ 'One item' | transloco }}</span>
                        } @else if (item.data.items.length > 1) {
                          <span class="description">{{ 'X items' | transloco: { quantity: item.data.items.length } }}</span>
                        }
                        <span class="description">{{ 'Moving day' | transloco }}: {{ item.data.movingDate | date: 'dd/MM/yyyy' }}</span>
                      </div>
                      <a class="link" routerLink="/private-site/my-movings">{{ 'Go to my movings' | transloco }}</a>
                    } @else {
                      <div class="empty-state">
                        <img class="image" src="/assets/illustrations/moving-bird.svg" alt="{{ 'You don\'t have active movings' | transloco }}" />
                        <span class="text">{{ 'You don\'t have active movings' | transloco }}</span>
                        <a class="link" routerLink="/moving">{{ 'Quote your next moving' | transloco }}</a>
                      </div>
                    }
                  </div>
                } @else if (item.type === 'home-assistance') {
                  <div class="home-assistance-container">
                    @if (item.data) {
                      <div class="content-header">
                        <span class="left">{{ 'Active since' | transloco }} {{ item.data.startAt | date: 'dd/MM/yyyy' }}</span>
                        <span class="right">{{ 'Active' | transloco }}</span>
                      </div>
                      <span class="status">{{ 'Active' | transloco }}</span>
                      <span class="description"><strong>{{ item.data.plan.name }}</strong></span>
                      <span class="description">{{ item.data.duration | transloco }}</span>
                      <span class="description">{{ 'Coverage' | transloco }}: {{ item.data.finishAt | date: 'dd/MM/yyyy' }}</span>
                      <a class="link" routerLink="/private-site/my-home-assistances">{{ 'Go to my assistance plans' | transloco }}</a>
                    } @else {
                      <div class="empty-state">
                        <img class="image" src="/assets/illustrations/pet-and-worker.svg" alt="{{ 'You have no active plans' | transloco }}" />
                        <span class="text">{{ 'Choose your assistance plan' | transloco }}</span>
                        <a class="link" routerLink="/home-assistance">{{ 'Explore your next assistance plan' | transloco }}</a>
                      </div>
                    }
                  </div>
                } @else if (item.type === 'remodeling') {
                  <div class="remodeling-container">
                    @if (item.data) {
                      <div class="content-header">
                        <span class="left">{{ item.createdAt | date: 'dd/MM/yyyy' }}</span>
                        <span class="right">{{ 'In progress' | transloco }}</span>
                      </div>
                      <div class="info-container">
                        @if (item.data.status === 'in-progress') {
                          <span class="status">{{ 'Project on site' | transloco }}</span>
                        } @else if (item.data.status === 'lead-created') {
                          <span class="status">{{ 'Request received' | transloco }}</span>
                        } @else if (item.data.status === 'pending') {
                          @if (!item.data.paid) {
                            <span class="status">{{ 'Pending payment' | transloco }}</span>
                          } @else {
                            <span class="status">{{ 'Signature pending' | transloco }}</span>
                          }
                        } @else if (item.data.status === 'waiting') {
                          <span class="status">{{ 'All set!' | transloco }}</span>
                        }
                        <span class="description">{{ item.data?.additionalData['service'] | transloco }}</span>
                        <span class="description">{{ item.data?.additionalData['opportunityName'] }}</span>
                      </div>
                      <a class="link" routerLink="/private-site/my-remodelings">{{ 'Go to my remodelings' | transloco }}</a>
                    } @else {
                      <div class="empty-state">
                        <img class="image" src="/assets/illustrations/remodeling-empty.svg" alt="{{ 'You don\'t have active remodeling' | transloco }}" />
                        <span class="text">{{ 'You don\'t have active remodeling' | transloco }}</span>
                        <a class="link" routerLink="/remodeling">{{ 'Quote the remodeling of your dreams' | transloco }}</a>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </app-retryable-section>
    </section>
    <section class="deals-container">
      <h2 class="title">{{ 'Deals for you' | transloco }}</h2>
      <p-carousel class="deals-carousel"
        [value]="promotionsCarouselItems"
        [numVisible]="1"
        [numScroll]="1"
        [circular]="true"
        [autoplayInterval]="5000"
        [showIndicators]="true"
        [showNavigators]="false">
        <ng-template let-item pTemplate="item">
          <div class="carousel-container">
            <div class="carousel-item">
              <div class="text-content">
                <h3 class="title">{{ item.title }}</h3>
                <div class="action-button-container">
                  <app-button [label]="item.buttonLabel" [href]="item.buttonUrl" [translate]="false" styleClass="primary negative expand" />
                </div>
              </div>
              <div class="image-container">
                <img class="image" [src]="item.image" alt="{{ item.text }}" />
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </section>
    <section class="opportunities">
      <h2 class="title">{{ 'Highlights of the month' | transloco }}</h2>
      <div class="opportunities-container">
        <div class="opportunity">
          <div class="left-card-container">
            <h4 class="title">{{ 'Apply for your card and pay for everything with a 30% discount' | transloco }}</h4>
            <app-button label="Request" styleClass="secondary" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener" />
          </div>
          <div class="right-card-container">
            <!-- TODO: change alt of images and also images. -->
            <img class="img" src="assets/images/banner-opportunity-01.png" alt="Opportunity image"/>
          </div>
        </div>
        <div class="opportunity">
          <div class="left-card-container">
            <h4 class="title">{{ 'Simulate that remodeling that you dream of so much' | transloco }}</h4>
            <app-button label="Simulate" styleClass="secondary" routerLink="/project-simulator/step-1" />
          </div>
          <div class="right-card-container">
            <!--  TODO: change alt of images and also images. -->
            <img class="img" src="assets/images/second-opportunity.jpg" alt="Opportunity image"/>
          </div>
        </div>
      </div>
    </section>
  </section>
</section>