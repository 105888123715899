// TODO: Move functions to @homein/utils and delete this utils

export function formatPhoneNumber(phoneNumber: string, template: string = '+(99) 99 9999 9999'): string {
  let formatted = '';
  let phoneIndex = 0;
  for (let i = 0; i < template.length; i++) {
    if (template[i] === '9') {
      if (phoneIndex < phoneNumber.length) {
        formatted += phoneNumber[phoneIndex];
        phoneIndex++;
      } else {
        formatted += ' ';
      }
    } else {
      formatted += template[i];
    }
  }
  return formatted;
}

export function isLowerCaseWord(text: string): boolean {
  return /^[a-záéíóúäëïöüñ]+$/.test(text);
}

export function isUpperCaseWord(text: string): boolean {
  return /^[A-ZÁÉÍÓÚÄËÏÖÜÑ]+$/.test(text);
}

export function toCapitalizeCase(text: string): string {
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
}
