import { Injectable } from '@angular/core';
import { AddFavoriteItemData, Favorite, RemoveFavoriteItemData, SearchEngineProduct } from '@homein-hogar-server';
import { BehaviorSubject, firstValueFrom, from, map, Observable, of, switchMap } from 'rxjs';
import { getUpdatedFavoriteItems, MAX_FAVORITES_ITEMS } from '../../utils/favorite.utils';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { MockService } from '../mock/mock.service';
import { SearchService } from '../search/search.service';
import { SessionsService } from '../sessions/sessions.service';
import { FavoriteDetail, FavoritesService, LocalFavorite } from './favorites.service';

@Injectable()
export class FavoritesServiceMock implements FavoritesService {
  private favoriteDetail: Observable<FavoriteDetail | null>;
  private localFavorite = new BehaviorSubject(this.getLocalFavorite());

  constructor(
    private dataStorageService: DataStorageService,
    private mockService: MockService,
    private searchService: SearchService,
    private sessionsService: SessionsService,
  ) {
    this.favoriteDetail = this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.mockService.getFavorite(session.userId).pipe(switchMap((favorite) => this.getMockedChildren(favorite)));
      }
      return this.localFavorite.asObservable().pipe(switchMap((localFavorite) => this.getMockedChildren(localFavorite)));
    }));
  }

  async addItem(data: AddFavoriteItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const favorite = await firstValueFrom(this.mockService.getFavorite(session.userId));
      if (!favorite) {
        return this.mockService.createFavorite({
          id: session.userId,
          items: [{
            ...data,
            addedAt: new Date(),
          }],
          userId: session.userId,
        }).then();
      }
      return this.mockService.updateFavorite(session.userId, {
        items: getUpdatedFavoriteItems({
          action: 'add',
          items: favorite.items,
          addItemData: data,
        }),
      });
    }
    let localFavorite = this.getLocalFavorite();
    if (!localFavorite) {
      localFavorite = {
        items: [{
          ...data,
          addedAt: new Date(),
        }],
      };
    } else {
      localFavorite = {
        ...localFavorite,
        items: getUpdatedFavoriteItems({
          action: 'add',
          items: localFavorite.items,
          addItemData: data,
        }),
      };
    }
    this.dataStorageService.setLocal<LocalFavorite>(DataKey.Favorites, localFavorite);
    this.localFavorite.next(localFavorite);
  }

  get(): Observable<FavoriteDetail | null> {
    return this.favoriteDetail;
  }

  hasFavorite(resourceType: string, resourceId: string): Observable<boolean> {
    return this.favoriteDetail.pipe(map((favorite) => !!favorite?.items.some((item) => item.resourceType === resourceType && item.resourceId === resourceId)));
  }

  hasFavoriteItems(): Observable<boolean> {
    return this.favoriteDetail.pipe(map((favorites) => !!favorites?.items.length));
  }

  async removeItem(data: RemoveFavoriteItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const favorite = await firstValueFrom(this.mockService.getFavorite(session.userId));
      if (!favorite) {
        return this.mockService.createFavorite({
          id: session.userId,
          items: [{
            ...data,
            addedAt: new Date(),
          }],
          userId: session.userId,
        }).then();
      }
      return this.mockService.updateFavorite(session.userId, {
        items: getUpdatedFavoriteItems({
          action: 'remove',
          items: favorite.items,
          removeItemData: data,
        }),
      });
    }
    let localFavorite = this.getLocalFavorite();
    if (!localFavorite) {
      localFavorite = {
        items: [],
      };
    } else {
      localFavorite = {
        ...localFavorite,
        items: getUpdatedFavoriteItems({
          action: 'remove',
          items: localFavorite.items,
          removeItemData: data,
        }),
      };
    }
    this.dataStorageService.setLocal<LocalFavorite>(DataKey.Favorites, localFavorite);
    this.localFavorite.next(localFavorite);
  }

  private getLocalFavorite(): LocalFavorite | null {
    return this.dataStorageService.getLocal<LocalFavorite>(DataKey.Favorites);
  }

  private getMockedChildren(favorite: Favorite | LocalFavorite | null): Observable<FavoriteDetail | null> {
    if (!favorite) {
      return of(null);
    }
    return from(this.searchService.search<SearchEngineProduct>('products', {
      filters: [
        {
          field: 'id',
          operator: 'in',
          value: favorite.items.map((item) => item.resourceId),
        }
      ],
      pagination: {
        documentsPerPage: MAX_FAVORITES_ITEMS,
      },
    })).pipe(map((paginable) => {
      const detailItems = favorite.items.map((item) => {
        if (item.resourceType === 'product') {
          return {
            ...item,
            resource: paginable.data.find((product) => product.id === item.resourceId) ?? null,
          };
        }
        return {
          ...item,
          resource: null,
        };
      }).filter((item) => item.resource);
      return {
        items: detailItems,
      };
    }));
  }
}