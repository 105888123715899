import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RemodelingPurchaseDetailsComponent } from '../../../../components/remodeling-purchase-details/remodeling-purchase-details.component';

@Component({
  selector: 'app-remodeling-details',
  standalone: true,
  imports: [
    CommonModule,
    RemodelingPurchaseDetailsComponent,
  ],
  templateUrl: './remodeling-details.page.html',
  styleUrl: './remodeling-details.page.scss',
})
export class RemodelingDetailsPage {}
