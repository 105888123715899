import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

const uppercaseRegex = /[A-Z]/;
const specialCharacterRegex = /[!@#$%^&*()\-_=+\[\]{}\\|;:'",<.>\/?]/;
export const passwordValidator = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (value === null || value === undefined || value === '') {
    return { minLength: true, maxLength: true, uppercase: true, specialCharacter: true };
  }
  const minLengthValidator = Validators.minLength(8)(control);
  const maxLengthValidator = Validators.maxLength(16)(control);
  const uppercaseValidator = Validators.pattern(uppercaseRegex)(control);
  const specialCharacterValidator = Validators.pattern(specialCharacterRegex)(control);
  if (!minLengthValidator && !maxLengthValidator && !uppercaseValidator && !specialCharacterValidator) {
    return null;
  }
  return {
    minLength: minLengthValidator,
    maxLength: maxLengthValidator,
    uppercase: uppercaseValidator,
    specialCharacter: specialCharacterValidator,
  };
};
