import { Inject, Injectable } from '@angular/core';
import { limit, orderBy, where } from '@angular/fire/firestore';
import { HomeAssistanceOrder } from '@homein-hogar-server';
import { map, Observable, of, switchMap } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { HomeAssistanceOrdersService } from './home-assistance-orders.service';

const COLLECTION_PATH = 'homeAssistanceOrders';

@Injectable()
export class HomeAssistanceOrdersServiceApi implements HomeAssistanceOrdersService {
  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {}

  get(id: string): Observable<HomeAssistanceOrder | null> {
    return this.firestore.doc<HomeAssistanceOrder>(`${COLLECTION_PATH}/${id}`);
  }

  getAll(): Observable<HomeAssistanceOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<HomeAssistanceOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            orderBy('createdAt', 'desc')
          ]
        });
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<HomeAssistanceOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<HomeAssistanceOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', '==', 'active'),
            orderBy('createdAt', 'desc'),
            limit(1),
          ],
        }).pipe(map((movingOrders) => movingOrders.length ? movingOrders[0] : null));
      }
      return of(null);
    }));
  }
}
