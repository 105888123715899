import { Injectable } from '@angular/core';
import { AddShoppingCartItemData, EcommercePricingExternal, RemoveShoppingCartItemData, SearchEngineProduct, ShoppingCart, ShoppingCartItemResourceType } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ShoppingCartsServiceApi } from './shopping-carts.service.api';
import { ShoppingCartsServiceMock } from './shopping-carts.service.mock';

export interface ShoppingCartItemProduct {
  addedAt: Date;
  quantity: number;
  resource: SearchEngineProduct | null;
  resourceId: string;
  resourceType: ShoppingCartItemResourceType;
}

export type ShoppingCartDetail = Omit<ShoppingCart, 'createdAt' | 'id' | 'items' | 'updatedAt' | 'userId'> & ({
  discounts: number;
  items: ShoppingCartItemProduct[];
  originalShippingCost: number;
  pricing: EcommercePricingExternal;
  shippingCost: number;
  subtotal: number;
  totalItems: number;
});

export type LocalShoppingCart = Omit<ShoppingCart, 'createdAt' | 'id' | 'updatedAt' | 'userId'>;

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? ShoppingCartsServiceMock : ShoppingCartsServiceApi,
})
export abstract class ShoppingCartsService {
  abstract addItem(data: AddShoppingCartItemData): Promise<void>;
  abstract get(): Observable<ShoppingCartDetail | null>;
  abstract removeItem(data: RemoveShoppingCartItemData): Promise<void>;
  abstract updateShippingAddress(shippingAddressId: string): Promise<void>;
}
