import { Injectable } from '@angular/core';
import { PostalCodeLocation } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PostalCodeLocationsServiceApi } from './postal-code-locations.service.api';
import { PostalCodeLocationsServiceMock } from './postal-code-locations.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? PostalCodeLocationsServiceMock : PostalCodeLocationsServiceApi
})
export abstract class PostalCodeLocationsService {
  abstract get(id: string): Observable<PostalCodeLocation | null>;
}
