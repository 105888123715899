import { Injectable } from '@angular/core';
import { CreateProjectSimulationData } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { ProjectSimulationsServiceApi } from './project-simulations.service.api';
import { ProjectSimulationsServiceMock } from './project-simulations.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? ProjectSimulationsServiceMock : ProjectSimulationsServiceApi,
})
export abstract class ProjectSimulationsService {
  abstract create(data: CreateProjectSimulationData): Promise<void>;
}