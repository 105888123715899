import { CommonModule, Location } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';
import { Subject, takeUntil } from 'rxjs';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../services/favorites/favorites.service';
import { PlatformService } from '../../services/platform/platform.service';
import { SearchHistoryService } from '../../services/search-history/search-history.service';
import { SearchFilter } from '../../services/search/search.service';
import { ShoppingCartDetail, ShoppingCartsService } from '../../services/shopping-carts/shopping-carts.service';
import { FilterSidebarComponent, FiltersSideBar } from '../filter-sidebar/filter-sidebar.component';
import { SearchInputComponent } from '../search-input/search-input.component';
import { SearchSidebarComponent } from '../search-sidebar/search-sidebar.component';

@Component({
  selector: 'app-complementary-ecommerce-bar',
  standalone: true,
  imports: [
    BadgeModule,
    CommonModule,
    FilterSidebarComponent,
    RouterModule,
    SearchInputComponent,
    SearchSidebarComponent,
    SidebarModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './complementary-ecommerce-bar.component.html',
  styleUrl: './complementary-ecommerce-bar.component.scss',
})
export class ComplementaryEcommerceBarComponent implements OnInit, OnDestroy {
  @ViewChild('filterSidebar') filterSidebar: FilterSidebarComponent;
  @Input() filters: SearchFilter[] | null;
  @Input() order: { direction: 'asc' | 'desc'; field: string; } | null;
  @Input() query: string | null = null;
  @ViewChild('searchSidebar') searchSidebar: SearchSidebarComponent;
  @Input() showBackButton = false;
  @Input() showFilters = false;
  @Input() showSearchIcon = false;
  activatedFilters: FiltersSideBar;
  hasActiveFilters = false;
  hasFavorites = false;
  searchHistory: string[] | null = null;
  shoppingCartDetail: ShoppingCartDetail | null = null;
  private isEcommerceSearch = false;
  private isMobile = false;
  private prevScrollPos = 0;
  private viewDestroyed = new Subject<void>();

  constructor(
    private analyticsService: AnalyticsService,
    private elementRef: ElementRef,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private location: Location,
    private platformService: PlatformService,
    private renderer: Renderer2,
    private router: Router,
    private searchHistoryService: SearchHistoryService,
    private shoppingCartsService: ShoppingCartsService,
  ) {
    this.router.events.pipe(takeUntil(this.viewDestroyed)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isEcommerceSearch = event.url.startsWith('/ecommerce/search');
      }
    });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
    if (this.platformService.isBrowser()) {
      this.isMobile = window.innerWidth < 1366;
      this.prevScrollPos = window.scrollY;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async changeQuery(query: string | null): Promise<void> {
    this.query = query;
    if (!this.query) {
      this.hasActiveFilters = false;
      this.filterSidebar.deleteFilters();
      await this.router.navigateByUrl('/ecommerce/search');
    }
  }

  async deleteSearchHistory(): Promise<void> {
    await this.searchHistoryService.removeAll();
  }

  async filter(filters: FiltersSideBar): Promise<void> {
    const encodedFilters = this.getEncodedFilters(filters);
    const encodedOrder = this.getEncodedOrder(filters);
    this.hasActiveFilters = !!encodedFilters || !!encodedOrder;
    await this.router.navigate(['/ecommerce/search'],
      {
        queryParams: {
          ...(this.query && { query: this.query }),
          ...(encodedFilters && { filters: encodedFilters }),
          ...(encodedOrder && { order: encodedOrder }),
        }
      });
  }

  goBack(): void {
    this.location.back();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const currentScrollPos = this.platformService.isBrowser() ? window.scrollY : 0;
    const mainContainer = this.elementRef.nativeElement.querySelector('.main-container');
    this.showSearchIcon = currentScrollPos >= 134;
    if (currentScrollPos >= 1 ) {
      this.renderer.setStyle(mainContainer, 'box-shadow', 'var(--light-shadow-08)');
    } else {
      this.renderer.setStyle(mainContainer, 'box-shadow', 'none');
    }
    if (currentScrollPos >= 134 && this.isMobile && !this.isEcommerceSearch) {
      this.renderer.setStyle(mainContainer, 'top', '71px');
      this.renderer.setStyle(mainContainer, 'position', 'sticky');
    }
    if (this.isEcommerceSearch) {
      if (currentScrollPos < 100) {
        this.renderer.addClass(mainContainer, 'sticky');
        this.renderer.setStyle(this.elementRef.nativeElement.querySelector('.ecommerce-bar-spacer'), 'height', '0px');
      }
    } else if (this.prevScrollPos > currentScrollPos) {
      this.renderer.setStyle(mainContainer, 'transform', 'translateY(0)');
    } else if (this.prevScrollPos < currentScrollPos && currentScrollPos > 110) {
      this.renderer.setStyle(mainContainer, 'transform', 'translateY(-100%)');
    }
    this.prevScrollPos = currentScrollPos;
  }

  openFilters(): void {
    this.filterSidebar.open();
  }

  openSearch(): void {
    this.searchSidebar.open();
  }

  async search(query: string): Promise<void> {
    if (this.searchSidebar.isOpen()) {
      this.searchSidebar.close();
    }
    this.query = query;
    this.analyticsService.logSearchEvent({ searchTerm: this.query });
    this.hasActiveFilters = false;
    await this.searchHistoryService.create(query);
    await this.router.navigateByUrl(`/ecommerce/search?query=${this.query}`);
  }

  private getEncodedFilters(filters: FiltersSideBar): string | null {
    const filtersToApply = {
      ...(filters.discountPercentage && { discount: { field: 'discountPercentage', operator: '>=', value: filters.discountPercentage } }),
      ...(filters.max && { max: { field: 'price', operator: '<=', value: filters.max } }),
      ...(filters.min && { min: { field: 'price', operator: '>=', value: filters.min } }),
      ...(filters.rating && { min: { field: 'rating', operator: '>=', value: filters.rating } }),
    };
    return Object.keys(filtersToApply).length ? JSON.stringify(filtersToApply) : null;
  }

  private getEncodedOrder(filters: FiltersSideBar): string | null {
    if (filters.sortBy?.field !== 'default') {
      return JSON.stringify(filters.sortBy);
    }
    return null;
  }

  private decodeFilters(): void {
    this.activatedFilters = {
      discountPercentage: this.filters?.find((filter) => filter.field === 'discount')?.value as number ?? null,
      max: this.filters?.find((filter) => filter.field === 'price' && filter.operator === '<=')?.value as number ?? null,
      min: this.filters?.find((filter) => filter.field === 'price' && filter.operator === '>=')?.value as number ?? null,
      rating: this.filters?.find((filter) => filter.field === 'rating')?.value as number ?? null,
      sortBy: this.order,
    };
    const { discountPercentage, max, min, rating, sortBy } = this.activatedFilters;
    this.hasActiveFilters = !!discountPercentage || !!max || !!min || !!rating || !!sortBy;
  }

  private initialize(): void {
    this.decodeFilters();
    this.favoritesService.hasFavoriteItems().pipe(takeUntil(this.viewDestroyed)).subscribe((hasFavorites) => this.hasFavorites = hasFavorites);
    this.searchHistoryService.getAll().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (searchHistory) => this.searchHistory = searchHistory,
      error: (error) => {
        this.errorReportingService.log('ComplementaryEcommerceBarComponent.initialize()', 'get-search-history', error);
        this.searchHistory = null;
      },
    });
    this.shoppingCartsService.get().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (shoppingCart) => this.shoppingCartDetail = shoppingCart,
      error: (error) => {
        this.errorReportingService.log('ComplementaryEcommerceBarComponent.initialize()', 'get-shopping-cart', error);
        this.shoppingCartDetail = null;
      }
    });
  }
}
