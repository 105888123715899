import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ButtonComponent } from '../../../components/button/button.component';
import { HomeAssistancePlanDetailsSidebarComponent } from '../../../components/home-assistance-plan-details-sidebar/home-assistance-plan-details-sidebar.component';
import { SelectAssistancePlanSidebarComponent } from '../../../components/select-assistance-plan-sidebar/select-assistance-plan-sidebar.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';

@Component({
  selector: 'app-plan-hogar',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    HomeAssistancePlanDetailsSidebarComponent,
    RouterLink,
    SelectAssistancePlanSidebarComponent,
    SharedModule,
    TermsBannerComponent,
    TranslocoPipe,
  ],
  templateUrl: './plan-hogar.page.html',
  styleUrl: './plan-hogar.page.scss',
})
export class PlanHogarPage implements OnInit {
  @ViewChild('assistancePlanDetailsSidebar') assistancePlanDetailsSidebar: HomeAssistancePlanDetailsSidebarComponent;
  @ViewChild('selectAssistancePlanSidebar') selectAssistancePlanSidebar: SelectAssistancePlanSidebarComponent;
  sidebarVisible = false;

  constructor(
    private metaTagsService: MetaTagsService,
  ) {}

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  cancel(): void {
    this.sidebarVisible = false;
  }

  openPlanDetails(): void {
    this.assistancePlanDetailsSidebar.open();
  }

  openSelectPlan(): void {
    this.selectAssistancePlanSidebar.open();
  }
}
