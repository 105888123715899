import { MortgageSimulation, MortgageSimulationPaymentTerm, MortgageSimulationType } from '@homein-hogar-server';

export enum MortgageSimulatorStep {
  SimulationType = 1,
  SimulationData = 2,
  RemodelingAmount = 3,
  ContactData = 4,
}

export type LocalMortgageSimulation = Pick<MortgageSimulation, 'amountNeeded' | 'downMortgage' | 'email' | 'fullName' | 'phoneNumber' | 'propertyValue' | 'remodelingExtraAmount'> & { paymentTerm: MortgageSimulationPaymentTerm | null; stepsCompleted: MortgageSimulatorStep[]; type: MortgageSimulationType | null; };

export const EMPTY_MORTGAGE_SIMULATION: LocalMortgageSimulation = {
  amountNeeded: 0,
  downMortgage: 0,
  email: '',
  fullName: '',
  paymentTerm: null,
  phoneNumber: '',
  propertyValue: 0,
  remodelingExtraAmount: 0,
  stepsCompleted: [],
  type: null,
};