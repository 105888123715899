import { ProjectSimulationType } from '@homein-hogar-server';

export function getImageByType(type: ProjectSimulationType | null): string {
  switch (type) {
  case 'kitchen':
    return '/assets/illustrations/project-simulation-type-kitchen.svg';
  case 'bathroom':
    return '/assets/illustrations/project-simulation-type-bathroom.svg';
  case 'integral-remodeling':
    return '/assets/illustrations/project-simulation-type-integral.svg';
  default:
    return '/assets/illustrations/default-house.svg';
  }
}

