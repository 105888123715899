import { Inject, Injectable } from '@angular/core';
import { limit, orderBy, where } from '@angular/fire/firestore';
import { CreateRemodelingOrderData, RemodelingOrder, RemodelingOrdersRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { map, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { RemodelingOrdersService } from './remodeling-orders.service';

const COLLECTION_PATH = 'remodelingOrders';

@Injectable()
export class RemodelingOrdersServiceApi implements RemodelingOrdersService {
  private client: CreateTRPCProxyClient<RemodelingOrdersRouter>;

  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<RemodelingOrdersRouter>(getTRPCClientOptions(`${environment.apiUrl}/${COLLECTION_PATH}`, () => this.sessionsService.getAccessToken()));
  }

  create(data: CreateRemodelingOrderData): Promise<string> {
    return this.client.create.mutate(data).then(({ id }) => id);
  }

  get(id: string): Observable<RemodelingOrder | null> {
    return this.firestore.doc<RemodelingOrder>(`${COLLECTION_PATH}/${id}`);
  }

  getAll(): Observable<RemodelingOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<RemodelingOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', '!=', 'initializing'),
            orderBy('createdAt', 'desc')
          ]
        });
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<RemodelingOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<RemodelingOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', 'in', ['in-progress', 'lead-created', 'pending', 'waiting']),
            orderBy('createdAt', 'desc'),
            limit(1),
          ],
        }).pipe(map((movingOrders) => movingOrders.length ? movingOrders[0] : null));
      }
      return of(null);
    }));
  }
}
