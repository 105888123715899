import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ButtonComponent } from '../../../components/button/button.component';
import { RemodelingQuoteSidebarComponent } from '../../../components/remodeling-quote-sidebar/remodeling-quote-sidebar.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    RemodelingQuoteSidebarComponent,
    RouterLink,
    SharedModule,
    TermsBannerComponent,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomePage implements OnInit {
  @ViewChild('remodelingQuoteSidebar') remodelingQuoteSidebar: RemodelingQuoteSidebarComponent;
  constructor(
    private activatedRoute: ActivatedRoute,
    private metaTagsService: MetaTagsService,
  ) {}

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    const action = this.activatedRoute.snapshot.queryParamMap.get('action') || this.activatedRoute.snapshot.data['action'];
    if (action === 'quote') {
      setTimeout(() => {
        this.openRemodelingQuoteSidebar();
      }, 0);
    }
  }

  openRemodelingQuoteSidebar(): void {
    this.remodelingQuoteSidebar.open();
  }
}
