import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-movings/my-movings.page').then(m => m.MyMovingsPage)
  },
  {
    path: ':orderId',
    loadComponent: () => import('./moving-status/moving-status.page').then(m => m.MovingStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('./moving-details/moving-details.page').then(m => m.MovingDetailsPage)
  }
] satisfies Routes;
