import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslationItem, TranslationService } from '../../services/translation/translation.service';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-retryable-section',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ProgressSpinnerModule,
    TranslocoPipe,
  ],
  templateUrl: './retryable-section.component.html',
  styleUrl: './retryable-section.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RetryableSectionComponent implements OnInit {
  @Input({ required: true }) error: boolean;
  @Input() errorMessage?: string | TranslationItem;
  @Input({ required: true }) loading: boolean;
  @Output() retry: EventEmitter<void> = new EventEmitter<void>();
  errorMessageTranslationItem: TranslationItem;

  constructor(
    private translationsService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  retryAction(): void {
    this.retry.emit();
  }

  private initialize(): void {
    this.errorMessageTranslationItem = this.translationsService.getTranslationItem(this.errorMessage ?? 'There was a problem loading the information. Please retry.');
  }
}
