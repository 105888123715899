import { Inject, Injectable } from '@angular/core';
import { RemoteConfig } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { RemoteConfigsService } from './remote-configs.service';

const COLLECTION_PATH = 'remoteConfigs';

@Injectable()
export class RemoteConfigsServiceApi implements RemoteConfigsService {
  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
  ) {}

  get(): Observable<RemoteConfig | null> {
    return this.firestore.doc(`${COLLECTION_PATH}/remote-configs`);
  }
}