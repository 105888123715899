import { Injectable } from '@angular/core';
import { GenerateInvoiceData, SubmitConsumerCreditDiscountData, SubmitContactEcommerceFormData, SubmitContactHomeAssistanceFormData, SubmitContactMovingFormData, SubmitContactPlatformFormData, SubmitContactRemodelingFormData } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { FormsServiceApi } from './forms.service.api';
import { FormsServiceMock } from './forms.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? FormsServiceMock : FormsServiceApi,
})
export abstract class FormsService {
  abstract generateInvoice(data: GenerateInvoiceData): Promise<void>;
  abstract submitConsumerCreditDiscount(data: SubmitConsumerCreditDiscountData): Promise<void>;
  abstract submitContactEcommerce(data: SubmitContactEcommerceFormData): Promise<void>;
  abstract submitContactHomeAssistance(data: SubmitContactHomeAssistanceFormData): Promise<void>;
  abstract submitContactMoving(data: SubmitContactMovingFormData): Promise<void>;
  abstract submitContactPlatform(data: SubmitContactPlatformFormData): Promise<void>;
  abstract submitContactRemodeling(data: SubmitContactRemodelingFormData): Promise<void>;
}
