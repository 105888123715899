<section class="complaints-and-returns-section">
  <div class="left-section">
    <app-retryable-section [error]="errorLoadingInitialData" [loading]="loadingInitialData" (retry)="initialize()">
      @if (user && !showSuccessScreen) {
        <div class="title-container">
          <h4 class="title">Hola, {{ userName }} 👋</h4>
          <p class="subtitle">Lamentamos que estés teniendo inconvenientes. ¡Te ayudaremos a solucionarlo!</p>
        </div>
        <p class="text-center">Primero confirma tus datos de contacto para seguimiento</p>
        <form [formGroup]="form" class="form-container">
          <div class="input-container">
            <label for="email">{{ 'Email' | transloco }}</label>
            <input id="email" type="email" pInputText formControlName="email" aria-describedby="email-help" [class.disabled]="form.controls.email.disabled" [class.input]="form.controls.email.enabled"/>
          </div>
          <div class="input-container">
            <label for="phone-number">{{ 'Phone number' | transloco }}</label>
            <p-inputMask id="phone-number" formControlName="phoneNumber" [autoClear]="false" [unmask]="true"
                         mask="+(99) 99 9999 9999" slotChar="" aria-describedby="phone-number-help"
                         [class.disabled]="form.controls.phoneNumber.disabled"
                         [class.input-mask]="form.controls.phoneNumber.enabled"/>
          </div>
          <a class="change-contact-information" routerLink="/private-site/profile/my-personal-data"
             [queryParams]="{ redirectTo: '/complaints' }">Modificar datos de contacto</a>
          <p class="text-center">Ahora ayúdanos con más información sobre la situación</p>
          <div class="input-container">
            <label for="service">{{ 'Service' | transloco }}</label>
            <p-dropdown id="service" class="service-input" placeholder="Selecciona un servicio..." (onChange)="loadOrders()" [class.active]="isActive(form.controls.service)" formControlName="service" [options]="suggestedServices"/>
          </div>
          <app-retryable-section [error]="errorLoadingOrders" [loading]="loadingOrders" (retry)="loadOrders()">
            @if (form.controls.service.value === 'Compras para el hogar' && (ecommerceOrders.length > 0)) {
              <form [formGroup]="formEcommerce">
                <label class="multiselect-label">{{ 'Producto' | transloco }}</label>
                <p-multiSelect [options]="orders"
                               showHeader="false"
                               group="true"
                               formControlName="selectedEcommerceOrder"
                               optionValue="value"
                               [maxSelectedLabels]="1"
                               (onChange)="handleSelectedEcommerceOrder($event)">
                  <ng-template let-selectedItems pTemplate="selectedItems">
                    @if (!selectedItems || selectedItems.length === 0) {
                      <span>Selecciona productos para tu solicitud</span>
                    } @else if (selectedItems?.length === 1) {
                      <span>{{ selectedItems[0].item.brand }} - {{ selectedItems[0].item.name }}</span>
                    } @else {
                      <span>Orden: {{ selectedItems[0].order.id }} - {{ selectedItems.length }} productos</span>
                    }
                  </ng-template>
                  <ng-template let-order pTemplate="item">
                    <img class="img" src="{{order.value.item.thumbnail}}" alt="Foto del producto"/>
                    <div class="item-container">
                      <span class="brand">{{ order.value.item.brand }}</span>
                      <span class="name">{{ order.value.item.name }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
                <div class="request-container">
                  <p class="text-center">Cuéntanos más sobre tu solicitud</p>
                  <div class="input-container">
                    <label for="requestType">{{ 'Tipo de solicitud' | transloco }}</label>
                    <p-dropdown id="requestType" class="service-input" placeholder="Tipo de solicitud"
                                (onChange)="handleRequestTypeChange($event)" formControlName="requestType"
                                [options]="requestOptions"/>
                  </div>
                  <div class="input-container">
                    <label for="requestDetail">{{ 'Detalle de solicitud' | transloco }}</label>
                    <p-dropdown id="requestDetail" class="service-input" placeholder="Detalle de solicitud"
                                formControlName="requestDetail" [options]="requestDetailOptions"/>
                  </div>
                </div>
              </form>
            } @else if (form.controls.service.value === 'Mudanza' && (movingOrders.length > 0)) {
              <form [formGroup]="formMoving">
                <div class="input-container">
                  <label for="service">{{ 'Compra' | transloco }}</label>
                  <p-dropdown id="movingPurchase" class="service-input" placeholder="Selecciona una mudanza"
                              formControlName="purchase" [options]="movingOrders">
                    <ng-template let-selectedItem pTemplate="selectedItem">
                      <div class="moving-container">
                        @if (!selectedItem || selectedItem.length === 0) {
                          <span class="brand">Selecciona tu mudanza</span>
                        } @else {
                          <span class="brand">{{ selectedItem.id }} - {{ selectedItem.items.length }} items, Dia de mudanza: {{ selectedItem.movingDate | date: 'dd/MM/yy' }}</span>
                        }
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <p>ID orden: {{ item.id }}</p>
                      <p><strong>{{ item.additionalData.serviceType ?? 'Servicio por seleccionar' }}</strong></p>
                      <p>{{ item.items.length }} items</p>
                      <p>Dia de mudanza: {{ item.movingDate | date: 'dd/MM/yy' }}</p>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="input-container">
                  <label for="movingRequestType">{{ 'Tipo de solicitud' | transloco }}</label>
                  <p-dropdown id="movingRequestType" class="service-input" placeholder="Selecciona tu solicitud"
                              formControlName="requestType" [options]="suggestedMovingApplications"/>
                </div>
              </form>
            } @else if (form.controls.service.value === 'Asistencia' && (homeAssistanceOrdersDetails.length > 0)) {
              <form [formGroup]="formHomeAssistance">
                <div class="input-container">
                  <label for="service">{{ 'Compra' | transloco }}</label>
                  <p-dropdown id="homeAssistancePurchase" class="service-input"
                              placeholder="Selecciona una asistencia" formControlName="purchase"
                              [options]="homeAssistanceOrdersDetails">
                    <ng-template let-selectedItem pTemplate="selectedItem">
                      <div class="home-assistance-container">
                        @if (!selectedItem || selectedItem.length === 0) {
                          <span class="brand">Selecciona tu asistencia</span>
                        } @else {
                          <span>{{ selectedItem.plan.name }} (ID: #{{ selectedItem.id }}) - </span>
                          <span>{{ selectedItem.duration === '1-month' ? ' Membresía mensual' : selectedItem.duration === '6-months' ? 'Membresía semestral' : selectedItem.duration === '12-months' ? 'Membresía anual' : selectedItem.duration }}</span>
                        }
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <p></p>
                      <p><strong>{{ item.plan.name }}</strong> (ID: #{{ item.id }})</p>
                      <p>{{ item.duration === '1-month' ? ' Membresía mensual' : item.duration === '6-months' ? 'Membresía semestral' : item.duration === '12-months' ? 'Membresía anual' : item.duration }}</p>
                      <p>Cobertura hasta: {{ item.finishAt | date : 'dd/MM/yy' }}</p>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="input-container">
                  <label for="movingRequestType">{{ 'Tipo de solicitud' | transloco }}</label>
                  <p-dropdown id="homeAssistanceRequestType" class="service-input"
                              placeholder="Selecciona tu solicitud" formControlName="requestType"
                              [options]="suggestedHomeAssistanceApplications"/>
                </div>
              </form>
            } @else if (form.controls.service.value === 'Remodelación' && (remodelingOrders.length > 0)) {
              <form [formGroup]="formRemodeling">
                <div class="input-container">
                  <label for="service">{{ 'Compra' | transloco }}</label>
                  <p-dropdown id="remodelingPurchase" class="service-input" placeholder="Selecciona una remodelación"
                              formControlName="purchase" [options]="remodelingOrders">
                    <ng-template let-selectedItem pTemplate="selectedItem">
                      <div class="remodeling-container">
                        @if (!selectedItem || selectedItem.length === 0) {
                          <span class="brand">Selecciona tu remodelación</span>
                        } @else {
                          <span>{{ selectedItem.id }} - </span>
                          <span>{{ selectedItem.additionalData.opportunityName }}</span>
                        }
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <p>N° de obra: {{ item.id }}</p>
                      <p>{{ item.additionalData.opportunityName }}</p>
                    </ng-template>
                  </p-dropdown>
                </div>
              </form>
            }
          </app-retryable-section>
          @if (form.controls.service.value && !errorLoadingOrders && !loadingOrders) {
            <div class="describe-application">
              <p class="text-center">Describe tu solicitud detalladamente</p>
              <div class="input-container">
                <textarea class="message" id="message" rows="3" [class.active]="isActive(form.controls.message)" [maxLength]="maxWords" pInputText formControlName="message" (input)="handleTextAreaEvent($event)"></textarea>
                <div class="word-count">
                  {{ wordCount }}/{{ maxWords }}
                </div>
              </div>
            </div>
          }
        </form>
        <div class="button-container">
          <app-button label="Send form" class="send" styleClass="primary expand" [disabled]="validateForms" [loading]="sendingForm" (action)="onSubmit()"/>
        </div>
      } @else if ( user && showSuccessScreen) {
        <div class="success-container">
          <img src="assets/images/success-img.svg" alt="{{ 'Success image' | transloco }}">
          <h2 class="title">{{ 'Application sent!' | transloco }}</h2>
          <span class="text">{{ 'Your request has been sent and we will contact you soon.' | transloco }}</span>
          <app-button label="Back to home" class="back" styleClass="primary" routerLink="/"/>
        </div>
      } @else {
        <div class="not-logged">
          <div class="content">
            <div class="container">
              <h4 class="title">Hola 👋</h4>
              <p class="subtitle">Lamentamos que estés teniendo inconvenientes. ¡Te ayudaremos a solucionarlo!</p>
              <p class="login-call">Para brindarte un servicio de ayuda personalizado es necesario que ingreses a tu cuenta Mundo Hogar</p>
              <app-button label="Enter" (action)="goToSignIn()"/>
            </div>
            <span class="help-account">Si tienes problemas para acceder tu cuenta Mundo Hogar o no tienes una, <a class="link" routerLink="/contact">presiona aquí</a></span>
          </div>
        </div>
      }
    </app-retryable-section>
  </div>
  <div class="right-section">
    <div class="text-container">
      <h2 class="title">Estamos para ayudarte</h2>
      <p class="subtitle">{{ 'Tu tranquilidad nos importa' | transloco }}</p>
    </div>
  </div>
</section>