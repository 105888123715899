<app-complementary-ecommerce-bar [showBackButton]="true" [showSearchIcon]="false" />
<section class="product-detail-container">
  <div class="breadcrumb-container">
    <div class="icon-container">
      <i class="icon icon-chevron-left" (click)="goBack()"></i>
    </div>
    <p-breadcrumb [model]="breadcrumbs" [home]="home" />
  </div>
  <app-retryable-section [error]="errorLoadingProduct" [loading]="loadingProduct" (retry)="getProduct()">
    @if (product) {
      <div class="product-container">
        <div class="product-images-reel">
          @for (image of product.images; track $index) {
            <img class="image" src="{{ image }}" (click)="toggleShowGallery()" alt="{{ 'Product image' | transloco }}">
          }
        </div>
        <div class="detail-container">
          <div class="information-container">
            <img class="image" src="{{ product.images[0] }}" (click)="toggleShowGallery()" alt="{{ 'Product image' | transloco }}">
            <div class="description-container">
              <span class="description" [ngClass]="{ 'collapsed': isCollapsed, 'expanded': !isCollapsed }">{{ product.description }}</span>
              <button class="read-more" (click)="toggleDescription()">{{ (isCollapsed ? 'See more' : 'See less') | transloco }}</button>
            </div>
            <span class="id">{{ 'Product ID' | transloco }}: {{ product.id }}</span>
          </div>
        </div>
        <div class="data-container">
          <div class="carousel-container">
            <p-carousel class="product-images-carousel"
                        [contentClass]="'carousel display-navigation'"
                        [value]="product.images"
                        [numVisible]="1"
                        [numScroll]="1"
                        [showNavigators]="true"
                        [showIndicators]="true">
              <ng-template let-item pTemplate="item">
                <img class="image" [src]="item" alt="{{ item.title }}" loading="lazy"  />
              </ng-template>
            </p-carousel>
          </div>
          <span class="brand">{{ product.brand }}</span>
          <span class="name">{{ product.name }}</span>
          @if (product.discountPercentage) {
            <span class="discount-percent">-{{ product.discountPercentage | translocoPercent }}</span>
          }
          <div class="price-container">
            <span class="current-price">{{ product.price | currencyFormatter: product.currency }} <span class="currency">{{ product.currency }}</span></span>
            @if (product.discountPercentage > 0) {
              <span class="original-price">{{ product.originalPrice | currencyFormatter: product.currency }}<span class="currency">{{ product.currency }}</span></span>
            }
          </div>
          <!-- TODO F&F: translate this -->
          <span class="installments-text">Paga hasta en 36 mensualidades de {{ product.pricing['credit-36'].monthlyPayment | currencyFormatter: product.currency }}</span>
          <span class="see-more-options" (click)="overlayPanel.toggle($event)">{{ 'See more options' | transloco }}</span>
          <div class="buttons-container">
            <app-button class="add-shopping-cart" label="Add to shopping cart" styleClass="primary dark" [loading]="addingProductToShoppingCart" (action)="addToShoppingCart()" />
            <button class="favorites" (click)="toggleFavorites()">
              @if (isFavorite && !togglingFavorite) {
                <i class="pi pi-heart-fill"></i>
              } @else if (!isFavorite && !togglingFavorite) {
                <i class="pi pi-heart"></i>
              } @else {
                <i class="pi pi-spin pi-spinner"></i>
              }
            </button>
          </div>
          <div class="description-container">
            <span class="description" [ngClass]="{ 'collapsed': isCollapsed, 'expanded': !isCollapsed }">{{ product.description }}</span>
            <button class="read-more" (click)="toggleDescription()">{{ (isCollapsed ? 'See more' : 'See less') | transloco }}</button>
          </div>
          <span class="id">{{ 'Product ID' }}: {{ product.id }}</span>
          <div class="delivery-container zip-code">
            <i class="icon icon-shipping"></i>
            <div class="information">
              <span class="title">{{ 'Estimated delivery' | transloco }}</span>
              @if (shippingDates && shippingDates.to && shippingDates.from) {
                <p class="detail">{{ 'Delivery for zip code X' | transloco: { postalCode: form.controls.postalCode.value! } }} {{ 'between XX and YY' | transloco: {
                    from: shippingDates.from | date: 'dd MMM',
                    to: shippingDates.to | date: 'dd MMM'
                } }}</p>
                <a class="link" (click)="onEnterAnotherZipCode()">{{ 'Enter another code' | transloco }}</a>
              } @else {
              <form [formGroup]="form" class="form-container">
                <div class="input-container">
                  <label class="detail" for="postal-code">{{ 'Enter zip code to calculate' | transloco }}</label>
                  <div class="zip-container">
                    <input class="input" pInputText id="postal-code" [formControl]="form.controls.postalCode" placeholder="{{ 'Enter zip code...' | transloco }}" maxlength="5" [class.active]="isActive(this.form.controls.postalCode)" />
                    <app-button class="button" styleClass="tertiary dark expand" label="Calculate" (action)="setShippingDates()" [loading]="loadingCalculate" />
                  </div>
                </div>
              </form>
              }
            </div>
          </div>
          <div class="dimensions-container">
            <i class="icon icon-dimensions"></i>
            <div class="information">
              <span class="title">{{ 'Product dimensions' | transloco }}</span>
              <span class="detail">{{ 'Height: X cm, Width: Y cm, Depth: Z cm' | transloco: { height: product.dimensions.height, depth: product.dimensions.depth, width: product.dimensions.width } }}</span>
            </div>
          </div>
        </div>
      </div>
      <p-galleria class="gallery"
        [value]="product.images"
        [visible]="showGallery"
        [containerStyle]="{ 'max-width': '50%' }"
        [numVisible]="9"
        [circular]="true"
        [fullScreen]="true"
        [showItemNavigators]="true"
        [showThumbnails]="false"
        (visibleChange)="toggleShowGallery()">
        <ng-template pTemplate="item" let-item>
          <img class="gallery-item" [src]="item" alt="{{ 'Product image' | transloco }}" />
        </ng-template>
      </p-galleria>
    }
  </app-retryable-section>
</section>
<!-- TODO F&F: uncomment this when products has categories
<section class="products-of-interest-section">
  @if (product) {
    <app-products-of-interest [filters]="[{ value: [product.category], operator: 'in', field: 'categories' }]" [title]="{ key: 'More from X', params: { brand: product.brand } }" ngSkipHydration />
  }
</section>
-->
<section class="products-of-interest-section">
  @if (product) {
    <app-products-of-interest [filters]="[{ value: [product.brand], operator: 'in', field: 'brand' }]" [title]="{ key: 'More from X', params: { brand: product.brand } }" ngSkipHydration />
  }
</section>
<p-overlayPanel styleClass="installment-options-overlay" #overlayPanel>
  <ng-template pTemplate="content">
    <span class="title-content">{{ 'Exclusive Santander cards' | transloco }}</span>
    <div class="separator"></div>
    <div class="titles">
      <span class="title">{{ 'Installment' | transloco }}</span>
      <span class="title">{{ 'Discount' | transloco }}</span>
      <span class="title">{{ 'Monthly payment' | transloco }}</span>
    </div>
    <div class="items">
      @for (entry of installmentOptions; track $index) {
        <div class="item">
          <span class="information">{{ entry.term }}</span>
          <span class="information">{{ entry.discount | translocoPercent }}</span>
          <div class="amounts-container">
            @if (product) {
              <span class="amount">{{ entry.monthlyPayment | currencyFormatter: product.currency }} {{ product.currency }}</span>
              <span class="amount">{{ 'Total' | transloco }}: {{ entry.total | currencyFormatter: product.currency }} {{ product.currency }}</span>
            }
          </div>
        </div>
      }
    </div>
  </ng-template>
</p-overlayPanel>