<section class="consumer-credit-discount-section">
  <div class="left-column">
    @if (displayBenefitKeyPoints) {
      <div class="benefit-key-points-container">
        <h2 class="title">{{ 'Key Benefit Points' | transloco }}</h2>
        <ul class="key-points-list">
          @for (keyPoint of benefitKeyPoints; track $index) {
            <li class="text">{{ keyPoint | transloco }}</li>
          }
        </ul>
        <div class="terms-and-conditions-container">
          <p-checkbox [(ngModel)]="acceptedTermsAndConditions" [binary]="true"></p-checkbox>
          <label class="text">{{ 'I have read and accept the' | transloco }} <span class="highlighted">{{ 'Terms and Conditions' | transloco }}</span> {{ 'of this benefit' | transloco }}</label>
        </div>
        <div class="action-button-container">
          <app-button label="Start" styleClass="primary expand" [disabled]="!acceptedTermsAndConditions" (action)="start()" />
        </div>
      </div>
    } @else if (displayConsumerCreditForm) {
      <div class="consumer-credit-discount-form-container">
        <h2 class="title">{{ 'Get a discount on your credit!' | transloco }}</h2>
        <p class="description">{{ 'Mundo Hogar rewards you for purchases made on the platform.' | transloco }}</p>
        <form [formGroup]="form" class="form">
          <div class="input-container">
            <label class="label" for="rfc">{{ 'RFC' | transloco }}</label>
            <p-inputMask id="rfc" [class.active]="isActive(form.controls.rfc)" [formControl]="form.controls.rfc" [autoClear]="false" [unmask]="true" mask="aaaa999999***" placeholder="ABCD1234567X8" slotChar="" aria-describedby="rfc-help"></p-inputMask>
            <small id="rfc-help" [class.hidden]="!this.form.controls.rfc.touched || !this.form.controls.rfc.errors" class="p-error input-error">{{ 'Enter a valid RFC.' | transloco }}</small>
          </div>
          <div class="input-container">
            <label for="requested-credit-date">{{ 'Requested credit date' | transloco }}</label>
            <p-calendar class="requested-credit-date" [class.active]="isActive(form.controls.requestedCreditDate)" id="requested-credit-date" dateFormat="dd/mm/yy" [maxDate]="maxRequestedCreditDate" [formControl]="form.controls.requestedCreditDate" aria-describedby="requested-credit-date-help" placeholder="DD/MM/AAAA" />
            <small id="requested-credit-date-help" [class.hidden]="!form.controls.requestedCreditDate.touched || !form.controls.requestedCreditDate.errors" class="p-error input-error">{{ 'Enter a valid requested credit date.' | transloco }}</small>
          </div>
          <div class="input-container">
            <label for="credit-amount">{{ 'Total amount of credit requested' | transloco }}</label>
            <p-inputNumber id="credit-amount" inputId="credit-amount" [formControl]="form.controls.creditAmount" locale="es-MX"  mode="currency" placeholder="{{ 'Enter the total credit' | transloco }}" currency="MXN" suffix=" MXN" [minFractionDigits]="2" [maxFractionDigits]="2" aria-describedby="credit-amount-help"/>
            <small id="credit-amount-help" [class.hidden]="!form.controls.creditAmount.touched || !form.controls.creditAmount.errors" class="p-error input-error">{{ 'Enter a valid total amount of credit requested.' | transloco }}</small>
          </div>
        </form>
        <p class="obtain-benefit-description">{{ 'You must have used 70% of your requested credit on purchases within Mundo Hogar to obtain the benefit.' | transloco }}</p>
        <div class="action-button-container">
          <app-button label="Calculate purchases" styleClass="primary expand" [disabled]="form.invalid || calculatingPurchases" [loading]="calculatingPurchases" (action)="calculatePurchases()" />
        </div>
      </div>
    } @else if(displayMeetsRequirements) {
      <div class="meets-requirements-section">
        <div class="go-back-and-title-container">
          <div class="title-container">
            <i class="icon icon-arrow-left" (click)="goBack()"></i>
            <h3 class="title">{{ (meetsRequirements ? 'You meet the requirements!' : 'You do not yet meet the requirements') | transloco }}</h3>
          </div>
          <p class="description">{{ (meetsRequirements ? 'Submit your request so that an executive can confirm the data.' : 'The total amount purchased is not enough.') | transloco }}</p>
        </div>
        <div class="purchases-bar-container">
          <div class="lower-and-upper-amount-container">
            <span class="amount">{{ 0 | currencyFormatter }} {{ currency }}</span>
            <span class="amount">{{ form.controls.creditAmount.value! | currencyFormatter }} {{ currency }}</span>
          </div>
          <div class="bar-container">
            <p-progressBar class="bar" [styleClass]="meetsRequirements ? 'bar meets-requirements' : 'bar'" showValue="false" [value]="completedPercentage * 100" />
          </div>
        </div>
        <div class="purchases-summary">
          <span class="quantity-label">{{ (paymentIntents.length > 1 ? 'X purchases in Mundo Hogar for' : '1 purchase in Mundo Hogar for') | transloco: { quantity: paymentIntents.length } }}</span>
          <span class="amount">{{ purchasesAmount | currencyFormatter }} {{ currency }}</span>
        </div>
        @if (paymentIntents.length) {
          <a class="see-all-purchases-link" (click)="seeAllPurchases()">{{ 'See all purchases' | transloco }}</a>
        }
        <div class="action-button-container">
          @if (meetsRequirements) {
            <app-button label="Send request" styleClass="primary expand" [loading]="sendingRequest" [disabled]="sendingRequest" (action)="sendRequest()" />
          } @else {
            <app-button label="Re-enter data" styleClass="secondary expand" (action)="goBack()" />
          }
        </div>
      </div>
    } @else if (displayRequestSuccess) {
      <div class="success-container">
        <img class="image" src="assets/images/success-img.svg" alt="{{ 'Image success check' | transloco }}">
        <h3 class="title">{{ 'We sent your request!' | transloco }}</h3>
        <p class="description">{{ 'We remind you that if the benefit is validated, it will be reflected in your next monthly payment.' | transloco }}</p>
        <div class="action-button-container">
          <app-button label="Go to Home" styleClass="primary expand" href="/" />
        </div>
      </div>
    }
  </div>
  <div class="right-column">
    <div class="text-container">
      <h4 class="title">{{ 'Mundo Hogar rewards you' | transloco }}</h4>
      <p class="subtitle">{{ 'Exclusive benefits for Santander customers' | transloco }}</p>
    </div>
    <img class="image" src="assets/images/woman-looking-sky.jpg" alt="{{ 'Image of consumer credit discount benefit' | transloco }}">
  </div>
</section>
<app-purchases-sidebar #purchasesSidebar
  [creditAmount]="form.controls.creditAmount.value!"
  [currency]="currency"
  [meetsRequirements]="meetsRequirements"
  [paymentIntents]="paymentIntents"
  [purchasesAmount]="purchasesAmount"
  [sendingRequest]="sendingRequest"
  (reEnterData)="goBack()"
  (sendRequest)="sendRequest()" />