import { Injectable } from '@angular/core';
import { MovingOrder } from '@homein-hogar-server';
import * as faker from 'faker';
import { map, Observable, of, switchMap } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { SessionsService } from '../sessions/sessions.service';
import { MovingOrdersService } from './moving-orders.service';


@Injectable()
export class MovingOrdersServiceMock implements MovingOrdersService {
  constructor(
    private mockService: MockService,
    private sessionsService: SessionsService,
  ) {}

  createId(): string {
    return faker.random.alphaNumeric(20);
  }

  get(id: string): Observable<MovingOrder | null> {
    return this.mockService.getMovingOrder(id);
  }

  getAll(): Observable<MovingOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap(session => {
      if (session) {
        return this.mockService.getMovingOrders().pipe(map((movingOrders) => {
          return movingOrders.filter((movingOrder) => movingOrder.userId === session.userId);
        }));
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<MovingOrder | null> {
    return this.mockService.getLastMovingOrderInProgress();
  }
}
