<p-sidebar [(visible)]="visible" styleClass="sidebar-container" position="left" blockScroll="true" showCloseIcon="false" (onHide)="close()">
  <ng-template pTemplate="headless">
    <div class="title-container">
      <h1 class="title">Mundo Hogar</h1>
      <p-button class="button" icon="pi pi-times" [rounded]="true" [text]="true" severity="secondary" (click)="close()" />
    </div>
    <p-scrollPanel [style]="scrollPanelConfig" class="scrollpanel">
      <p-accordion [multiple]="true" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
        <p-accordionTab class="accordion-tab-home" [class.selected]="currentPage === 'home'" iconPos="end">
          <ng-template pTemplate="header">
            <a class="accordion-header" [routerLink]="hasSession ? '/private-site' : '/'">
              <i class="icon icon-house"></i>
              <span class="category-title">{{ 'My Home' | transloco }}</span>
            </a>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'my-profile'" class="accordion-tab" [class.selected]="currentPage === 'my-profile'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-account-user"></i>
              <span class="category-title">{{ 'My profile' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            @if (hasSession) {
              <a class="selectable" routerLink="/private-site/profile/my-balance">{{ 'Balance' | transloco }}</a>
              <p-accordionTab class="subcategory-title" header="{{ 'Services and shopping' | transloco }}" iconPos="end">
                  <a class="selectable" routerLink="/private-site/my-home-assistances">{{ 'My home assistance plans' | transloco }}</a>
                  <a class="selectable" routerLink="/warehouse">{{ 'My warehouses' | transloco }}</a>
                  <a class="selectable" routerLink="/private-site/my-purchases">{{ 'My purchases' | transloco }}</a>
                  <a class="selectable" routerLink="/private-site/my-movings">{{ 'My movings' | transloco }}</a>
                  <a class="selectable" routerLink="/private-site/my-remodelings">{{ 'My remodeling' | transloco }}</a>
              </p-accordionTab>
              <a class="selectable" routerLink="/private-site/profile/my-personal-data">{{ 'Personal data' | transloco }}</a>
            } @else {
              <a class="selectable" routerLink="/enrollment/sign-in">{{ 'Sign in' | transloco }}</a>
              <a class="selectable" routerLink="/enrollment/sign-up">{{ 'Register' | transloco }}</a>
            }
          </ng-template>
        </p-accordionTab>
        <p-divider class="divider" />
        <p-accordionTab [selected]="currentPage === 'remodeling'" class="accordion-tab" [class.selected]="currentPage === 'remodeling'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-remodeling-hat"></i>
              <span class="category-title">{{ 'Remodeling' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/remodeling">{{ 'Go to remodeling' | transloco }}</a>
            <a class="selectable" routerLink="/project-simulator">{{ 'Simulate remodeling' | transloco }}</a>
            @if (hasSession) {
              <a class="selectable" routerLink="/remodeling" [queryParams]="{ action: 'quote' }">{{ 'Quote a remodeling' | transloco }}</a>
              <a class="selectable" routerLink="/private-site/my-remodelings">{{ 'My remodeling' | transloco }}</a>
            }
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'moving'" class="accordion-tab" [class.selected]="currentPage === 'moving'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-truck"></i>
              <span class="category-title">{{ 'Movings' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/moving">{{ 'Go to movings' | transloco }}</a>
            @if (hasSession) {
              <a class="selectable" routerLink="/moving" [queryParams]="{ action: 'quote' }">{{ 'Quote my movings' | transloco }}</a>
              <a class="selectable" routerLink="/private-site/my-movings">{{ 'My movings' | transloco }}</a>
            }
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'ecommerce'" class="accordion-tab" [class.selected]="currentPage === 'ecommerce'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-supermarketcart"></i>
              <span class="category-title">{{ 'Shopping for home' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/ecommerce">{{ 'Go to shopping' | transloco }}</a>
            <a class="selectable" routerLink="/ecommerce/my-favorites">{{ 'My favorites' | transloco }}</a>
            <a class="selectable" routerLink="/ecommerce/shopping-cart">{{ 'My shopping cart' | transloco }}</a>
            @if (hasSession) {
              <a class="selectable" routerLink="/private-site/my-purchases">{{ 'My purchases' | transloco }}</a>
            }
            <p-accordionTab class="subcategory-title" header="{{ 'Categories' | transloco }}" iconPos="end">
              @for (category of categories; track $index) {
                <p-accordionTab class="sub-subcategory-title" [header]="category.label" iconPos="end">
                  @for (children of category.children; track $index) {
                    <a class="sub-subcategory-text selectable" routerLink="/ecommerce/search" [queryParams]="children.queryParams">{{ children.label }}</a>
                  }
                </p-accordionTab>
              }
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'warehouse'" class="accordion-tab" [class.selected]="currentPage === 'warehouse'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-pin-place"></i>
              <span class="category-title">{{ 'Rent warehouse' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/warehouse">{{ 'Go to warehouses' | transloco }}</a>
            @if (hasSession) {
              <a class="selectable" routerLink="/warehouse">{{ 'My warehouses' | transloco }}</a>
              <a class="selectable" routerLink="/warehouse">{{ 'Quote my warehouses' | transloco }}</a>
            }
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'home-assistance'" class="accordion-tab" [class.selected]="currentPage === 'home-assistance'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-phone-support"></i>
              <span class="category-title">{{ 'Home assistance' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" routerLink="/home-assistance">{{ 'Go to home assistance' | transloco }}</a>
            @if (hasSession) {
              <a class="selectable" routerLink="/private-site/my-home-assistances">{{ 'My home assistance plans' | transloco }}</a>
            }
            <p-accordionTab class="subcategory-title" header="{{ 'Quote my home assistance plan' | transloco }}" iconPos="end">
              <a class="selectable" routerLink="/home-assistance/plan-hogar">Plan Hogar</a>
              <a class="selectable" routerLink="/home-assistance/plan-mascotas">Plan Mascotas</a>
              <a class="selectable" routerLink="/home-assistance/plan-hogar-mascotas">Plan Hogar + Mascotas</a>
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'finance-and-insurance'" class="accordion-tab" [class.selected]="currentPage === 'finance-and-insurance'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-hand-plant"></i>
              <span class="category-title">{{ 'Finance and insurance' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" href="https://www.santander.com.mx/personas/creditos-hipotecarios.html" target="_blank" rel="noopener">{{ 'Santander mortgage' | transloco }}</a>
            <a class="selectable" href="https://www.santander.com.mx/personas/creditos-personales.html" target="_blank" rel="noopener">{{ 'Credit consume Santander' | transloco }}</a>
            <a class="selectable" href="https://www.santander.com.mx/personas/tarjetas-de-credito.html" target="_blank" rel="noopener">{{ 'Credit card Santander' | transloco }}</a>
            <a class="selectable" href="https://www.santander.com.mx/personas/seguros.html" target="_blank" rel="noopener">{{ 'Santander insurance' | transloco }}</a>
            <p-accordionTab class="subcategory-title" header="{{ 'Simulators' | transloco }}" iconPos="end">
              <a class="selectable" routerLink="/mortgage-simulator">{{ 'Simulate mortgage' | transloco }}</a>
              <a class="selectable" routerLink="/project-simulator">{{ 'Remodeling simulator' | transloco }}</a>
            </p-accordionTab>
          </ng-template>
        </p-accordionTab>
        <p-divider />
        <p-accordionTab [selected]="currentPage === 'help-center'" class="accordion-tab" [class.selected]="currentPage === 'help-center'" iconPos="end">
          <ng-template pTemplate="header">
            <div class="accordion-header">
              <i class="icon icon-help-circle"></i>
              <span class="category-title">{{ 'Help center' | transloco }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/es/" target="_blank">{{ 'Go to help center' | transloco }}</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/preguntas-frecuentes" target="_blank">{{ 'FAQ' | transloco }}</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank">{{ 'Privacy policies' | transloco }}</a>
            <a class="selectable" href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank">{{ 'Terms and conditions' | transloco }}</a>
            <a class="selectable" routerLink="/complaints-and-returns" target="_blank">{{ 'Complaints and returns' | transloco }}</a>
            <a class="selectable" routerLink="/contact">{{ 'Contact' | transloco }}</a>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </p-scrollPanel>
  </ng-template>
</p-sidebar>