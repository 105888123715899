import { Injectable } from '@angular/core';
import { Address, CreateAddressData } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AddressesServiceApi } from './addresses.service.api';
import { AddressesServiceMock } from './addresses.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? AddressesServiceMock : AddressesServiceApi
})
export abstract class AddressesService {
  abstract create(data: CreateAddressData): Promise<string>;
  abstract get(id: string): Observable<Address | null>;
  abstract getAll(): Observable<Address[]>;
}
