import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-remodelings/my-remodelings.page').then(m => m.MyRemodelingsPage)
  },
  {
    path: ':orderId',
    loadComponent: () => import('./remodeling-status/remodeling-status.page').then(m => m.RemodelingStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('./remodeling-details/remodeling-details.page').then(m => m.RemodelingDetailsPage)
  }
] satisfies Routes;
