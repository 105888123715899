import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonComponent } from '../../../components/button/button.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    RouterLink,
    SharedModule,
    TermsBannerComponent,
    TooltipModule,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
})
export class HomePage implements OnInit {
  constructor(
    private metaTagsService: MetaTagsService,
  ) {}

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
  }
}
