import { Injectable } from '@angular/core';
import { PostalCodeLocation } from '@homein-hogar-server';
import { Observable, of, switchMap } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { SessionsService } from '../sessions/sessions.service';
import { PostalCodeLocationsService } from './postal-code-locations.service';

@Injectable()
export class PostalCodeLocationsServiceMock implements PostalCodeLocationsService {
  constructor(
    private mockService: MockService,
    private sessionsService: SessionsService,
  ) {}

  get(id: string): Observable<PostalCodeLocation | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.mockService.getPostalCodeLocation(id);
      }
      return of(null);
    }));
  }
}
