import { Component, inject } from '@angular/core';
import { filter } from 'rxjs';
import { SessionsService } from '../../services/sessions/sessions.service';
import { PlatformService } from '../../services/platform/platform.service';

@Component({
  standalone: true,
  template: '',
})
export class SessionDependantComponent {
  platformService = inject(PlatformService);
  sessionsService = inject(SessionsService);

  constructor() {
    this.sessionsService.onSignIn().pipe(filter(() => this.platformService.isBrowser())).subscribe(() => this.onSignIn());
    this.sessionsService.onSignOut().pipe(filter(() => this.platformService.isBrowser())).subscribe(() => this.onSignOut());
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  onSignIn(): void {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  onSignOut(): void {}
}
