<section class="project-simulator-container">
  <div>
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">{{ 'Back' | transloco }}</span>
      <span class="mobile-text">{{ 'Simulate your project' | transloco }} </span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">{{ 'Simulates the cost of the project you have in mind' | transloco }}</h5>
      <app-circular-progress [currentStep]="3" [numberSteps]="6" [stepTitle]="titleByType | transloco" [stepSubtitle]="subtitleByType | transloco"></app-circular-progress>
    </div>
    <div class="bottom-container animate__fadeIn">
      @if (projectSimulation && projectSimulation.type === 'bathroom') {
        <form [formGroup]="bathroomForm" class="project-simulation-types-container">
          <h2 class="title">{{ 'Number of bathrooms to be remodelled' | transloco }}</h2>
          @for (bathroom of [1, 2, 3, 4, 5]; track $index) {
            <div class="project-simulation-type-container" [class.selected]="bathroomForm.controls.numberOfBathrooms.value === bathroom" (click)="setFormControlValue(bathroomForm.controls.numberOfBathrooms, bathroom)">
              <span class="text">{{ bathroom }}</span>
            </div>
          }
        </form>
      }
      @if (projectSimulation && projectSimulation.type === 'kitchen') {
        <form [formGroup]="kitchenForm" class="project-simulation-types-container">
          <h2 class="title">{{ 'Select the type of finishing' | transloco }}</h2>
          <p class="subtitle">{{ 'Each option offers different levels of cabinetry, worktop and splashback.' | transloco }}</p>
          <div class="project-simulation-type-container" [class.selected]="kitchenForm.controls.counterTop.value === 'economic'" (click)="setFormControlValue(kitchenForm.controls.counterTop, 'economic')">
            <i class="icon-piggy-bank"></i>
            <span class="text">{{ 'Economic' | transloco }}</span>
          </div>
          <div class="project-simulation-type-container" [class.selected]="kitchenForm.controls.counterTop.value === 'intermediate'" (click)="setFormControlValue(kitchenForm.controls.counterTop, 'intermediate')">
            <i class="icon-price-award"></i>
            <span class="text">{{ 'Intermediate' | transloco }}</span>
          </div>
          <div class="project-simulation-type-container" [class.selected]="kitchenForm.controls.counterTop.value === 'premium'" (click)="setFormControlValue(kitchenForm.controls.counterTop, 'premium')">
            <i class="icon-trophy"></i>
            <span class="text">{{ 'Premium' | transloco }}</span>
          </div>
        </form>
      }
      @if (projectSimulation && projectSimulation.type === 'integral-remodeling') {
        <form [formGroup]="integralRemodelingForm" class="project-simulation-types-container">
          <h2 class="title">{{ 'Select the standard of the finishes' | transloco }}</h2>
          <p class="subtitle">{{ 'Each option offers different levels of material quality and aesthetics for the finishes.' | transloco }}</p>
          <div class="project-simulation-type-container" [class.selected]="integralRemodelingForm.controls.surfaces.value === 'economic'" (click)="setFormControlValue(integralRemodelingForm.controls.surfaces, 'economic')">
            <i class="icon-piggy-bank"></i>
            <span class="text">{{ 'Economic' | transloco }}</span>
          </div>
          <div class="project-simulation-type-container" [class.selected]="integralRemodelingForm.controls.surfaces.value === 'intermediate'" (click)="setFormControlValue(integralRemodelingForm.controls.surfaces, 'intermediate')">
            <i class="icon-price-award"></i>
            <span class="text">{{ 'Intermediate' | transloco }}</span>
          </div>
          <div class="project-simulation-type-container" [class.selected]="integralRemodelingForm.controls.surfaces.value === 'premium'" (click)="setFormControlValue(integralRemodelingForm.controls.surfaces, 'premium')">
            <i class="icon-trophy"></i>
            <span class="text">{{ 'Premium' | transloco }}</span>
          </div>
        </form>
      }
      <div class="right-container">
        <img class="illustration animate__fadeIn" [src]="selectedImage" alt="{{ 'Illustration of the selected option' | transloco }}" />
        <div class="continue-button-container">
          <app-button label="Continue" styleClass="primary expand" (action)="onSubmit()" [disabled]="isInvalid" />
        </div>
      </div>
    </div>
  </div>
</section>